export function JsonObjToQueryString(_object: object) {
  const keyValuePairs = [];

  for (let counter = 0; counter < Object.keys(_object).length; counter += 1) {
    keyValuePairs.push(
      `${encodeURIComponent(
        Object.keys(_object)[counter],
      )}=${encodeURIComponent(Object.values(_object)[counter])}`,
    );
  }

  return keyValuePairs.join("&");
}
