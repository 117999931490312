import React from "react";

import {
  Container,
  Box,
  Typography,
  Card,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import ContentListController, { Props } from "./ContentListController";
import { ListData } from "./domain/contentModeration.dto";
export default class ContentListWeb extends ContentListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cardData = (item: ListData) => {
    if (item.attributes.is_text_approved) {
      return (
        <Container
          data-test-id={`container-${item.id}`}
          style={webStyle.marginTop}
          maxWidth={"sm"}>
          <Typography variant="body1" component="div">
            {item.attributes.text_content}
          </Typography>
          {item.attributes.is_image_approved && item.attributes.image ? (
            <img
              src={item.attributes.image}
              style={webStyle.image}
              alt={item.attributes.text_content}
            />
          ) : (
            <Typography
              variant="body1"
              style={webStyle.errorMsg}
              component="div">
              {"Image they have uploaded is against guidelines."}
            </Typography>
          )}
        </Container>
      );
    } else {
      return (
        <Typography style={webStyle.errorMsg} variant="body1" component="div">
          {"Content they have uploaded is against guidelines."}
        </Typography>
      );
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="subtitle1" component="div">
              {"Content Analyse List"}
            </Typography>
            {this.state.listContent.map((item) => (
              <Card key={item.id.toString()} style={webStyle.cardView}>
                {this.cardData(item)}
              </Card>
            ))}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  cardView: {
    padding: "20px",
    margin: "10px",
    width: "600px",
  },
  image: {
    width: "400px",
    height: "400px",
  },
  errorMsg: {
    color: "red",
    fontSize: 12,
  },
  marginTop: {
    margin: "10px",
  },
};
// Customizable Area End
