import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import {
  Certificate,
  Employee,
  EmployeeCertificateResponse,
  EmployeeCertificateStatusItem,
} from "../types";
import { employeeCertificateStatusList } from "../constants";
import { handleResponseMessage } from "../helpers/handle-response-message";
import createRequestMessage from "../helpers/create-request-message";
import * as Yup from "yup";
import { getStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employeeCertId: number;
  employee: Employee;
  certificate: Certificate;
  employeeCertificateStatus: EmployeeCertificateStatusItem;
  employeeCertificateDate: Date | null;
  employeeCertValidityDate: Date | null;
  employeeCertIsActive: boolean;
  employeeList: Employee[];
  certificateList: Certificate[];
  token: string;
  isLoading: boolean;
  isEmployeeLoading: boolean;
  isCertificateLoading: boolean;
  isFetching: boolean;
  alertModal: boolean;
  titleModal: string;
  contentModal: string;
  success: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeCertCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeeListWebApi: string;
  getCertListWebApi: string;
  getEmployeeCertWebApi: string;
  createEmployeeCertApi: string;
  updateEmployeeCertApi: string;
  initialEmployeeCertId: number;
  defaultWebSchema: object;
  employeeCertCreateSchema: object;
  employeeCertUpdateSchema: object;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.initialEmployeeCertId =
      (this.props.navigation?.getParam("id") as number) ?? 0;

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      employeeCertId: this.initialEmployeeCertId ?? 0,
      isLoading: false,
      isEmployeeLoading: false,
      isCertificateLoading: false,
      employeeList: [],
      certificateList: [],
      token: "",
      employee: {},
      certificate: {},
      employeeCertificateDate: null,
      employeeCertValidityDate: null,
      employeeCertificateStatus: {},
      employeeCertIsActive: true,
      isFetching: false,
      alertModal: false,
      titleModal: "",
      contentModal: "",
      success: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.defaultWebSchema = {
      employee: Yup.number().required("Required"),
      certificate: Yup.number().required("Required"),
      status: Yup.number().required("Required"),
    };

    this.employeeCertCreateSchema = Yup.object().shape({
      ...this.defaultWebSchema,
    });

    this.employeeCertUpdateSchema = Yup.object().shape({
      ...this.defaultWebSchema,
      isActive: Yup.boolean(),
    });

    this.handleSubmitWeb = this.handleSubmitWeb.bind(this);
    this.handleGoBackWeb = this.handleGoBackWeb.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    this.getEmployeeListWebApi = "";
    this.getCertListWebApi = "";
    this.getEmployeeCertWebApi = "";
    this.createEmployeeCertApi = "";
    this.updateEmployeeCertApi = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const apiResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const apiErrorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      switch (apiResponse) {
        case this.getEmployeeListWebApi: {
          this.setState({
            isEmployeeLoading: false,
          });
          handleResponseMessage({
            responseJson: apiResponseJson,
            errorJson: apiErrorJson,
            onSuccess: () => {
              const { employees } = apiResponseJson;

              this.setState({
                employeeList: employees,
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Get Employee List Failed!",
              });
            },
          });
          break;
        }

        case this.getCertListWebApi: {
          this.setState({
            isCertificateLoading: false,
          });
          handleResponseMessage({
            responseJson: apiResponseJson,
            errorJson: apiErrorJson,
            onSuccess: () => {
              const { certificates } = apiResponseJson;

              this.setState({
                certificateList: certificates,
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Get Certification List Failed!",
              });
            },
          });
          break;
        }

        case this.getEmployeeCertWebApi: {
          handleResponseMessage({
            responseJson: apiResponseJson,
            errorJson: apiErrorJson,
            onSuccess: () => {
              this.handleResponseEmpCert(apiResponseJson);
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Get Failed!",
              });
            },
          });

          break;
        }

        case this.createEmployeeCertApi: {
          handleResponseMessage({
            responseJson: apiResponseJson,
            onSuccess: () => {
              this.setState({
                alertModal: true,
                titleModal: "Success",
                success: true,
                contentModal: "Employee-Certificate created successfully!",
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Create Failed!",
              });
            },
          });
          break;
        }

        case this.updateEmployeeCertApi: {
          handleResponseMessage({
            responseJson: apiResponseJson,
            errorJson: apiErrorJson,
            onSuccess: () => {
              this.setState({
                alertModal: true,
                titleModal: "Success",
                success: true,
                contentModal: "Employee-Certificate updated successfully!",
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Update Failed!",
              });
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleGetTokenEmployeeCertWeb();
  }

  async handleGetTokenEmployeeCertWeb() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        if (this.initialEmployeeCertId) {
          this.handleGetEmployeeCertDetailWeb();
        }
        this.handleGetCertListWeb();
        this.handleGetEmployeeListWeb();
      },
    );
  }
  handleResponseEmpCert(resp: EmployeeCertificateResponse) {
    if (resp.data) {
      this.setState({
        employee: resp.data.employee!,
        employeeCertId: resp.data.id!,
        certificate: resp.data.certificate!,
        employeeCertificateStatus: employeeCertificateStatusList.find(
          (item) => item.code === resp.data!.status,
        )!,
        employeeCertIsActive: resp.data.is_active!,
        employeeCertificateDate:
          resp.data.date !== null ? new Date(resp.data.date!) : null,
        employeeCertValidityDate:
          resp.data.validity_date !== null
            ? new Date(resp.data.validity_date!)
            : null,
        isFetching: false,
      });
    }
  }
  handleGetEmployeeListWeb() {
    this.setState({
      isEmployeeLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getEmployeeListWebApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.employeesURLEndPoint,
      method: configJSON.getApiMethodType,
      token: this.state.token,
    });
  }
  handleGetCertListWeb() {
    this.setState({
      isCertificateLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getCertListWebApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.certificatesURLEndPoint,
      method: configJSON.getApiMethodType,
      token: this.state.token,
    });
  }
  handleGetEmployeeCertDetailWeb() {
    this.setState({
      isLoading: true,
      isFetching: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getEmployeeCertWebApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.employeesCertificateURLEndPoint}/${this.state.employeeCertId}`,
      method: configJSON.getApiMethodType,
      token: this.state.token,
    });
  }

  handleCreateEmployeeCertWeb(requestBody: string) {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createEmployeeCertApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.employeesCertificateURLEndPoint}`,
      method: configJSON.postApiMethodType,
      token: this.state.token,
      body: requestBody,
    });
  }

  handleUpdateEmployeeCertWeb(requestBody: string) {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updateEmployeeCertApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.employeesCertificateURLEndPoint}/${this.state.employeeCertId}`,
      method: configJSON.putApiMethodType,
      token: this.state.token,
      body: requestBody,
    });
  }

  handleSubmitWeb(values: {
    employee: number;
    certificate: number;
    date: Date | null;
    validityDate: Date | null;
    status: number;
    isActive: boolean;
  }) {
    const { employee, certificate, date, validityDate, status, isActive } =
      values;

    const requestBody = {
      employee_certificate: {
        employee_id: employee,
        certificate_id: certificate,
        status: status,
        date: date ? date : undefined,
        validity_date: validityDate ? validityDate : undefined,
        is_active: isActive,
      },
    };

    if (this.initialEmployeeCertId) {
      this.handleUpdateEmployeeCertWeb(JSON.stringify(requestBody));
    } else {
      this.handleCreateEmployeeCertWeb(JSON.stringify(requestBody));
    }
  }

  handleCloseAlert() {
    this.setState({
      alertModal: false,
      titleModal: "",
      contentModal: "",
    });

    if (this.state.success) {
      this.props.navigation.navigate("EmployeeCertificateMappingList");
    }
  }

  handleGoBackWeb() {
    this.props.navigation.navigate("EmployeeCertificateMappingList");
  }
  // Customizable Area End
}
