import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { createRequest } from "./helpers/create-request";
import { handleResponse } from "./helpers/handle-response";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: string;
  email: string;
  password: string;
  passwordVisible: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginGoalWebApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      token: "",
      email: configJSON.loginEmail,
      password: configJSON.loginPassword,
      passwordVisible: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGoToGoalCreateWeb = this.handleGoToGoalCreateWeb.bind(this);
    this.handleGoToGoalRetrieveWeb = this.handleGoToGoalRetrieveWeb.bind(this);
    this.handleLoginGoalWeb = this.handleLoginGoalWeb.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleTogglePassword = this.handleTogglePassword.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiResponseWeb = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseWebJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorWebJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      if (apiResponseWeb === this.loginGoalWebApi) {
        handleResponse({
          responseJson: responseWebJson,
          errorJson: errorWebJson,
          onSuccess: () => {
            this.handleWebLoginSuccess(responseWebJson);
          },
          onFail: () => {
            this.showAlert(`Get Token Failed`, "Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authToken = await getStorageData("token", false);
    this.handleSetTokenWeb(authToken);
  }
  handleSetTokenWeb(authToken: string) {
    if (authToken) {
      this.setState({
        token: authToken,
      });
    }
  }
  handleLoginGoalWeb() {
    this.setState({
      isLoading: true,
    });

    const { email, password } = this.state;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.loginGoalWebApi = requestMessage.messageId;

    const body = {
      data: {
        attributes: {
          email: email,
          password: password,
        },
        type: "email_account",
      },
    };

    createRequest({
      requestMsg: requestMessage,
      endPoint: configJSON.loginURLEndPoint,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  }
  handleWebLoginSuccess(input: {
    meta: {
      id: number;
      token: string;
      refresh_token: string;
    };
  }) {
    const { token: respToken, id } = input.meta;
    const { email } = this.state;
    this.setState({
      token: respToken,
      isLoading: false,
    });
    setStorageData("token", respToken);
    setStorageData("loginEmail", email);
    setStorageData("accountId", id.toString());
  }
  handleGoToGoalCreateWeb() {
    this.props.navigation.navigate("GoalCreate");
  }
  handleGoToGoalRetrieveWeb() {
    this.props.navigation.navigate("GoalRetrieve");
  }

  handleChangeEmail(event: { target: { value: string } }) {
    this.setState({
      email: event.target.value,
    });
  }
  handleChangePassword(event: { target: { value: string } }) {
    this.setState({
      password: event.target.value,
    });
  }
  handleSignOut() {
    this.setState({
      token: "",
    });
    removeStorageData("token");
    removeStorageData("loginEmail");
    removeStorageData("accountId");
  }
  handleTogglePassword() {
    let { passwordVisible } = this.state;
    this.setState({ passwordVisible: !passwordVisible });
  }
  // Customizable Area End
}
