import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
const urlToBlob = require("urltoblob-ext");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  title: string;
  width: string;
  height: string;
  videoRes: File | string;
  section: string;
  x: string;
  y: string;
  isLoader: boolean;
  page: string;
  videopath: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoInputController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      title: "",
      width: "",
      height: "",
      videoRes: "",
      section: "",
      x: "",
      y: "",
      isLoader: false,
      page: "",
      videopath: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  apiUploadVideoCallId: string = "";
  fileInputRef: HTMLInputElement | null = null;
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      if (apiRequestCallId !== null) {
        if (apiRequestCallId === this.apiUploadVideoCallId) {
          if (responseJson) {
            this.setState({
              isLoader: false,
              title: "",
              width: "",
              height: "",
              x: "",
              y: "",
              videopath: "",
              videoRes: "",
              section: "",
            });
            this.props.navigation.navigate("VideoPageList");
          }
        }
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeValues = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target ?? { name: "", value: "" };
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  handleFileInputRef = (reference: HTMLInputElement | null) => {
    this.fileInputRef = reference;
  };

  handleChooseFileClick = () => {
    if (this.fileInputRef && typeof this.fileInputRef.click === "function") {
      this.fileInputRef.click();
    }
  };

  handleUploadInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const uploadedFile = event.target.files && event.target.files[0];
    if (uploadedFile) {
      const blob = await urlToBlob.urlToBlob([uploadedFile], {
        type: uploadedFile.type,
      });
      const fileUrl = URL.createObjectURL(blob);
      this.setState({ videopath: fileUrl, videoRes: uploadedFile });
    }
  };

  async uploadVideo() {
    const { title, page, width, height, x, y, videoRes } = this.state;
    const variables = [
      { name: title, message: "Please add title" },
      { name: page, message: "Please add page" },
      { name: width, message: "Please add width" },
      { name: height, message: "Please add height" },
      { name: x, message: "Please add x" },
      { name: y, message: "Please add y" },
      { name: videoRes, message: "Please Upload Video" },
    ];

    for (const variable of variables) {
      if (!variable.name) {
        this.showAlert("Warning", variable.message);
        return;
      }
    }

    await this.uploadData();
  }

  uploadData = async () => {
    let token = await getStorageData("Token");
    const header = {
      token: token,
    };

    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("video", this.state.videoRes);
    formData.append("page", this.state.page);
    formData.append("section", this.state.section);
    formData.append("scale_w", this.state.width);
    formData.append("scale_h", this.state.height);
    formData.append("scale_x", this.state.x);
    formData.append("scale_y", this.state.y);

    this.setState((prevState) => ({ ...prevState, loading: true }));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.apiUploadVideoCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadVideoEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
