import React from "react";

import {
  Box,
  Button,
  Chip,
  Container,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loader from "../../../components/src/Loader.web";
import WikiCreateController, { Props } from "./WikiCreateController.web";

// Customizable Area Start
const dropDownData = [
  { label: "jQuery", value: "jQuery" },
  { label: "Script", value: "Script" },
  { label: "Net", value: "Net" },
];
const theme = createTheme({
  palette: {
    primary: {
      main: "#000040",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class WikiCreate extends WikiCreateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  toolBarOption = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "emoji",
      "image",
      "remove",
      "history",
    ],
    image: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: false,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: this.uploadImageCallBack,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "50px",
        width: "50px",
      },
    },
  };

  // Customizable Area Start
  _renderChips = () =>
    this.state.wikiTags.length !== 0 ? (
      this.state.wikiTags.map((item, index) => (
        <Chip
          data-test-id={"chips-" + index}
          key={item}
          size="small"
          style={webStyle.chips}
          label={item}
          onDelete={() => this.handleDelete(item)}
        />
      ))
    ) : (
      <p>Select Wiki Tags</p>
    );

  // Customizable Area End

  render() {
    if (this.state.loading) {
      return <Loader loading />;
    }
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.mainWrapperModal}>
              <TextField
                variant="outlined"
                data-test-id="wiki-name"
                placeholder="Enter wiki name"
                style={webStyle.textInputs}
                value={this.state.wikiName}
                onChange={(event: { target: { value: string } }) =>
                  this.handleOnChange(event.target.value, "wikiName")
                }
              />
              <Box sx={webStyle.dropDownBox}>
                <Select
                  variant="outlined"
                  style={webStyle.textInputs}
                  data-test-id={"selectTagsTest"}
                  value={""}
                  onChange={(
                    event: React.ChangeEvent<{ name?: string; value: unknown }>,
                  ) => this.handleWikiTagChange(event)}>
                  {dropDownData.map((item) => (
                    <MenuItem key={item.label} value={item.label}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={webStyle.chipsStyle}>{this._renderChips()}</Box>
              </Box>
              <Box sx={webStyle.editorStyles} component="section">
                <Editor
                  data-test-id="html-editor"
                  editorState={this.state.editorState}
                  toolbar={this.toolBarOption}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                  placeholder={"HTML Editor"}
                  editorStyle={webStyle.editorElementStyles}
                />
              </Box>
              <Box sx={webStyle.buttonView}>
                <Button
                  data-test-id={"buttonSubmit"}
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}>
                  {this.initialId > 0 ? "Update" : "Save"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  mainWrapperModal: {
    maxHeight: "500",
  },
  textInputs: {
    width: "100%",
    marginBottom: 10,
    marginTop: 10,
    p: "10px 20px",
  },
  chipsStyle: {
    position: "absolute",
    top: "50%",
    left: "2%",
    transform: "translate(0%, -50%)",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: 4,
  },
  editorStyles: {
    border: "1px solid black",
    width: "100%",
    minHeight: "10rem",
    zIndex: -100,
  },
  editorElementStyles: {
    minHeight: "13rem",
  },
  chips: {
    marginLeft: 4,
  },
  buttonView: {
    marginTop: 10,
  },
  dropDownBox: {
    width: "100%",
    position: "relative",
  },
};
// Customizable Area End
