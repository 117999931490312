import React from "react";

import {
  Box,
  CircularProgress,
  Container,
  List,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import EmployeeListController, { Props } from "./EmployeeListController.web";
import { Employee } from "../types";
import FloatingButtonWeb from "../components/FloatingButton/FloatingButton.web";
import EmployeeListItemWeb from "../components/EmployeeListItem/EmployeeListItem.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const ListContainer = styled(List)({
  width: "100%",
});
// Customizable Area End

export default class EmployeeList extends EmployeeListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { employees, isLoading } = this.state;
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <FloatingButtonWeb
            buttonType={"backward"}
            onClick={this.handleGoBack}
          />
          <Container maxWidth={"sm"} data-test-id={"containerId"}>
            <Box sx={webSxStyle.mainWrapperStyle}>
              <Typography variant="h6">
                Employee List{" "}
                {isLoading && <CircularProgress color="inherit" />}
              </Typography>
            </Box>
            <ListContainer>
              {employees.length > 0 &&
                employees.map((item: Employee) => (
                  <EmployeeListItemWeb
                    dataTestId={"listItemId"}
                    key={item.id}
                    employee={item}
                    onClick={() => this.handleEmployeeUpdate(item)}
                  />
                ))}
            </ListContainer>
          </Container>
        </ThemeProvider>
        <FloatingButtonWeb
          buttonType={"add"}
          onClick={this.handleNavigateToEmployeeCreate}
        />
      </>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const webSxStyle = {
  mainWrapperStyle: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};
// Customizable Area End
