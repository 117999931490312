import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type Field = "title" | "description" | "amount";

interface S {
  // Customizable Area Start
  title: string;
  description: string;
  amount: string;
  fundraiserDetails: string;
  errors: Record<Field, string>;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FundraiserCreationFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  #token: string | null = null;

  fundraiserRequestId: string = "";

  fundraiserInfoRequestId: string = "";

  fundraiserId: string = "";
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      title: "",
      description: "",
      amount: "",
      fundraiserDetails: "",
      errors: {
        title: "",
        amount: "",
        description: "",
      },
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.fundraiserId = this.props.navigation.getParam("fundraiserId");
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.#token = await getStorageData("TOKEN");

    if (!this.#token) {
      this.props.navigation.navigate("PledgeTracking");
    }

    if (this.fundraiserId) {
      await this.getFundDetails(this.fundraiserId);
    }

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (responseJson !== null && responseJson.error) {
        this.showAlert("Error", responseJson.error);
        return;
      }

      if (this.fundraiserRequestId === apiRequestCallId) {
        this.handleSaveFundraiserResponse(
          responseJson.requests.data.id,
          responseJson.message,
        );
      }

      if (this.fundraiserInfoRequestId === apiRequestCallId) {
        const { amount, description, title } =
          responseJson.requests.data.attributes;

        this.setState({
          loading: false,
          amount,
          description,
          title,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSaveFundraiserResponse(fundraiserId: string, message: string) {
    if (!fundraiserId) {
      this.showAlert("Error", message);
      return;
    }

    this.props.navigation.navigate("FundraiserDetails", {
      fundraiserId,
    });
  }

  updateFormValue<T extends string>(fieldName: keyof S, value: T) {
    this.setState(
      ({ errors: errorsPrev }) =>
        ({
          [fieldName]: value,
          errors: { ...errorsPrev, [fieldName]: "" },
        } as unknown as Pick<S, keyof S>),
    );
  }

  onChangeTitle(value: string) {
    this.updateFormValue("title", value);
  }

  onChangeDescription(value: string) {
    this.updateFormValue("description", value);
  }

  onChangeAmount(value: string) {
    this.updateFormValue("amount", value);
  }

  isStringNullOrBlank(value: string) {
    return value === null || value.length === 0;
  }

  setFieldError(fieldName: Field, error?: string) {
    this.setState(({ errors: errorsPrev }) => ({
      errors: {
        ...errorsPrev,
        [fieldName]: error || configJSON.fieldIsRequiredMessage,
      },
    }));
  }

  async createFundraising() {
    const errorFields = (
      ["title", "description", "amount"] as Array<Field>
    ).filter((fieldName) => this.isStringNullOrBlank(this.state[fieldName]));
    const isAmountNumber = Boolean(
      this.state.amount.match(configJSON.currencyRegExp),
    );

    if (errorFields.length) {
      errorFields.forEach((errorField) => this.setFieldError(errorField));

      return false;
    }

    if (!isAmountNumber) {
      this.setFieldError("amount", configJSON.notNumber);

      return;
    }

    const header = {
      token: this.#token,
    };

    const formData = new FormData();
    formData.append("amount", this.state.amount);
    formData.append("title", this.state.title);
    formData.append("description", this.state.description);

    const requestMethod = this.fundraiserId ? "PATCH" : "POST";

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.fundraiserRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreatingfundreaiserAPIEndPoint}/${this.fundraiserId || ""}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async getFundDetails(fundraiserId: string) {
    this.setState({ loading: true });

    const headers = {
      token: this.#token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.fundraiserInfoRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetFundraiser}/${fundraiserId}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetFundraiser,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  redirectBack() {
    this.props.navigation.goBack();
  }
}

// Customizable Area End
