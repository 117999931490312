import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { GraphData, SpendItemSelect } from "./types";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  NewExpanse: boolean;
  token: string;
  NewExpanseName: string;
  NewExpanseAmount: number | string;
  NewExpanseDate: string;
  totalSpends: number;
  allSpends: Array<object>;
  transactions: Array<object>;
  totalTransaction: Array<object>;
  expensesLabels: Array<object>;
  TransactionLimitedResponse: Transaction[];
  TranPageNo: number;
  deleteTransaction: boolean;
  deleteTransactionID: string | null;
  EditTransactionId: string | null;
  authToken: string;
  date: string;
  transactionName: string;
  spendNameArrayWithId: SpendItemSelect[];
  selectSpendItemID: string | number;
  spendItemIDSelect: {
    id: number | string;
    attributes: {
      name: string;
    };
  };
  // Customizable Area End
}
interface Transaction {
  id: string;
  type: string;
  attributes: TransactionAttributes;
}

interface TransactionAttributes {
  account_id: number;
  amount: number;
  created_at: string;
  name: string;
  updated_at: string;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SpendAnalysisController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subScribedMsg: string[];
  getTotalAmount: string | undefined;
  loginApiCallId: string | undefined;
  postNewSpends: string | undefined;
  viewTransactionsFirstPage: string | undefined;
  removeTransactionApiCallId: string | undefined;
  updateExpensesApiRequest: string | undefined;
  viewUserExpense: string | undefined;
  getAllTransactionList: string | undefined;
  getSpendItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMsg = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      NewExpanse: false,
      NewExpanseName: "",
      NewExpanseAmount: 0,
      NewExpanseDate: "",
      allSpends: [],
      totalSpends: 0,
      transactions: [],
      totalTransaction: [],
      expensesLabels: [],
      TransactionLimitedResponse: [],
      TranPageNo: 1,
      deleteTransaction: false,
      deleteTransactionID: null,
      EditTransactionId: null,
      authToken: "",
      date: new Date().toISOString().slice(0, 10),
      transactionName: "",
      spendNameArrayWithId: [
        {
          id: "Uncatergorised",
          attributes: {
            name: "Uncatergorised",
          },
        },
      ],
      selectSpendItemID: "Uncatergorised",
      spendItemIDSelect: {
        id: "Uncategorized",
        attributes: {
          name: "Uncatergorised",
        },
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMsg);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.signin();
  }

  receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (!responseJson?.errors) {
        switch (apiRequestCallId) {
          case this.loginApiCallId: {
            this.setState(
              {
                authToken: responseJson?.meta.token,
              },
              () => {
                this.totalSpends();
                this.transactionSpends();
                this.transactionsApiFirstPage(this.state.TranPageNo);
                this.spendItemList();
              },
            );
            break;
          }
          case this.getTotalAmount: {
            this.setState({
              totalSpends: responseJson.total_spends,
            });
            const array: Array<Object> = [
              ["", "Amount"],
              ...responseJson.spend_trend.map((element: GraphData) => [
                element.spend_item?.name,
                parseInt(element.total_amount as string, 10),
              ]),
            ];
            this.setState({ expensesLabels: array });
            break;
          }
          case this.getAllTransactionList: {
            this.setState({
              totalTransaction: responseJson.data,
            });
            break;
          }
          case this.getSpendItemCallId:
            this.setState({
              spendNameArrayWithId: [
                ...this.state.spendNameArrayWithId,
                ...responseJson.data,
              ],
            });
            this.setState({
              spendNameArrayWithId: [
                ...this.state.spendNameArrayWithId,
                ...responseJson.data,
              ],
            });
            break;
          case this.postNewSpends: {
            this.totalSpends();
            this.showAlert(`Success`, "New Spend Added Successfully");
            this.transactionsApiFirstPage(this.state.TranPageNo);
            break;
          }
          case this.removeTransactionApiCallId: {
            this.setState({
              deleteTransaction: false,
              deleteTransactionID: null,
            });
            this.showAlert(`Success`, "Spend Deleted Successfully");
            this.transactionsApiFirstPage(this.state.TranPageNo);
            this.totalSpends();
            break;
          }
          case this.updateExpensesApiRequest: {
            this.setState({
              NewExpanseName: "",
              NewExpanseAmount: 0,
              NewExpanseDate: "",
              spendItemIDSelect: {
                id: "Uncatergorised",
                attributes: {
                  name: "Uncatergorised",
                },
              },
            });
            this.setState({ ...this.state, NewExpanse: false });
            this.showAlert(`Success`, "Spend Updated Successfully");
            this.transactionsApiFirstPage(this.state.TranPageNo);
            this.totalSpends();
            break;
          }
          case this.viewUserExpense: {
            let updatedData = responseJson.data.attributes;
            let resAmount = updatedData.amount;
            let convertAmount = resAmount.toString();
            this.setState(
              {
                NewExpanseName: updatedData.name,
                NewExpanseAmount: convertAmount,
                NewExpanseDate: updatedData.updated_at.slice(0, 10),
                spendItemIDSelect: {
                  id: updatedData.spend_item_id,
                  attributes: {
                    name: updatedData.name,
                  },
                },
              },
              () => {
                this.setState({ NewExpanse: true });
              },
            );
            this.setState(
              {
                NewExpanseName: updatedData.name,
                NewExpanseAmount: convertAmount,
                NewExpanseDate: updatedData.updated_at.slice(0, 10),
                spendItemIDSelect: {
                  id: updatedData.spend_item_id,
                  attributes: {
                    name: updatedData.name,
                  },
                },
              },
              () => {
                this.setState({ NewExpanse: true });
              },
            );
            break;
          }
          case this.viewTransactionsFirstPage: {
            this.setState({
              TransactionLimitedResponse: responseJson.data,
            });
            break;
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSpendItemChange = (value: number | string) => {
    this.setState({ selectSpendItemID: value });
  };

  handleExpanseClose = () => {
    this.setState({
      NewExpanse: false,
      EditTransactionId: null,
      NewExpanseAmount: "",
      NewExpanseName: "",
      NewExpanseDate: "",
      spendItemIDSelect: {
        id: "Uncatergorised",
        attributes: {
          name: "Uncatergorised",
        },
      },
    });
  };

  handleDeleteTransaction = (deletedId: string, transactionName: string) => {
    this.setState({
      deleteTransaction: true,
      deleteTransactionID: deletedId,
      transactionName: transactionName,
    });
  };

  handleDeleteTransactionClose = () => {
    this.setState({ deleteTransaction: false, deleteTransactionID: null });
  };

  deleteTransactionMethod = (removeId: string | null) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.removeTransactionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTransactionsEndPoint + removeId,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  signin = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleExpanseOpen = () => {
    this.setState({ ...this.state, NewExpanse: true });
  };

  createNewName = (createEventName: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseName: createEventName.target.value });
  };

  createNewAmount = (createEventAmount: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseAmount: createEventAmount.target.value });
  };

  CreateNewDate = (createEventDate: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseDate: createEventDate.target.value });
  };

  totalSpends = () => {
    const header = {
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getTotalAmount = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTotalSpendEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  transactionSpends = () => {
    const header = {
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getAllTransactionList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSpendEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  spendItemList = () => {
    const header = {
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getSpendItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getspendItemListEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGet,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTransactionPage(event: object, newPagTransaction: number) {
    this.setState({ ...this.state, TranPageNo: newPagTransaction });
    this.transactionsApiFirstPage(newPagTransaction);
  }

  transactionsApiFirstPage = (pageNumber: number) => {
    const header = {
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewTransactionsFirstPage = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSpendEndPoint +
        pageNumber +
        configJSON.getAllSpendEndPointEnd,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  putExpensesApi = () => {
    this.setState({ EditTransactionId: null });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const body = {
      data: {
        attributes: {
          name: this.state.NewExpanseName,
          amount: this.state.NewExpanseAmount,
          date: this.state.NewExpanseDate,
          spend_item_id:
            this.state.selectSpendItemID === 0 ||
            this.state.selectSpendItemID === "Uncatergorised" ||
            this.state.selectSpendItemID === "NaN"
              ? null
              : this.state.selectSpendItemID,
        },
      },
    };

    this.updateExpensesApiRequest = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSpendApi + this.state.EditTransactionId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditTransaction = (editId: string) => {
    this.getEditedUserDetails(editId);
    this.setState({ EditTransactionId: editId });
  };

  getEditedUserDetails = (editId: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewUserExpense = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpendTransactionById + editId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkAllDataFilled = () => {
    if (
      this.state.NewExpanseName === "" ||
      this.state.NewExpanseAmount === ""
    ) {
      this.showAlert("Error", "All fields are mandatory");
    } else {
      this.createNewSpend();
      this.transactionsApiFirstPage(this.state.TranPageNo);
      this.totalSpends();
      this.handleExpanseClose();
    }
  };
  createNewSpend = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    const body = {
      data: {
        attributes: {
          name: this.state.NewExpanseName,
          amount: this.state.NewExpanseAmount,
          created_at: "",
          spend_item_id:
            this.state.selectSpendItemID === 0 ||
            this.state.selectSpendItemID === "Uncatergorised" ||
            this.state.selectSpendItemID === "NaN"
              ? null
              : this.state.selectSpendItemID,
        },
      },
    };
    this.postNewSpends = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postNewSpendsEndpoints,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  options = {
    chart: {
      title: "Company Performance",
    },
  };

  navigateToSpendItem = () => {
    this.props.navigation.navigate("SpendItem");
  };
  // Customizable Area End
}
