import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";

import { Revision } from "./Types";
import createRequestMessage from "./helpers/create-request-message";
import { handleResponseMessage } from "./helpers/handle-response-message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  revisions: Revision[];
  loading: boolean;
  authToken: string;
  isModalOpen: boolean;
  wikiID: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WikiRevisionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRevision_callID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      revisions: [],
      loading: false,
      authToken: "",
      isModalOpen: false,
      wikiID: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      if (responseData?.wiki_id) {
        this.setState({ wikiID: responseData.wiki_id }, () => {
          if (this.isPlatformWeb()) {
            this.getAllRevisions();
          }
        });
      } else if (responseData?.errors) {
        this.showAlert("Error", "Problem with navigation");
      }
    }

    const apiRequestMessageID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    const responseDataJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    const errorDataJson = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );

    if (apiRequestMessageID === this.getRevision_callID) {
      handleResponseMessage({
        responseJson: responseDataJson,
        errorJson: errorDataJson,
        onSuccess: () => {
          const revisions = responseDataJson?.data;
          this.setState({
            revisions: revisions,
            loading: false,
          });
        },
        onFail: () => {
          this.setState({
            loading: false,
          });
          this.showAlert(`Error`, "Get Revision Failed.");
        },
      });
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getAllRevisions = () => {
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const getRevision = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRevision_callID = getRevision.messageId;
    const endPoint = `${configJSON.Wiki_EndPoint}/${this.state.wikiID}/wiki_revisions`;
    this.setState({ loading: true });
    createRequestMessage({
      endPoint: endPoint,
      requestMessage: getRevision,
      method: configJSON.getApiMethod,
      header: headers,
    });
  };

  // to extract the key
  _keyExtractor = (item: { id: number }) => `${item.id}`;

  // show the current revision data
  currentItemShown = (currentItemID: number) => {
    const revisions = this.state.revisions?.map((item) => {
      if (item.id === currentItemID) {
        item.is_shown = !item.is_shown;
      } else {
        item.is_shown = false;
      }
      return item;
    });
    this.setState({
      revisions,
      loading: false,
    });
  };

  async componentDidMount() {
    let token = await storage.get("token");
    if (token) {
      this.setState({ authToken: token }, () => {
        if (!this.isPlatformWeb()) {
          this.getAllRevisions();
        }
      });
    }
  }
  // Customizable Area End
}
