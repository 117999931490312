import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Pagination } from "@material-ui/lab";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import LeaveRequestController, { Props } from "./LeaveRequestController.web";
import Button from "@material-ui/core/Button";
import Loader from "../../../components/src/Loader";
// Customizable Area End

export default class LeaveRequest extends LeaveRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedLeaveStatus, searchText } = this.state;
    return (
      <div style={styles.container} data-test-id="container">
        <div style={styles.header}>
          <div style={styles.title}>Leave Request</div>
        </div>
        <div style={styles.searchContainer}>
          <TextField
            {...this.searchTextInputData}
            style={styles.searchTextInput}
            id="input-with-icon-textfield"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            data-test-id="searchLeave"
          />
        </div>
        <Button
          style={styles.allEmployeesLeaveDataBtn}
          {...this.navigateToAllEmployeeLeaveList}>
          All Employees leave Data
        </Button>
        <div style={styles.leaveStatus}>
          <Button
            data-test-id="pendingLeaveStatus"
            style={
              selectedLeaveStatus === "Pending"
                ? styles.selected
                : styles.selectedRejected
            }
            {...this.listLeaveStatusPending}>
            <div
              style={
                selectedLeaveStatus === "Pending"
                  ? styles.leaveStatusTextSelected
                  : styles.leaveStatusText
              }>
              Pending
            </div>
          </Button>
          <Button
            data-test-id="approvedLeaveStatus"
            style={
              selectedLeaveStatus === "Approved"
                ? styles.selected
                : styles.selectedRejected
            }
            {...this.listLeaveStatusApproved}>
            <div
              style={
                selectedLeaveStatus === "Approved"
                  ? styles.leaveStatusTextSelected
                  : styles.leaveStatusText
              }>
              Approved
            </div>
          </Button>
          <Button
            data-test-id="rejectedLeaveStatus"
            style={
              selectedLeaveStatus === "Rejected"
                ? styles.selected
                : styles.selectedRejected
            }
            {...this.listLeaveStatusRejected}>
            <div
              style={
                selectedLeaveStatus === "Rejected"
                  ? styles.leaveStatusTextSelected
                  : styles.leaveStatusText
              }>
              Rejected
            </div>
          </Button>
        </div>
        <div style={styles.cardsContainer}>
          {this.state.isLoading && <Loader loading={this.state.isLoading} />}
          {!this.state.isLoading &&
            this.state.leaveListData?.map?.((item) => {
              return (
                <div key={item.id} style={styles.cardContainer}>
                  <div style={styles.rowJustify1}>
                    <div style={styles.row}>
                      <img
                        style={styles.userImage}
                        src={""}
                        alt={`Profile Photo - ${item.attributes.employee_name}`}
                      />
                      <div style={styles.info}>
                        <div style={styles.name}>{item.attributes.employee_name}</div>
                        <div style={styles.designation}>Designer</div>
                      </div>
                    </div>
                    <div style={styles.row}>
                      <CalendarIcon fontSize="small" />
                      <div style={styles.date}>
                        {this.getDateValue(item.attributes.created_at)}
                      </div>
                    </div>
                  </div>

                  <div style={styles.rowJustify}>
                    <div>
                      <div style={styles.name}>Start Date</div>
                      <div style={styles.startDateText}>
                        {this.getDateValue(item.attributes.start_date)}
                      </div>
                    </div>
                    <div>
                      <div style={styles.name}>End Date</div>
                      <div style={styles.startDateText}>
                        {this.getDateValue(item.attributes.end_date)}
                      </div>
                    </div>
                    <div>
                      <div style={styles.name}>Leave Type</div>
                      <div style={styles.startDateText}>
                        {item.attributes.leave_type}
                      </div>
                    </div>
                  </div>
                  <div style={styles.rowJustify}>
                    {
                      item.attributes.leave_reason !== null &&
                      <div>
                        <div style={styles.name}>Reason for Leave</div>
                        <div style={styles.startDateText}>
                          {item.attributes.leave_reason}
                        </div>
                      </div>
                    }
                    <div style={styles.marginRight}>
                      <div style={styles.name}>Leave Name</div>
                      <div style={styles.startDateText}>
                        {item.attributes.name}
                      </div>
                    </div>
                  </div>
                  {item.attributes.leave_status === "Pending" && (
                    <div style={styles.leaveSetContainer}>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={styles.btnReject}
                        {...this.rejectButtonProps(item)}
                        data-test-id="rejectedBtn">
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        style={styles.btnAccept}
                        {...this.approveButtonProps(item)}
                        data-test-id="approveBtn">
                        Approve
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <BottomPagination
          data-test-id="paginationButton"
          count={this.state.totalPageWeb}
          page={this.state.pageIndexWeb}
          onChange={this.handleChangePage}
          shape="round"
        />
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    background: "#cccccc",
    fontFamily: "Roboto-Medium",
    paddingBottom: "30px",
    height: "calc(100vh - 140px)",
  },
  cardsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    width: "100%",
    overflow: "auto",
  },
  btnAccept: {
    width: 135,
    height: 36,
    backgroundColor: "green",
    color: "white",
  },
  btnReject: {
    width: 135,
    height: 36,
  },
  img: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  leaveSetContainer: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    marginTop: 24,
  },
  startDateText: {
    fontSize: 14,
    fontWeight: "normal" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#9090ad",
  },
  date: {
    fontWeight: "normal" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#9090ad",
    marginLeft: 9,
  },
  dateIcon: {
    width: 15,
    height: 17,
    resizeMode: "contain",
  },
  info: {
    marginLeft: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
  },
  rowJustify: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  rowJustify1: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    paddingBottom: 16,
    borderBottom: "1px solid #cccccc",
  },
  userImage: {
    width: 36,
    height: 36,
    borderRadius: "50%",
    borderColor: "#000",
    borderWidth: "1px solid black",
  },
  designation: {
    fontSize: 14,
    fontWeight: "normal" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#9090ad",
  },
  name: {
    fontSize: 16,
    fontWeight: "bold" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#22215b",
    marginBottom: 4,
  },
  cardContainer: {
    width: "40%",
    backgroundColor: "#ffffff",
    shadowColor: "#00000014",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    elevation: 8,
    shadowRadius: 40,
    shadowOpacity: 1,
    borderRadius: 6,
    padding: 24,
    marginBottom: 48,
    boxSizing: "border-box" as const,
  },
  selectedRejected: {
    width: 109,
    height: 44,
    borderRadius: 22,
    justifyContent: "center",
    alignItems: "center",
  },
  leaveStatusTextSelected: {
    fontSize: 14,
    fontWeight: "bold" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center" as const,
    color: "#ffffff",
  },
  leaveStatusText: {
    fontSize: 14,
    fontWeight: "normal" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center" as const,
    color: "#697b7a",
  },
  selected: {
    width: 109,
    height: 44,
    borderRadius: 22,
    backgroundColor: "#826ffc",
    justifyContent: "center",
    alignItems: "center",
  },
  allEmployeesLeaveDataBtn: {
    width: "40%",
    height: 44,
    borderRadius: 12,
    backgroundColor: "#826ffc",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 24,
    color: "white",
  },
  leaveStatus: {
    width: "40%",
    height: 44,
    borderRadius: 22,
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    borderColor: "#eee",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 24,
  },
  searchContainer: {
    height: 44,
    borderRadius: 22,
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row" as const,
    borderColor: "#eee",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 24,
    width: "40%",
    paddingLeft: 7,
    paddingRight: 7,
  },
  searchIcon: {
    width: 16,
    height: 16,
    resizeMode: "contain",
  },
  searchTextInput: {
    fontSize: 13,
    fontWeight: "normal" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#697b7a",
    width: "100%",
  },
  header: {
    display: "flex",
    backgroundColor: "#826ffc",
    height: 56,
    justifyContent: "center",
    flexDirection: "row" as const,
    alignItems: "center",
    width: "100%",
    marginBottom: 24,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center" as const,
    color: "#ffffff",
  },
  marginRight: { marginRight: "45%" }
};

const BottomPagination = styled(Pagination)({
  marginTop: 16,
  display: "flex",
  justifyContent: "center",
});
// Customizable Area End
