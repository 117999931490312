import { GoalStatusNumber, GoalStatusObject, Team } from "./types";

export const goalStatusList: string[] = ["tracked", "advanced", "delayed"];

export const goalStatusObject: GoalStatusObject = {
  tracked: {
    id: GoalStatusNumber.TRACKED,
    code: "tracked",
    name: "Tracked",
  },
  advanced: {
    id: GoalStatusNumber.ADVANCED,
    code: "advanced",
    name: "Advanced",
  },
  delayed: {
    id: GoalStatusNumber.DELAYED,
    code: "delayed",
    name: "Delayed",
  },
};

export const individualGoal = {
  id: 0,
  name: "Individual",
};

export const mockGoalTeamData: Team[] = [
  {
    id: 0,
    name: "individual",
    settings: null,
    accounts: [{}, {}],
  },
  {
    id: 1,
    name: "team1",
    settings: null,
    accounts: [{}, {}],
  },
  {
    id: 2,
    name: "team2",
    settings: null,
    accounts: [{}, {}],
  },
  {
    id: 3,
    name: "team3",
    settings: null,
    accounts: [{}, {}],
  },
];
