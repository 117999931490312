import React from "react";

import {
  Container,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LeaveTrackerController, { Props } from "./LeaveTrackerController.web";

export default class LeaveTracker extends LeaveTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={webStyle.mainWrapper} maxWidth={"sm"}>
          <div>
            <Button
              onClick={() => this.navigateToAdminScreen()}
              style={webStyle.buttonStyle}
              color={"primary"}
              data-test-id={"navigateAdminBtn"}>
              Navigate as Admin
            </Button>
          </div>
          <div>
            <Button
              onClick={() => this.navigateToUserScreen()}
              style={webStyle.buttonStyle}
              color={"primary"}
              data-test-id={"navigateUserBtn"}>
              Navigate as User
            </Button>
          </div>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as const,
    alignItems: "center",
    paddingBottom: "30px",
    flex: 1,
    background: "#fff",
    justifyContent: "center" as const,
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
