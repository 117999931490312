import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WhiteTheme } from "./WhiteTheme";
import createRequestMessage from "framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "framework/src/Helpers/handle-response-message";
import { setStorageData } from "framework/src/Utilities";
import { Platform } from "react-native";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export const imageEditorProps = {
  includeUI: {
    loadImage: {
      path: "",
      name: "logo",
    },
    uiSize: {
      width: "100%",
      height: "700px",
    },
    menu: [
      "crop",
      // "flip",
      // "rotate",
      "draw",
      "shape",
      "icon",
      "text",
      // "filter",
    ],
    menuBarPosition: "bottom",
    theme: WhiteTheme,
  },
  cssMaxWidth: 1200,
  cssMaxHeight: 800,
  selectionStyle: {
    cornerSize: 20,
    rotatingPointOffset: 70,
  },
};

export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  filesize: number;
  filename: string;
  is_active: boolean;
  account_id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string | null;
  logo: Logo[];
}

export interface Logo {
  id: number;
  filename: string;
  url: string;
  type: string;
}

export interface Props {
  navigation: any;
  id: string;
  path: string;
  colors?: string[];
  stickers?: string[];
  hiddenControls?: string[];
}

interface S {
  logoList: Root[];
  imageId: number;
  image: string;
  imageBackGround: string;
  token: string;
  loader: boolean;
  isUserLogo: boolean;
  isShow: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class LogoDesign2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ImageUploadApiID: string;
  loginApiCallId: string;
  logoListApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.doButtonPressed = this.doButtonPressed.bind(this);
    this.signIn = this.signIn.bind(this);
    this.getLogoList = this.getLogoList.bind(this);
    this.userLogoList = this.userLogoList.bind(this);
    this.ImageUploadApiID = "";
    this.loginApiCallId = "";
    this.logoListApiCallId = "";

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      isUserLogo: false,
      loader: false,
      isShow: false,
      logoList: [],
      image: "",
      imageId: 0,
      imageBackGround: "white",
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      switch (apiRequestCallId) {
        case this.loginApiCallId: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: async () => {
              this.setState({
                token: responseJson.meta.token,
                loader: false,
              });
              this.getLogoList();
              await setStorageData("TOKEN", responseJson.meta.token);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Token Failed. Please retry!");
              this.setState({
                loader: false,
              });
            },
          });
          break;
        }

        case this.logoListApiCallId: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: async () => {
              this.setState({
                logoList: responseJson.data,
                loader: false,
              });
              await setStorageData("LogoList", responseJson.data);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Logo List Failed. Please retry!");
              this.setState({
                loader: false,
              });
            },
          });
          break;
        }

        case this.ImageUploadApiID: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                loader: false,
              });
              this.state.isUserLogo ? this.userLogoList() : this.getLogoList();
              this.showAlert(`Uploaded`, "Logo Uploaded Succesfully!");
            },
            onFail: () => {
              this.setState({
                loader: false,
              });
              this.showAlert(`Error`, "Update Logo Failed. Please retry!");
            },
          });
          break;
        }
      }
    }
  }

  signIn = () => {
    this.setState({
      loader: true,
    });
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.loginApiCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: "bx_block_login/logins",
      method: "POST",
      body: JSON.stringify(body),
    });
  };

  getLogoList = () => {
    this.setState({
      loader: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.logoListApiCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: "bx_block_logo_design/logo_design",
      method: "GET",
      token: this.state.token,
    });
  };

  userLogoList = () => {
    this.setState({
      loader: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.logoListApiCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: "bx_block_logo_design/user_logo",
      method: "GET",
      token: this.state.token,
    });
  };

  dataURItoBlob = async (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const abBuffer = new ArrayBuffer(byteString.length);
    const iaUnit = new Uint8Array(abBuffer);
    for (let itemInd = 0; itemInd < byteString.length; itemInd++) {
      iaUnit[itemInd] = byteString.charCodeAt(itemInd);
    }
    return new Blob([abBuffer], { type: mimeString });
  };

  async doButtonPressed(image: string) {
    this.setState({
      loader: true,
    });
    const header = {
      "Content-Type":
        Platform.OS === "web" ? "application/json" : "multipart/form-data",
    };
    let formData: FormData | string = new FormData();
    if (Platform.OS === "web") {
      formData = JSON.stringify({
        base_64: true,
        filename: "logo.png",
        logo: { data: image },
        logo_design_id: this.state.imageId,
      });
    } else {
      const file = {
        name: "logo",
        type: "image/jpg",
        uri: Platform.OS === "ios" ? image.replace("file://", "") : image,
      };
      formData.append("logo", file as unknown as Blob);
      formData.append("logo_design_id", this.state.imageId.toString());
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    this.ImageUploadApiID = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      // endPoint: `bx_block_logo_design/user_logo`,
      endPoint: `bx_block_logo_design/user_logo/${
        this.state.isUserLogo ? this.state.imageId.toString() : ""
      }`,
      method: this.state.isUserLogo ? "PUT" : "POST",
      header: header,
      token: this.state.token,
    });
  }
  async componentDidMount() {
    this.signIn();
  }

  // web events

  onPressLogoList = () => {
    this.setState({
      logoList: [],
      isUserLogo: false,
      isShow: !this.state.isShow,
    });
    this.getLogoList();
  };
  onPressUserLogoList = () => {
    this.userLogoList();
    this.setState({
      logoList: [],
      isUserLogo: true,
      isShow: !this.state.isShow,
    });
  };
  onPressImage = (image: string, imageId: number) => {
    this.setState({
      image: image,
      imageId: imageId,
      isShow: !this.state.isShow,
    });
  };
  onClose = () => {
    this.setState({ isShow: !this.state.isShow });
  };
  setImageValue = (text: string) => {
    this.setState({ image: text });
  };

  // Customizable Area Start
  // Customizable Area End
}
