import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert } from "react-native";
import { FeedbackList, IPagination } from "./types";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import { createRequestMessage } from "../../../framework/src/Helpers/create-request-message";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  name: string;
  description: string;
  accountID: string;
  email: string;
  password: string;
  listFeedbackData: FeedbackList[];
  pagination: IPagination;
  currentPage: number | null;
  isVisible: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeedbackCollectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitFeedbackAPICallId: string;
  loginApiCallId: string | null;
  listFeedbackAPICallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      isLoading: false,
      name: "",
      description: "",
      accountID: "",
      email: "",
      password: "",
      listFeedbackData: [],
      pagination: {},
      isVisible: false,
      currentPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.login = this.login.bind(this);
    this.listFeedback = this.listFeedback.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.submitFeedbackAPICallId = "";
    this.loginApiCallId = "";
    this.listFeedbackAPICallId = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      this.setState({
        isLoading: false,
      });

      if (apiRequestCallId === this.listFeedbackAPICallId) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.setState({
              listFeedbackData: responseJson.feedbacks.data as FeedbackList[],
              pagination: responseJson.pagination.meta.pagination,
              isLoading: false,
            });
          },
          onFail: () => {
            this.showAlert(`Get List Feedback Failed`, "Please retry!");
          },
        });
      } else if (apiRequestCallId === this.submitFeedbackAPICallId) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.listFeedback();
            this.handleCloseDialog();
            this.setState({ name: "", description: "" });
            Alert.alert(responseJson.message);
          },
          onFail: () => {
            this.showAlert(`Submit Feedback Failed`, "Please retry!");
          },
        });
      } else if (apiRequestCallId === this.loginApiCallId) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.setState({
              token: responseJson.meta.token,
              accountID: responseJson.meta.id,
              isLoading: false,
            });
          },
          onFail: () => {
            this.showAlert(`Login Failed`, "Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // web events
  // Customizable Area Start

  btnSubmitProps = {
    onPress: () => this.onSubmitButtonPressed(),
  };

  onSubmitButtonPressed() {
    this.submitFeedback();
  }

  btnSubmitNewFeedbackProps = {
    onPress: () =>
      this.setState({
        isVisible: true,
      }),
  };
  async componentDidMount() {
    this.login();
    this.listFeedback();
  }

  onChangeName(text: string) {
    this.setState({
      name: text,
    });
  }
  onChangeDescription(text: string) {
    this.setState({
      description: text,
    });
  }

  changeCurrentPage(pageNumber: number | null) {
    this.setState(
      {
        currentPage: pageNumber,
      },
      () => {
        this.listFeedback();
      },
    );
  }

  btnSubmitNewFeedbackPropsWeb = {
    onClick: () =>
      this.setState((prevState) => ({
        isVisible: !prevState.isVisible,
      })),
  };

  handleSubmitFeedback = () => {
    if (this.state.name.trim() !== "" && this.state.description !== "") {
      this.submitFeedback();
    } else {
      this.handleCloseDialog();
      this.showAlert("Could not add feedback", `Please fill the blank field`);
    }
  };

  handleCloseDialog = () => {
    this.setState({ isVisible: false });
  };

  login = () => {
    const body = {
      data: {
        attributes: {
          email: `${configJSON.loginId}`,
          password: `${configJSON.loginPass}`,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.loginApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.loginInApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  async submitFeedback() {
    this.setState({
      isLoading: true,
    });

    const body = {
      data: {
        name: this.state.name,
        description: this.state.description,
        account_id: this.state.accountID,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.submitFeedbackAPICallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.getFeedbackByPaginationFirst}${this.state.currentPage}${configJSON.getFeedbackByPaginationSecond}`,
      method: configJSON.postApiMethodType,
      token: this.state.token,
      body: JSON.stringify(body),
    });
  }
  listFeedback() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.listFeedbackAPICallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.getFeedbackByPaginationFirst}${this.state.currentPage}${configJSON.getFeedbackByPaginationSecond}`,
      method: configJSON.getApiMethodType,
    });
  }
  // Customizable Area End
}
