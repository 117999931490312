// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postApiMethodType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ScreenSharing";
exports.labelBodyText = "ScreenSharing Body";

exports.btnExampleTitle = "CLICK ME";
exports.videoSDKBaseURL = "https://api.videosdk.live/v2/";
exports.getTokenEndPoint = "get-token";
exports.createRoomEndPoint = "rooms";
exports.validateRoomEndPoint = "rooms/validate/";
// Customizable Area End
