import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import storage from "framework/src/StorageProvider.web";
import { WikiResponse } from "./Types";
import createRequestMessage from "./helpers/create-request-message";
import { handleResponseMessage } from "./helpers/handle-response-message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  wikis: WikiResponse[];
  loading: boolean;
  authToken: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Wiki2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWikiDetailApi: string = "";
  loginApi: string = "";
  deleteWikiApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleGoToCreateScreen = this.handleGoToCreateScreen.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      wikis: [],
      loading: false,
      authToken: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestWeb = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const successData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorData = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        loading: false,
      });
      switch (apiRequestWeb) {
        case this.getWikiDetailApi: {
          handleResponseMessage({
            responseJson: successData,
            errorJson: errorData,
            onSuccess: () => {
              this.handleGetWikiListSuccess(successData);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Wiki Failed! Please retry");
              this.handleLoginUserWeb();
            },
          });
          break;
        }
        case this.loginApi: {
          handleResponseMessage({
            responseJson: successData,
            errorJson: errorData,
            onSuccess: async () => {
              await this.handleLoginSuccess(successData);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Token Failed. Please retry!");
            },
          });
          break;
        }
        case this.deleteWikiApi: {
          handleResponseMessage({
            responseJson: successData,
            errorJson: errorData,
            onSuccess: () => {
              this.handleDeleteWikiSuccess(successData);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Wiki Failed! Please retry");
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // delete particular wiki
  handleDeleteWikiSuccess = (apiResponse: {
    data: WikiResponse;
    message: string;
  }) => {
    this.showAlert("warning", apiResponse.message);
    this.handleGetWikiList();
  };

  //login waste function
  handleLoginSuccess = async (response: { meta: { token: string } }) => {
    await storage.set("token", response.meta.token);
    this.setState({ authToken: response.meta.token }, () => {
      this.handleGetWikiList();
    });
  };

  // Get all wiki success
  handleGetWikiListSuccess = (apiResponse: { wikis?: WikiResponse[] }) => {
    if (apiResponse.wikis) {
      this.setState({
        wikis: apiResponse.wikis,
        loading: false,
      });
    }
  };

  // fake login function
  handleLoginUserWeb() {
    this.setState({
      loading: true,
    });
    const loginMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.loginApi = loginMessage.messageId;
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };
    createRequestMessage({
      requestMessage: loginMessage,
      endPoint: configJSON.Login_EndPoint,
      method: configJSON.postApiMethod,
      header: headers,
      body: JSON.stringify({
        data: {
          attributes: {
            email: configJSON.Email,
            password: configJSON.Password,
          },
          type: "email_account",
        },
      }),
    });
  }

  // get new wikis
  handleGetWikiList = () => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const getWiki = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWikiDetailApi = getWiki.messageId;
    createRequestMessage({
      endPoint: configJSON.Wiki_EndPoint,
      requestMessage: getWiki,
      method: configJSON.getApiMethod,
      header: headers,
    });
  };
  // delete wiki from data
  handleDeleteWikiWeb = async (wikiId: number) => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken,
      "Content-Type": configJSON.apiContentType,
    };
    const deleteWikis = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteWikiApi = deleteWikis.messageId;
    createRequestMessage({
      endPoint: `${configJSON.Wiki_EndPoint}/${wikiId}`,
      requestMessage: deleteWikis,
      method: configJSON.deleteApiMethod,
      header: headers,
    });
  };

  async componentDidMount() {
    let { authToken: token } = this.state;
    if (!token) {
      this.handleLoginUserWeb();
    } else {
      this.handleGetWikiList();
    }
  }

  // navigate to revision
  goToRevision = (wiki_id: number) => {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Revision",
    );
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { wiki_id });
    navigationMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage,
    );
    this.send(navigationMessage);
  };

  handleGoToCreateScreen(wiki?: WikiResponse | null) {
    if (wiki) {
      this.props.navigation.navigate("WikiCreate", {
        wikiId: wiki.id,
      });
    } else {
      this.props.navigation.navigate("WikiCreate");
    }
  }
  // Customizable Area End
}
