import React from "react";

import {
  Container,
  Box,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import VideoBackgroundsController, {
  Props,
  configJSON,
} from "./VideoBackgroundsController.web";

export default class VideoBackgrounds extends VideoBackgroundsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapperContent}>
            <Box
              data-test-id="btnAddVideoUpload"
              component="button"
              sx={webStyle.mainButtonStyle}>
              <Button
                color={"primary"}
                data-test-id={"btnVideoUpload"}
                title={configJSON.btnVideoUpload}
                onClick={() => this.navigateVideoBackgorundInput()}>
                {configJSON.btnVideoUpload}
              </Button>
            </Box>
            <Box
              data-test-id="btnAddList"
              component="button"
              sx={webStyle.mainButtonStyle}>
              <Button
                style={webStyle.colorButton}
                data-test-id={"btnVideoList"}
                title={configJSON.btnVideoList}
                onClick={() => this.navigateVideoPageList()}>
                {configJSON.btnVideoList}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapperContent: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  mainButtonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  colorButton: {
    color: "#fff",
  },
};
// Customizable Area End
