import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import VideoPageListController, { Props } from "./VideoPageListController.web";
import { PageData, VideoData } from "./domain/VideoBackground.dto";
export default class VideoPageList extends VideoPageListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getVideoListData = (item: VideoData) => {
    return (
      <Box>
        <Box>
          <video
            src={item.attributes.video}
            width={item.attributes.scale_w}
            height={item.attributes.scale_h}
            autoPlay
            loop
            controls
          />
          <Box>
            <Typography>{item.attributes.title}</Typography>
          </Box>
        </Box>
        <Box>
          <Button
            data-test-id={`btnEdit-${item.id}`}
            onClick={() => this.editVideo(item.id)}>
            <Typography>Edit</Typography>
          </Button>
          <Button
            data-test-id={`btnDelete-${item.id}`}
            onClick={() => this.deleteVideo(item.id)}>
            <Typography>Delete</Typography>
          </Button>
        </Box>
      </Box>
    );
  };
  showPageList = (item: PageData, index: number) => {
    return (
      <Button
        data-test-id={`selectPageBtn-${item.title}`}
        key={index}
        onClick={() => {
          this.selectPageVideo(item.title);
        }}
        style={styles.buttonContainer}>
        <Typography style={styles.pageInfoContent}>{item.title}</Typography>
      </Button>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={styles.mainWrapper}>
            <Box sx={styles.row}>
              {this.state.pageList.map((item: PageData, index: number) => {
                return this.showPageList(item, index);
              })}
              <Button
                data-test-id="btnPageListAll"
                onClick={() => {
                  this.selectPageVideo("All");
                }}
                style={styles.buttonContainer}>
                <Typography style={styles.pageInfoContent}>All</Typography>
              </Button>
            </Box>
            {this.state.videoList.map((item: VideoData) => {
              return (
                <Box key={item.id} style={styles.boxContainer}>
                  {this.getVideoListData(item)}
                </Box>
              );
            })}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  row: {
    flexDirection: "row",
    marginTop: 10,
    flex: 1,
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#967BB6",
    borderRadius: 5,
    padding: 10,
    flex: 6,
    margin: 2,
  },
  boxContainer: {
    margin: 10,
    elevation: 5,
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 5,
  },
  pageInfoContent: {
    fontSize: 15,
    color: "#fff",
  },
};
// Customizable Area End
