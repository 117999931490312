import React, { Fragment } from "react";

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import EmployeeCertStatusController, {
  Props,
} from "./EmployeeCertStatusController.web";
import SelectDropdownComponentWeb from "../components/SelectDropdownComponent/SelectDropdownComponent.web";
import { certificateStatusList } from "../constants";
import FloatingButtonWeb from "../components/FloatingButton/FloatingButton.web";
import AlertModal from "../components/AlertDialog/AlertModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const ListContainer = styled(List)({
  width: "100%",
});
const Name = styled(Typography)({
  display: "inline",
});
const SelectDropdownContainer = styled(Box)({
  width: "50%",
});
// Customizable Area End

export default class EmployeeCertStatus extends EmployeeCertStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      certificateMappingObject,
      certificateIdArray,
      isLoading,
      titleModal,
      alertModal,
      contentModal,
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FloatingButtonWeb
          buttonType={"backward"}
          onClick={this.handleGoBackWeb}
        />
        <Container maxWidth={"sm"} data-test-id={"containerId"}>
          <Box sx={webSxStyle.mainWrapperStyle}>
            <Typography variant="h6">
              Employee's Certificates List{" "}
              {isLoading && <CircularProgress color="inherit" />}
            </Typography>
          </Box>
          <ListContainer>
            {certificateIdArray?.length > 0 &&
              certificateIdArray.map((item: string, index: number) => (
                <Fragment key={item}>
                  <ListItem
                    alignItems="flex-start"
                    key={index}
                    role={undefined}>
                    <ListItemText
                      primary={certificateMappingObject[item]?.name ?? ""}
                      secondary={
                        <React.Fragment>
                          <Name variant="body2" color="textPrimary">
                            {certificateMappingObject[item]?.description ?? ""}
                          </Name>
                        </React.Fragment>
                      }
                    />

                    <SelectDropdownContainer>
                      <SelectDropdownComponentWeb
                        label={"Status"}
                        dataTestId={"dropdownId"}
                        onChange={(event) =>
                          this.handleUpdateCertStatusWeb(
                            event.target.value as number,
                            certificateMappingObject[item],
                          )
                        }
                        value={
                          certificateStatusList.find(
                            (certificate) =>
                              certificateMappingObject[item] &&
                              certificate.code ===
                                certificateMappingObject[item].status,
                          )?.id
                        }
                        valueList={certificateStatusList}
                      />
                    </SelectDropdownContainer>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </Fragment>
              ))}
          </ListContainer>
          <AlertModal
            alertTitle={titleModal}
            handleCloseAlert={this.handleCloseAlert}
            alertVisible={alertModal}
            alertContent={contentModal}
          />
        </Container>
      </ThemeProvider>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const webSxStyle = {
  mainWrapperStyle: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};
// Customizable Area End
