import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  InputBase,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./styles/feedback.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import FeedbackCollectionController, {
  Props,
} from "./FeedbackCollectionController";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";

export default class FeedbackCollection extends FeedbackCollectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Box sx={webStyle.container}>
            <Box sx={webStyle.stickyHeader}>
              <Button
                {...this.btnSubmitNewFeedbackPropsWeb}
                className="feedback-submit-button"
                data-test-id="submitFeedbackBtn">
                Submit New Feedback
              </Button>
              <Typography align="center" style={webStyle.mainTitle}>
                Feedback List
              </Typography>
            </Box>
            <View style={webStyles.webBottomView}>
              <TouchableOpacity
                testID="previous-page-web"
                onPress={() =>
                  this.changeCurrentPage(
                    this.state.pagination.prev_page || null,
                  )
                }
                disabled={!this.state.pagination.prev_page}
                style={
                  !this.state.pagination.prev_page
                    ? webStyles.webDisabledButtonStyle
                    : webStyles.webActiveButtonStyle
                }>
                <Text style={webStyles.webWhite}>{"<-"} Previous</Text>
              </TouchableOpacity>

              <Text style={webStyles.webMargin48}>
                Page {this.state.pagination.current_page} of{" "}
                {this.state.pagination.total_pages}
              </Text>
              <TouchableOpacity
                testID="next-page-web"
                onPress={() =>
                  this.changeCurrentPage(
                    this.state.pagination.next_page || null,
                  )
                }
                disabled={!this.state.pagination.next_page}
                style={
                  !this.state.pagination.next_page
                    ? webStyles.webDisabledButtonStyle
                    : webStyles.webActiveButtonStyle
                }>
                <Text style={webStyles.webWhite}>Next {"->"}</Text>
              </TouchableOpacity>
            </View>
            <Container maxWidth={"md"}>
              <Box sx={webStyle.feedbackBody}>
                {this.state.listFeedbackData.map((item) => (
                  <Box
                    className="feedback-item"
                    key={item.id}
                    data-test-id="container"
                    sx={webStyle.feedbackContainer}>
                    <Typography style={webStyle.date}>
                      {new Date(item.attributes!.created_at!).toUTCString()}
                    </Typography>
                    <Typography style={webStyle.title}>
                      {item.attributes!.name}
                    </Typography>
                    <Typography style={webStyle.description}>
                      {item.attributes!.description}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Dialog
                data-test-id="dialog"
                open={this.state.isVisible}
                scroll="body"
                onClose={this.handleCloseDialog}
                maxWidth={false}
                className="add-feedback-dialog"
                id="add-feedback-dialog">
                <DialogContent className="dialog-container">
                  <Typography align="center" style={webStyle.dialogTitle}>
                    Add Feedback
                  </Typography>
                  <InputBase
                    style={webStyle.inputBox}
                    data-test-id="textInputName"
                    value={this.state.name}
                    onChange={(event) => this.onChangeName(event.target.value)}
                    placeholder="Name"
                  />
                  <InputBase
                    multiline
                    maxRows={5}
                    style={{ ...webStyle.inputBox, ...webStyle.textarea }}
                    data-test-id="textInputMessage"
                    value={this.state.description}
                    onChange={(event) =>
                      this.onChangeDescription(event.target.value)
                    }
                    placeholder="Message"
                  />
                  <Button
                    onClick={this.handleSubmitFeedback}
                    className="add-feedback-button"
                    data-test-id="submitBtn">
                    Submit New Feedback
                  </Button>
                </DialogContent>
              </Dialog>
            </Container>
          </Box>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
  },
  boxBottomView: {
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    textAlign: "center",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  feedbackBody: {
    marginTop: 50,
  },
  feedbackContainer: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid gray",
    width: "100%",
  },
  mainTitle: {
    margin: 20,
    fontSize: 20,
    fontWeight: 600,
  },
  date: {
    color: "#999",
    fontWeight: 600,
  },
  title: {
    marginTop: 10,
    fontWeight: 600,
  },
  description: {
    marginTop: 5,
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  inputBox: {
    border: "1px solid #eee",
    padding: 10,
    marginTop: 10,
    width: "100%",
  },
  textarea: {
    minHeight: 150,
  },
};
const webStyles = StyleSheet.create({
  webWhite: {
    color: "white",
  },
  webMargin48: {
    marginHorizontal: 48,
  },
  webBottomView: {
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  webDisabledButtonStyle: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: "lightgray",
  },
  webActiveButtonStyle: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: "lightblue",
  },
});
// Customizable Area End
