import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  textContent: string;
  imagePath: string;
  imageRes: File | string;
  isLoader: boolean;
  selectedFile: File;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateContentWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      textContent: "",
      imagePath: "",
      imageRes: "",
      isLoader: false,
      selectedFile: {} as File,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  apiUploadContentWebCallId: string | null = "";
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (
        apiRequestCallId &&
        apiRequestCallId === this.apiUploadContentWebCallId
      ) {
        this.handleUploadFunction(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleUploadFunction = (responseJson: {
    data: {
      attributes: { is_text_approved: boolean; is_image_approved: boolean };
    };
  }) => {
    if (responseJson) {
      this.setState({
        isLoader: false,
        textContent: "",
        imagePath: "",
      });

      if (!responseJson.data.attributes.is_text_approved) {
        this.showAlert("", "Content they have uploaded is against guidelines.");
      } else if (!responseJson.data.attributes.is_image_approved) {
        this.showAlert("", "Image they have uploaded is against guidelines.");
      } else {
        this.navigateToListPage();
      }
    }
  };

  async createData() {
    let token = await getStorageData("TOKEN");
    this.setState({ isLoader: true });
    const formData = new FormData();
    formData.append("text_content", this.state.textContent);
    this.state.selectedFile !== null &&
      formData.append(
        "image",
        this.state.selectedFile,
        this.state.selectedFile.name,
      );
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.apiUploadContentWebCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleWebAPiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handleTitle(text: string) {
    this.setState({ textContent: text });
  }

  navigateToListPage() {
    this.props.navigation.navigate("ContentModeration");
  }
  async createContentData() {
    if (this.state.textContent === "") {
      this.showAlert(`Error`, "Please add all feilds");
    } else {
      await this.createData();
    }
  }

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target.files) {
      return;
    }
    this.setState({ selectedFile: event.target.files[0] });
  };

  // Customizable Area End
}
