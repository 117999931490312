const API_BASE_URL: string = "https://api.videosdk.live/v2";

export const createMeeting: ({
  token,
}: {
  token: string;
}) => Promise<string> = async ({ token }) => {
  const authUrl = `${API_BASE_URL}/rooms`;
  const xhr = new XMLHttpRequest();
  xhr.open("POST", authUrl);
  xhr.setRequestHeader("Authorization", token);
  xhr.setRequestHeader("Content-Type", "application/json");

  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status === 200) {
        const responseData = JSON.parse(xhr.responseText);
        resolve(responseData.roomId);
      } else {
        reject(new Error("Failed to create the meeting"));
      }
    };

    xhr.onerror = () => {
      reject(new Error("Failed to create the meeting"));
    };

    xhr.send();
  });
};
