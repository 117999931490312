import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import { LeaveData, LeaveTrackers, LeavesDetails } from "./types";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  email: string;
  password: string;
  token: string;
  accountID: string;
  selectedLeaveStatus: string;
  leaveListData: Array<LeaveData>;
  unMutatedLeaveListData: Array<LeaveData>;
  searchText: string;
  totalCountWeb: number;
  totalPageWeb: number;
  pageIndexWeb: number;
  pageSizeWeb: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeaveRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listAllLeavesCallID: string;
  updateLeaveStatusCallID: string;
  loginCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      email: "",
      password: "",
      token: "",
      accountID: "",
      selectedLeaveStatus: "Pending",
      leaveListData: [],
      unMutatedLeaveListData: [],
      searchText: "",
      pageIndexWeb: 1,
      totalCountWeb: 1,
      totalPageWeb: 1,
      pageSizeWeb: 10,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.loginCallId = "";
    this.updateLeaveStatusCallID = "";
    this.listAllLeavesCallID = "";

    this.leaveReject = this.leaveReject.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      if (apiRequestCallId === this.listAllLeavesCallID) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.allLeavesCallIDSuccess(responseJson);
            this.handleUpdatePagination(responseJson.pagination.meta);
          },
          onFail: () => {
            this.setState({ isLoading: false });
            this.showAlert(`Login  Failed`, "Please retry!");
          },
        });
      } else if (apiRequestCallId === this.updateLeaveStatusCallID) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.showAlert(``, responseJson.message);
            this.allLeaves();
          },
          onFail: () => {
            this.setState({ isLoading: false });
            this.showAlert(`Login  Failed`, "Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  allLeavesCallIDSuccess = (responseJson: LeaveTrackers) => {
    this.setState({
      leaveListData: responseJson.leave_trackers.data,
      unMutatedLeaveListData: responseJson.leave_trackers.data,
    });

    let nameData = this.state.leaveListData.filter((item) =>
      item.attributes.leave_status.includes(this.state.selectedLeaveStatus),
    );
    this.setState({
      leaveListData: nameData,
      isLoading: false,
    });
  };

  listLeaveStatusPending = {
    onClick: () => {
      this.setState(
        {
          searchText: "",
          selectedLeaveStatus: "Pending",
          leaveListData: this.state.unMutatedLeaveListData,
          isLoading: true,
        },
        () => {
          this.allLeaves();
          let nameData = this.state.leaveListData
            .filter((item) => item.attributes.leave_status.includes("Pending"))
            .map((filteredName) => filteredName);

          this.setState({
            leaveListData: nameData,
            isLoading: false,
          });
        },
      );
    },
  };
  listLeaveStatusApproved = {
    onClick: () => {
      this.setState(
        {
          searchText: "",
          selectedLeaveStatus: "Approved",
          leaveListData: this.state.unMutatedLeaveListData,
          isLoading: true,
        },
        () => {
          this.allLeaves();
          let nameData = this.state.leaveListData.filter((item) =>
            item.attributes.leave_status.includes("Approved"),
          );

          this.setState({
            leaveListData: nameData,
            isLoading: false,
          });
        },
      );
    },
  };
  listLeaveStatusRejected = {
    onClick: () => {
      this.setState(
        {
          searchText: "",
          selectedLeaveStatus: "Rejected",
          leaveListData: this.state.unMutatedLeaveListData,
          isLoading: true,
        },
        () => {
          this.allLeaves();
          let nameData = this.state.leaveListData
            .filter((item) => item.attributes.leave_status.includes("Rejected"))
            .map((filteredName) => filteredName);

          this.setState({
            leaveListData: nameData,
            isLoading: false,
          });
        },
      );
    },
  };

  searchTextInputData = {
    onChange: (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      this.setState({
        searchText: event.target.value,
      });

      if (event.target.value === "") {
        this.allLeaves();
      } else {
        let nameData = this.state.unMutatedLeaveListData
          .filter(
            (item) =>
              item.attributes.name
                .toLowerCase()
                .includes(event.target.value.toLowerCase()) &&
              item.attributes.leave_status
                .toLowerCase()
                .includes(this.state.selectedLeaveStatus.toLowerCase()),
          )
          .map((filteredName) => filteredName);

        this.setState({
          leaveListData: nameData,
        });
      }
    },
  };

  async allLeaves() {
    const userToken = await localStorage.getItem("userToken");
    this.setState({ isLoading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.listAllLeavesCallID = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.listAllEmployeeLeavesApiEndPoint}?per_page=${
        this.state.pageSizeWeb
      }&page=${1}&status=${this.state.selectedLeaveStatus}`,
      method: configJSON.getApiMethodType,
      token: userToken!,
    });
  }

  handleChangePage = async (
    event?: React.ChangeEvent<unknown>,
    page?: number,
  ) => {
    if (page) {
      this.setState({
        pageIndexWeb: page,
        isLoading: true,
        searchText: "",
      });
      const userToken = await localStorage.getItem("userToken");

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.listAllLeavesCallID = requestMessage.messageId;

      createRequestMessage({
        requestMessage: requestMessage,
        endPoint: `${configJSON.listAllEmployeeLeavesApiEndPoint}?per_page=${this.state.pageSizeWeb}&page=${page}&status=${this.state.selectedLeaveStatus}`,
        method: configJSON.getApiMethodType,
        token: userToken!,
      });
    }
  };

  handleUpdatePagination = (meta: {
    pagination: {
      current_page: number;
      next_page: number | null;
      prev_page: number | null;
      total_pages: number;
      total_count: number;
      current_count: number;
      per_page: number;
    };
  }) => {
    if (meta && meta.pagination) {
      const { total_pages, total_count, current_page } = meta.pagination;

      this.setState({
        totalPageWeb: total_pages,
        totalCountWeb: total_count,
        pageIndexWeb: current_page,
      });
    }
  };

  rejectButtonProps = (item: LeaveData) => {
    return { onClick: () => this.leaveReject(item) };
  };

  approveButtonProps = (item: LeaveData) => {
    return { onClick: () => this.leaveApprove(item) };
  };

  leaveReject = (item: LeaveData) =>
    this.updateLeaveStatus("Rejected", item.attributes);

  leaveApprove = (item: LeaveData) =>
    this.updateLeaveStatus("Approved", item.attributes);

  async updateLeaveStatus(leaveStatus: string, data: LeavesDetails) {
    const userToken = await localStorage.getItem("userToken");
    data.leave_status = leaveStatus;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updateLeaveStatusCallID = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint:
        `${configJSON.listAllEmployeeLeavesApiEndPoint}` + `/${data.id}?`,
      method: configJSON.updateApiMethodType,
      token: userToken!,
      body: JSON.stringify(data),
    });
  }

  async componentDidMount() {
    this.allLeaves();
  }

  getDateValue = (value: Date | string) => {
    const date = new Date(value);
    return this.formatDateVale(date);
  };

  formatDateVale(date: Date) {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  navigateToAllEmployeeLeaveList = {
    onClick: () => this.props.navigation.navigate("AllEmployeeLeaveList"),
  };
  // Customizable Area End
}
