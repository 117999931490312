import React from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

export function Controls() {
  const { leave, toggleScreenShare } = useMeeting();
  return (
    <div>
      <button style={styles.btn} onClick={() => leave()}>
        Leave
      </button>
      <button style={styles.btn} onClick={() => toggleScreenShare()}>
        ScreenShare
      </button>
    </div>
  );
}

const styles = {
  btn: {
    background: "#5568FE",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: 6,
    borderWidth: 0,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
  },
};
