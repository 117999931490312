import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import { parseISO } from "date-fns";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: Record<string, string>;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  createLeaveStartDate: Date;
  createLeaveEndDate: Date;
  leaveName: string;
  leaveReason: string;
  leaveType: string;
  isLoading: boolean;
  isUpdate: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateLeaveController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createLeaveCallID: string;
  updateLeaveCallID: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      createLeaveStartDate: new Date(),
      createLeaveEndDate: new Date(),
      leaveName: "",
      leaveReason: "",
      leaveType: "Vacation",
      isLoading: false,
      isUpdate: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.leaveType = this.leaveType.bind(this);
    this.leaveSubmit = this.leaveSubmit.bind(this);
    this.handleLeaveStartDateChange =
      this.handleLeaveStartDateChange.bind(this);
    this.handleLeaveEndDateChange = this.handleLeaveEndDateChange.bind(this);
    this.createLeaveCallID = "";
    this.updateLeaveCallID = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      this.setState({
        isLoading: false,
      });

      if (apiRequestCallId === this.createLeaveCallID) {
        handleResponseMessage({
          responseJson: responseJson.message,
          errorJson: responseJson.data.type,
          onSuccess: () => {
            this.showAlert("Success", "Leave created successfully");
            setTimeout(() => {
              this.props.navigation.navigate("LeaveTrackerHome");
            }, 3000)
          },
          onFail: () => {
            this.showAlert("Error", "You already applied leave for this date");
          },
        });
      } else if (apiRequestCallId === this.updateLeaveCallID) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.showAlert(`Success`, responseJson.message);
            setTimeout(() => {
              this.props.navigation.navigate("LeaveTrackerHome");
            }, 3000)
          },
          onFail: () => {
            this.showAlert("Error", responseJson.message);
            setTimeout(() => {
              this.props.navigation.navigate("LeaveTrackerHome");
            }, 3000)
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const leaveDetails = JSON.parse(
      localStorage.getItem("leaveDetails") as string,
    );

    if (localStorage.getItem("updateLeave") && leaveDetails) {
      const startDate = leaveDetails.start_date;
      const endDate = leaveDetails.end_date;
      const leaveType = leaveDetails.leave_type;
      const leaveName = leaveDetails.name;
      const leaveReason = leaveDetails.leave_reason;

      this.setState({
        createLeaveStartDate: parseISO(startDate),
        createLeaveEndDate: parseISO(endDate),
        leaveName,
        leaveReason,
        leaveType,
        isUpdate: true,
      });
    }
  }

  async componentWillUnmount() {
    localStorage.removeItem("updateLeave");
    localStorage.removeItem("leaveDetails");
  }

  leaveType = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    const name = event.target.value;
    this.setState({
      leaveType: name as string,
    });
  };

  formatDate(value: Date) {
    const date = new Date(value);
    const dayDate = date.getDate();
    const month = date.getMonth() + 1;
    return `${dayDate < 10 ? "0" + dayDate : dayDate}/${month < 10 ? "0" + month : month
      }/${date.getFullYear()}`;
  }

  handleLeaveStartDateChange(date: Date) {
    return this.setState({
      createLeaveStartDate: date,
    });
  }

  handleLeaveEndDateChange(date: Date) {
    return this.setState({
      createLeaveEndDate: date,
    });
  }

  handleLeaveNameChange(text: string) {
    this.setState({
      leaveName: text,
    });
  }

  handleLeaveReasonChange(text: string) {
    this.setState({
      leaveReason: text,
    });
  }

  onPressCreateLeaveSubmit = {
    onClick: async () => {
      if (localStorage.getItem("updateLeave")) {
        const leaveId = JSON.parse(
          localStorage.getItem("leaveDetails") as string,
        )?.id;
        await this.leaveUpdate(leaveId);
      } else {
        await this.leaveSubmit();
      }
    },
  };

  async leaveSubmit() {
    const userToken = await localStorage.getItem("userToken");
    const startDate = this.formatDate(this.state.createLeaveStartDate);
    const endDate = this.formatDate(this.state.createLeaveEndDate);

    const body = {
      employee_id: 150,
      name: this.state.leaveName,
      leave_type: this.state.leaveType,
      leave_reason: this.state.leaveReason,
      start_date: startDate,
      end_date: endDate,
      is_active: "true",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createLeaveCallID = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.createLeaveApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
      token: userToken!,
    });
  }

  async leaveUpdate(leaveId?: string) {
    const userToken = await localStorage.getItem("userToken");
    const startDate = this.formatDate(this.state.createLeaveStartDate);
    const endDate = this.formatDate(this.state.createLeaveEndDate);

    const body = {
      name: this.state.leaveName,
      leave_type: this.state.leaveType,
      leave_reason: this.state.leaveReason,
      start_date: startDate,
      end_date: endDate,
      is_active: "true",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updateLeaveCallID = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.updateLeaveApiEndPoint}${leaveId}?`,
      method: configJSON.updateApiMethodType,
      body: JSON.stringify(body),
      token: userToken!,
    });
  }
  // Customizable Area End
}
