import React from "react";

import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  // Customizable Area Start
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import moment from "moment";
import TimeSheetWorkerLogsController, {
  Props,
  configJSON,
} from "./TimeSheetWorkerLogsController";
import Title from "./components/Title.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
});

export default class TimeSheetWorkerLogs extends TimeSheetWorkerLogsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  contextMenu(timeSheetTaskId: string, logId: string) {
    const { contextMenuAnchorEl, contextMenuId } = this.state;

    return (
      <div style={webStyle.contextMenu}>
        {this.state.accountId === this.state.userAccountId && (
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            data-test-id={`context_menuWeb`}
            onClick={(event: { stopPropagation: Function; target: {} }) =>
              this.openContextMenu(event, logId)
            }>
            <MoreVertIcon />
          </IconButton>
        )}
        <Menu
          id="long-menu"
          anchorEl={contextMenuAnchorEl as HTMLAnchorElement}
          keepMounted
          open={contextMenuId === logId}
          onClose={() => this.closeContextMenu()}>
          <MenuItem
            data-testId={`contextEditWeb`}
            onClick={() => this.editLogsWeb(timeSheetTaskId, logId)}>
            {configJSON.edit}
          </MenuItem>
          <MenuItem
            data-testId={`context_delete_web`}
            onClick={() => this.deleteLogWeb(logId)}>
            {configJSON.delete}
          </MenuItem>
        </Menu>
      </div>
    );
  }

  logsList() {
    return this.state.logsData.map(({ attributes }) => {
      const { title, id, start_time, hours, timesheet_task_id } = attributes;

      return (
        <Card
          data-test-id="log"
          key={id}
          variant="outlined"
          style={webStyle.userCard}
          color="primary">
          <CardContent style={webStyle.cardContent}>
            <Typography variant="body1">
              {configJSON.labelActivityDate}
              <Typography color="textSecondary" component="span">
                {moment(start_time).format("YYYY-MM-DD")}
              </Typography>
            </Typography>

            <Typography variant="body2">
              {configJSON.labelLogName}{" "}
              <Typography color="textSecondary" component="span">
                {title}
              </Typography>
            </Typography>

            <Typography variant="body2">
              {configJSON.labelTotalHours}
              <Typography color="textSecondary" component="span">
                {hours}
              </Typography>
            </Typography>

            {this.contextMenu(timesheet_task_id, id)}
          </CardContent>
        </Card>
      );
    });
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { workerData } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Title title="Employee Profile" data-test-id="title" />
        <Container maxWidth="sm">
          <Box sx={webStyle.mainWrapper}>
            <Card>
              <CardContent>
                <Box sx={{ ...webStyle.flexWrapper, ...webStyle.taskTitle }}>
                  <Box>
                    <Typography color="primary" variant="h6">
                      {workerData.name}
                    </Typography>
                    <Typography>{workerData.email}</Typography>
                  </Box>
                </Box>
                <Typography variant="body2">
                  {configJSON.labelTotalHours}
                  <Typography color="textSecondary" component="span">
                    {workerData.totalHours}
                  </Typography>
                </Typography>
              </CardContent>
            </Card>

            <Box>{this.logsList()}</Box>
            <Dialog
              open={this.state.isDeleteLogModal}
              onClose={this.handleCloseDeleteModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                {configJSON.messageDeleteLog}
              </DialogTitle>
              <DialogActions>
                <Box style={webStyle.deleteModal}>
                  <Button
                    data-testid="no_delete_button"
                    style={webStyle.cancelButton}
                    onClick={this.handleCloseDeleteModal}>
                    Cancel
                  </Button>
                  <Button
                    data-testid="yes_delete_button"
                    style={webStyle.deleteButton}
                    onClick={() => this.callDeleteLog()}>
                    Delete
                  </Button>
                </Box>
              </DialogActions>
            </Dialog>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
    gap: 32,
  },
  flexWrapper: {
    with: "100%",
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  taskTitle: {
    marginBottom: 16,
  },
  allMembers: {
    width: "100%",
  },
  firstUser: {
    width: 32,
    height: 32,
  },
  userCard: {
    background: "#F3F0FF",
    marginBottom: 16,
  },
  deleteModal: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "15px",
  },
  cancelButton: {
    backgroundColor: "cornsilk",
  },
  deleteButton: {
    backgroundColor: "floralwhite",
  },
  cardContent: {
    position: "relative",
  } as React.CSSProperties,
  contextMenu: {
    position: "absolute",
    top: 4,
    right: 4,
  } as React.CSSProperties,
};
// Customizable Area End
