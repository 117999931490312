import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import storage from "../../../framework/src/StorageProvider";
import {
  ProfileDataWeb,
  ResponseLogin,
  ResponseProfileTemplate,
  SummaryData,
} from "./Types";

type fileType = null | string | FileList;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  resumePublishTypeWeb: string;
  isAddResumeModalOpenWeb: boolean;
  newResumeUploadedFileWeb: fileType;
  editResumeUploadFileWeb: fileType;
  searchTextWeb: string;
  resumesWeb: SummaryData[];
  titleWeb: string;
  newResumeSummaryWeb: string;
  profileDataWeb: ProfileDataWeb;
  isProfileShownWeb: boolean;
  allow_publish_web: string | boolean;
  resumeEditIdWeb: string;
  nameWeb: string;
  personAgeWeb: string;
  years_of_experience_web: string;
  experience_summary_web: string;
  education_summary_web: string;
  education_file_web: fileType;
  experience_file_web: fileType;
  authTokenWeb: string;
  userIdWeb?: number;
  isEditProfileWeb: boolean;
  isEditResumeWeb: boolean;
  showAlertMsg: string;
  isSelectTypeOpen: boolean
  // Customizable Area End
}

export default class CvresumeCandidateManagement2Controller extends BlockComponent<
  Props,
  S,
  S
> {
  // Customizable Area Start
  getResumesDataCallIdWeb: string = "";
  addResumesDataCallIdWeb: string = "";
  updateResumesDataCallIdWeb: string = "";
  deleteResumesDataCallIdWeb: string = "";
  getCandidateDetailsCallIdWeb: string = "";
  addCandidateDetailsCallIdWeb: string = "";
  loginApiCallIdWeb: string = "";
  editCandidateDetailsCallIdWeb: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      resumePublishTypeWeb: "",
      isAddResumeModalOpenWeb: false,
      searchTextWeb: "",
      resumesWeb: [],
      profileDataWeb: {
        name: "",
        age: 0,
        years_of_experience: 0,
        experience_summary: "",
        education_summary: "",
        created_by: "",
        updated_by: "",
        created_at: "",
        updated_at: "",
        experience_file: null,
        education_file: null,
      },
      titleWeb: "",
      newResumeSummaryWeb: "",
      isProfileShownWeb: false,
      authTokenWeb: "",
      allow_publish_web: false,
      resumeEditIdWeb: "",
      experience_summary_web: "",
      nameWeb: "",
      years_of_experience_web: "",
      personAgeWeb: "",
      education_summary_web: "",
      education_file_web: "",
      experience_file_web: "",
      isEditProfileWeb: false,
      newResumeUploadedFileWeb: null,
      editResumeUploadFileWeb: null,
      isEditResumeWeb: false,
      showAlertMsg: "",
      isSelectTypeOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value,
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      switch (requestCallDataId) {
        case this.getResumesDataCallIdWeb: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.onSuccessGetResumesWeb(responseSuccessWeb);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Cv resume Failed! Please retry");
            },
          });
          break;
        }
        case this.addResumesDataCallIdWeb: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.getResumesData();
            },
            onFail: () => {
              this.showAlert(`Error`, "adding resume Failed! Please retry");
            },
          });
          break;
        }
        case this.deleteResumesDataCallIdWeb: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.onSuccessDeleteResumes(responseSuccessWeb);
            },
            onFail: () => {
              this.showAlert(`Error`, "Unable to delete! Please retry");
            },
          });
          break;
        }
        case this.updateResumesDataCallIdWeb: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.onSuccessUpdateResumes();
            },
            onFail: () => {
              this.showAlert(
                `Error`,
                "Unable to Update resume ! Please retry",
              );
            },
          });
          break;
        }
        case this.getCandidateDetailsCallIdWeb: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.onSuccessGetCandidatesWeb(responseSuccessWeb);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Cv Resume Details Failed! Please Retry");
            },
          });
          break;
        }
        case this.editCandidateDetailsCallIdWeb: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.onSuccessUpdateCandidates(responseSuccessWeb);
              this.showAlert(
                `Note`,
                "Profile Updated",
              );
            },
            onFail: () => {
              this.showAlert(
                `Error`,
                "Update details Failed ! Please Upload all required files and retry",
              );
              this.getCandidateDetails()
            },
          });
          break;
        }
        case this.loginApiCallIdWeb: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.onSuccessLogin(responseSuccessWeb);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Token Failed. Please try again!");
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  toggleMainProfile = () =>
    this.setState({ isProfileShownWeb: !this.state.isProfileShownWeb });

  toggleEditProfile = () => {
    this.setState({
      isEditProfileWeb: !this.state.isEditProfileWeb,
      showAlertMsg: "",
    });
    this.getCandidateDetails()
  }


  toggleCheckbox = () =>
    this.setState({ allow_publish_web: !this.state.allow_publish_web });

  handleAddResumeModalOpen = () =>
    this.setState({
      isAddResumeModalOpenWeb: !this.state.isAddResumeModalOpenWeb,
      titleWeb: "",
      newResumeSummaryWeb: "",
      newResumeUploadedFileWeb: null,
    });

  handleEditResumeModalOpen = (item?: SummaryData) => {
    this.setState({
      isEditResumeWeb: !this.state.isEditResumeWeb,
      resumeEditIdWeb: item?.id ? item.id : "",
      titleWeb: item?.attributes.title ? item.attributes.title : "",
      newResumeSummaryWeb: item?.attributes.summary
        ? item.attributes.summary
        : "",
      allow_publish_web: item ? item.attributes.allow_publish : "",
      showAlertMsg: "",
    });
  };

  handleAddResumeFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newResumeUploadedFileWeb: event.target.files,
    });
  };

  handleEducationFile = (text: ChangeEvent<HTMLInputElement>) => {
    if (this.state.isEditProfileWeb && this.state.profileDataWeb) {
      this.setState({
        profileDataWeb: {
          ...this.state?.profileDataWeb,
          education_file: text.target.files,
        },
      });
    } else {
      this.setState({
        education_file_web: text.target.files,
      });
    }
  };

  handleExperienceFile = (text: ChangeEvent<HTMLInputElement>) => {
    if (this.state.isEditProfileWeb && this.state.profileDataWeb) {
      this.setState({
        profileDataWeb: {
          ...this.state?.profileDataWeb,
          experience_file: text.target.files,
        },
      });
    } else {
      this.setState({
        experience_file_web: text.target.files,
      });
    }
  };

  handleSubmitResumeWeb = () => {
    if (this.state.titleWeb.length <= 0) {
      this.setState({ showAlertMsg: "Please enter a title" });
      return false;
    }
    if (this.state.newResumeUploadedFileWeb === null) {
      this.setState({ showAlertMsg: "Please select a resume file" });
      return false;
    }
    if (this.state.newResumeSummaryWeb.length <= 0) {
      this.setState({ showAlertMsg: "Please write a summary" });
      return false;
    }
    this.state.resumeEditIdWeb ? this.updateResumeById() : this.addResumeWeb();
  };

  addResumeWeb = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.addResumesDataCallIdWeb = requestMessage.messageId;
    const headers = {
      "Content-Type": "multipart/form-data",
      "token": this.state.authTokenWeb,
    };
    const body = new FormData();
    body.append("title", this.state.titleWeb);
    body.append("summary", this.state.newResumeSummaryWeb);
    body.append("allow_publish", this.state.allow_publish_web.toString());
    this.state.newResumeUploadedFileWeb &&
      body.append("file", this.state.newResumeUploadedFileWeb[0]);
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.resume_EndPoint,
      method: configJSON.postApiMethodType,
      header: headers,
      body: body,
      token: this.state.authTokenWeb,
      isFormDataRequest: true,
    });
    this.setState({
      isAddResumeModalOpenWeb: !this.state.isAddResumeModalOpenWeb,
      showAlertMsg: "",
      newResumeUploadedFileWeb: null,
      titleWeb: "",
      newResumeSummaryWeb: "",
      allow_publish_web: false,
    });
  };

  updateResumeById = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.updateResumesDataCallIdWeb = requestMessage.messageId;
    const headers = {
      "token": this.state.authTokenWeb,
      "Content-Type": "multipart/form-data",
    };
    const body = new FormData();
    body.append("title", this.state.titleWeb);
    body.append("summary", this.state.newResumeSummaryWeb);
    body.append("allow_publish", this.state.allow_publish_web.toString());
    this.state.newResumeUploadedFileWeb &&
      body.append("file", this.state.newResumeUploadedFileWeb[0]);

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.resume_EndPoint}/${this.state.resumeEditIdWeb}`,
      method: configJSON.updateApiMethodType,
      header: headers,
      body: body,
      token: this.state.authTokenWeb,
      isFormDataRequest: true,
    });
    this.setState({
      isEditResumeWeb: !this.state.isEditResumeWeb,
      showAlertMsg: "",
      titleWeb: "",
      editResumeUploadFileWeb: null,
      allow_publish_web: false,
      newResumeSummaryWeb: "",
      resumeEditIdWeb: "",
    });
  };

  searchByTextWeb = (text: {
    target: {
      value: string;
    };
  }) => {
    this.setState({ searchTextWeb: text.target.value });
  };

  handleSubmitProfile = () => {
    if (
      this.state.profileDataWeb &&
      this.state.profileDataWeb?.name.length <= 0
    ) {
      this.setState({ showAlertMsg: "Please enter a name" });
      return false;
    }
    if (this.state.profileDataWeb && this.state.profileDataWeb?.age <= 0) {
      this.setState({ showAlertMsg: "Please enter your age" });
      return false;
    }
    if (
      this.state.profileDataWeb &&
      this.state.profileDataWeb?.years_of_experience.toString().length < 1
    ) {
      this.setState({ showAlertMsg: "Please enter a years_of_experience" });
      return false;
    }
    if (
      this.state.profileDataWeb &&
      this.state.profileDataWeb.experience_summary.length === 0
    ) {
      this.setState({ showAlertMsg: "Please enter a experience_summary" });
      return false;
    }
    if (
      this.state.profileDataWeb &&
      this.state.profileDataWeb.education_summary.length === 0
    ) {
      this.setState({ showAlertMsg: "Please enter a education_summary" });
      return false;
    }
    this.state.isEditProfileWeb && this.handleUpdateProfile();
  };

  handleUpdateProfile = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.editCandidateDetailsCallIdWeb = requestMessage.messageId;
    const headers = {
      "Content-Type": "multipart/form-data",
      "token": this.state.authTokenWeb,
    };
    const body = new FormData();

    body.append("name", this.state.profileDataWeb?.name);
    body.append("age", this.state.profileDataWeb?.age.toString());
    body.append(
      "years_of_experience",
      this.state.profileDataWeb?.years_of_experience.toString(),
    );
    body.append(
      "experience_summary",
      this.state.profileDataWeb?.experience_summary,
    );
    body.append(
      "education_summary",
      this.state.profileDataWeb?.education_summary,
    );
    this.state.profileDataWeb.education_file &&
      body.append(
        "education_file",
        this.state.profileDataWeb.education_file[0],
      );
    this.state.profileDataWeb.experience_file &&
      body.append(
        "experience_file",
        this.state.profileDataWeb?.experience_file[0],
      );

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.profile_EndPoint}/${this.state.userIdWeb}`,
      method: configJSON.updateApiMethodType,
      header: headers,
      body: body,
      token: this.state.authTokenWeb,
      isFormDataRequest: true,
    });
    this.setState({
      isEditProfileWeb: !this.state.isEditProfileWeb,
      isProfileShownWeb: !this.state.isProfileShownWeb,
    });
  };

  handleFakeLoginUserWeb() {
    const requestApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.loginApiCallIdWeb = requestApiMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      data: {
        attributes: {
          email: configJSON.USERNAME,
          password: configJSON.PASSWORD,
        },
        type: "email_account",
      },
    };
    createRequestMessage({
      requestMessage: requestApiMessage,
      endPoint: configJSON.Login_EndPoint,
      method: configJSON.postApiMethodType,
      header: headers,
      body: JSON.stringify(body),
    });
  }

  getCandidateDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getCandidateDetailsCallIdWeb = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authTokenWeb,
    };
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.profile_EndPoint}/${this.state.userIdWeb}`,
      method: configJSON.getApiMethodType,
      header: headers,
    });
  };

  onSuccessLogin = async (responseDataJson: ResponseLogin) => {
    await storage.set("token", responseDataJson.meta.token);
    await storage.set("user", responseDataJson.meta.id);
    this.setState(
      {
        authTokenWeb: responseDataJson.meta.token,
        userIdWeb: responseDataJson.meta.id,
      },
      () => {
        this.getCandidateDetails();
        this.getResumesData();
      },
    );
  };

  onSuccessGetResumesWeb = (responseDataJson: { data: SummaryData[] }) => {
    this.setState({
      resumesWeb: responseDataJson.data,
    });
  };

  onSuccessUpdateResumes = () => {
    this.getResumesData();
    this.setState({
      isAddResumeModalOpenWeb: false,
      titleWeb: "",
      allow_publish_web: false,
      resumeEditIdWeb: "",
      newResumeSummaryWeb: "",
    });
  };

  async componentDidMount() {
    const tokenWeb = await storage.get("token");
    const userId = await storage.get("user");
    if (!tokenWeb || !userId) {
      this.handleFakeLoginUserWeb();
    } else {
      this.setState({ authTokenWeb: tokenWeb, userIdWeb: userId }, () => {
        this.getCandidateDetails();
        this.getResumesData();
      });
    }
  }

  onSuccessGetCandidatesWeb = (responseDataJson: ResponseProfileTemplate) => {
    if (responseDataJson?.data !== null && responseDataJson.data.attributes) {
      this.setState({ profileDataWeb: responseDataJson.data?.attributes });
    }
  };

  onSuccessUpdateCandidates = (responseDataJson: ResponseProfileTemplate) => {
    if (responseDataJson?.data) {
      this.getCandidateDetails();
      this.setState({
        isEditProfileWeb: false,
        isProfileShownWeb: false,
      });
    }
  };

  getResumesData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getResumesDataCallIdWeb = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authTokenWeb,
    };
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.resume_EndPoint,
      method: configJSON.getApiMethodType,
      header: headers,
    });
  };

  deleteResumeById = (resumeDeleteId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.deleteResumesDataCallIdWeb = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authTokenWeb,
    };
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.resume_EndPoint}/${resumeDeleteId}`,
      method: configJSON.deleteApiMethodType,
      header: headers,
    });
  };

  onSuccessDeleteResumes = (responseDataJson: { message: string }) => {
    this.showAlert("success", responseDataJson.message);
    this.getResumesData();
  };

  handleUpdateProfileChangeText = (text: string, stateName: string) => {
    return this.setState({
      profileDataWeb: { ...this.state?.profileDataWeb, [stateName]: text },
    });
  };

  handleResumeInputChangeText = (text: string, stateName: string) => {
    this.setState({ ...this.state, [stateName]: text });
  };

  goToTemplateScreen = () => {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CvresumeCandidateTemplateList",
    );
    this.send(navigationMessage);
  };

  goToPublicResumeWeb = () => {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CvresumeCandidateList",
    );
    this.send(navigationMessage);
  };

  handleFilterResumePublishType = (text: string) => {
    this.setState({ resumePublishTypeWeb: text });
  };

  toggleIsSelectTypeOpen = () => {
    this.setState({ isSelectTypeOpen: !this.state.isSelectTypeOpen })
  }
}
// Customizable Area End
