Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "VideoBackgrounds";
exports.labelBodyText = "VideoBackgrounds Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnVideoUpload = "Upload";
exports.btnVideoList = "List";
exports.uploadVideoEndPoint = "bx_block_video_backgrounds/files";
exports.formDataApiContentType = "multipart/form-data;";
exports.loginEndPoint = "bx_block_login/logins";
exports.getVideoEndPoint = "bx_block_video_backgrounds/files";
exports.deleteVideoEndPoint = "bx_block_video_backgrounds/files/";
exports.apiDeleteMethodType = "Delete";
exports.apiEditMethodType = "PATCH";
exports.getVideoByIDEndPoint = "bx_block_video_backgrounds/files/";
exports.getpageListEndPoint = "bx_block_video_backgrounds/files/get_page_names";
exports.getVideoByPageEndPoint = "bx_block_video_backgrounds/files/";

exports.loginEmail = "test11@gmail.com";
exports.loginPassword = "Password@123";

exports.loginEmailWeb = "test11@gmail.com";
exports.loginPasswordWeb = "Password@123";
// Customizable Area End
