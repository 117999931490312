import * as React from "react";
import { PropsWithChildren, ReactElement, useMemo } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";

export function FloatingButton(
  props: PropsWithChildren<FloatingButtonProps>,
): ReactElement {
  const { onClick, buttonType } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const className = useMemo(() => {
    if (buttonType === "add") {
      return classes.addButton;
    } else {
      return classes.backwardButton;
    }
  }, [classes.addButton, classes.backwardButton, buttonType]);

  const icon = useMemo(() => {
    if (buttonType === "add") {
      return <AddIcon color={"primary"} />;
    } else {
      return <ArrowBackIcon color={"primary"} />;
    }
  }, [buttonType]);

  return (
    <Fab
      aria-label={"Add"}
      className={className}
      color={"inherit"}
      onClick={onClick}>
      {icon}
    </Fab>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      backgroundColor: "#6200ee",
    },
    backwardButton: {
      position: "absolute",
      top: theme.spacing(5),
      left: theme.spacing(2),
      backgroundColor: "#6200ee",
    },
  }),
);

export interface FloatingButtonProps {
  onClick: () => void;
  buttonType: "add" | "backward";
}

FloatingButton.defaultProps = {
  //
};

export default FloatingButton;
