import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  Modal,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import RedEye from "@material-ui/icons/RemoveRedEye";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { imgProfile } from "./assets";
import React, { ChangeEvent } from "react";
import { SummaryData } from "./Types";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CvresumeCandidateManagement2Controller, {
  Props,
} from "./CvresumeCandidateManagement2Controller.web";

export default class CvresumeCandidateManagement2 extends CvresumeCandidateManagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderAllResume = () => {
    const { searchTextWeb, resumesWeb, resumePublishTypeWeb } = this.state;
    let filterItems;
    if (resumePublishTypeWeb === "true") {
      const filteredTrue = resumesWeb.filter((trueItem) => trueItem?.attributes.allow_publish === true)
      filterItems = filteredTrue.filter((item) =>
        item?.attributes?.title
          .toLowerCase()
          .includes(searchTextWeb.toLowerCase()),
      );
    }
    else if (resumePublishTypeWeb === "false") {
      const filtered = resumesWeb.filter((falseItems) => falseItems?.attributes.allow_publish === false)
      filterItems = filtered.filter((items) =>
        items?.attributes?.title
          .toLowerCase()
          .includes(searchTextWeb.toLowerCase()),
      );
    }
    else {
      filterItems = resumesWeb.filter((elseItem) =>
        elseItem?.attributes?.title
          .toLowerCase()
          .includes(searchTextWeb.toLowerCase()),
      );
    }
    return (
      <Box sx={webStyle.listContainer}>
        {filterItems.length !== 0 ? (
          filterItems?.map((item: SummaryData, index: number) => {
            return (
              <Box sx={webStyle.listItemsContainer} key={item.id}>
                <Box sx={webStyle.listBoxItem}>
                  <Box sx={webStyle.listBoxNameAndSummaryItem}>Name:</Box>
                  <Box sx={webStyle.listBoxItemData}>
                    {item?.attributes?.title}
                  </Box>
                </Box>
                <Box sx={webStyle.listBoxItem}>
                  <Box sx={webStyle.listBoxNameAndSummaryItem}>Summary:</Box>
                  <Box sx={webStyle.listBoxItemData}>
                    {item?.attributes?.summary}
                  </Box>
                </Box>
                <Box sx={webStyle.listBoxItem}>
                  <Box sx={webStyle.listBoxPublishTypeItem}>Publish type:</Box>
                  <Box sx={webStyle.listBoxItemData}>
                    {JSON.stringify(item?.attributes?.allow_publish)}
                  </Box>
                </Box>
                <Box sx={webStyle.listBoxItemButtons}>
                  <Link
                  target="_blank"
                    style={webStyle.eyeBtn}
                    data-test-id={`downloadResumeBtn-${index}`}
                    href={item?.attributes.resume?.toString()}>
                    <RedEye fontSize="large" />
                  </Link>
                  <Button
                    data-test-id={`delResumeBtn-${index}`}
                    onClick={() => this.deleteResumeById(item?.id)}
                    style={webStyle.deleteBtn}>
                    <DeleteIcon fontSize="large" />
                  </Button>
                  <Button
                    style={webStyle.editBtn}
                    data-test-id={`editResumeBtn-${index}`}
                    onClick={() => this.handleEditResumeModalOpen(item)}>
                    <CreateIcon fontSize="large" />
                  </Button>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography style={webStyle.headingMyResumes}>
            No Resume available
          </Typography>
        )}
      </Box>
    );
  };

  renderEditResumeModal = () => {
    return (
      <Modal open={this.state.isEditResumeWeb} style={webStyle.modal}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.modalContainer}>
            <Typography variant="h5" style={webStyle.modalHeading}>
              Update Resume
            </Typography>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                placeholder="Enter the title for this resume"
                style={webStyle.modalTextInput}
                value={this.state.titleWeb}
                data-test-id="editResumeTitleInput"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  this.handleResumeInputChangeText(
                    event.target.value,
                    "titleWeb",
                  )
                }
              />
            </Box>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="file"
                id="updateResumeFile"
                style={webStyle.modalTextInputFile}
                inputProps={{ accept: "application/pdf" }}
                placeholder="Select resume file"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  this.handleAddResumeFileUpload(event)
                }
                data-test-id="editResumeFileInput"
              />
              <label htmlFor="updateResumeFile" style={webStyle.candidateManagementLabel}>
                Upload resume file ("pdf format only")
              </label>
            </Box>
            <Box style={webStyle.modalTextInputBox}>
              <Input
                type="text"
                style={webStyle.modalTextInput}
                placeholder="Enter your resume summary"
                value={this.state.newResumeSummaryWeb}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  this.handleResumeInputChangeText(
                    event.target.value,
                    "newResumeSummaryWeb",
                  )
                }
                data-test-id="editResumeSummaryInput"
              />
            </Box>
            <Box sx={webStyle.checkboxWithNoteContainer}>
              <Checkbox
                onClick={this.toggleCheckbox}
                checked={Boolean(this.state.allow_publish_web)}
                value={this.state.allow_publish_web}
                data-test-id="editResumeCheckboxInput"
              />
              <Typography style={webStyle.checkBoxNote}>
                Do you want to show this resume to all ?
              </Typography>
            </Box>
            <Box sx={webStyle.modelButtonsContainer}>
              <Button
                style={webStyle.modalBtnCancel}
                onClick={() => this.handleEditResumeModalOpen()}
                data-test-id="editResumeCancelBtn">
                Cancel
              </Button>
              <Button
                style={webStyle.modalBtnSubmit}
                onClick={this.handleSubmitResumeWeb}
                data-test-id="editResumeSubmitBtn">
                Submit
              </Button>
            </Box>
            <Box sx={webStyle.showAlertMsg}>{this.state.showAlertMsg}</Box>
          </Box>
        </Container>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.headerContainer}>
              <img
                src={imgProfile}
                alt="profile"
                style={webStyle.profileImg}
                data-test-id="profileImg"
                onClick={this.toggleMainProfile}
              />
              <Typography style={webStyle.mainHeading}>Dashboard</Typography>
            </Box>
            <Box style={webStyle.searchAndButtonsContainer}>
            <Box sx={webStyle.searchContainer}>
              <Box sx={webStyle.searchIconBox}>
                <SearchIcon fontSize="large" />
              </Box>
              <Input
                type="search"
                style={webStyle.searchInput}
                placeholder="Search"
                inputProps={{ min: 0, style: { textAlign: "center" } }}
                onChange={(text) => this.searchByTextWeb(text)}
                data-test-id="txtInput"
              />
              <Accordion
              expanded={this.state.isSelectTypeOpen} 
              onClick={this.toggleIsSelectTypeOpen}
              style={webStyle.typeDropdownContainer}>
                <AccordionSummary
                  data-test-id="dropdownBtn"
                  expandIcon={<TuneIcon fontSize="large" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                />

                <AccordionDetails style={webStyle.accordionDetails}>
                  <Box sx={webStyle.typeDropdownBtnsContainer}>
                    <Button
                      data-test-id="dropdownAllBtn"
                      onClick={() => this.handleFilterResumePublishType("")}>
                      All
                    </Button>
                    <Button
                      data-test-id="dropdownPublicBtn"
                      onClick={() =>
                        this.handleFilterResumePublishType("true")
                      }>
                      Public
                    </Button>
                    <Button
                      data-test-id="dropdownPrivateBtn"
                      onClick={() =>
                        this.handleFilterResumePublishType("false")
                      }>
                      Private
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Box sx={webStyle.navigationBtnBox1}>
                <Button
                  style={webStyle.navigationBtn}
                  data-test-id="addNewResumeBtn"
                  onClick={this.handleAddResumeModalOpen}>
                  <Typography style={webStyle.navigationBtnText}>
                    Add New Resume
                  </Typography>
                </Button>
                <Button
                  style={webStyle.navigationBtn}
                  data-test-id="goToTemplatePageBtn"
                  component="section"
                  onClick={this.goToTemplateScreen}>
                  <Typography style={webStyle.navigationBtnText}>
                    Go To Template
                  </Typography>
                </Button>
              </Box>
              <Button
                style={webStyle.navigationLargeBtn}
                data-test-id="goToPublicResumesPageBtn"
                onClick={this.goToPublicResumeWeb}>
                <Typography style={webStyle.navigationBtnText}>
                  Publically Available Resumes
                </Typography>
              </Button>
            </Box>
            </Box>
            <Typography style={webStyle.headingMyResumes} variant="h6">
              My Resumes
            </Typography>

            {this.renderAllResume()}
            {this.renderEditResumeModal()}
          </Box>
          <Modal
            open={this.state.isAddResumeModalOpenWeb}
            style={webStyle.modal}>
            <Container maxWidth={"sm"}>
              <Box sx={webStyle.modalContainer}>
                <Typography variant="h5" style={webStyle.modalHeading}>
                  Add New Resume
                </Typography>
                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="text"
                    placeholder="Enter the title for this resume"
                    style={webStyle.modalTextInput}
                    value={this.state.titleWeb}
                    data-test-id="addNewResumeTitleInput"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      this.handleResumeInputChangeText(
                        event.target.value,
                        "titleWeb",
                      )
                    }
                  />
                </Box>
                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="file"
                    id="addNewResumeFileLabel"
                    data-test-id="addNewResumeFileInput"
                    inputProps={{ accept: "application/pdf" }}
                    placeholder="Select resume file"
                    style={webStyle.modalTextInputFile}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      this.handleAddResumeFileUpload(event)
                    }
                  />
                  <label htmlFor="addNewResumeFileLabel" style={webStyle.candidateManagementLabel}>
                    Select resume file ("pdf format only")
                  </label>
                </Box>
                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="text"
                    data-test-id="addNewResumeSummaryInput"
                    placeholder="Enter your resume summary"
                    style={webStyle.modalTextInput}
                    value={this.state.newResumeSummaryWeb}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      this.handleResumeInputChangeText(
                        event.target.value,
                        "newResumeSummaryWeb",
                      )
                    }
                  />
                </Box>
                <Box sx={webStyle.checkboxWithNoteContainer}>
                  <Checkbox
                    onClick={this.toggleCheckbox}
                    data-test-id="addNewResumeCheckbox"
                  />
                  <Typography style={webStyle.checkBoxNote}>
                    Do you want to show this resume to all ?
                  </Typography>
                </Box>
                <Box sx={webStyle.modelButtonsContainer}>
                  <Button
                    style={webStyle.modalBtnCancel}
                    onClick={this.handleAddResumeModalOpen}>
                    Cancel
                  </Button>
                  <Button
                    style={webStyle.modalBtnSubmit}
                    onClick={this.handleSubmitResumeWeb}
                    data-test-id="addNewResumeSubmitBtn">
                    Submit
                  </Button>
                </Box>
                <Box sx={webStyle.showAlertMsg}>{this.state.showAlertMsg}</Box>
              </Box>
            </Container>
          </Modal>
          <Modal open={this.state.isProfileShownWeb}>
            <Container maxWidth="sm">
              <Box sx={webStyle.darkBgModal}>
                <Typography style={webStyle.darkBgHeading} variant="h3">
                  Profile Details
                </Typography>
                <Box sx={webStyle.profileDetailsContainer}>
                  <Box sx={webStyle.profileDetailsContainerRow}>
                    <Box sx={webStyle.rowHeading}>Person Name:</Box>
                    <Box sx={webStyle.rowInfo}>
                      {this.state.profileDataWeb?.name}
                    </Box>
                  </Box>
                  <Box sx={webStyle.profileDetailsContainerRow}>
                    <Box sx={webStyle.rowHeading}>Age:</Box>
                    <Box sx={webStyle.rowInfo}>
                      {this.state.profileDataWeb?.age}
                    </Box>
                  </Box>
                  <Box sx={webStyle.profileDetailsContainerRow}>
                    <Box sx={webStyle.rowHeading}>Years of Experience:</Box>
                    <Box sx={webStyle.rowInfo}>
                      {this.state.profileDataWeb?.years_of_experience}
                    </Box>
                  </Box>
                  <Box sx={webStyle.profileDetailsContainerRow}>
                    <Box sx={webStyle.rowHeading}>Experience Summary:</Box>
                    <Box sx={webStyle.rowInfo}>
                      {this.state.profileDataWeb?.experience_summary}
                    </Box>
                  </Box>
                  <Box sx={webStyle.profileDetailsContainerRow}>
                    <Box sx={webStyle.rowHeading}>Education Summary:</Box>
                    <Box sx={webStyle.rowInfo}>
                      {this.state.profileDataWeb?.education_summary}
                    </Box>
                  </Box>
                  <Box sx={webStyle.profileDetailsContainerRow}>
                    <Box sx={webStyle.rowHeading}>Education File:</Box>
                    <Link
                      style={webStyle.rowInfo}
                      target="_blank"
                      href={
                        this.state.profileDataWeb.education_file?.toString() &&
                        this.state.profileDataWeb.education_file.toString()
                      }
                      data-test-id="downloadEduFile">
                      <RedEye fontSize="medium" />
                    </Link>
                  </Box>
                  <Box sx={webStyle.profileDetailsContainerRow}>
                    <Box sx={webStyle.rowHeading}>Experience File:</Box>
                    <Link
                      style={webStyle.rowInfo}
                      target="_blank"
                      href={
                        this.state.profileDataWeb.experience_file?.toString() &&
                        this.state.profileDataWeb?.experience_file.toString()
                      }
                      data-test-id="downloadExpFile">
                      <RedEye fontSize="medium" />
                    </Link>
                  </Box>
                  <Box sx={webStyle.profileDetailsButtonsContainer}>
                    <Button
                      style={webStyle.profileDetailsBtn}
                      onClick={this.toggleMainProfile}
                      data-test-id="goBackBtn">
                      Go Back
                    </Button>
                    <Button
                      style={webStyle.profileDetailsBtn}
                      onClick={this.toggleEditProfile}
                      data-test-id="editProfilesBtn">
                      Edit Profile
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Modal>

          <Modal
            open={this.state.isEditProfileWeb}
            style={webStyle.editProfileModal}>
            <Container maxWidth={"sm"}>
              <Box sx={webStyle.lightBgModal}>
                <Box sx={webStyle.lightBgHeadingContainer}>
                  <Typography variant="h5">Update Profile</Typography>
                </Box>
                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="text"
                    placeholder="Enter your profile name"
                    style={webStyle.modalTextInput}
                    value={this.state.profileDataWeb?.name}
                    data-test-id="updateProfileNameInput"
                    onChange={(text) =>
                      this.handleUpdateProfileChangeText(
                        text.target.value,
                        "name",
                      )
                    }
                  />
                </Box>

                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="text"
                    placeholder="Enter your age"
                    style={webStyle.modalTextInput}
                    value={this.state.profileDataWeb?.age}
                    data-test-id="updateProfileAgeInput"
                    onChange={(text) =>
                      this.handleUpdateProfileChangeText(
                        text.target.value,
                        "age",
                      )
                    }
                  />
                </Box>

                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="text"
                    placeholder="Enter your years of experience"
                    style={webStyle.modalTextInput}
                    value={this.state.profileDataWeb?.years_of_experience}
                    data-test-id="updateProfileYearsOfExpInput"
                    onChange={(text) =>
                      this.handleUpdateProfileChangeText(
                        text.target.value,
                        "years_of_experience",
                      )
                    }
                  />
                </Box>

                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="text"
                    placeholder="Enter your profile experience summary"
                    style={webStyle.modalTextInput}
                    value={this.state.profileDataWeb?.experience_summary}
                    data-test-id="updateProfileExpSummaryInput"
                    onChange={(text) =>
                      this.handleUpdateProfileChangeText(
                        text.target.value,
                        "experience_summary",
                      )
                    }
                  />
                </Box>

                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="text"
                    placeholder="Enter your profile education summary"
                    style={webStyle.modalTextInput}
                    value={this.state.profileDataWeb?.education_summary}
                    data-test-id="updateProfileEduSummaryInput"
                    onChange={(text) =>
                      this.handleUpdateProfileChangeText(
                        text.target.value,
                        "education_summary",
                      )
                    }
                  />
                </Box>

                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="file"
                    id="updateProfileEducationLabel"
                    inputProps={{ accept: "application/pdf" }}
                    placeholder="Select education file"
                    style={webStyle.modalTextInputFile}
                    data-test-id="updateProfileEduFileInput"
                    onChange={(text: ChangeEvent<HTMLInputElement>) =>
                      this.handleEducationFile(text)
                    }
                  />
                  <label htmlFor="updateProfileEducationLabel" style={webStyle.candidateManagementLabel}>Upload education file</label>
                </Box>
                <Box style={webStyle.modalTextInputBox}>
                  <Input
                    type="file"
                    id="updateProfileExperienceLabel"
                    inputProps={{ accept: "application/pdf" }}
                    placeholder="Select experience"
                    style={webStyle.modalTextInputFile}
                    onChange={(text: ChangeEvent<HTMLInputElement>) =>
                      this.handleExperienceFile(text)
                    }
                    data-test-id="updateProfileExpFileInput"
                  />
                  <label htmlFor="updateProfileExperienceLabel" style={webStyle.candidateManagementLabel}>Upload experience file</label>
                </Box>
                <Box sx={webStyle.showAlertMsg}>{this.state.showAlertMsg}</Box>
                <Box sx={webStyle.twoBtnContainer}>
                  <Button
                    style={webStyle.updateProfileSubmitBtn}
                    onClick={this.toggleEditProfile}
                    data-test-id="updateProfileCancelBtn">
                    Cancel
                  </Button>
                  <Button
                    style={webStyle.updateProfileSubmitBtn}
                    onClick={this.handleSubmitProfile}
                    data-test-id="updateProfileUpdateBtn">
                    Update
                  </Button>
                </Box>
              </Box>
            </Container>
          </Modal>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  accordionDetails: {
    padding: "0 6px 0",
  },
  typeDropdownBtnsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "2px",
  },
  typeDropdownContainer: {
    backgroundColor: "grey",
    padding: "5px",
    width: "90px",
    boxShadow: "none",
    zIndex: 99999,
    marginRight:"5px"
  },
  showAlertMsg: {
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "tomato",
  },
  rowInfo: {
    width: "50%",
    color: "white",
    fontWeight: 700,
  },
  rowHeading: {
    width: "50%",
    color: "white",
    fontWeight: "bold",
  },
  editProfileModal: {
    position: "absolute" as "absolute",
    overflow: "scroll",
    display: "block",
  },
  updateProfileSubmitBtn: {
    backgroundColor: "#800000",
    height: "50px",
    width: "120px",
    color: "white",
    borderRadius: "12px",
  },
  profileDetailsBtn: {
    width: "160px",
    height: "60px",
    color: "white",
    backgroundColor: "grey",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  profileDetailsButtonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "15px",
    margin: "10px 0",
  },
  profileDetailsContainerRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    marginBottom: "10px",
  },
  profileDetailsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "15px",
  },
  lightBgHeadingContainer: {
    width: "100%",
    textAlign: "center",
    margin: "10px 0",
  },
  lightBgModal: {
    bgcolor: "white",
    padding: "10px",
  },
  darkBgModal: {
    height: "100%",
    bgcolor: "black",
    padding: "10px",
  },
  darkBgHeading: {
    color: "white",
    fontWeight: 500,
    fontSize: "22px",
    margin: "0px auto",
    width: "max-content",
  },
  singleBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  twoBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "10px 0",
  },
  modalBtnCancel: {
    height: "50px",
    width: "120px",
    backgroundColor: "#260041",
    color: "white",
    fontWeight: 600,
    borderRadius: "12px",
  },
  modalBtnSubmit: {
    height: "50px",
    width: "120px",
    backgroundColor: "003300",
    color: "white",
    fontWeight: 600,
    borderRadius: "12px",
  },
  checkBox: {
    height: "15px",
    width: "15px",
    border: "1px solid black",
  },
  checkBoxNote: {
    fontSize: "20px",
    fontWeight: 500,
  },
  checkboxWithNoteContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "20px 0",
  },
  singleEyeBtnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
  },
  modelButtonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    margin: "30px 0",
  },
  modalTextInputBox: {
    height: "80px",
    border: ".5px solid lightgrey",
    backgroundColor: "grey",
    borderRadius: "12px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTextInput: {
    height: "80px",
    width: "80%",
    color: "white",
  },
  modalTextInputFile: {
    display: "none",
    cursor: "pointer"
  },
  modalHeading: {
    margin: "5px auto",
    width: "220px",
  },
  modalContainer: {
    height: "100%",
    backgroundColor: "white",
    padding: "10px",
  },
  modalDarkBgContainer: {
    backgroundColor: "black",
    padding: "15px",
    marginTop: "36px",
    height: "100%",
  },
  modal: {
    backgroundColor: "white",
  },
  listBoxItemButtons: {
    display: "flex",
    justifyContent: "space-between",
    height: "90px",
    alignItems: "center",
    padding: "0 20px",
    color: "white",
  },
  listBoxPublishTypeItem: {
    width: "192spx",
    fontSize: "19px",
    fontWeight: "bold",
  },
  listBoxNameAndSummaryItem: {
    width: "162px",
    fontSize: "19px",
    fontWeight: "bold",
  },
  listBoxItemData: {
    width: "100%",
    textAlign: "center",
    textTransform: "capitalize",
    fontSize: "20px",
    padding: "2px 0",
  },
  listBoxItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    padding: "6px 0",
  },
  listContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "20px 0",
  },
  listItemsContainer: {
    border: "3px solid white",
    borderRadius: "12px",
    padding: "15px",
    width: "520px",
    minHeight: "330px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "10px",
  },
  navigationBtnBox1: {
    display: "flex",
    justifyContent: "space-between",
  },
  eyeBigBtn: {
    height: "55px",
    width: "150px",
    backgroundColor: "skyblue",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  eyeBtn: {
    height: "55px",
    width: "80px",
    backgroundColor: "#0000cc",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
  },
  deleteBtn: {
    height: "55px",
    width: "80px",
    backgroundColor: "tomato",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
  },
  editBtn: {
    height: "55px",
    width: "80px",
    backgroundColor: "006600",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    color: "#fff",
    fontSize: "22px",
    flexDirection:"column"
  },
  mainHeading: {
    color: "#fff",
    fontSize: "29px",
    margin: "12px auto",
  },
  searchContainer: {
    height: "70px",
    width: "100%",
    margin: "20px auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.5px solid grey",
    borderRadius: "10px",
    backgroundColor: "grey",
  },
  searchIconBox: {
    height: "100%",
    width: "80px",
    color: "darkgrey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchInput: {
    color: "white",
    height: "100%",
  },
  navigationBtn: {
    cursor: "pointer",
    height: "60px",
    width: "40%",
    backgroundColor: "green",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
  navigationLargeBtn: {
    cursor: "pointer",
    height: "60px",
    width: "100%",
    backgroundColor: "green",
    marginTop: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
  navigationBtnText: {
    color: "#fff",
    fontWeight: 400,
  },
  headingMyResumes: {
    color: "white",
    fontSize: "22px",
    width: "100%",
    textAlign: "center" as "center",
    textDecoration: "underline",
  },
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    minHeight: "100vh",
    background: "black",
  },
  profileImg: {
    border: "0.5px solid black",
    borderRadius: "50%",
    height: "80px",
    width: "80px",
    cursor: "pointer",
  },
  candidateManagementLabel: {
    cursor: "pointer"
  },
  searchAndButtonsContainer:{
    padding:"10px"
  }
};
// Customizable Area End
