import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import TimesheetManagementController, {
  Props,
  configJSON,
} from "./TimesheetManagementController";
import { homeScreen } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export default class TimesheetManagement extends TimesheetManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <div style={webStyle.imageWrapper}>
              <img src={homeScreen} alt="Home Screen, Welcome!" />
            </div>
            <Typography
              variant="subtitle1"
              component="div"
              style={webStyle.text}>
              {configJSON.labelSelectOption}
            </Typography>

            <Box sx={webStyle.buttonsWrapper}>
              <Button
                data-test-id="btn-timesheet"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => this.openTimeSheets()}>
                {configJSON.labeViewTimeSheets}
              </Button>

              <Button
                data-test-id="btn-tasks"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => this.openTasks()}>
                {configJSON.labelViewTasks}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  text: {
    marginTop: 50,
    marginBottom: 24,
  },
  imageWrapper: {
    height: "200px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    marginTop: 50,
  },
  buttonsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
  },
};
// Customizable Area End
