import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  meetingId: string;
  micEnabled: boolean;
  webcamEnabled: boolean;
  token: string;
  roomId: string;
  isRoomValidated: string;
  displayName: string;
  isVisibleCreateMeetingContainer: boolean;
  isVisibleJoinMeetingContainer: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ScreenSharingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTokenCallId: string = "";
  createRoomCallId: string = "";
  validateRoomCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      meetingId: "",
      micEnabled: true,
      webcamEnabled: true,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI4ZTJhN2U3OS1jMDkzLTQ3OTYtYjc4OC1iOWM2NThjNmYxOGYiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY4MjQ5ODE4NCwiZXhwIjoxNjg1MDkwMTg0fQ.9X8u8gERmcbi-Lo5H7r3ncpTbn5fMJkLFVDejcjSLiE",
      roomId: "",
      isRoomValidated: "",
      displayName: "",
      isVisibleCreateMeetingContainer: false,
      isVisibleJoinMeetingContainer: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
