import React from "react";

// Customizable Area Start
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import { Styles } from "@material-ui/styles";
import { withStyles, Theme } from "@material-ui/core/styles";

import LeaveTrackerHomeController, {
  Props,
} from "./LeaveTrackerHomeController.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles: Styles<Theme, {}, string> = () => ({
  pickerPartContainer: {
    "& .color-green": {
      borderRadius: "0.3rem",
      backgroundColor: "green",
      color: "white",
    },
    "& .color-red": {
      borderRadius: "0.3rem",
      backgroundColor: "red",
      color: "white",
    },
    "& .color-orange": {
      borderRadius: "0.3rem",
      backgroundColor: "orange",
      color: "white",
    },
  },
});
// Customizable Area End

export class LeaveTrackerHome extends LeaveTrackerHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkLeaveStatusValue = (value: string) => {
    switch (value) {
      case "Approved":
        return <div style={webStyles.greenDot} />;
      case "Pending":
        return <div style={webStyles.pending} />;

      case "Rejected":
        return <div style={webStyles.redDot} />;

      default:
        return <div />;
    }
  };
  checkLeaveStatusValueTxt = (value: string) => {
    switch (value) {
      case "Approved":
        return webStyles.dateApproved;
      case "Pending":
        return webStyles.datePending;
      case "Rejected":
        return webStyles.dateRejected;
      default:
        return {};
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { leaveDetails } = this.state;

    return (
      <div style={webStyles.container} data-test-id="container">
        <div style={webStyles.header}>
          <div style={webStyles.title}>Leave List</div>
        </div>
        <div style={webStyles.line} />
        <div
          className={this.props?.classes?.pickerPartContainer}
          style={webStyles.pickerPartContainer}>
          <DatePicker
            data-test-id={"datePicker"}
            selected={new Date()}
            onChange={this.handleDateChange}
            dateFormat="dd/MM/yyyy"
            highlightDates={this.state.markedDates as []}
            selectsRange
            inline
          />

          <div style={webStyles.leaveStatusContainer}>
            <div style={webStyles.rowDirection}>
              <div style={{ ...webStyles.dot, ...webStyles.redColor }} />
              <div style={webStyles.statusText}>Rejected</div>
            </div>
            <div style={webStyles.rowDirection}>
              <div style={{ ...webStyles.dot, ...webStyles.greenColor }} />
              <div style={webStyles.statusText}>Approved</div>
            </div>
            <div style={webStyles.rowDirection}>
              <div style={{ ...webStyles.dot, ...webStyles.orangeColor }} />
              <div style={webStyles.statusText}>Pending</div>
            </div>
          </div>
          <div style={webStyles.addIconContainer}>
            <div
              {...this.navigateToCreateLeaveView}
              data-test-id="navigateToCreateLeave">
              <AddCircleIcon
                onClick={this.navigateToCreateLeaveView}
                style={webStyles.addIcon}
              />
            </div>
          </div>
        </div>
        <div>
          <Dialog
            open={this.state.isLeaveDetailModalVisible}
            onClose={this.onClose}
            fullWidth={true}
            maxWidth={"sm"}>
            <DialogContent>
              <div style={webStyles.modalContainer}>
                <div style={webStyles.leaveDetailText}>Leave Details</div>
                <div style={webStyles.dataContainer}>
                  <div style={webStyles.titleText}>Employee ID</div>
                  <div style={webStyles.valueText}>
                    {leaveDetails.employee_id}
                  </div>
                </div>
                <div style={webStyles.dataContainer}>
                  <div style={webStyles.titleText}>Employee Name</div>
                  <div style={webStyles.valueText}>{leaveDetails.employee_name}</div>
                </div>
                <div style={webStyles.dataContainer}>
                  <div style={webStyles.titleText}>Leave Name</div>
                  <div style={webStyles.valueText}>{leaveDetails.name}</div>
                </div>
                <div style={webStyles.dataContainer}>
                  <div style={webStyles.titleText}>Leave Type</div>
                  <div style={webStyles.valueText}>
                    {leaveDetails.leave_type}
                  </div>
                </div>
                <div style={webStyles.dataContainer}>
                  <div style={webStyles.titleText}>Leave Status</div>
                  <div style={webStyles.valueText}>
                    {leaveDetails.leave_status}
                  </div>
                </div>

                <div style={webStyles.rowDirection}>
                  <div style={webStyles.dataContainer}>
                    <div style={webStyles.titleText}>Start Date</div>
                    <div style={webStyles.valueText}>
                      {this.handleFormatDate(leaveDetails.start_date)}
                    </div>
                  </div>
                  <div
                    style={{
                      ...webStyles.dataContainer,
                      ...webStyles.endDate,
                    }}>
                    <div style={webStyles.titleText}>End Date</div>
                    <div style={webStyles.valueText}>
                      {this.handleFormatDate(leaveDetails.end_date)}
                    </div>
                  </div>
                </div>
                <div style={webStyles.dataContainer}>
                  <div style={webStyles.titleText}>Reason For Leave</div>
                  <div style={webStyles.valueText}>
                    {leaveDetails.leave_reason}
                  </div>
                </div>
                <div style={{ margin: 20 }} />
                {leaveDetails.leave_status === "Pending" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleUpdateClick}
                    data-test-id="updateLeave">
                    Update Leave
                  </Button>
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  greenDot: {
    width: 4,
    height: 4,
    backgroundColor: "#00d40e",
    borderRadius: 2,
    position: "absolute" as const,
    top: 2,
  },
  redDot: {
    width: 4,
    height: 4,
    backgroundColor: "#e02020",
    borderRadius: 2,
    position: "absolute" as const,
    top: 2,
  },
  pending: {
    width: 4,
    height: 4,
    backgroundColor: "orange",
    borderRadius: 2,
    position: "absolute" as const,
    top: 2,
  },
  dateRejected: { color: "red" },
  dateApproved: { color: "green" },
  datePending: { color: "orange" },
  addIconContainer: {
    position: "absolute" as const,
    bottom: "5%",
    right: "10%",
    cursor: "pointer",
  },
  addIcon: {
    fontSize: "50px",
    color: "#826ffc",
    cursor: "pointer",
  },
  rowDirection: { display: "flex", flexDirection: "row" as const },
  endDate: {
    marginLeft: 60,
  },
  valueText: {
    fontFamily: "PlusJakartaSans",
    fontSize: 16,
    fontWeight: "bold" as const,
    fontStyle: "normal" as const,
    letterSpacing: -0.04,
    color: "#545454",
    marginTop: 4,
  },
  titleText: {
    opacity: 0.6,
    fontFamily: "PlusJakartaSans",
    fontSize: 14,
    fontWeight: "normal" as const,
    fontStyle: "normal" as const,
    letterSpacing: 0.01,
    color: "#333333",
  },
  dataContainer: {
    marginTop: 24,
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column" as const,
    backgroundColor: "#fcfcfc",
    padding: 24,
  },
  leaveDetailText: {
    fontSize: 18,
    fontWeight: "bold" as const,
    fontStyle: "normal" as const,
    letterSpacing: 0,
    color: "#000000b2",
  },
  line: {
    backgroundColor: "#ececec",
    width: "100%",
    height: 2,
    top: 50,
    zIndex: 5,
  },
  header: {
    display: "flex",
    backgroundColor: "#826ffc",
    height: 56,
    justifyContent: "center",
    flexDirection: "row" as const,
    alignItems: "center",
    paddingRight: 24,
    paddingLeft: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold" as const,
    fontStyle: "normal" as const,
    letterSpacing: 0,
    textAlign: "center" as const,
    color: "#ffffff",
  },
  leaveStatusContainer: {
    display: "flex",
    flexDirection: "row" as const,
    marginTop: 45,
    marginLeft: 29,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  pickerPartContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  dot: {
    width: 14,
    height: 14,
    borderRadius: 7,
    alignSelf: "center",
  },
  redColor: {
    backgroundColor: "#e02020",
    marginRight: 8.5,
  },
  greenColor: {
    backgroundColor: "#00d40e",
    borderRadius: 7,
    marginLeft: 16.5,
    marginRight: 8.5,
  },
  orangeColor: {
    backgroundColor: "orange",
    borderRadius: 7,
    marginLeft: 16.5,
    marginRight: 8.5,
  },
  statusText: {
    fontSize: 12,
    fontWeight: "bold" as const,
    fontStyle: "normal" as const,
    letterSpacing: 0,
    textAlign: "center" as const,
    color: "#697b7a",
  },
};

export default withStyles(useStyles)(LeaveTrackerHome);
// Customizable Area End
