import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLeaveDetailModalVisible: boolean;
  email: string;
  securedText: string;
  token: string;
  accountID: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeaveTrackerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.loginCallId = "";
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLeaveDetailModalVisible: false,
      isLoading: false,
      email: "y@y.com",
      securedText: "Y@123",
      token: "",
      accountID: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      this.setState({
        isLoading: false,
      });
      if (apiRequestCallId === this.loginCallId) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.setState({
              token: responseJson.meta.token,
              accountID: responseJson.meta.id,
            });
            localStorage.setItem("userToken", responseJson.meta.token);
            localStorage.setItem(
              "accountID",
              JSON.stringify(responseJson.meta.id),
            );
          },
          onFail: () => {
            this.showAlert(`Login  Failed`, "Please retry!");
          },
        });
      }
      // Customizable Area End
    }
  }
  // Customizable Area Start

  signIn = () => {
    this.setState({
      isLoading: true,
    });
    const body = {
      data: {
        attributes: {
          email: this.state.email,
          password: this.state.securedText,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.loginCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.loginInApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  async componentDidMount() {
    this.signIn();
  }

  navigateToAdminScreen = () => {
    this.props.navigation.navigate("LeaveRequest");
  };

  navigateToUserScreen = () => {
    this.props.navigation.navigate("LeaveTrackerHome");
  };
  // Customizable Area End
}
