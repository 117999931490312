Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.exampleApiContentType = "application/json";
exports.Referal_EndPoint = "/bx_block_referrals/referral_codes";
exports.Login_EndPoint = "/bx_block_login/logins";
exports.USERNAME = "buraktest1@gmail.com";
exports.PASSWORD = "123456";
// Customizable Area End
