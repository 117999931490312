import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import SpendItemController, { Props } from "./SpendItemController.web";

export default class SpendItem extends SpendItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"} style={webStyle.backGroundContent}>
          <Button
            data-testid="new_spend_item"
            onClick={this.handleSpendItemOpen}
            style={webStyle.newSpend}>
            Add Spend Item
          </Button>
          <Modal
            open={this.state.NewExpanse}
            onClose={this.handleSpendItemClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="new_spend_modal">
            <Box sx={webStyle.modalDesign}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {this.state.EditSpendItemId !== null
                  ? "Update Spend Item"
                  : "Add Spend Item"}
              </Typography>
              <Divider />

              <Box style={webStyle.marginContent}>
                <InputLabel>Name</InputLabel>
                <input
                  data-testid="newSpendName"
                  type="text"
                  onChange={this.createNewName}
                  value={this.state.NewExpanseName}
                />
              </Box>
              <Box style={webStyle.marginContent}>
                <InputLabel>Description</InputLabel>
                <input
                  data-testid="newSpendAmount"
                  type="text"
                  onChange={this.createNewAmount}
                  value={this.state.NewExpanseAmount}
                />
              </Box>
              <Box style={webStyle.marginContent}>
                <InputLabel>Date</InputLabel>
                <input
                  data-testid="newSpendDate"
                  type="Date"
                  placeholder=""
                  onChange={this.CreateNewDate}
                  value={this.state.date}
                />
              </Box>
              <Box style={webStyle.saveButton}>
                {this.state.EditSpendItemId !== null ? (
                  <Button
                    data-testid="edit_new_spend"
                    style={webStyle.spendExpense}
                    onClick={this.updateSpendItem}>
                    Update
                  </Button>
                ) : (
                  <Button
                    data-testid="save_spend_expense"
                    style={webStyle.spendExpense}
                    onClick={this.checkAllDataFilled}>
                    Save
                  </Button>
                )}
              </Box>
            </Box>
          </Modal>
          <Dialog
            open={this.state.deleteSpendItem}
            onClose={this.handleDeleteSpendItemClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <Typography style={webStyle.textWrapper}>Delete</Typography>
              Are you sure, you want to delete ${this.state.deleteSpendName}?
            </DialogTitle>
            <DialogActions>
              <Button
                data-testid="yes_delete_button"
                style={webStyle.spendExpense}
                onClick={() =>
                  this.deleteSpendItem(this.state.deleteSpendItemID)
                }>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Typography style={{ marginTop: "30px" }}>Spend Item</Typography>
          <Table data-testid="Spend_Item_Table" style={webStyle.tableWrapper}>
            <TableHead style={webStyle.tableBackground}>
              <TableRow>
                <TableCell style={webStyle.textColor}>Name</TableCell>
                <TableCell style={webStyle.textColor} align="right">
                  description
                </TableCell>
                <TableCell style={webStyle.textColor} align="right">
                  Date
                </TableCell>
                <TableCell style={webStyle.textColor} align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={webStyle.tableBodyWrap}>
              {this.state.spendItemData?.map((item, index) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item?.attributes.name}
                    </TableCell>
                    <TableCell align="right">
                      {item?.attributes.description}
                    </TableCell>
                    <TableCell align="right">
                      {item?.attributes.updated_at.slice(0, 10)}
                    </TableCell>
                    <TableCell align="right">
                      <Edit
                        data-testid="EditSpendItem"
                        onClick={async () => {
                          await this.handleEditSpendItem(
                            this.state.spendItemData[index].id,
                          );
                        }}
                        style={webStyle.editIconWrap}
                      />
                      <DeleteIcon
                        data-testid="deleteSpendItem"
                        onClick={() => {
                          this.handleDeleteSpendItem(
                            this.state.spendItemData[index].id,
                            this.state.spendItemData[index].attributes.name,
                          );
                        }}
                        style={webStyle.deleteIconWrap}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  backGroundContent: {
    background: "#D3D3D3",
  },
  tableBodyWrap: {
    background: "#FFFFFF",
  },
  tableBackground: {
    background: "#9370DB",
  },
  textColor: {
    color: "#FFFFFF",
  },
  saveButton: {
    marginLeft: "10px",
  },
  textWrapper: {
    fontSize: "22px",
  },
  newSpend: {
    background: "#9370DB",
    color: "#FFFFFF",
  },
  marginContent: {
    margin: "10px",
  },
  spendExpense: {
    backgroundColor: "#0ca3d5",
    width: "130px",
    color: "#FFFFFF",
  },
  tableWrapper: {
    margin: "10px 0px 10px 0px",
    width: "100%",
  },
  modalDesign: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgColor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    background: "#FFFFFF",
    borderRadius: "15px",
  },
  deleteIconWrap: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  editIconWrap: {
    cursor: "pointer",
  },
};
// Customizable Area End
