// Customizable Area Start
// Customizable Area End
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Certificate, CertificationMappingObject } from "../types";
import { handleResponseMessage } from "../helpers/handle-response-message";
import createRequestMessage from "../helpers/create-request-message";
import { getStorageData } from "../../../../framework/src/Utilities";
import { certificateStatusList } from "../constants";

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  userId: number;
  token: string;
  isLoading: boolean;
  certificateMappingObject: CertificationMappingObject;
  certificateIdArray: string[];
  alertModal: boolean;
  titleModal: string;
  contentModal: string;
  success: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeCertStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeeCertWebApi: string;
  updateCertStatusWebApi: string;
  initialId: number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleUpdateCertStatusWeb = this.handleUpdateCertStatusWeb.bind(this);
    this.handleGetEmployeeCertWeb = this.handleGetEmployeeCertWeb.bind(this);
    this.handleGoBackWeb = this.handleGoBackWeb.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    this.updateCertStatusWebApi = "";
    this.getEmployeeCertWebApi = "";
    this.initialId = this.props.navigation?.getParam("id") as number;

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userId: this.initialId ?? 0,
      token: "",
      isLoading: false,
      certificateMappingObject: {},
      certificateIdArray: [],
      alertModal: false,
      titleModal: "",
      contentModal: "",
      success: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      switch (apiId) {
        case this.getEmployeeCertWebApi: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              const { data } = responseJson;

              let certMappingObject: CertificationMappingObject = {};

              let dataIdArray: string[] = [];

              data.map((item: Certificate) => {
                certMappingObject = {
                  ...certMappingObject,
                  [item.id!.toString()]: item,
                };
                dataIdArray = [...dataIdArray, item.id!.toString()];
              });

              this.setState({
                certificateMappingObject: certMappingObject,
                certificateIdArray: dataIdArray,
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Get List Failed!",
              });
            },
          });
          break;
        }

        case this.updateCertStatusWebApi: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              const { data } = responseJson;

              this.setState((prevState) => ({
                certificateMappingObject: {
                  ...prevState.certificateMappingObject,
                  [data.id]: data,
                },
              }));

              this.setState({
                alertModal: true,
                titleModal: "Success",
                success: true,
                contentModal: "Status of certificate updated successfully!",
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Update Failed!",
              });
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleGetTokenWeb();
  }

  async handleGetTokenWeb() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        this.handleGetEmployeeCertWeb();
      },
    );
  }

  handleGetEmployeeCertWeb() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getEmployeeCertWebApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.employeesURLEndPoint}/${this.state.userId}/${configJSON.employeesCertificate}`,
      method: configJSON.getApiMethodType,
      token: this.state.token,
    });
  }

  handleUpdateCertStatusWeb(statusId: number, certificate: Certificate) {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updateCertStatusWebApi = requestMessage.messageId;

    const status = certificateStatusList.find(
      (item) => item.id === statusId,
    )?.code;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.employeesCertificateTrackingURLEndPoint}/${certificate.id}/update/certificate?status=${status}`,
      method: configJSON.putApiMethodType,
      token: this.state.token,
    });
  }

  handleGoBackWeb() {
    this.props.navigation.navigate("EmployeeCertificateEmployeeList");
  }

  handleCloseAlert() {
    this.setState({
      alertModal: false,
      titleModal: "",
      contentModal: "",
    });
  }
  // Customizable Area End
}
