Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postApiMethod = "POST";
exports.deleteAPIMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveStreaming";
exports.labelBodyText = "LiveStreaming Body";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.deleteApiMethodType = "DELETE";
exports.loginInApiEndPoint = "bx_block_login/logins";
exports.generateTokenApiEndPoint =
  "bx_block_livestream/livestream/generate_token";
exports.fetchAllMeetingsApiEndPoint =
  "bx_block_livestream/livestream/list_meeting";
exports.fetchAllRecordingsApiEndPoint =
  "bx_block_livestream/livestream/list_recording";
exports.createMeetingApiEndPoint =
  "bx_block_livestream/livestream/create_meeting";
exports.validateMeetingApiEndPoint =
  "bx_block_livestream/livestream/validate_meeting";
exports.validateMeetingParticipantApiEndPoint =
  "bx_block_livestream/livestream/validate_participant";
exports.startRecordingApiEndPoint =
  "bx_block_livestream/livestream/start_recording";
exports.stopRecordingApiEndPoint =
  "bx_block_livestream/livestream/stop_recording";
exports.deleteRecordingApiEndPoint =
  "bx_block_livestream/livestream/delete_recording";
exports.startRtmpLiveApiEndPoint = "bx_block_livestream/livestream/start_rtmp";
exports.stopRtmpLiveApiEndPoint = "bx_block_livestream/livestream/end_rtmp";
exports.startHlsLiveApiEndPoint = "bx_block_livestream/livestream/start_stream";
exports.stopHlsLiveApiEndPoint = "bx_block_livestream/livestream/stop_stream";
exports.hlsLiveListApiEndPoint = "bx_block_livestream/livestream/list_streams";
exports.liveActiveHlsApiEndPoint =
  "bx_block_livestream/livestream/active_stream";
exports.loginId = "buraktest1@gmail.com";
exports.loginPass = "123456";

exports.btnExampleTitle = "CLICK ME";
const authUrl = "/bx_block_livestream/livestream/";
exports.Generate_Token_EndPoint = `${authUrl}generate_token`;
exports.Create_Meeting_EndPoint = `${authUrl}create_meeting`;
exports.Validate_Meeting_EndPoint = `${authUrl}validate_meeting`;
exports.fetch_Session_EndPoint = `${authUrl}get_sessions`;
exports.Start_Recording_EndPoint = `${authUrl}start_recording`;
exports.Stop_Recording_EndPoint = `${authUrl}stop_recording`;
exports.Start_LiveStream_EndPoint = `${authUrl}start_rtmp`;
exports.Stop_LiveStream_EndPoint = `${authUrl}end_rtmp`;
exports.Start_HLStream_EndPoint = `${authUrl}start_stream`;
exports.Stop_HLStream_EndPoint = `${authUrl}stop_stream`;
exports.Active_HLStream_EndPoint = `${authUrl}active_stream`;
exports.Login_EndPoint = "/bx_block_login/logins";
exports.Meeting_EndPoint = "/bx_block_livestream/livestream/list_meeting";
exports.recordings_EndPoint = "/bx_block_livestream/livestream/list_recording";
exports.deleteRecordings_EndPoint =
  "/bx_block_livestream/livestream/delete_recording";
exports.listLiveStream_EndPoint =
  "/bx_block_livestream/livestream/list_streams";
exports.validateParticipantApiEndPoint =
  "/bx_block_livestream/livestream/validate_participant";
exports.Email = "buraktest1@gmail.com";
exports.Password = "123456";
// Customizable Area End
