import React from "react";
import moment from "moment";

import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  OutlinedInput,
  Card,
  CardContent,
  // Customizable Area Start
  Dialog,
  DialogTitle,
  DialogActions,
  // Customizable Area End
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { Pagination } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const CardContentStyled = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "20px 24px",
  position: "relative",
});
// Customizable Area End

import TimeSheetTaskListController, {
  Props,
  configJSON,
} from "./TimeSheetTaskListController";
import Title from "./components/Title.web";

export default class TimeSheetTaskList extends TimeSheetTaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  contextMenu(taskId: number) {
    const { contextMenuAnchorEl, contextMenuId } = this.state;

    return (
      <div
        data-testId="mainDivElement"
        style={webStyle.contextMenu as React.CSSProperties}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          data-testId="context_menu"
          className="moreVertIcons"
          onClick={(event: { stopPropagation: Function; target: {} }) =>
            this.openContextMenu(event, taskId)
          }>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={contextMenuAnchorEl as HTMLAnchorElement}
          keepMounted
          open={contextMenuId === taskId}
          data-testId="closeContext"
          onClose={() => this.closeContextMenu()}>
          <MenuItem
            data-test-id={`context_edit`}
            onClick={(event: { stopPropagation: Function; target: {} }) =>
              this.editTaskWeb(event, taskId)
            }>
            {configJSON.edit}
          </MenuItem>
          <MenuItem
            data-testId="contextDelete"
            onClick={(event) => this.removeTaskWeb(event, taskId)}>
            {configJSON.delete}
          </MenuItem>
        </Menu>
      </div>
    );
  }

  taskList() {
    if (!this.state.taskList.length) {
      return <Typography>{configJSON.noTasks}</Typography>;
    }

    return this.state.taskList.map((task) => {
      const { title, planned_hours, id } = task;

      return (
        <Card variant="outlined" key={id} data-testId="mainCard">
          <CardContentStyled data-testId="cardContent">
            <Box data-testId="card" onClick={() => this.openTaskDetailsWeb(id)}>
              <Typography variant="h6">{title}</Typography>
              <Typography color="textSecondary">
                {`${configJSON.labelTotalAllocatedTime}${planned_hours}Hrs`}
              </Typography>
            </Box>
            {this.contextMenu(id)}
          </CardContentStyled>
        </Card>
      );
    });
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Title
          title="Task list"
          data-test-id="title"
          onBackClick={() => this.redirectToMainScreen()}
        />
        <Container maxWidth="sm">
          <Box sx={webStyle.mainWrapper}>
            <OutlinedInput
              data-test-id="search"
              placeholder="Search"
              onChange={(event) =>
                this.txtSearchInputChange.onChangeText(event.target.value)
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />

            <Card variant="outlined">
              <CardContent style={webStyle.flexWrapper}>
                <Box sx={webStyle.flexMainItem}>
                  <Typography>{configJSON.createNewTask}</Typography>
                  <Typography color="textSecondary" variant="body2">
                    {moment().format("YYYY-MM-DD")}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  data-test-id="createTask"
                  onClick={() => this.openCreateTask()}>
                  {configJSON.create}
                </Button>
              </CardContent>
            </Card>
            <Typography>{configJSON.allTask}</Typography>
            {this.taskList()}
          </Box>
          <Pagination
            data-testId="paginationTaskList"
            count={this.state.totalPage}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            size="large"
            onChange={(event: object, newPageList: number) => {
              this.handleTaskListPage(event, newPageList);
            }}
          />
          <Dialog
            open={this.state.isDeleteTaskModal}
            onClose={this.handleCloseDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              {configJSON.messageDeleteTask}
            </DialogTitle>
            <DialogActions>
              <Box style={webStyle.deleteModal}>
                <Button
                  data-testid="no_delete_button"
                  style={webStyle.cancelButton}
                  onClick={this.handleCloseDeleteModal}>
                  Cancel
                </Button>
                <Button
                  data-testid="yes_delete_button"
                  style={webStyle.deleteButton}
                  onClick={() => this.callDeleteTask()}>
                  Delete
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
    gap: 32,
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  flexMainItem: {
    flex: "1 0 auto",
  },
  contextMenu: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  deleteModal: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "15px",
  },
  cancelButton: {
    backgroundColor: "cornsilk",
  },
  deleteButton: {
    backgroundColor: "floralwhite",
  },
};
// Customizable Area End
