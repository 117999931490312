import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert, Linking } from "react-native";
import * as Yup from "yup";
import storage from "../../../framework/src/StorageProvider";
import { EmergencySosTypes } from "./Types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  name: string;
  phone_number: string;
  description: string;
  type: string;
  is_active: string;
  is_modalOpen: boolean;
  authToken: string;
  emergencySos: EmergencySosTypes[];
  filteredData: EmergencySosTypes[];
  loading: boolean;
  editId?: number;
  selectedItem: string;
  formErrors: { [key: string]: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmergencySosController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getEmergencies_callID: string = "";
  postEmergencies_callID: string = "";
  deleteEmergencies_callID: string = "";
  editEmergencies_callID: string = "";
  login_callID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start

      description: "",
      is_active: "",
      name: "",
      phone_number: "",
      type: "",
      is_modalOpen: false,
      authToken: "",
      emergencySos: [],
      loading: false,
      filteredData: [],
      selectedItem: "all",
      formErrors: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value,
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    this.receiveLogin(message);
    this.receiveGet(message);
    this.receiveAdd(message);
    this.receiveUpdate(message);
    this.receiveDelete(message);
    // Customizable Area End
  }

  // Customizable Area Start

  receiveLogin = async (message: Message) => {
    if (
      this.login_callID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiResponse) {
        if (apiResponse.errors) {
          this.setState(
            {
              loading: false,
            },
            () => {
              Alert.alert("Not found " + JSON.stringify(apiResponse));
            },
          );
        } else {
          await storage.set("token", apiResponse.meta.token);
          this.setState({ authToken: apiResponse.meta.token }, () => {
            this.emergenciesFun();
          });
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  //get all list of the emergency alerts
  receiveGet = async (message: Message) => {
    if (
      this.getEmergencies_callID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiResponse) {
        if (apiResponse.errors) {
          this.setState(
            {
              loading: false,
            },
            () => {
              this.loginFunction();
            },
          );
        } else {
          this.setState({
            emergencySos: apiResponse,
            filteredData: apiResponse,
            loading: false,
          });
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  //add emergency in  list of the emergency alerts
  receiveAdd = async (message: Message) => {
    if (
      this.postEmergencies_callID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiResponse) {
        if (apiResponse.errors) {
          this.setState({ loading: false }, () => {
            Alert.alert("Error", JSON.stringify(apiResponse.errors));
          });
        } else {
          this.emergenciesFun();
          this.setState({
            loading: false,
          });
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  //edit emergency in  list of the emergency alerts
  receiveUpdate = async (message: Message) => {
    if (
      this.editEmergencies_callID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiResponse.data) {
        this.emergenciesFun();
      } else {
        this.setState({
          loading: false,
        });
        Alert.alert("error", JSON.stringify(apiResponse));
      }
    }
  };

  //delete emergency in  list of the emergency alerts
  receiveDelete = async (message: Message) => {
    if (
      this.deleteEmergencies_callID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiResponse) {
        if (apiResponse.errors) {
          this.setState({ loading: false }, () => {
            Alert.alert("Error", JSON.stringify(apiResponse.errors));
          });
        } else {
          this.emergenciesFun();
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  };

  emergencyValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name should be minimum of 3 characters")
      .required("Name should be minimum of 3 characters"),
    phone_number: Yup.string()
      .matches(
        /^[6-9]\d{9}$/,
        "Mobile number must valid start from 6 to 9 and must be 10 digits",
      )
      .required("Please write the mobile number")
      .min(10, "Mobile number must be 10 digits"),
    description: Yup.string()
      .min(3, "description is min of 3 character or more")
      .required("Please filled the description"),
    type: Yup.string().required("please select type"),
    is_active: Yup.string().required("please select status"),
  });

  // add the emergency
  addEmergencySOS = async (values: {
    name: string;
    phone_number: string;
    description: string;
    type: string;
    is_active: string;
  }) => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const postEmergencies = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postEmergencies_callID = postEmergencies.messageId;
    postEmergencies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Emergency_EndPoint,
    );
    postEmergencies.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );
    postEmergencies.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );

    const Data = {
      emergencies: {
        ...values,
        is_active: values.is_active.toLowerCase() === "active",
      },
    };

    postEmergencies.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Data),
    );
    runEngine.sendMessage(postEmergencies.id, postEmergencies);
    this.toggleModal();
  };

  //update the emergency
  updateEmergencySos = async (values: {
    name: string;
    phone_number: string;
    description: string;
    type: string;
    is_active: string;
  }) => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const editEmergencies = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.editEmergencies_callID = editEmergencies.messageId;
    editEmergencies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.Emergency_EndPoint}/${this.state.editId}`,
    );
    editEmergencies.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );
    editEmergencies.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT",
    );
    const Data = {
      emergencies: {
        ...values,
        is_active: values.is_active.toLowerCase() === "active",
      },
    };
    editEmergencies.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Data),
    );
    runEngine.sendMessage(editEmergencies.id, editEmergencies);
    this.toggleModal();
  };

  // delete emergency from data
  deleteEmergencySOS = async (emergencyId: number) => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const deleteEmergencies = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.deleteEmergencies_callID = deleteEmergencies.messageId;
    deleteEmergencies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.Emergency_EndPoint}/${emergencyId}`,
    );
    deleteEmergencies.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );
    deleteEmergencies.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE",
    );

    runEngine.sendMessage(deleteEmergencies.id, deleteEmergencies);
  };

  // for editing emergency service
  editEmergencySOS = (item: EmergencySosTypes) => {
    this.setState({
      editId: item.id,
      description: item.description,
      is_active: item.is_active ? "active" : "not active",
      name: item.name,
      phone_number: item.phone_number,
      type: item.type,
      is_modalOpen: true,
    });
  };

  //for toggling the modal
  toggleModal = () => {
    this.setState({
      is_modalOpen: !this.state.is_modalOpen,
      description: "",
      name: "",
      phone_number: "",
      type: "",
      editId: undefined,
      formErrors: {},
    });
  };

  //get all initial emergency list
  emergenciesFun = async () => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const getEmergencies = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getEmergencies_callID = getEmergencies.messageId;
    getEmergencies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Emergency_EndPoint,
    );
    getEmergencies.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );
    getEmergencies.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET",
    );
    runEngine.sendMessage(getEmergencies.id, getEmergencies);
  };

  // fake login function
  loginFunction = async () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
    };
    const loginRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.login_callID = loginRequest.messageId;
    loginRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Login_EndPoint,
    );
    loginRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );
    loginRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    const Data = {
      data: {
        attributes: {
          email: configJSON.USERNAME,
          password: configJSON.PASSWORD,
        },
        type: "email_account",
      },
    };

    loginRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Data),
    );
    runEngine.sendMessage(loginRequest.id, loginRequest);
  };

  async componentDidMount() {
    let token = await storage.get("token");
    if (!token) {
      this.loginFunction();
    } else {
      this.setState({ authToken: token }, () => {
        this.emergenciesFun();
      });
    }
  }

  // filtered by types :- LOR || SOS
  filteredByType = (type: string) => {
    this.setState({
      filteredData: this.state.emergencySos.filter((element) => {
        if ("all".toLowerCase() === type.toLowerCase()) {
          return element;
        } else {
          return element.type.toLowerCase() === type.toLowerCase();
        }
      }),
    });
  };

  // Web Functions
  filteredByTypeWeb = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    this.setState({
      selectedItem: event.target.value as string,
      filteredData: this.state.emergencySos.filter((element) => {
        if (
          "all".toLowerCase() === (event.target.value as string).toLowerCase()
        ) {
          return element;
        } else {
          return (
            element.type.toLowerCase() ===
            (event.target.value as string).toLowerCase()
          );
        }
      }),
    });
  };

  handleInputChange = (value: string, field: string) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  handleWebFormSubmit = () => {
    const formErrors: { [key: string]: string } = {};
    const { name, description, phone_number, is_active, type } = this.state;
    const formValues = {
      name,
      description,
      phone_number,
      is_active,
      type,
    };

    if (!name || name.length < 3) {
      formErrors.name = "Name should be minimum of 3 characters";
    }
    if (!description || description.length < 3) {
      formErrors.description = "Description should be minimum of 3 characters";
    }
    if (
      !phone_number ||
      phone_number.length !== 10 ||
      (!phone_number.startsWith("6") &&
        !phone_number.startsWith("7") &&
        !phone_number.startsWith("8") &&
        !phone_number.startsWith("9"))
    ) {
      formErrors.phone_number =
        "Mobile number must valid start from 6 to 9 and must be 10 digits";
    }

    if (!is_active) {
      formErrors.is_active = "Status is required.";
    }
    if (!type) {
      formErrors.type = "Type is required.";
    }

    if (Object.keys(formErrors).length < 1) {
      this.state.editId
        ? this.updateEmergencySos(formValues)
        : this.addEmergencySOS(formValues);
      this.setState({ formErrors: {} });
    } else {
      this.setState({ formErrors });
    }
  };
  // Call Emergency SOS

  callEmergencySos = (item: EmergencySosTypes) => {
    Linking.openURL(`tel:${item.phone_number}`);
  };

  // Customizable Area End
}
