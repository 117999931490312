import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Phone from "@material-ui/icons/Phone";

import EditIcon from "@material-ui/icons/Edit";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

const typeSOS = ["sos", "lor"];
const typeSOS1 = ["all", "sos", "lor"];
const isActiveSOS = ["active", "not active"];

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import EmergencySosController, {
  Props,
  configJSON,
} from "./EmergencySosController";

export default class EmergencySos extends EmergencySosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderList = () => {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return this.state.filteredData.length > 0 ? (
      <div style={webStyle.listContainer}>
        {this.state.filteredData.map((item, index) => (
          <div key={item.id} style={webStyle.cardContainer}>
            <div style={webStyle.title}>
              <Typography style={webStyle.idStyles}>{index + 1}.</Typography>
              <Typography style={webStyle.titleText}>{item.name}</Typography>
            </div>
            <div style={webStyle.title}>
              <Typography style={webStyle.textSub}>Type :</Typography>
              <Typography style={webStyle.textSub1}>{item.type}</Typography>
            </div>
            <div style={webStyle.title}>
              <Typography style={webStyle.textSub}>number :</Typography>
              <Typography style={webStyle.textSub1}>
                {item.phone_number}
              </Typography>
            </div>
            <div style={webStyle.title}>
              <Typography style={webStyle.textSub}>status :</Typography>
              <Typography style={webStyle.textSub1}>
                {item.is_active ? "active" : "not active"}
              </Typography>
            </div>
            <div style={webStyle.title}>
              <Typography style={webStyle.fontMed}>Description :</Typography>
              <Typography style={webStyle.textSub1}>
                {item.description ? item.description : "Na"}
              </Typography>
            </div>
            <div style={webStyle.iconContainer}>
              <a href={`tel:${item.phone_number}`}>
                <Phone style={webStyle.phoneIcon} color="secondary" />
              </a>

              <DeleteIcon
                style={webStyle.deleteIcon}
                color="secondary"
                data-test-id="deleteIcon"
                onClick={() => this.deleteEmergencySOS(item.id)}
              />
              <EditIcon
                style={webStyle.editIcon}
                data-test-id="editIcon"
                onClick={() => this.editEmergencySOS(item)}
              />
            </div>
          </div>
        ))}
      </div>
    ) : (
      <Typography variant="h6">No data found</Typography>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <div style={webStyle.buttonPartContainer}>
              <Button
                style={{ ...webStyle.buttonStyle, ...webStyle.bgGrey }}
                data-test-id="openModalBtn"
                onClick={this.toggleModal}>
                Add New Emergency
              </Button>

              <div style={webStyle.selectContainer}>
                <Typography style={webStyle.typeLabel}>
                  Filtered by type
                </Typography>
                <Select
                  style={webStyle.typeSelect}
                  data-test-id="bntFilterEmergency"
                  labelId="select-filled-label"
                  id="simple-select-filled"
                  value={this.state.selectedItem}
                  onChange={this.filteredByTypeWeb}>
                  {typeSOS1.map((item) => (
                    <MenuItem key={`typeSOS1-${item}`} value={item}>
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            {this.renderList()}
            <Dialog
              open={this.state.is_modalOpen}
              fullWidth={true}
              maxWidth={"sm"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <div style={webStyle.modalContainer}>
                  <Typography style={webStyle.modalTitle}>
                    Add New Emergency Query
                  </Typography>

                  <TextField
                    style={webStyle.textField}
                    data-test-id={"nameField"}
                    variant="outlined"
                    name={"name"}
                    placeholder={"Enter your name"}
                    onChange={(event) =>
                      this.handleInputChange(event.target.value, "name")
                    }
                    value={this.state.name}
                    required
                  />
                  {this.state.formErrors.name && (
                    <Typography variant="caption" style={webStyle.error}>
                      {this.state.formErrors.name}
                    </Typography>
                  )}

                  <TextField
                    style={webStyle.textField}
                    data-test-id={"phoneField"}
                    color={"primary"}
                    variant="outlined"
                    name={"phone_number"}
                    placeholder={"Enter your phone number"}
                    onChange={(event) =>
                      this.handleInputChange(event.target.value, "phone_number")
                    }
                    value={this.state.phone_number}
                    required
                  />
                  {this.state.formErrors.phone_number && (
                    <Typography variant="caption" style={webStyle.error}>
                      {this.state.formErrors.phone_number}
                    </Typography>
                  )}

                  <TextField
                    style={webStyle.textField}
                    data-test-id={"descriptionField"}
                    color={"primary"}
                    variant="outlined"
                    name={"description"}
                    placeholder={"Description"}
                    onChange={(event) =>
                      this.handleInputChange(event.target.value, "description")
                    }
                    value={this.state.description}
                    required
                  />
                  {this.state.formErrors.description && (
                    <Typography variant="caption" style={webStyle.error}>
                      {this.state.formErrors.description}
                    </Typography>
                  )}

                  <div style={webStyle.dropdownContainer}>
                    <Typography style={webStyle.typeLabel}>
                      Select the type
                    </Typography>
                    <Select
                      style={webStyle.typeSelect}
                      data-test-id="selectDrop"
                      labelId="select-filled-label"
                      id="simple-select-filled"
                      value={this.state.type}
                      onChange={(event) =>
                        this.handleInputChange(
                          event.target.value as string,
                          "type",
                        )
                      }>
                      {typeSOS.map((item) => (
                        <MenuItem key={`typeSOS-${item}`} value={item}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {this.state.formErrors.type && (
                    <Typography variant="caption" style={webStyle.error}>
                      {this.state.formErrors.type}
                    </Typography>
                  )}

                  <div style={webStyle.dropdownContainer}>
                    <Typography style={webStyle.typeLabel}>
                      Select the status
                    </Typography>
                    <Select
                      style={webStyle.typeSelect}
                      data-test-id="selectDrop"
                      labelId="select-filled-label"
                      id="simple-select-filled"
                      value={this.state.is_active}
                      onChange={(event) =>
                        this.handleInputChange(
                          event.target.value as string,
                          "is_active",
                        )
                      }>
                      {isActiveSOS.map((item) => (
                        <MenuItem key={`isActiveSOS-${item}`} value={item}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {this.state.formErrors.is_active && (
                    <Typography variant="caption" style={webStyle.error}>
                      {this.state.formErrors.is_active}
                    </Typography>
                  )}

                  <div style={webStyle.buttonsContainer}>
                    <Button
                      style={{ ...webStyle.buttonStyle, ...webStyle.closeBtn }}
                      data-test-id="closeBtn"
                      onClick={this.toggleModal}>
                      Close
                    </Button>

                    <Button
                      style={{ ...webStyle.buttonStyle, ...webStyle.addBtn }}
                      data-test-id="addBtn"
                      onClick={this.handleWebFormSubmit}>
                      {this.state.editId ? "Update" : "Add"}
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  error: {
    color: "red",
    marginTop: "-15px",
    marginBottom: "15px",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "red",
    cursor: "pointer",
    color: "white",
  },
  bgGrey: {
    backgroundColor: "grey",
  },
  buttonPartContainer: {
    display: "flex",
  },
  listContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  cardContainer: {
    width: "calc(50% - 40px)",
    marginLeft: "20px",
    marginBottom: "20px",
    borderRadius: "4px",
  },
  title: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    marginTop: 10,
    marginBottom: 10,
    textTransform: "capitalize" as "capitalize",
  },
  idStyles: {
    width: "30%",
    fontSize: 20,
  },
  titleText: {
    fontSize: 20,
    textTransform: "capitalize" as "capitalize",
  },
  textSub: {
    fontSize: 15,
    color: "#000",
  },
  textSub1: {
    fontSize: 14,
    marginLeft: 5,
    fontWeight: 400,
    width: "50%",
  },
  fontMed: { fontSize: 12 },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "16px",
  },
  editIcon: {
    color: "#006600",
    cursor: "pointer",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  phoneIcon: {
    color: "black",
    cursor: "pointer",
  },
  selectContainer: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "#3b3fa4",
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    borderRadius: "4px",
    marginLeft: "20px",
  },
  dropdownContainer: {
    width: "100%",
    height: "55px",
    backgroundColor: "#e8e8e8",
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    marginBottom: "20px",
  },
  typeSelect: {
    backgroundColor: "white",
    marginLeft: "16px",
    borderRadius: "4px",
    padding: "0 8px",
  },
  typeLabel: {
    fontSize: "12px",
  },
  modalContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  modalTitle: {
    marginBottom: "30px",
    fontWeight: 600,
    fontSize: "24px" as string,
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
    backgroundColor: "#e8e8e8",
  },
  closeBtn: {
    backgroundColor: "#3b3fa4",
    marginTop: 0,
    marginRight: "20px",
  },
  addBtn: {
    backgroundColor: "#008400",
    marginTop: 0,
  },
  buttonsContainer: {
    display: "flex",
  },
};
// Customizable Area End
