import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import { ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface ConvsersationResInterface {
  pinned_conversations: PinnedConversations
  message: string
}

export interface PinnedConversations {
  data: Daum[]
}

export interface Daum {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  id: number
  account_id: number
  message: string
  is_active: boolean
  created_by: number
  updated_by: number
  pinned: boolean
  responses: Responses
}

export interface Responses {
  data: Daum2[]
}

export interface Daum2 {
  id: string
  type: string
  attributes: Attributes2
}

export interface Attributes2 {
  id: number
  conversation_id: number
  account_id: number
  message: string
  is_active: boolean
  created_by: number
  created_at: string
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface PinConversationInterface {
  id: number;
  account_id: number;
  conversation_id?: number;
  message: string;
  is_active: boolean;
  created_by: number;
  created_at?: string;
}

interface ConversationInterface {
  id: number;
  account_id: number;
  message: string;
  responses: PinConversationInterface[];
  is_active?: boolean;
  created_by?: number;
  updated_by?: number;
  pinned: boolean;
}

interface ConversationApiInterface {
  pinnedConversation: ConversationInterface[];
}

interface S {
  conversation: ConversationApiInterface;
  token: string;
  totalPage: number;
  totalCount: number;
  pageIndex: number;
  currentPage: number;
  pageSize: number,
  prePage: number | null,
  nextPage: number | null,
  account_id: number;
  loader: boolean;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PinConversationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  InsertPinConversationApiID: string;
  loginApiCallId: string;
  viewAllConversationApiID: string;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.doButtonPressed = this.doButtonPressed.bind(this);
    this.login = this.login.bind(this);
    this.coversationList = this.coversationList.bind(this);
    this.handleUpdatePagination = this.handleUpdatePagination.bind(this);
    this.InsertPinConversationApiID = "";
    this.loginApiCallId = "";
    this.viewAllConversationApiID = "";
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      token: "",
      loader: false,
      account_id: 7,
      pageIndex: 1,
      totalCount: 1,
      totalPage: 1,
      pageSize: 5,
      prePage: null,
      nextPage: null,
      currentPage: 1,
      conversation: {
        pinnedConversation: [],
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.login();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      switch (apiRequestCallId) {
        case this.loginApiCallId:
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: async () => {
              this.setState({
                token: responseJson.meta.token,
                account_id: responseJson.meta.id,
              });
              this.coversationList(null, 1);
              this.setState({
                loader: false,
              });
              await setStorageData("TOKEN", responseJson.meta.token);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Token Failed. Please retry!");
              this.setState({
                loader: false,
              });
            },
          });
          break;

        case this.InsertPinConversationApiID:
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: async () => {
              this.coversationList(null, 1);
              this.setState({
                loader: false,
              });
            },
            onFail: () => {
              this.setState({
                loader: false,
              });
              this.showAlert(`Error`, "Pin Conversation Failed. Please retry!");
            },
          });
          break;

        case this.viewAllConversationApiID:
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: async () => {
              let conversationResponse: ConversationApiInterface = {
                pinnedConversation: [],
              };
              responseJson.pinned_conversations.data.forEach(
                (pinCon: Daum, index: number) => {
                  let conversation: Attributes =
                    pinCon.attributes;
                  let responses: PinConversationInterface[] = [];
                  if (
                    conversation.responses &&
                    conversation.responses.data.length
                  ) {
                    conversation.responses.data.forEach(
                      (response: Daum2, resInd: number) => {
                        responses.push({
                          id: response.attributes.id,
                          account_id: response.attributes.account_id,
                          conversation_id: response.attributes.conversation_id,
                          message: response.attributes.message,
                          is_active: response.attributes.is_active,
                          created_by: response.attributes.created_by,
                          created_at: response.attributes.created_at,
                        });
                      },
                    );
                  }
                  let conversationRes = {
                    id: conversation.id,
                    account_id: conversation.account_id,
                    message: conversation.message,
                    responses: responses,
                    is_active: conversation.is_active,
                    created_by: conversation.created_by,
                    updated_by: conversation.updated_by,
                    pinned: conversation.pinned,
                  };

                  conversationResponse.pinnedConversation.push(conversationRes);
                },
              );

              this.setState({
                conversation: conversationResponse,
                prePage: responseJson.pagination.meta.pagination.prev_page,
                nextPage: responseJson.pagination.meta.pagination.next_page,
                currentPage: responseJson.pagination.meta.pagination.current_page,
                loader: false,
              });
              this.handleUpdatePagination(responseJson.pagination.meta);
            },
            onFail: () => {
              this.setState({
                loader: false,
              });
              this.showAlert(
                `Error`,
                "Pin Conversation getting list failed. Please retry!",
              );
            },
          });
          break;
      }

      // Customizable Area End
    }
  }

  // Customizable Area Start

  login = () => {
    this.setState({
      loader: true,
    });
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.loginApiCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: "bx_block_login/logins",
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  coversationList = async (event?: ChangeEvent<unknown> | null, page?: number) => {
    this.setState({
      loader: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewAllConversationApiID = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `bx_block_conversation/conversations?per_page=${this.state.pageSize}&page=${page}`,
      method: "GET",
      token: this.state.token,
    });
  };

  async doButtonPressed(payload: ConversationInterface) {
    this.setState({
      loader: true,
    });
    const header = {
      "Content-Type": configJSON.RestAPIRequestContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.InsertPinConversationApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_conversation/conversations/" + payload.id.toString() + "/pinned_conversations",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "pinned": !payload.pinned
      }),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleUpdatePagination = (meta: {
    pagination: {
      current_page: number;
      next_page: number | null;
      prev_page: number | null;
      total_pages: number;
      total_count: number;
      current_count: number;
      per_page: number;
    };
  }) => {
    if (meta && meta.pagination) {
      const { total_pages, total_count, current_page } = meta.pagination;

      this.setState({
        totalPage: total_pages,
        totalCount: total_count,
        pageIndex: current_page,
      });
    }
  };

  incrementPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      () => {
        this.coversationList(null, this.state.currentPage);
      },
    );
  };

  decrementPage = () => {
    this.setState(
      {
        currentPage: this.state.currentPage - 1,
      },
      () => {
        this.coversationList(null, this.state.currentPage);
      },
    );
  };

  // Customizable Area End
}
