import React from "react";

import {
  Container,
  Divider,
  Avatar,
  Grid,
  Paper,
  Box,
  Modal,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import StarBorder from "@material-ui/icons/StarBorder";
import Star from "@material-ui/icons/Star";
import PinConversationController, { Props } from "./PinConversationController";
import Loader from "../../../components/src/Loader";

const imgLink =
  "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const BottomPagination = styled(Pagination)({
  marginTop: 16,
  display: "flex",
  justifyContent: "center",
});

// Customizable Area End

export default class PinConversation extends PinConversationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container>
          {this.state.conversation.pinnedConversation.map((message) => {
            return (
              <Paper
                key={message.id}
                style={{ padding: "40px 20px", marginTop: 10 }}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar alt="Remy Sharp" src={imgLink} />
                  </Grid>
                  <Grid item xs>
                    <Box sx={styles.itemWrapper}>
                      <h4>Michel Michel</h4>
                      {message.pinned ?
                        <Star
                          data-test-id={"pinned-" + message.id}
                          style={{ paddingTop: 15 }}
                          onClick={async () => {
                            await this.doButtonPressed(message);
                          }}
                        />
                        :
                        <StarBorder
                          data-test-id={"pinned-" + message.id}
                          style={{ paddingTop: 15 }}
                          onClick={async () => {
                            await this.doButtonPressed(message);
                          }}
                        />
                      }
                    </Box>
                    <p style={{ textAlign: "left" }}>{message.message}</p>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
                {message.responses.map((response) => {
                  return (
                    <Box key={response.id}>
                      <Box width="90%">
                        <Grid
                          container
                          wrap="nowrap"
                          spacing={2}
                          style={{ marginLeft: 50 }}>
                          <Grid item>
                            <Avatar alt="Remy Sharp" src={imgLink} />
                          </Grid>
                          <Grid item xs>
                            <Box sx={styles.itemWrapper}>
                              <p style={{ textAlign: "left" }}>
                                {response.message}
                              </p>

                            </Box>
                          </Grid>
                        </Grid>

                        <Divider
                          variant="fullWidth"
                          style={{ margin: "5px 0" }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Paper>
            );
          })}

          <Divider
            variant="fullWidth"
            style={{ margin: "10px 0", marginTop: 50 }}
          />
          <BottomPagination
            count={this.state.totalPage}
            page={this.state.pageIndex}
            onChange={this.coversationList}
            shape="round"
          />
          <Modal open={this.state.loader}>
            <Loader loading={this.state.loader} />
          </Modal>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
// Customizable Area End
