import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import VideoBackgroundEditController, {
  Props,
} from "././VideoBackgroundEditController.web";
export default class VideoBackgroundEdit extends VideoBackgroundEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={editStyles.editMainWrapper}>
            <Box sx={editStyles.editInputStyle}>
              <Typography>{"Title"}</Typography>
              <Input
                data-test-id="TxtEditTitle"
                onChange={(event) => this.handleEditChangeValues(event)}
                value={this.state.editTitle}
                placeholder="Enter Title"
                type="text"
                name="editTitle"
              />
            </Box>
            <Box sx={editStyles.editInputStyle}>
              <Typography>{"Page"}</Typography>
              <Input
                data-test-id="TxtEditPage"
                onChange={(event) => this.handleEditChangeValues(event)}
                value={this.state.editPage}
                placeholder="Enter Page"
                type="text"
                name="editPage"
              />
            </Box>
            <Box sx={editStyles.editInputStyle}>
              <Typography>{"Section (Optional)"}</Typography>
              <Input
                data-test-id="TxtEditSection"
                onChange={(event) => this.handleEditChangeValues(event)}
                value={this.state.editSection}
                placeholder="Enter Section"
                type="text"
                name="editSection"
              />
            </Box>

            <Box sx={editStyles.editInputStyle}>
              <Typography>{"Width"}</Typography>
              <Input
                data-test-id="TxtEditWidth"
                onChange={(event) => this.handleEditChangeValues(event)}
                value={this.state.editWidth}
                placeholder="Enter Width"
                type="number"
                name="editWidth"
              />
            </Box>

            <Box sx={editStyles.editInputStyle}>
              <Typography>{"Height"}</Typography>
              <Input
                data-test-id="TxtEditHeight"
                onChange={(event) => this.handleEditChangeValues(event)}
                value={this.state.editHeight}
                placeholder="Enter Height"
                type="number"
                name="editHeight"
              />
            </Box>
            <Box sx={editStyles.editInputStyle}>
              <Typography>{"X"}</Typography>
              <Input
                data-test-id="TxtEditX"
                onChange={(event) => this.handleEditChangeValues(event)}
                value={this.state.editX}
                placeholder="Enter X "
                type="number"
                name="editX"
              />
            </Box>
            <Box sx={editStyles.editInputStyle}>
              <Typography>{"Y"}</Typography>
              <Input
                data-test-id="TxtEditY"
                onChange={(event) => this.handleEditChangeValues(event)}
                value={this.state.editY}
                placeholder="Enter Y"
                type="number"
                name="editY"
              />
            </Box>
            <Box
              data-test-id="btnUploadVideo"
              component="button"
              sx={editStyles.editButtonStyle}>
              <input
                style={{ display: "none" }}
                ref={this.handleFileInputRef}
                type={"file"}
                onChange={(event) => {
                  this.handleEditUploadInputChange(event);
                }}
                data-test-id="fileAttach"
              />
              <Button
                color={"primary"}
                data-test-id="openEditvideopicker"
                onClick={() => this.handleEditChooseFileClick()}>
                Upload Video
              </Button>
            </Box>
            {this.state.videoEditPath !== "" && (
              <Box>
                <Typography>{this.state.videoEditPath}</Typography>
              </Box>
            )}
            {
              <Box
                data-test-id="btnUploadEditSubmit"
                component="button"
                sx={editStyles.editButtonStyle}>
                <Button
                  onClick={() => this.uploadEditVideo()}
                  color={"primary"}
                  data-test-id="openvideoEditsubmit">
                  Submit
                </Button>
              </Box>
            }
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const editStyles = {
  editMainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  editInputStyle: {
    marginTop: "5px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  editButtonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "10px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
