// Customizable Area Start
import React from "react";

import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import Button from "@material-ui/core/Button";
import { Styles } from "@material-ui/styles";
import { withStyles, Theme } from "@material-ui/core/styles";

import CreateLeaveController, { Props } from "./CreateLeaveController.web";

const useStyles: Styles<Theme, {}, string> = () => ({
  createLeaveContainer: {
    "& .MuiInput-root": {
      position: "relative",
      width: "100%",
      padding: "12px",
      border: "1px solid grey",
      borderRadius: "8px",
      fontSize: 14,
    },
    "& .react-datepicker__input-container input": {
      width: "100%",
      padding: "12px",
      fontSize: 14,
      borderRadius: "8px",
      borderColor: "grey",
    },
    "& .MuiInput-underline:after ": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before ": {
      borderBottom: "none",
      content: "none",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
});
// Customizable Area End

export class CreateLeave extends CreateLeaveController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderOptions = () => {
    const options = ["Sick", "half day", "Vacation", "Other", "Unpaid"];

    return options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { leaveType, leaveReason, createLeaveStartDate, createLeaveEndDate, isUpdate } =
      this.state;
    const { classes } = this.props;

    return (
      <div
        className={classes?.createLeaveContainer}
        style={styles.createLeaveContainer}
        data-test-id="container">
        <div style={styles.header}>
          <div style={styles.title}>
            {isUpdate ? "Update Leave" : "Create Leave"}
          </div>
        </div>
        <div style={styles.createLeaveContentContainer}>
          <div style={styles.dataContainer}>
            <div style={styles.titleTxt}>Leave Type</div>
            <Select
              native
              value={leaveType}
              data-test-id={"select-leave"}
              onChange={this.leaveType}
              label="Age"
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}>
              {this.renderOptions()}
            </Select>
          </div>
          <div style={styles.dataContainer}>
            <div style={styles.titleTxt}>Leave Name</div>
            <TextField
              data-test-id="leaveName"
              color={"primary"}
              variant="outlined"
              type={"text"}
              name={"leave_name"}
              placeholder={"Enter leave name..."}
              minRows={2}
              value={this.state.leaveName}
              onChange={(event) =>
                this.handleLeaveNameChange(event.target.value)
              }
            />
          </div>
          <div style={styles.dataContainer}>
            <div style={styles.titleTxt}>Start Date</div>
            <div style={styles.dateContainer}>
              <DatePicker
                data-test-id="startLeavePicker"
                selected={createLeaveStartDate}
                onChange={this.handleLeaveStartDateChange}
                selectsStart
                dateFormat="dd/MM/yyyy"
                startDate={createLeaveStartDate}
                endDate={createLeaveEndDate}
              />
            </div>
          </div>
          <div style={styles.dataContainer}>
            <div style={styles.titleTxt}>End Date</div>
            <div style={styles.dateContainer}>
              <DatePicker
                data-test-id="endLeavePicker"
                selected={createLeaveEndDate}
                onChange={this.handleLeaveEndDateChange}
                selectsStart
                dateFormat="dd/MM/yyyy"
                startDate={createLeaveStartDate}
                endDate={createLeaveEndDate}
                minDate={createLeaveStartDate}
              />
            </div>
          </div>
          <div style={styles.dataContainer}>
            <div style={styles.titleTxt}>Reason For Leave</div>
            <TextField
              data-test-id="leaveReason"
              color={"primary"}
              variant="outlined"
              type={"text"}
              name={"leave_reason"}
              placeholder={"Type something..."}
              multiline
              minRows={2}
              value={leaveReason}
              onChange={(event) =>
                this.handleLeaveReasonChange(event.target.value)
              }
            />
          </div>
          <Button
            style={styles.submitContainer}
            variant="contained"
            color="primary"
            {...this.onPressCreateLeaveSubmit}
            data-test-id="setLeaveBtn">
            {isUpdate ? "Update" : "Submit"}
          </Button>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  createLeaveContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    backgroundColor: "#826ffc",
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center" as const,
    color: "#ffffff",
  },
  createLeaveContentContainer: {
    padding: 24,
    width: "50%",
  },
  dataContainer: {
    marginTop: 24,
  },
  titleTxt: {
    fontSize: 14,
    fontWeight: "bold" as const,
    fontStyle: "normal",
    letterSpacing: 0.01,
    color: "#333333",
    marginBottom: "8px",
  },
  dateContainer: {
    width: "100%",
  },
  submitContainer: {
    backgroundColor: "#826ffc",
    width: "100%",
    marginTop: 24,
    textAlign: "center" as const,
  },
  typeSomethingTxt: { width: "95%" },
  typeSomething: {
    borderRadius: 8,
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#d2d5da",
    padding: 16,
    paddingTop: 12,
    marginTop: 8,
  },
};

export default withStyles(useStyles)(CreateLeave);
// Customizable Area End
