Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EmergencySos";
exports.labelBodyText = "EmergencySos Body";
exports.Emergency_EndPoint = "bx_block_emergency/emergencies";
exports.Login_EndPoint = "/bx_block_login/logins";
exports.USERNAME = "buraktest1@gmail.com";
exports.PASSWORD = "123456";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
