import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./styles/localInternationalShipping.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LocalInternationalShippingController, {
  Props,
} from "./LocalInternationalShippingController.web";
import ProductViewWeb from "./ProductView.web";
import CartView from "./CartView.web";

export default class LocalInternationalShipping extends LocalInternationalShippingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.container}>
          {this.state.view === "product" ? (
            <ProductViewWeb
              data-test-id="product-view-list"
              productList={this.state.productList}
              cartList={this.state.cartList}
              handleChangeShippingAddress={() => {}}
              handleChangeView={() => this.handleChangeView("cart")}
              handleAddToCart={this._onAddToCartItem}
              handleCreateNewProduct={() => {}}
              handleModifyShippingDetails={() => {}}
              handleGetListOfProducts={this.getListOfProducts}
              handleRetrieveAddress={this._retrieveData}
              countries={this.state.countries}
            />
          ) : (
            <CartView
              data-test-id="cart-view-list"
              cartList={this.state.cartList}
              handleRemoveFromCart={this._onClearSelectedItem}
              handleChangeView={() => this.handleChangeView("product")}
              handleEmptyCart={this.handleEmpty}
            />
          )}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
  },
};
// Customizable Area End
