import React from "react";

// Customizable Area Start
import VideoComponent from "./VideoComponent.web";
// Customizable Area End

import LiveStreamingController, { Props } from "./LiveStreamingController.web";

export default class LiveStreaming extends LiveStreamingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <VideoComponent
          data-test-id="video-component"
          handleGenerateToken={this.handleGenerateTokenFunction}
          handleValidateParticipantMeeting={
            this.handleValidateParticipantMeetingFunction
          }
          handleFetchAllMeetings={this.handleFetchAllMeetingFunction}
          handleFetchAllRecordings={this.handleFetchAllRecordingFunction}
          handleLiveHlsList={this.handleLiveHlsListFunction}
          handleStartRecording={this.handleStartRecordingFunction}
          handleStopRecording={this.handleStopRecordingFunction}
          handleStartRtmpLive={this.handleStartLiveRtmpFunction}
          handleStopRtmpLive={this.handleStopLiveRtmpFunction}
          handleStartLiveHls={this.handleStartLiveHlsFunction}
          handleStopLiveHls={this.handleStopLiveHlsFunction}
          handleLiveActiveHls={this.handleLiveActiveHlsFunction}
          handleDeleteRecording={this.handleDeleteRecordingFunction}
          handleSetDownStreamUrl={this.getDownStreamUrlFunction}
          token={this.state.token}
          roomId={this.state.roomId}
          meetingId={this.state.meetingId}
          meetingList={this.state.meetingList}
          recordingList={this.state.recordingList}
          hlsList={this.state.hlsList}
          isParticipant={this.state.isParticipant}
          isValid={this.state.isValid}
          meetingInfo={this.state.meetingInfo}
          handleChangeMeetingId={this.getMeetingIdFunction}
          handleChangeView={this.handleChangeViewFunction}
          downStreamUrl={this.state.downStreamUrl}
          alert={(title: string, error: string) => this.showAlert(title, error)}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
