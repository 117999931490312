import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import GoalManagementController, {
  Props,
} from "./GoalManagementController.web";
import {
  arrowFullRightIcon,
  background,
  createGoalIcon,
  retrieveGoalIcon,
} from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import UserIcon from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const UpperBox = styled(Box)({
  height: "50%",
  display: "flex",
  flexDirection: "column",
});

const LowerBox = styled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ButtonContainerBox = styled(Box)({
  width: "50%",
  display: "flex",
  flexDirection: "column",
});

const ButtonBox = styled(Box)({
  backgroundColor: "#ffffff",
  padding: 24,
  borderRadius: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "24px 0",
});

const Title = styled(Typography)({
  fontSize: 60,
  fontWeight: "bold",
  margin: "0px 24px",
  color: "#ffffff",
});

const Background = styled(Box)({
  backgroundImage: "url(" + background + ")",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  flex: 1,
});

const LoginContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "80%",
});

const TextFieldCustom = styled(TextField)({
  marginBottom: 16,
});

const OptionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  alignItems: "center",
  justifyContent: "center",
});

const CircularProgressContainer = styled(Box)({
  marginLeft: 16,
});

// Customizable Area End

export default class GoalManagement extends GoalManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { isLoading, token, email, password, passwordVisible } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Background sx={webStyle.mainWrapper}>
          <UpperBox>
            <Title>Hello,</Title>
            <Title>Welcome</Title>
          </UpperBox>
          <LowerBox>
            <>
              {token.length === 0 ? (
                <LoginContainer>
                  <TextFieldCustom
                    id="outlined-basic"
                    label="Email"
                    placeholder={"Enter your email"}
                    variant="filled"
                    onChange={this.handleChangeEmail}
                    value={email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <UserIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFieldCustom
                    id="outlined-basic"
                    label="Password"
                    placeholder={"Enter your password"}
                    variant="filled"
                    type={passwordVisible ? "text" : "password"}
                    onChange={this.handleChangePassword}
                    value={password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleTogglePassword}
                            edge="end">
                            {passwordVisible ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button onClick={this.handleLoginGoalWeb}>
                    Login{" "}
                    {isLoading && (
                      <CircularProgressContainer>
                        <CircularProgress size={20} />
                      </CircularProgressContainer>
                    )}
                  </Button>
                </LoginContainer>
              ) : (
                <OptionContainer>
                  <ButtonContainerBox>
                    {[
                      {
                        title: "Create Goal",
                        onClick: this.handleGoToGoalCreateWeb,
                        icon: createGoalIcon,
                      },
                      {
                        title: "Retrieve Goal",
                        onClick: this.handleGoToGoalRetrieveWeb,
                        icon: retrieveGoalIcon,
                      },
                    ].map((item, index) => (
                      <ButtonBox
                        component={"button"}
                        key={index}
                        onClick={item.onClick}>
                        <img alt={item.title} src={item.icon} />
                        <Typography>{item.title}</Typography>
                        <img alt={item.title} src={arrowFullRightIcon} />
                      </ButtonBox>
                    ))}
                  </ButtonContainerBox>
                  <Button onClick={this.handleSignOut}>Sign out</Button>
                </OptionContainer>
              )}
            </>
          </LowerBox>
        </Background>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
  },
};
// Customizable Area End
