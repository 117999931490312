import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { Department, Designation, Employee, EmployeeResponse } from "../types";
import { handleResponseMessage } from "../helpers/handle-response-message";
import createRequestMessage from "../helpers/create-request-message";
import { getStorageData } from "../../../../framework/src/Utilities";
import * as Yup from "yup";
import { ChangeEvent } from "react";

// Customizable Area End

export const configEmpCreateJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employeeId: number;
  employeeName: string;
  employeePicture: string | File;
  employeeThumbnail: string | File;
  employeeIsActive: boolean;
  isLoading: boolean;
  token: string;
  designationList: Designation[];
  designation: Designation;
  designationId: number | null;
  designationLoading: boolean;
  departmentList: Department[];
  department: Department;
  departmentId: number | null;
  departmentLoading: boolean;
  isActiveChoiceVisible: boolean;
  isFetching: boolean;
  alertModal: boolean;
  titleModal: string;
  contentModal: string;
  success: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDesignationListApi: string;
  getDepartmentListApi: string;
  getEmployeeApi: string;
  createEmployeeApi: string;
  updateEmployeeApi: string;
  initialEmployeeId: number;
  defaultWebSchema: object;
  employeeCreateSchema: object;
  employeeUpdateSchema: object;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.initialEmployeeId =
      (this.props.navigation?.getParam("id") as number) ?? 0;

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      designation: {},
      designationId: 0,
      designationList: [],
      designationLoading: false,
      department: {},
      departmentId: 0,
      departmentList: [],
      departmentLoading: false,
      employeeId: this.initialEmployeeId ?? 0,
      employeeIsActive: true,
      employeeName: "",
      employeePicture: "",
      employeeThumbnail: "",
      isActiveChoiceVisible: false,
      isLoading: false,
      token: "",
      isFetching: false,
      alertModal: false,
      titleModal: "",
      contentModal: "",
      success: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleOpenFileWeb = this.handleOpenFileWeb.bind(this);
    this.handleGoBackWeb = this.handleGoBackWeb.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    this.getDesignationListApi = "";
    this.getDepartmentListApi = "";
    this.createEmployeeApi = "";
    this.getEmployeeApi = "";
    this.updateEmployeeApi = "";

    this.defaultWebSchema = {
      name: Yup.string().required("Required"),
      designation: Yup.number().required("Required").moreThan(0, "Required"),
      department: Yup.number().required("Required").moreThan(0, "Required"),
      picture: Yup.string(),
      thumbnail: Yup.string(),
    };

    this.employeeCreateSchema = Yup.object().shape({
      ...this.defaultWebSchema,
    });

    this.employeeUpdateSchema = Yup.object().shape({
      ...this.defaultWebSchema,
      isActive: Yup.boolean(),
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequest = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      switch (apiRequest) {
        case this.getEmployeeApi: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.handleResponseEmployeeWeb(responseJson);
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Failed",
                success: false,
                contentModal: "Get Employee Failed!",
              });
            },
          });
          break;
        }

        case this.createEmployeeApi: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                alertModal: true,
                titleModal: "Success",
                success: true,
                contentModal: "Employee created successfully!",
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Failed",
                success: false,
                contentModal: "Employee created fail!",
              });
            },
          });
          break;
        }

        case this.updateEmployeeApi: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                alertModal: true,
                titleModal: "Success",
                success: true,
                contentModal: "Employee updated successfully!",
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Failed",
                success: false,
                contentModal: "Employee updated fail!",
              });
            },
          });
          break;
        }

        case this.getDesignationListApi: {
          this.setState({
            designationLoading: false,
          });
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                designationList: responseJson,
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Failed",
                success: false,
                contentModal: "Get Designation Failed! Please try again!",
              });
            },
          });
          break;
        }

        case this.getDepartmentListApi: {
          this.setState({
            departmentLoading: false,
          });
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                departmentList: responseJson,
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Failed",
                success: false,
                contentModal: "Get Department Failed! Please try again!",
              });
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponseEmployeeWeb(responseJson: EmployeeResponse) {
    if (responseJson.data) {
      const {
        id,
        employee_thumbnail,
        employee_name,
        is_active,
        employee_picture,
        designation,
        department,
      }: Employee = responseJson.data;

      this.setState({
        employeeId: id!,
        employeeName: employee_name!,
        employeeIsActive: is_active!,
        employeePicture: employee_picture ?? "",
        employeeThumbnail: employee_thumbnail ?? "",
        designation: designation ?? {},
        designationId: designation?.id ?? 0,
        department: department ?? {},
        departmentId: department?.id ?? 0,
        isFetching: false,
      });
    }
  }
  handleOpenFileWeb(
    event: ChangeEvent<HTMLInputElement>,
    isEmployeePicture: boolean,
    setField: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const filePath = URL.createObjectURL(file);
    if (isEmployeePicture) {
      this.setState(
        {
          employeePicture: file,
        },
        () => {
          setField("picture", filePath);
        },
      );
    } else {
      this.setState(
        {
          employeeThumbnail: file,
        },
        () => {
          setField("thumbnail", filePath);
        },
      );
    }
  }

  async componentDidMount() {
    this.handleGetTokenEmployeeWeb();
  }

  async handleGetTokenEmployeeWeb() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        if (this.initialEmployeeId) {
          this.handleGetEmployeeWeb();
        }
        if (this.state.designationList.length === 0) {
          this.handleGetDesignationListWeb();
        }
        if (this.state.departmentList.length === 0) {
          this.handleGetDepartmentListWeb();
        }
      },
    );
  }

  handleGetDesignationListWeb() {
    this.setState({
      designationLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getDesignationListApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configEmpCreateJSON.designationURLEndPoint,
      method: configEmpCreateJSON.getApiMethodType,
      token: this.state.token,
    });
  }
  handleGetEmployeeWeb() {
    this.setState({
      isLoading: true,
      isFetching: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getEmployeeApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configEmpCreateJSON.employeesURLEndPoint}/${this.initialEmployeeId}`,
      method: configEmpCreateJSON.getApiMethodType,
      token: this.state.token,
    });
  }
  async handleCreateEmployeeWeb(formData: FormData) {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createEmployeeApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage,
      endPoint: configEmpCreateJSON.employeesURLEndPoint,
      method: configEmpCreateJSON.postApiMethodType,
      header: { "Content-Type": "multipart/form-data" },
      token: this.state.token,
      body: formData,
      isFormDataRequest: true,
    });
  }

  handleUpdateEmployeeWeb(formData: FormData) {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updateEmployeeApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage,
      endPoint: `${configEmpCreateJSON.employeesURLEndPoint}/${this.state.employeeId}`,
      method: configEmpCreateJSON.putApiMethodType,
      header: { "Content-Type": "multipart/form-data" },
      token: this.state.token,
      body: formData,
      isFormDataRequest: true,
    });
  }

  handleGetDepartmentListWeb() {
    this.setState({
      departmentLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getDepartmentListApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configEmpCreateJSON.departmentURLEndPoint,
      method: configEmpCreateJSON.getApiMethodType,
      token: this.state.token,
    });
  }

  handleSubmitWeb(values: {
    name: string;
    designation: number;
    department: number;
    picture: string;
    thumbnail: string;
    isActive: boolean;
  }) {
    const { name, designation, department, isActive } = values;

    const formData = new FormData();

    formData.append("employee[employee_name]", name);

    formData.append("employee[designation_id]", designation.toString());

    formData.append("employee[department_id]", department.toString());

    formData.append("employee[is_active]", isActive.toString());

    if (typeof this.state.employeePicture !== "string") {
      formData.append(
        "employee[employee_picture]",
        this.state.employeePicture as unknown as Blob,
      );
    }

    if (typeof this.state.employeeThumbnail !== "string") {
      formData.append(
        "employee[employee_thumbnail]",
        this.state.employeeThumbnail as unknown as Blob,
      );
    }

    if (this.initialEmployeeId && this.initialEmployeeId > 0) {
      this.handleUpdateEmployeeWeb(formData);
    } else {
      this.handleCreateEmployeeWeb(formData);
    }
  }

  handleGoBackWeb() {
    this.props.navigation.navigate("EmployeeList");
  }

  handleCloseAlert() {
    this.setState({
      alertModal: false,
      titleModal: "",
      contentModal: "",
    });

    if (this.state.success) {
      this.props.navigation.navigate("EmployeeList");
    }
  }
  // Customizable Area End
}
