import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import { getStorageData } from "framework/src/Utilities";
import { FlatList, ImageSourcePropType } from "react-native";
import { JsonObjToQueryString } from "./helpers/JsonObjToQueryString";
import lodash from "lodash";
import React, { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface Members {
  key: string;
  img: ImageSourcePropType;
}

export interface PageItem {
  label: number;
  isSelected: boolean;
}

export interface TaskList {
  id: number;
  title: string;
  planned_hours: number;
  description: string;
  is_active: boolean;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  status: string;
  members?: {
    key: string;
    img: ImageSourcePropType;
  }[];
}

export interface TaskListData {
  data: TaskList[];
  message: string;
  success: boolean;
  meta: {
    pagination: {
      current_page: number;
      total_pages: number;
      total_count: number;
    };
  };
}
interface S {
  // Customizable Area Start
  txtSearchInputValue: string;
  taskList: TaskList[];
  token: string;
  isInternetConnected: boolean;
  responseMessage: string;
  currentPage: number;
  totalPage: number;
  displayPages: PageItem[];
  isSearchFocused: boolean;
  isOptionSelected: boolean;
  selectedIndex: number;
  isDeleteTaskModal: boolean;
  deleteTaskId: number;
  contextMenuId: number | string;
  contextMenuAnchorEl: unknown;
  taskListPageNo: number;
  isNavigateSuccess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TimeSheetTaskListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listTaskApiCallId: string = "";
  taskDeleteApiCallId: string = "";
  flatListRef = createRef<FlatList<TaskList>>();
  timer: null | ReturnType<typeof setTimeout> = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isInternetConnected: false,
      txtSearchInputValue: "",
      taskList: [],
      responseMessage: "",
      currentPage: 0,
      totalPage: 0,
      displayPages: [],
      isSearchFocused: false,
      isOptionSelected: false,
      selectedIndex: -1,
      isDeleteTaskModal: false,
      deleteTaskId: -1,
      contextMenuId: 0,
      contextMenuAnchorEl: null,
      taskListPageNo: 1,
      isNavigateSuccess: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse) {
        this.parseApiCatchErrorResponse(errorResponse);
      } else if (responseJson && (responseJson.error || responseJson.errors)) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    NetInfo.addEventListener((state: NetInfoState) => {
      this.setState({
        isInternetConnected: state.isConnected,
      });
    });
    const _token = await getStorageData("TOKEN");
    this.setState(
      {
        token: _token,
      },
      () => {
        this.getTaskList(false);
      },
    );
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: TaskListData) => {
    switch (apiRequestCallId) {
      case this.listTaskApiCallId: {
        if (responseJson) {
          const pageCount = lodash.get(
            responseJson,
            "meta.pagination.total_pages",
            0,
          );
          const _currentPage = lodash.get(
            responseJson,
            "meta.pagination.current_page",
            0,
          );
          this.setState(
            {
              taskList: lodash.get(responseJson, "data", []),
              responseMessage: lodash.get(responseJson, "message", ""),
              currentPage: _currentPage,
              totalPage: pageCount,
              displayPages: this.generateArrayUsingMathCeil(
                pageCount,
                _currentPage,
              ),
            },
            () => {
              this.scrollListToTop();
            },
          );
        }
        break;
      }
      case this.taskDeleteApiCallId: {
        if (responseJson?.success) {
          this.setState({
            responseMessage: lodash.get(responseJson, "message", ""),
            taskList: this.state.taskList.filter(
              (item) => item.id !== this.state.deleteTaskId,
            ),
            deleteTaskId: -1,
          });
          this.timer && clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.showAlert(
              configJSON.taskList,
              responseJson.message
                ? responseJson.message
                : configJSON.messageDeleteTaskSuccess,
            );
          }, 100);
        }
      }
    }
  };

  scrollListToTop = () => {
    this.flatListRef.current?.scrollToIndex({
      animated: true,
      index: 0,
    });
  };

  onScrollToIndexFailed = (error: {
    index: number;
    highestMeasuredFrameIndex: number;
    averageItemLength: number;
  }) => {
    this.flatListRef.current?.scrollToOffset({
      offset: error.averageItemLength * error.index,
      animated: true,
    });
    setTimeout(() => {
      if (this.state.taskList.length !== 0 && this.flatListRef !== null) {
        this.flatListRef.current?.scrollToIndex({
          index: error.index,
          animated: true,
        });
      }
    }, 50);
  };

  openContextMenu(
    event: { stopPropagation: Function; target: {} },
    taskId: TaskList["id"],
  ) {
    event.stopPropagation();
    this.setState({ contextMenuAnchorEl: event.target, contextMenuId: taskId });
  }

  closeContextMenu() {
    this.setState({ contextMenuAnchorEl: null, contextMenuId: 0 });
  }

  generateArrayUsingMathCeil(pages: number, current: number) {
    const count = Math.ceil(pages - 1);
    const resultArray: PageItem[] = Array.from(
      { length: count + 1 },
      (_value, position) => ({
        label: 1 + position,
        isSelected: position === current - 1,
      }),
    );

    return resultArray;
  }

  setCurrentPage(_item: PageItem, _position: number) {
    if (!_item.isSelected) {
      let copiedArray = [...this.state.displayPages];
      copiedArray.forEach((value: PageItem, position: number) => {
        value.isSelected = position === _position;
        return value;
      });

      this.setState(
        {
          currentPage: _position,
          displayPages: copiedArray,
          responseMessage: "",
        },
        () => {
          this.getTaskList(false);
        },
      );
    }
  }

  txtSearchInputChange = {
    onChangeText: (text: string) => {
      this.setState(
        {
          txtSearchInputValue: text,
          isSearchFocused: true,
          selectedIndex: -1,
          isOptionSelected: false,
        },
        () => {
          this.timer && clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.getTaskList(text !== "");
          }, 1000);
        },
      );
    },
    onFocus: () => {
      this.setState({ isSearchFocused: true });
    },
    onSubmitEditing: () => this.submitSearch(),
  };

  submitSearch = () => {
    this.setState(
      {
        isSearchFocused: false,
        responseMessage: "",
        currentPage: 0,
        totalPage: 0,
        displayPages: [],
        taskList: [],
      },
      () => {
        this.getTaskList(true);
      },
    );
  };

  openCreateTask = () => {
    this.hideOptions();
    this.props.navigation.navigate("TimeSheetCreateTask", {
      onGoBack: this.resetList,
    });
  };

  openTaskDetails(task: TaskList) {
    this.setState({
      isOptionSelected: false,
      selectedIndex: -1,
    });
    this.props.navigation.push("TimeSheetTaskDetails", {
      taskData: JSON.stringify(task),
      taskId: task.id,
    });
  }

  openTaskDetailsWeb = async (task: number) => {
    this.setState({ isNavigateSuccess: true });
    this.props.navigation.navigate("TimeSheetTaskDetails", {
      taskId: task,
    });
  };

  redirectToMainScreen() {
    this.props.navigation.navigate("TimesheetManagement");
  }

  handleTaskListPage(event: object, newPageList: number) {
    this.setState({ ...this.state, currentPage: newPageList - 1 }, () => {
      this.getTaskList(false);
    });
  }

  getTaskList = (isSearchCall: boolean) => {
    const { isInternetConnected, token, currentPage, txtSearchInputValue } =
      this.state;
    if (isInternetConnected) {
      const header = {
        token,
      };
      const queryString = JsonObjToQueryString(
        isSearchCall
          ? {
              title: txtSearchInputValue,
            }
          : {
              page: currentPage + 1,
              per_page: 10,
            },
      );
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
      this.listTaskApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        (isSearchCall
          ? configJSON.taskListSearchTaskApiEndPoint
          : configJSON.listTaskApiEndPoint) + `?${queryString}`,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet,
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  showOptions = (index: number) => {
    this.setState({
      isOptionSelected:
        this.state.selectedIndex === index
          ? !this.state.isOptionSelected
          : true,
      selectedIndex: index,
    });
  };

  hideOptions = () => {
    this.setState({
      isOptionSelected: false,
      selectedIndex: -1,
    });
    this.hideKeyboard();
  };

  editTask = (item: TaskList) => {
    this.hideOptions();
    this.props.navigation.navigate("TimeSheetCreateTask", {
      taskData: JSON.stringify(item),
      onGoBack: this.updateData,
    });
  };

  editTaskWeb = async (
    event: { stopPropagation: Function; target: {} },
    taskId: number,
  ) => {
    this.setState({ isNavigateSuccess: true });
    event.stopPropagation();
    this.props.navigation.navigate("TimeSheetCreateTask", {
      taskId: taskId,
    });
  };

  updateData = (data: TaskList) => {
    const { taskList } = this.state;
    const updatedData = taskList.map((item) => {
      if (item.id === data.id) {
        return { ...item, ...data };
      }
      return item;
    });
    this.setState({ taskList: updatedData });
  };

  resetList = () => {
    this.setState(
      {
        isSearchFocused: false,
        responseMessage: "",
        currentPage: 0,
        totalPage: 0,
        displayPages: [],
        taskList: [],
      },
      () => {
        this.getTaskList(false);
      },
    );
  };

  hideDeleteModal = () => {
    this.setState({
      isDeleteTaskModal: false,
      selectedIndex: -1,
      isOptionSelected: false,
    });
  };

  eraseTask = (taskId: number) => {
    this.hideOptions();
    this.setState({
      deleteTaskId: taskId,
      isDeleteTaskModal: true,
      isOptionSelected: false,
    });
  };

  removeTaskWeb = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    taskId: number,
  ) => {
    event.stopPropagation();
    this.setState({
      deleteTaskId: taskId,
      isDeleteTaskModal: true,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      isDeleteTaskModal: false,
    });
  };

  callDeleteTask = async () => {
    const { isInternetConnected, token, deleteTaskId } = this.state;
    if (isInternetConnected) {
      this.setState({ isDeleteTaskModal: false });
      const header = {
        token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
      this.taskDeleteApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.apiTaskDelete}${deleteTaskId}`,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeDelete,
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Customizable Area End
}
