import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useState } from "react";
import { MeetingViewProps } from "../../types";
import { Controls } from "./Controls";
import ParticipantView from "./ParticipantView";

export function MeetingView(props: MeetingViewProps) {
  const [joined, setJoined] = useState("");

  const { join, participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      props.onMeetingLeave();
    },
  });

  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };

  const participantIds = participants ? [...participants.keys()] : [];

  let content: JSX.Element;
  if (joined && joined === "JOINED") {
    content = (
      <div style={webStyle.divStyle}>
        <div style={webStyle.divStyles2}>
          {participantIds.map((participantId) => (
            <ParticipantView
              participantId={participantId}
              key={participantId}
            />
          ))}
        </div>
        <div>
          <Controls />
        </div>
      </div>
    );
  } else if (joined && joined === "JOINING") {
    content = <p style={webStyle.alignSelfCenter}>Joining the meeting...</p>;
  } else {
    content = (
      <button
        onClick={joinMeeting}
        style={webStyle.buttonStyle}
        data-testid="join-button">
        Join
      </button>
    );
  }

  return (
    <div style={webStyle.divStyles4}>
      <h3 style={webStyle.alignSelfCenter}>Meeting Id: {props.meetingId}</h3>
      <div style={webStyle.divStyles3}>{content}</div>
    </div>
  );
}

const webStyle = {
  divStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    height: "100%",
  },
  divStyles2: {
    width: "100%",
    height: "100%",
    background: "#181b1f",
    display: "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    overflow: "auto",
  },
  divStyles3: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as "column",
    overflow: "hidden",
  },
  divStyles4: {
    display: "flex",
    flexDirection: "column" as "column",
    height: "100%",
    width: "100%",
  },
  buttonStyle: {
    background: "#5568FE",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: 6,
    borderWidth: 0,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
    width: "100px",
    margin: "0 auto",
  },
  alignSelfCenter: { alignSelf: "center" },
};
