import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Select,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import TimeSheetCreateTaskController, {
  Props,
  configJSON,
  TaskStatus,
  taskStatuses,
} from "./TimeSheetCreateTaskController";
import Input from "./components/Input.web";
import Title from "./components/Title.web";

export default class TimeSheetCreateTask extends TimeSheetCreateTaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Title title="Task form" />
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.inputWrapper}>
            <InputLabel>{configJSON.labelTitle}</InputLabel>
            <Input
              data-test-id="title"
              value={this.state.title}
              onChange={(event) =>
                this.txtTitleInputChange.onChangeText(event.target.value)
              }
            />
          </Box>

          <Box sx={webStyle.inputWrapper}>
            <InputLabel>{configJSON.labelDescription}</InputLabel>
            <Input
              data-test-id="description"
              value={this.state.description}
              onChange={(event) =>
                this.txtDescriptionInputChange.onChangeText(event.target.value)
              }
              multiline
              minRows={4}
            />
          </Box>

          <Box sx={webStyle.inputWrapper}>
            <InputLabel>{configJSON.labelAllocatedHours}</InputLabel>
            <Input
              data-test-id="time"
              value={this.state.allocatedTime}
              onChange={(event) =>
                this.txtAllocatedTimeInputChange.onChangeText(
                  event.target.value,
                )
              }
              type="number"
            />
          </Box>

          {this.state.taskId && (
            <Box sx={webStyle.inputWrapper}>
              <InputLabel>{configJSON.labelStatus}</InputLabel>
              <Select
                native
                value={this.state.status}
                variant="outlined"
                fullWidth
                style={webStyle.select}
                onChange={(event) =>
                  this.handleStatusInputChange(event.target.value as TaskStatus)
                }
                label="Age"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}>
                <option aria-label="None" value="" />
                {Object.entries(taskStatuses).map(([status, name]) => (
                  <option key={name} value={status}>
                    {name}
                  </option>
                ))}
              </Select>
            </Box>
          )}

          <Box style={webStyle.buttonWrapper}>
            <Button
              data-test-id="cancel"
              variant="contained"
              onClick={() => this.cancelTasks()}>
              {configJSON.cancel}
            </Button>
            <Button
              data-test-id="submit"
              variant="contained"
              color="primary"
              onClick={() => this.checkValidation()}>
              {this.state.taskId ? "Update" : configJSON.create}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputWrapper: {
    marginTop: 24,
  },
  select: {
    marginTop: 16,
  },
  buttonWrapper: {
    display: "flex",
    gap: 24,
    marginTop: 24,
  },
};
// Customizable Area End
