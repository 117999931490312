import React from "react";

type Nullable<T> = T | null;
interface ConfigJSON {
  labelAnyone: string;
  labelFriends: string;
  labelFamily: string;
}
interface DropdownOnChange {
  (index: number): (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
export interface DropdownProps {
  dropDownText: string;
  isDataAvailable: boolean;
  isUpdating: boolean;
  selectValue: Nullable<string>;
  onChange: DropdownOnChange;
  index: number;
  configJSON: ConfigJSON;
  testId: string;
  selectTestId: string;
}

function Dropdown({
  dropDownText,
  isDataAvailable,
  isUpdating,
  selectValue,
  onChange,
  index,
  configJSON,
  testId,
  selectTestId,
}: DropdownProps) {
  const shouldDisable = !(isDataAvailable || isUpdating);

  return (
    <div style={webStyle.row} id={testId}>
      <span id={"dropdownTextId"}>{dropDownText}</span>

      <select
        disabled={shouldDisable}
        value={selectValue ?? ""}
        style={webStyle.picker}
        onChange={onChange(index)}
        data-testid={selectTestId}>
        <option value={configJSON.labelAnyone}>{configJSON.labelAnyone}</option>
        <option value={configJSON.labelFriends}>
          {configJSON.labelFriends}
        </option>
        <option value={configJSON.labelFamily}>{configJSON.labelFamily}</option>
      </select>
    </div>
  );
}
export default Dropdown;

const webStyle = {
  picker: {
    height: 50,
    width: 150,
    borderRadius: 8,
    marginTop: 5,
    marginBottom: 5,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
};
