import React from "react";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import RemoteParticipantPresenter from "./RemoteParticipantPresenter";
import LocalParticipantPresenter from "./LocalParticipantPresenter";

type ParticipantViewProps = {
  participantId: string;
};

const ParticipantView: React.FC<ParticipantViewProps> = ({ participantId }) => {
  const { localScreenShareOn, presenterId } = useMeeting();
  const { displayName } = useParticipant(participantId);

  const isPresenter = participantId === presenterId;
  const isLocalPresenter = !localScreenShareOn && isPresenter;

  const containerStyle = {
    width: isLocalPresenter ? "100%" : "25%",
    height: isLocalPresenter ? "calc(100% - 40px)" : undefined,
    flex: isLocalPresenter ? "auto" : "1 1 calc(25% - 2px)",
    margin: "2px",
    background: "#22272c",
    justifyContent: "center",
  };

  let presenterComponent = null;

  if (isLocalPresenter) {
    presenterComponent = (
      <RemoteParticipantPresenter presenterId={presenterId} />
    );
  } else if (isPresenter) {
    presenterComponent = <LocalParticipantPresenter />;
  }

  return (
    <div style={containerStyle}>
      <div>
        <span style={webstyle.spanStyle} data-testid="displayName">
          Name: {displayName}
        </span>
      </div>
      {presenterComponent}
    </div>
  );
};

export default ParticipantView;
const webstyle = {
  spanStyle: { color: "white", fontSize: 20 },
};
