import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  TextInput,
  Button,
} from "react-native";

import {
  Container,
  Dialog,
  Typography,
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { IEmployee, IEmployeeHierarchy } from "./types";
import { anonymousPng } from "./assets";

// Customizable Area End

import OrganisationHierarchyController, {
  Props,
} from "./OrganisationHierarchyController";

export default class OrganisationHierarchy extends OrganisationHierarchyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      employeeHierarchy,
      employees,
      selectedOrgHierarchy,
      searchInputValue,
      employeeDetailModal,
      employeeTaskDetailModal,
      specialTaskList,
    } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <View style={{ backgroundColor: "#E3E3E3" }}>
            <Dialog open={employeeDetailModal}>
              <View style={{ width: "100%", height: "100%", padding: 24 }}>
                <View style={styles.modalFirstView}>
                  <FillView />
                  <ModalTitle>Employee Info</ModalTitle>
                  <Button
                    testID="webModal"
                    title="X"
                    onPress={() => this.changeEmployeeModalStatus(false)}
                  />
                </View>
                {specialTaskList.map((item) => (
                  <>
                    <View style={styles.employeesMapView}>
                      {this.employeeImage(item)}
                      <View style={{ marginLeft: 16 }}>
                        <Typography>{item.employee_name} </Typography>
                        <Typography>{item.designation} </Typography>
                        <Typography>{item.department} </Typography>
                      </View>
                    </View>
                    <HalfDivider />
                  </>
                ))}
                {specialTaskList.length < 1 && (
                  <Typography>There is no employee</Typography>
                )}
              </View>
            </Dialog>
            <Dialog open={employeeTaskDetailModal}>
              <View style={{ width: "100%", height: "100%", padding: 24 }}>
                <View style={styles.selectedEmployeeInfoView}>
                  <View style={styles.employeeInfoFirstView} />
                  <Typography>Employee -Task</Typography>
                  <Button
                    title="X"
                    onPress={() => this.openEmployeeDetailModal(false)}
                  />
                </View>
                {this.employeeInfo()}
                {this.taskInfo()}
              </View>
            </Dialog>
            <View style={styles.selectedViewPage}>
              <TouchableOpacity
                testID="tabButton1Web"
                onPress={() => this.changeSelectedTabView(true)}
                style={{ marginRight: 8 }}>
                {selectedOrgHierarchy ? (
                  <SelectedHeaderText>
                    Organisation Hierarchy
                  </SelectedHeaderText>
                ) : (
                  <UnselectedHeaderText>
                    Organisation Hierarchy
                  </UnselectedHeaderText>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                testID="tabButton2Web"
                onPress={() => this.changeSelectedTabView(false)}
                style={{ marginLeft: 16 }}>
                {!selectedOrgHierarchy ? (
                  <SelectedHeaderText>Task List</SelectedHeaderText>
                ) : (
                  <UnselectedHeaderText>Task List</UnselectedHeaderText>
                )}
              </TouchableOpacity>
            </View>
            {selectedOrgHierarchy ? (
              <View style={styles.container}>
                {employeeHierarchy.map(
                  (item: IEmployeeHierarchy, index: number) => (
                    <View style={styles.itemView} key={item.id}>
                      {item.workers &&
                        item.workers.length > 0 &&
                        !item.isOpen && (
                          <View style={styles.workerLengthInfo}>
                            <WorkerLengthInfoText>
                              {item.workers.length}
                            </WorkerLengthInfoText>
                          </View>
                        )}
                      <TouchableOpacity
                        testID="isOpenWeb"
                        disabled={item.workers?.length === 0}
                        onPress={() => {
                          this.changeIsOpen(index);
                        }}>
                        <View style={styles.bossContainer}>
                          <View style={styles.itemImg}>
                            <View style={styles.imageView}>
                              {item.employee_picture ? (
                                <Image
                                  style={styles.bossImg}
                                  source={{ uri: item.employee_picture }}
                                />
                              ) : (
                                <Image
                                  style={styles.bossImg}
                                  source={anonymousPng}
                                />
                              )}
                              <Box sx={{ marginLeft: 8 }}>
                                <EmployeeNameText>
                                  {item.employee_name}
                                </EmployeeNameText>
                                {employees.map(
                                  (employee: IEmployee) =>
                                    employee.id === item.id && (
                                      <>
                                        <EmployeeDesignationName>
                                          {employee.designation &&
                                            employee.designation.name}
                                        </EmployeeDesignationName>
                                        <EmployeeDepartmentName>
                                          {employee?.department?.name}
                                        </EmployeeDepartmentName>
                                      </>
                                    ),
                                )}
                              </Box>
                            </View>
                            <Button
                              testID="openDetailModalWeb"
                              title="Detail"
                              onPress={() =>
                                this.openEmployeeDetailModal(true, item.id)
                              }
                            />
                          </View>
                        </View>
                      </TouchableOpacity>
                      {item.workers &&
                        item.workers?.length > 0 &&
                        item.isOpen && (
                          <DynamicView>{this.dynamicWorkers(item)}</DynamicView>
                        )}
                    </View>
                  ),
                )}
              </View>
            ) : (
              <View>
                <TextInput
                  testID="searchBarWeb"
                  style={styles.textInput}
                  placeholder="Search Task Name"
                  value={searchInputValue}
                  onChangeText={(value) => {
                    this.searchData(value);
                    this.onChangeTextInputValue(value);
                  }}
                />
                {this.searchedDataFunc()}
              </View>
            )}
          </View>
        </Container>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const EmployeeDepartmentName = styled(Typography)({
  fontSize: 14,
  marginLeft: 8,
  color: "#6C6A6A",
});

const ModalTitle = styled(Typography)({
  alignSelf: "center",
  fontWeight: "bold",
});

const EmployeeDesignationName = styled(Typography)({
  marginLeft: 8,
  fontSize: 14,
  color: "gray",
});

const SelectedHeaderText = styled(Typography)({
  fontSize: 16,
  fontWeight: "bold",
});

const UnselectedHeaderText = styled(Typography)({
  fontSize: 16,
  fontWeight: "normal",
});

const EmployeeNameText = styled(Typography)({
  marginLeft: 8,
  fontSize: 16,
  fontWeight: "bold",
});

const WorkerLengthInfoText = styled(Typography)({
  color: "white",
  fontSize: 12,
});

const DynamicView = styled(Box)({
  backgroundColor: "#B7B7B7",
  paddingTop: 16,
  paddingBottom: 16,
  paddingRight: 16,
  borderRadius: 12,
});

const HalfDivider = styled(Box)({
  width: "100%",
  height: 2,
  backgroundColor: "gray",
  marginBottom: 12,
  marginTop: 12,
});

const FillView = styled(Box)({
  width: 10,
  height: 20,
});

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    width: "100%",
    backgroundColor: "#E3E3E3",
  },
  itemView: {
    backgroundColor: "#fff",
    borderRadius: 8,
    paddingTop: 8,
    padding: 4,
    marginTop: 20,
    marginBottom: 12,
  },
  bossContainer: {
    flexDirection: "row",
    marginBottom: 8,
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 16,
    marginLeft: 16,
  },
  bossImg: {
    width: 45,
    height: 45,
    borderRadius: 160,
  },

  textInput: {
    height: 40,
    marginHorizontal: 12,
    marginVertical: 8,
    borderWidth: 1,
    borderColor: "gray",
    padding: 10,
    borderRadius: 8,
  },
  modalFirstView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },

  selectedViewPage: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 16,
    marginBottom: 16,
  },

  imageView: {
    flexDirection: "row",
    alignItems: "center",
  },

  workerLengthInfo: {
    backgroundColor: "darkred",
    width: 16,
    height: 16,
    borderRadius: 8,
    position: "absolute",
    bottom: -8,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
  },

  selectedEmployeeInfoView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  employeeInfoFirstView: {
    width: 8,
    height: 8,
  },

  itemImg: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },

  employeesMapView: {
    flexDirection: "row",
    alignItems: "center",
  },
});
// Customizable Area End
