import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import VideoInputController, { Props } from "./VideoInputController.web";
export default class VideoInput extends VideoInputController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={styles.mainWrapper}>
            <Box sx={styles.inputStyle}>
              <Typography>{"Title"}</Typography>
              <Input
                data-test-id="TxtTitle"
                onChange={(event) => this.handleChangeValues(event)}
                value={this.state.title}
                placeholder="Enter Title"
                type="text"
                name="title"
              />
            </Box>
            <Box sx={styles.inputStyle}>
              <Typography>{"Page"}</Typography>
              <Input
                data-test-id="TxtPage"
                onChange={(event) => this.handleChangeValues(event)}
                value={this.state.page}
                placeholder="Enter Page"
                type="text"
                name="page"
              />
            </Box>
            <Box sx={styles.inputStyle}>
              <Typography>{"Section (Optional)"}</Typography>
              <Input
                data-test-id="TxtSection"
                onChange={(event) => this.handleChangeValues(event)}
                value={this.state.section}
                placeholder="Enter Section"
                type="text"
                name="section"
              />
            </Box>

            <Box sx={styles.inputStyle}>
              <Typography>{"Width"}</Typography>
              <Input
                data-test-id="TxtWidth"
                onChange={(event) => this.handleChangeValues(event)}
                value={this.state.width.toString()}
                placeholder="Enter Width"
                type="number"
                name="width"
              />
            </Box>

            <Box sx={styles.inputStyle}>
              <Typography>{"Height"}</Typography>
              <Input
                data-test-id="TxtHeight"
                onChange={(event) => this.handleChangeValues(event)}
                value={this.state.height.toString()}
                placeholder="Enter Height"
                type="number"
                name="height"
              />
            </Box>
            <Box sx={styles.inputStyle}>
              <Typography>{"X"}</Typography>
              <Input
                data-test-id="TxtX"
                onChange={(event) => this.handleChangeValues(event)}
                value={this.state.x}
                placeholder="Enter X "
                type="number"
                name="x"
              />
            </Box>
            <Box sx={styles.inputStyle}>
              <Typography>{"Y"}</Typography>
              <Input
                data-test-id="TxtY"
                onChange={(event) => this.handleChangeValues(event)}
                value={this.state.y}
                placeholder="Enter Y"
                type="number"
                name="y"
              />
            </Box>
            <Box
              data-test-id="btnUploadVideo"
              component="button"
              sx={styles.buttonStyle}>
              <input
                data-test-id="fileInputRef"
                style={{ display: "none" }}
                ref={this.handleFileInputRef}
                type={"file"}
                onChange={(event) => this.handleUploadInputChange(event)}
              />
              <Button
                color={"primary"}
                data-test-id="openvideopicker"
                onClick={() => this.handleChooseFileClick()}>
                Upload Video
              </Button>
            </Box>
            {this.state.videopath !== "" && (
              <Box>
                <Typography>{this.state.videopath}</Typography>
              </Box>
            )}
            {
              <Box
                data-test-id="btnUploadSubmit"
                component="button"
                sx={styles.buttonStyle}>
                <Button
                  onClick={() => this.uploadVideo()}
                  color={"primary"}
                  data-test-id="openvideosubmit">
                  Submit
                </Button>
              </Box>
            }
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    marginTop: "5px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "10px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
