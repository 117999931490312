import { Message } from "./Message";
import MessageEnum, {
    getName,
} from "./Messages/MessageEnum";
import { runEngine } from "./RunEngine";

export function createRequestMessage(request: {
    requestMessage: Message,
    endPoint: string,
    header?: any,
    method: string,
    token?: string,
    body?: any,
}) {
    //

    const { requestMessage, endPoint, header, method, token, body } = request;

    const initialHeader = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: token ?? undefined,
        ...header,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(initialHeader)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );

    body &&
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

export default createRequestMessage;
//