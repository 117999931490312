import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  MenuItem,
  InputLabel,
  Select,
  Typography,
  Table,
  TableBody,
  Checkbox,
  TableRow,
  TableHead,
  TableCell,
  Divider,
  FormGroup,
  Modal,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import { Chart } from "react-google-charts";
import { RemoveCircleOutline, Edit } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { CSVLink } from "react-csv";
export const configJSON = require("./config");

// Customizable Area End

import ExpenseTrackingController, {
  Props,
  CategoryData,
} from "./ExpenseTrackingController.web";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
};

export default class ExpenseTracking extends ExpenseTrackingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    let allCategories: string[] = [];
    let categories_id: number[] = [];
    this.state.userAllCategories.forEach((item: CategoryData) => {
      let categoryData = item.attributes;
      allCategories.push(categoryData.name);
      categories_id.push(categoryData.id);
    });

    let arrayOfDates: number[] = Array.from(
      { length: 31 },
      (test, item) => item + 1,
    );
    let numberOfMonths: number[] = Array.from(
      { length: 12 },
      (test, item) => item + 1,
    );

    // merge Engine - render - Start
    return (
      // Customizable Area Start
      <>
        {/* categories delete modal  */}
        <Dialog
          open={this.state.deleteCategories}
          onClose={this.handleDeleteCategoriesClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          data-testID="delete_confirmation_for_category">
          <DialogTitle id="alert-dialog-title">
            {"Are You Sure, You Want To Delete This Category?"}
          </DialogTitle>
          <DialogActions>
            <Button
              data-testID="Delete_YES_Category"
              onClick={() => this.removeItems(this.state.deleteCategoriesID)}>
              yes
            </Button>
            <Button
              data-testID="Delete_NO_Category"
              onClick={this.handleDeleteCategoriesClose}>
              no
            </Button>
          </DialogActions>
        </Dialog>

        {/* transaction delete modal  */}

        <Dialog
          open={this.state.deleteTransaction}
          onClose={this.handleDeleteTransactionClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          data-testID="delete_confirmation_view_details">
          <DialogTitle id="alert-dialog-title">
            {"Are You Sure, You Want To Delete This Transaction?"}
          </DialogTitle>
          <DialogActions>
            <Button
              data-testID="yes_delete_button"
              onClick={() =>
                this.removeTransaction(this.state.deleteTransactionID)
              }>
              yes
            </Button>
            <Button
              data-testID="no_delete_button"
              onClick={this.handleDeleteTransactionClose}>
              no
            </Button>
          </DialogActions>
        </Dialog>

        {/* // New Expanse Modal */}
        <Modal
          open={this.state.NewExpanse}
          onClose={this.handleExpanseClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testID="new_expanse_modal">
          <Box sx={webStyle.modalDesign}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Expense
            </Typography>
            <Divider />
            <Box style={webStyle.marginStyle}>
              <InputLabel>Name</InputLabel>
              <input
                data-testID="newExpenseName"
                type="text"
                onChange={this.createNewName}
                value={this.state.NewExpanseName}
              />
            </Box>
            <Box style={webStyle.marginStyle}>
              <InputLabel>Amount</InputLabel>
              <input
                data-testID="newExpenseAmount"
                type="number"
                onChange={this.createNewAmount}
                value={this.state.NewExpanseAmount}
              />
            </Box>
            <Box style={webStyle.marginStyle}>
              <InputLabel>Date</InputLabel>
              <input
                data-testID="newExpenseDate"
                type="Date"
                placeholder=""
                onChange={this.createNewDate}
                value={this.state.NewExpanseDate}
              />
            </Box>
            <Box style={webStyle.marginStyle}>
              <InputLabel>Categories</InputLabel>
              <Select
                data-testID="newExpenseCategory"
                style={webStyle.paddingRightStyle}
                onChange={this.createNewCaTId}
                value={this.state.ExpanseCategoryId}>
                {allCategories.map((item: string, index: number) => {
                  return (
                    <MenuItem
                      key={categories_id[index]}
                      value={categories_id[index]}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box style={webStyle.marginStyle}>
              <FormGroup data-testID="newExpenseCheckbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeIsSelected}
                      checked={this.state.NewExpanseRecurring}
                    />
                  }
                  label="Recurring Checkbox"
                />
              </FormGroup>
            </Box>
            <Box style={webStyle.marginStyle}>
              <InputLabel>Recurring Date</InputLabel>
              <Select
                data-testID="newExpenseRecurringDate"
                style={webStyle.paddingRightStyle}
                defaultValue={1}
                onChange={this.createArrayOfDates}
                value={this.state.RecurringDate}>
                {arrayOfDates.map((item: number) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box>
              {this.state.EditTransactionId !== "" ? (
                <Button
                  data-testID="edit_new_expense"
                  onClick={this.updateExpensesApi}>
                  Save
                </Button>
              ) : (
                <Button
                  data-testID="save_new_expense"
                  style={webStyle.saveButton}
                  onClick={this.checkIfAllDataFilled}>
                  Save
                </Button>
              )}
            </Box>
          </Box>
        </Modal>

        {/* // CSV File Modal */}
        <Modal
          data-testId="export_close"
          open={this.state.NewExport}
          onClose={this.handleExportClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={webStyle.modalDesign}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Export CSV File
            </Typography>
            <Divider />
            <Box style={webStyle.marginStyle}>
              <Select
                data-testID="SelectForCSVchange"
                defaultValue={"monthly"}
                onChange={(event) => {
                  this.changeAccordingCSVExport(event.target.value);
                }}
                style={webStyle.accordionWrapper}>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
                <MenuItem value={"yearly"}>Yearly</MenuItem>
              </Select>
              <Box style={webStyle.filterWrapper}>
                <div style={webStyle.flexContent}>
                  <Select
                    data-testID="csvFilterMonth"
                    defaultValue={this.state.monthSelect}
                    onChange={(event) => {
                      this.createCSVMonth(event.target.value);
                    }}
                    style={webStyle.widthContainer}>
                    {numberOfMonths.map((item: number) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    data-testID="csvFilterYear"
                    defaultValue={this.state.yearSelect}
                    onChange={(event) => {
                      this.createCSVYear(event.target.value);
                    }}
                    MenuProps={MenuProps}
                    style={webStyle.widthContainer}>
                    {Array.from(
                      { length: 2028 - 2000 },
                      (test, value) => 2000 + value,
                    ).map((item: number) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Box>
            </Box>
            <CSVLink {...this.state.csvReport} style={webStyle.textDecorator}>
              <Button data-testID="ExportButton" style={webStyle.csvLinkStyle}>
                Download CSV
              </Button>
            </CSVLink>
          </Box>
        </Modal>
        {/* Details - total Expanses Dialog */}
        <Dialog
          data-testID="viewDetailsId"
          open={this.state.TotalDetailsExpanse}
          onClose={this.handleDetailExpanseClose}>
          <DialogTitle>Details - Total Expanses</DialogTitle>
          <Table
            data-testID="total_expanse_table"
            style={webStyle.totalExpenseTable}>
            <TableHead>
              <TableRow style={webStyle.tableCellStyle}>
                <TableCell style={webStyle.tableCellStyle}>Name</TableCell>
                <TableCell style={webStyle.tableCellStyle} align="right">
                  Amount
                </TableCell>
                <TableCell style={webStyle.tableCellStyle} align="right">
                  Date
                </TableCell>
                <TableCell style={webStyle.tableCellStyle} align="right">
                  Cat.
                </TableCell>
                <TableCell style={webStyle.tableCellStyle} align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.detailExpenses.map((rowData, index) => {
                let latestData = this.state.detailExpenses[index].attributes;
                let latestID = this.state.detailExpenses[index];
                return (
                  <TableRow key={rowData.id} style={webStyle.tableCellStyle}>
                    <TableCell component="th" scope="row">
                      {latestData.name}
                    </TableCell>
                    <TableCell style={webStyle.tableCellStyle} align="right">
                      {latestData.amount}
                    </TableCell>
                    <TableCell style={webStyle.tableCellStyle} align="right">
                      {latestData.date}
                    </TableCell>
                    <TableCell style={webStyle.tableCellStyle} align="right">
                      {latestData.category_name}
                    </TableCell>
                    <TableCell style={webStyle.tableCellStyle} align="right">
                      <Edit
                        data-testID="Edit_view_detail"
                        onClick={() => {
                          this.handleEditTransaction(latestID.id);
                        }}
                      />
                      <RemoveCircleOutline
                        data-testID="Delete_view_detail"
                        onClick={() => {
                          this.handleDeleteTransaction(latestID.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div style={webStyle.paginationStyle}>
            <Pagination
              data-testID="pagination_viewDetails"
              count={this.state.viewDetailsCount}
              variant="outlined"
              shape="rounded"
              onChange={(
                event: React.ChangeEvent<unknown>,
                newPage: number,
              ) => {
                this.pageDataChangeDetails(event, newPage);
              }}
            />
          </div>
        </Dialog>
        <Dialog
          data-testID="New_Category_Button"
          open={this.state.CategoriesDetails}
          onClose={this.handleCategories}>
          <DialogTitle>Categories</DialogTitle>
          <Box style={webStyle.marginStyle}>
            <InputLabel>Name</InputLabel>
            <input
              data-testID="txtNameCategory"
              type="text"
              onChange={this.txtInputNameProps}
              value={this.state.CreateNewCategory}
            />
          </Box>
          <Box style={webStyle.marginStyle}>
            <InputLabel>Budget Limit</InputLabel>
            <input
              data-testID="BudgetLimit"
              type="text"
              onChange={this.txtInputZipCodeProps}
              value={this.state.CreateNewBudget}
            />
          </Box>
          <Box style={webStyle.marginStyle}>
            <InputLabel>Currency</InputLabel>
            <input
              data-testID="Currency"
              type="text"
              disabled
              defaultValue={configJSON.expenseCurrency}
              onChange={this.txtInputFirstNameProps}
              value={configJSON.expenseCurrency}
            />
          </Box>
          {this.state.EditCategoriesId !== "" ? (
            <Button
              data-testID="Save_Edit_Category"
              onClick={this.updateCategories}>
              Save
            </Button>
          ) : (
            <Button
              data-testID="save_new_category"
              onClick={this.postCreateNewCategories}>
              Save
            </Button>
          )}
        </Dialog>
        <div style={webStyle.buttonDivStyle}>
          <Button
            data-testID="new_expense"
            onClick={this.handleExpanseOpen}
            style={webStyle.buttonStyle}>
            New Expense
          </Button>
          <input
            data-testID="importInput"
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={this.selectFile}
            style={webStyle.noneInput}
          />
          <label
            style={webStyle.labelStyle}
            htmlFor="csvFileInput"
            className="custom-file-upload">
            IMPORT
          </label>
          <Button style={webStyle.exportButton} onClick={this.handleExportOpen}>
            Export
          </Button>
        </div>
        <div style={webStyle.mainDivStyle}>
          <div style={webStyle.totalExpanseStyle}>
            <div>Monthly Expenses</div>
            <div>
              {configJSON.expenseCurrencySymbol}
              {this.state.expensesMonth}.00
            </div>
            <Button
              data-testID="viewDetailsButton"
              style={webStyle.viewDetailStyle}
              onClick={() => this.handleDetailExpanseOpen("monthly")}>
              View Details
            </Button>
          </div>
          <div style={webStyle.yearlyExpanseStyle}>
            <div>Yearly Expenses</div>
            <div>
              {configJSON.expenseCurrencySymbol}
              {this.state.expensesYear}.00
            </div>
            <Button
              style={webStyle.viewDetailStyle}
              onClick={() => this.handleDetailExpanseOpen("yearly")}>
              View Details
            </Button>
          </div>
        </div>
        <div style={webStyle.chartWrapper}>
          <Select
            data-testID="SelectForDataChange"
            defaultValue={"monthly"}
            onChange={(event) => {
              this.changeAccordingExpenseChart(event.target.value);
            }}
            style={webStyle.accordionWrapper}>
            <MenuItem value={"monthly"}>Monthly</MenuItem>
            <MenuItem value={"yearly"}>Yearly</MenuItem>
          </Select>
          <Typography style={{ textAlign: "center" }}>
            Trending Transaction Expense
          </Typography>
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={this.state.expenseChartData}
            options={this.options}
          />
        </div>
        <div style={webStyle.mainDivStyle}>
          <div style={webStyle.tableDivStyle}>
            <div style={webStyle.marginBottomStyle}>Categories</div>
            <Button
              data-testID="NewButton"
              style={webStyle.buttonNewStyle}
              onClick={this.handleCategoriesOpen}>
              New
            </Button>
            <Table data-testID="Category_Table">
              <TableHead>
                <TableRow style={webStyle.tableCellStyle}>
                  <TableCell style={webStyle.tableCellStyle}>Name</TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Amount
                  </TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Currency
                  </TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Budget
                  </TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.userCategoriesLimited.map((rowData, index) => {
                  let rowCategoryData =
                    this.state.userCategoriesLimited[index].attributes;
                  return (
                    <TableRow key={rowData.id} style={webStyle.tableCellStyle}>
                      <TableCell component="th" scope="row">
                        {rowCategoryData.name}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        {rowCategoryData.total_amount}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        {rowCategoryData.currency}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        {rowCategoryData.amount}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        <Edit
                          data-testID="Edit_Category"
                          onClick={() => {
                            this.handleEditCategories(
                              this.state.userCategoriesLimited[index].id,
                            );
                          }}
                        />
                        <RemoveCircleOutline
                          data-testID="Delete_Category"
                          onClick={() =>
                            this.handleDeleteCategories(
                              this.state.userCategoriesLimited[index].id,
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <div style={webStyle.paginationStyle}>
              <Pagination
                data-testID="pagination_category"
                count={this.state.categoriesPageNo}
                variant="outlined"
                shape="rounded"
                onChange={(event: React.ChangeEvent<unknown>, newPage) => {
                  this.pageDataChange(event, newPage);
                }}
              />
            </div>
          </div>
        </div>
        <div style={webStyle.mainDivStyle}>
          <div style={webStyle.tableDivStyle}>
            <div style={webStyle.marginBottomStyle}>Transactions</div>
            <Select
              data-testID="selectForFilterUpdate"
              defaultValue={"monthly"}
              onChange={(event) => {
                this.changeAccordingTransaction(event.target.value);
              }}
              style={webStyle.accordionWrapper}>
              <MenuItem value={"monthly"}>Monthly</MenuItem>
              <MenuItem value={"yearly"}>Yearly</MenuItem>
            </Select>
            <Box style={webStyle.filterWrapper}>
              <div style={webStyle.flexContent}>
                {this.state.transactionTracking !== "yearly" && (
                  <Select
                    data-testID="createMonths"
                    defaultValue={this.state.selectMonth}
                    onChange={(event) => {
                      this.createMonth(event.target.value);
                    }}
                    MenuProps={MenuProps}
                    style={webStyle.widthContainer}>
                    {numberOfMonths.map((item: number) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                <Select
                  data-testID="createYears"
                  defaultValue={this.state.selectYear}
                  onChange={(event) => {
                    this.createYear(event.target.value);
                  }}
                  MenuProps={MenuProps}
                  style={webStyle.widthContainer}>
                  {Array.from(
                    { length: 2028 - 2000 },
                    (test, value) => 2000 + value,
                  ).map((item: number) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <Button
                data-testID="filterTransaction"
                style={webStyle.transactionWrapper}
                onClick={() => this.transactionsApiFirstPage(1)}>
                Filter
              </Button>
            </Box>
            <Table data-testID="Transaction_table">
              <TableHead>
                <TableRow style={webStyle.tableCellStyle}>
                  <TableCell style={webStyle.tableCellStyle}>Name</TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Amount
                  </TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Date
                  </TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Cat.
                  </TableCell>
                  <TableCell style={webStyle.tableCellStyle} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(
                  (!this.state.errorResponseTransaction &&
                    this.state.TransactionFilterData) ||
                  []
                ).map((rowData, index) => {
                  let updatedData =
                    this.state.TransactionFilterData[index].attributes;
                  return (
                    <TableRow key={rowData.id} style={webStyle.tableCellStyle}>
                      <TableCell component="th" scope="row">
                        {updatedData.name}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        {updatedData.amount}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        {updatedData.date}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        {updatedData.category_name}
                      </TableCell>
                      <TableCell style={webStyle.tableCellStyle} align="right">
                        <Edit
                          data-testID="EditTransaction"
                          onClick={() => {
                            this.handleEditTransaction(
                              this.state.TransactionFilterData[index].id,
                            );
                          }}
                        />
                        <RemoveCircleOutline
                          data-testID="DeleteTransaction"
                          onClick={() => {
                            this.handleDeleteTransaction(
                              this.state.TransactionFilterData[index].id,
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {this.state.errorResponseTransaction && (
                  <TableRow style={webStyle.errorResTable}>
                    <TableCell colSpan={5} align="center">
                      {this.state.errorResponseTransaction}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div style={webStyle.paginationStyle}>
              {!this.state.errorResponseTransaction && (
                <Pagination
                  data-testID="TransactionPagination"
                  count={Math.ceil(this.state.totalPages)}
                  variant="outlined"
                  shape="rounded"
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newPagTransaction: number,
                  ) => {
                    this.handleTransactionPage(event, newPagTransaction);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  tableCellStyle: {
    border: "dashed",
  },
  modalDesign: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  marginStyle: {
    margin: "10px",
  },
  mainDivStyle: {
    display: "flex",
    justifyContent: "center",
    margin: "10px",
  },
  paddingRightStyle: {
    paddingRight: "115px",
  },
  marginBottomStyle: {
    marginBottom: "15px",
  },
  labelStyle: {
    backgroundColor: "#a8a838",
    fontSize: "14px",
    width: "80px",
    alignItems: "center",
    color: "white",
    borderRadius: "5px",
    paddingTop: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
  paginationStyle: {
    marginTop: "10px",
  },
  buttonDivStyle: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "10px",
  },
  buttonStyle: {
    backgroundColor: "green",
    width: "130px",
    alignItems: "center",
    color: "white",
  },
  totalExpanseStyle: {
    border: "solid black 1px",
    width: "240px",
    height: "150px",
    display: "grid",
    justifyContent: "center",
    margin: "0 15px 15px 0px",
  },
  yearlyExpanseStyle: {
    border: "solid black 1px",
    width: "240px",
    height: "150px",
    display: "grid",
    justifyContent: "center",
    margin: "0 0 15px 15px",
  },
  viewDetailStyle: {
    backgroundColor: "#b712cd",
    width: "130px",
    height: "40px",
    color: "white",
  },
  exportButton: {
    backgroundColor: "red",
    color: "#ffffff",
  },
  tableDivStyle: {
    border: "solid black 1px",
    width: "75%",
    padding: "25px",
    marginBottom: "30px",
  },
  csvLinkStyle: {
    backgroundColor: "red",
    width: "150px",
    alignItems: "center",
    color: "white",
  },
  buttonNewStyle: {
    backgroundColor: "green",
    marginBottom: "15px",
    width: "0px",
  },
  chartWrapper: {
    width: "74%",
    margin: "0 auto",
    border: "solid black 1px",
    padding: "25px",
  },
  textDecorator: {
    textDecoration: "none",
  },
  filterWrapper: {
    display: "flex",
    marginBottom: "15px",
  },
  accordionWrapper: {
    marginBottom: "20px",
  },
  saveButton: {
    backgroundColor: "#0ca3d5",
    width: "130px",
  },
  transactionWrapper: {
    background: "green",
    color: "#FFFFFF",
    height: "35px",
  },
  errorResTable: {
    height: "200px",
  },
  widthContainer: {
    width: "100px",
  },
  flexContent: {
    display: "flex",
  },
  noneInput: {
    display: "none",
  },
  totalExpenseTable: {
    margin: "15px",
    width: "70%",
  },
  chartText: {
    textAlign: "center",
  },
};

// Customizable Area End
