import React, { useEffect, useRef } from "react";

import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";

import ImageEditor from "tui-image-editor";

export interface Props {
  tuiImageEditorProps: tuiImageEditor.IOptions;
  doButtonPressed: (image: string) => Promise<void>;
  initialImage: string;
}

export interface ActiveMenuEventProps {
  activeMenuEvent: () => Promise<void>;
}

const ImageEditorContainer = (props: Props) => {
  const rootEl = useRef<HTMLDivElement>(null);
  const instance = useRef<ImageEditor | null>(null);

  useEffect(() => {
    instance.current = new ImageEditor(rootEl.current as HTMLDivElement, {
      ...props.tuiImageEditorProps,
    });
    return () => {
      instance?.current?.destroy();
    };
  }, [instance, props.tuiImageEditorProps]);

  useEffect(() => {
    const loadEditor = async () => {
      try {
        await instance?.current?.loadImageFromURL(
          props.initialImage,
          "background",
        );
        setTimeout(() => {
          if (instance.current) {
            const editedImageDataURL: ActiveMenuEventProps = instance.current
              .ui as unknown as ActiveMenuEventProps;
            editedImageDataURL.activeMenuEvent();
          }
        }, 1000);
      } catch (error) {}
    };

    loadEditor();
  }, [props.initialImage]);

  const handleApplyButtonClick = async () => {
    if (instance.current) {
      const editedImageDataURL = instance.current.toDataURL(); // Get the edited image as a base64 data URL
      await props.doButtonPressed(editedImageDataURL);
      // Do something with the edited image data URL
    }
  };

  return (
    <div className="PR">
      <div ref={rootEl} />
      <button
        onClick={() => {
          handleApplyButtonClick();
        }}>
        Upload
      </button>
    </div>
  );
};

export default ImageEditorContainer;
