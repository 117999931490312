import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { ChangeEvent } from "react";
const urlToBlob = require("urltoblob-ext");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  editTitle: string;
  editWidth: string;
  editHeight: string;
  videoEditPath: string;
  videoRes: File | string;
  editSection: string;
  editX: string;
  editY: string;
  isLoader: boolean;
  editPage: string;
  videoId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoBackgroundEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      editTitle: "",
      editWidth: "",
      editHeight: "",
      videoEditPath: "",
      videoRes: "",
      editSection: "",
      editX: "",
      editY: "",
      isLoader: false,
      editPage: "",
      videoId: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  apiEditVideoCallId: string = "";
  apiGetVideoDataCallId: string = "";
  fileInputRef: HTMLInputElement | null = null;

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      if (apiRequestCallId !== null) {
        if (apiRequestCallId === this.apiEditVideoCallId) {
          this.setState({
            isLoader: false,
            editTitle: "",
            editWidth: "",
            editHeight: "",
            editX: "",
            editY: "",
            videoEditPath: "",
            videoRes: "",
            editSection: "",
            editPage: "",
          });
          this.props.navigation.navigate("VideoPageList");
        }

        if (apiRequestCallId === this.apiGetVideoDataCallId) {
          if (responseJson) {
            this.setState({
              editTitle: responseJson.data.attributes.title,
              editWidth: responseJson.data.attributes.scale_w.toString(),
              editHeight: responseJson.data.attributes.scale_h.toString(),
              videoEditPath: responseJson.data.attributes.video,
              editSection: responseJson.data.attributes.section,
              editX: responseJson.data.attributes.scale_x.toString(),
              editY: responseJson.data.attributes.scale_y.toString(),
              editPage: responseJson.data.attributes.page,
            });
          }
        }
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getVideoData(this.props.navigation?.getParam("id"));
    this.setState({ videoId: this.props.navigation?.getParam("id") });
  }

  handleEditChangeValues = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event?.target ?? { name: "", value: "" };
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  };

  handleFileInputRef = (event: HTMLInputElement | null) => {
    this.fileInputRef = event;
  };

  handleEditChooseFileClick = () => {
    if (this.fileInputRef && typeof this.fileInputRef.click === "function") {
      this.fileInputRef.click();
    }
  };

  handleEditUploadInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const uploadedFile = event.target.files && event.target.files[0];
    if (uploadedFile) {
      const blob = await urlToBlob.urlToBlob([uploadedFile], {
        type: uploadedFile.type,
      });
      const fileUrl = URL.createObjectURL(blob);
      this.setState({ videoEditPath: fileUrl, videoRes: uploadedFile });
    }
  };

  async getVideoData(videoId: string) {
    let token = await getStorageData("Token");
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.apiGetVideoDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVideoByIDEndPoint + videoId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async uploadEditVideo() {
    const { editTitle, editPage, editWidth, editHeight, editX, editY } =
      this.state;
    const variables = [
      { name: editTitle, message: "Please add title" },
      { name: editPage, message: "Please add page" },
      { name: editWidth, message: "Please add width" },
      { name: editHeight, message: "Please add height" },
      { name: editX, message: "Please add x" },
      { name: editY, message: "Please add y" },
    ];

    for (const variable of variables) {
      if (!variable.name) {
        this.showAlert("Warning", variable.message);
        return;
      }
    }

    await this.uploadEditData();
  }

  async uploadEditData() {
    const token = await getStorageData("Token");

    this.setState({ isLoader: true });

    const formData = new FormData();
    formData.append("title", this.state.editTitle);

    this.state.videoRes !== "" && formData.append("video", this.state.videoRes);

    formData.append("page", this.state.editPage);

    this.state.editSection &&
      formData.append("section", this.state.editSection);

    formData.append("scale_w", this.state.editWidth);
    formData.append("scale_h", this.state.editHeight);
    formData.append("scale_x", this.state.editX);
    formData.append("scale_y", this.state.editY);

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.apiEditVideoCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVideoByIDEndPoint + this.state.videoId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiEditMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area Start
  // Customizable Area End
}
