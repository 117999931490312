import * as React from "react";
import { PropsWithChildren, ReactElement } from "react";
import {
  Typography,
  TextField,
  OutlinedTextFieldProps,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export interface InputProps extends OutlinedTextFieldProps {
  errorTitle?: string | false;
}

const TextInput = styled(TextField)({
  marginTop: 16,
  borderColor: "rgba(0, 0, 0, 0.23)",
});

const ErrorTypo = styled(Typography)({
  color: "red",
  marginTop: 8,
});

export function Input(
  props: PropsWithChildren<Partial<InputProps>>,
): ReactElement {
  const { errorTitle, ...restProps } = props;

  return (
    <>
      <TextInput
        color="primary"
        variant="outlined"
        fullWidth
        error={!!errorTitle}
        {...restProps}
      />
      {errorTitle && <ErrorTypo data-test-id="error">{errorTitle}</ErrorTypo>}
    </>
  );
}

Input.defaultProps = {};

export default Input;
