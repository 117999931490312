import React from "react";
import { Box, Typography } from "@material-ui/core";
import colors from "../../../styles/colors";

class WaitingToJoinView extends React.PureComponent {
  render(): React.ReactNode {
    return (
      <Box sx={webStyle.container}>
        <Typography style={webStyle.waitingText}>
          Creating a room waiting ...
        </Typography>
      </Box>
    );
  }
}

export default WaitingToJoinView;

const webStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    backgroundColor: colors.primary["900"],
  },
  waitingText: {
    fontSize: 30,
    color: "#FFF",
    fontWeight: 600,
  },
};
