import React from "react";

import { Container, Typography } from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import PasswordProtectedPagesController, {
  Props,
} from "./PasswordProtectedPagesController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class PasswordProtectedPages extends PasswordProtectedPagesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Dialog
            open={this.state.modalVisible}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
              <div>
                <div style={webStyle.modalContentContainer}>
                  {this.state.visibleWarning && (
                    <Typography style={webStyle.labelWarning}>
                      {this.state.errorMessage}
                    </Typography>
                  )}

                  <Typography style={webStyle.labelModal}>
                    Password <span style={webStyle.asterisk}>*</span>{" "}
                  </Typography>

                  <div style={webStyle.passwordContainer}>
                    <TextField
                      data-test-id={"passwordInput"}
                      color={"primary"}
                      variant="outlined"
                      type={this.state.visiblePassword ? "text" : "password"}
                      name={"name"}
                      placeholder={`Enter page's password`}
                      onChange={(event) =>
                        this.handleEnterPassword(event.target.value)
                      }
                      required
                      style={{ flex: 1, outline: "none" }}
                    />
                    <div
                      style={webStyle.showHide}
                      onClick={this.handleToggleVisiblePassword}>
                      {this.state.visiblePassword ? "Hide" : "Show"}
                    </div>
                  </div>

                  <div style={webStyle.buttonContainer}>
                    <div
                      data-test-id={"seeContentBtn"}
                      onClick={() => {
                        if (this.state.isLoading) {
                          return;
                        }
                        this.handleConfirm(true);
                      }}
                      // loading={isLoading}
                      style={webStyle.seeBtn}>
                      See Content
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            open={this.state.showSuccessModal}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
              <div style={webStyle.successModalContainer}>
                <div style={webStyle.successModalMessagecontainer}>
                  <div style={webStyle.successText}>Success</div>
                  <div>You can access page now</div>
                </div>
                <div
                  onClick={this.handleSuccessModalClose.bind(this)}
                  style={webStyle.successModalBtn}>
                  Ok
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  successModalContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  successText: {
    fontWeight: "bolder" as const,
    marginBottom: 10,
    fontSize: 18,
  },
  successModalMessagecontainer: {
    paddingTop: 16,
    paddingBottom: 16,
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    borderBottom: "1px solid grey",
    alignItems: "center",
    width: "100%",
    padding: "0px 24px 20px",
  },
  successModalBtn: {
    paddingTop: 16,
    paddingBottom: 16,
    background: "white",
    color: "#2196f3",
    textAlign: "center" as const,
    cursor: "pointer",
    padding: "20px 0px 12px",
    fontWeight: "bold" as const,
  },
  showHide: {
    padding: 16,
    paddingRight: 0,
    cursor: "pointer",
  },
  seeBtn: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 8,
    paddingLeft: 8,
    color: "white",
    background: "#2196f3",
    borderRadius: 4,
    cursor: "pointer",
  },
  modalContentContainer: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 16,
  },
  asterisk: {
    color: "red",
  },
  labelModal: {
    fontSize: 16,
    marginBottom: 16,
  },
  passwordContainer: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between",

    "& .MuiFormControl-root": {
      flex: 1,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  labelWarning: {
    marginBottom: 16,
    fontSize: 16,
    color: "red",
  },
};
// Customizable Area End
