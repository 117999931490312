Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
exports.loginApiEndPoint = "bx_block_login/logins";
exports.productApiEndPoint = "bx_block_centralized_billings/products";
exports.createProductApiEndPoint = "bx_block_centralized_billings/products";
exports.addShippingDetailsApiEndPoint =
  "bx_block_local_international_shipping/local_shippings/";
exports.getShippingDetailsApiEndPoint =
  "bx_block_local_international_shipping/product_wise_local_shippings/";
exports.getShippingAvailabilityAPIEndPoint = `bx_block_local_international_shipping/product_shipping_wise_block_countries/`;
exports.updateShippingDetailApiEndPoint =
  "bx_block_local_international_shipping/local_shippings/";
exports.checkCartApiEndPoint =
  "bx_block_local_international_shipping/product_shipped?query=";
exports.getContinentsApiEndPoint =
  "bx_block_local_international_shipping/continents";
exports.getCountriesApiEndPoint =
  "bx_block_local_international_shipping/countries?name_of_continents=";
exports.email = "BB1@gmail.com";
exports.securedText = "Password@BB1";
exports.changeShippingAddress = "Change \nShipping Address";
exports.addToCart = "Add to Cart";
exports.modifyShippingDetails = "Modify the Shipping Details";
exports.price = "Price: ";
exports.createNewProduct = "Create \nNew Product";
exports.productCreationForm = "Product Creation Form";
exports.name = "Name*";
exports.description = "Description";
exports.serialNo = "Serial Number*";
exports.mandatoryPrice = "Price*";
exports.done = "Done";
exports.shippingDetailsForm = "Shipping Details Form";
exports.continent = "Continent*";
exports.blockCountries = "Block Countries*";
exports.proceedToCheckout = "Proceed to Checkout";
exports.remove = "Remove";
exports.notShipped = "Product can't shipped to your address";
exports.cart = "Cart";
exports.pinCode = "Pin Code*";
exports.address2 = "Address 2 (Optional)";
exports.address1 = "Address 1*";
exports.addressForm = "Address Form";
exports.country = "Country*";
const sellerDetails = {
  data: {
    id: "",
    type: "",
    attributes: {
      activated: true,
      country_code: "",
      email: "",
      first_name: "",
      full_phone_number: "",
      last_name: "",
      phone_number: "",
      type: "",
      created_at: "",
      updated_at: "",
      device_id: "",
      unique_auth_id: "",
    },
  },
};
exports.tempStore = {
  id: "",
  type: "",
  attributes: {
    product_name: "",
    serial_number: "",
    available_quantity: 0,
    price: "",
    product_description: "",
    product_status: "",
    image: "",
    shipped: false,
    is_active: "",
    seller_details: sellerDetails,
  },
};
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LocalinternationalShipping";
exports.labelBodyText = "LocalinternationalShipping Body";

exports.btnExampleTitle = "CLICK ME";

exports.labelCart = "Cart";
exports.labelEmptyCart = "Cart is empty";
exports.labelDone = "Done";
exports.labelCreationForm = "Product Creation Form";
exports.labelAddressForm = "Address Form";
exports.labelContinent = "Continent";
exports.labelCountry = "Country";
exports.labelSelect = "Select";
exports.labelBlockCountries = "Block Countries";
exports.labelShippingTitle = "Local International Shipping";
exports.labelChangeShipping = "Change Shipping Address";
exports.labelCreateNewProduct = "Create New Product";
exports.loginEmail = "sudarshan.khairnar@qubited.com";
exports.loginPassword = "Password@123";
// Customizable Area End
