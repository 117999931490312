import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import { getStorageData } from "framework/src/Utilities";
import { BackHandler, FlatList, Platform } from "react-native";
import { createRef } from "react";
import lodash from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface LogResponseWeb {
  data: {
    id: number;
    title: string;
    description: string;
    is_active: boolean;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    status: string;
    planned_hours: number;
  };
}

export interface LogResponse {
  logs: TaskDetail[];
  total_logged_hours: string;
  meta: {
    pagination: {
      current_page: number;
      total_pages: number;
      total_count: number;
    };
  };
}

export interface TaskDetail {
  id: number;
  tracking_date: string;
  title: string;
  description: string;
  start_time: string;
  end_time: string;
  hours: string;
  account_id: string;
  created_by: string;
  updated_by: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  timesheet_task_id: string;
}

export interface TaskDetailWeb {
  id: number;
  logData?: TaskDetail[];
  name?: string;
  title: string;
  description: string;
  status?: string;
  planned_hours: number;
  is_active?: boolean;
}

interface S {
  // Customizable Area Start
  isInternetConnected: boolean;
  token: string;
  taskDetail: TaskDetail[];
  taskDetailWeb: TaskDetailWeb;
  logList: TaskDetail[];
  taskId: string;
  totalLoggedHours: string;
  plannedHours: string;
  description: string;
  title: string;
  status: string;
  clickedIndex: number;
  isNavigateSuccess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TimeSheetTaskDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  taskDetailsApiCallId: string = "";
  getLogsApiCallId: string = "";
  getLogsListApiCallId: string = "";
  flatListRef = createRef<FlatList<TaskDetail>>();
  statusData = [
    { label: "To Do", value: "to_do" },
    { label: "In Progress", value: "in_progress" },
    { label: "Done", value: "done" },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isInternetConnected: false,
      token: "",
      taskId: "",
      taskDetail: [],
      logList: [],
      totalLoggedHours: "0:0",
      plannedHours: "0:0",
      description: "",
      title: "",
      status: "",
      clickedIndex: 0,
      isNavigateSuccess: false,
      taskDetailWeb: {
        id: 1,
        title: "",
        status: "",
        planned_hours: 0,
        description: "",
        logData: [],
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse || responseJson.error || responseJson.errors) {
        this.parseApiCatchErrorResponse(errorResponse);
      }
      switch (apiRequestCallId) {
        case this.getLogsApiCallId:
          this.handleLogsWeb(responseJson);
          break;
        case this.getLogsListApiCallId:
          this.handleLogResponse(responseJson);
          break;
        case this.taskDetailsApiCallId:
          this.handleTaskDetails(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.isPlatformWeb()) {
      const _token = await getStorageData("TOKEN");
      this.setState(
        {
          token: _token,
          isInternetConnected: true,
          taskId: this.props.navigation.getParam("taskId"),
        },
        () => this.getInfo(),
      );

      return;
    }

    NetInfo.addEventListener((state: NetInfoState) => {
      this.setState({
        isInternetConnected: state.isConnected,
      });
    });
    const _token = await getStorageData("TOKEN");
    const taskData = this.props.navigation.state.params.taskData;
    const detail = JSON.parse(taskData);
    const position = lodash.findIndex(this.statusData, function (data) {
      return data.value === detail.status;
    });
    this.setState(
      {
        taskId: detail.id,
        token: _token,
        description: detail.description,
        title: detail.title,
        status: this.statusData[position].label,
        plannedHours: detail.planned_hours
          ? detail.planned_hours.toString()
          : detail.hours,
      },
      () => {
        this.getInfo();
      },
    );
  }

  UNSAFE_componentWillMount = async () => {
    BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleBackButtonClick,
    );
  };

  async componentWillUnmount() {
    super.componentWillUnmount();
    Platform.OS === "ios"
      ? this.handleBackButtonClick()
      : BackHandler.removeEventListener(
          "hardwareBackPress",
          this.handleBackButtonClick,
        );
  }

  handleBackButtonClick = () => {
    const onGoBack = this.props.navigation.state.params.onGoBack;
    if (onGoBack) {
      this.props.navigation.state.params.onGoBack();
    }
    return true;
  };

  handleTaskDetails = (responseJson: LogResponse) => {
    if (responseJson && responseJson.logs) {
      this.setState(
        {
          taskDetail: responseJson.logs,
          totalLoggedHours: responseJson.total_logged_hours.toString(),
        },
        () => this.scrollListToTop(),
      );
    }
  };
  handleLogResponse = (responseJson: LogResponse) => {
    if (responseJson.logs) {
      this.setState({
        logList: responseJson.logs,
        totalLoggedHours: responseJson.total_logged_hours,
      });
    }
  };

  handleLogsWeb = (responseJson: LogResponseWeb) => {
    if (responseJson.data) {
      this.setState({
        taskDetailWeb: {
          id: responseJson.data.id,
          title: responseJson.data.title,
          status: this.statusData.find(
            (element) => element.value === responseJson.data.status,
          )?.label,
          planned_hours: responseJson.data.planned_hours,
          description: responseJson.data.description,
        },
      });
    }
  };

  getInfo = () => {
    if (this.isPlatformWeb()) {
      this.initialGetLogs();
      this.getLogListWeb();
    } else {
      this.getTaskDetails();
    }
  };

  openCreateLog() {
    this.setState({ isNavigateSuccess: true });
    if (this.isPlatformWeb()) {
      this.props.navigation.navigate("TimeSheetCreateLog", {
        taskId: this.state.taskId,
      });
    } else {
      this.props.navigation.push("TimeSheetCreateLog", {
        taskId: JSON.stringify(this.state.taskId),
        onGoBack: this.getInfo,
      });
    }
  }

  scrollListToTop = async () => {
    this.state.taskDetail.length > 0 &&
      this.flatListRef.current &&
      this.flatListRef.current.scrollToIndex({
        animated: true,
        index: this.state.clickedIndex,
      });
  };

  onScrollToIndexFailed = (error: {
    index: number;
    highestMeasuredFrameIndex: number;
    averageItemLength: number;
  }) => {
    this.flatListRef.current &&
      this.flatListRef.current.scrollToOffset({
        offset: error.averageItemLength * error.index,
        animated: true,
      });
    setTimeout(() => {
      if (this.state.taskDetail.length !== 0 && this.flatListRef !== null) {
        this.flatListRef.current &&
          this.flatListRef.current.scrollToIndex({
            index: error.index,
            animated: true,
          });
      }
    }, 50);
  };

  redirectToUser(userId: TaskDetail["account_id"]) {
    this.setState({ isNavigateSuccess: true });
    this.props.navigation.navigate("TimeSheetWorkerLogs", {
      userId,
    });
  }

  redirectToTaskList() {
    this.props.navigation.navigate("TimeSheetTaskList");
  }

  getTaskDetails = () => {
    if (!this.state.isInternetConnected) {
      this.showAlert(
        configJSON.taskDetailErrorTitle,
        configJSON.checkInternetConnection,
      );
    } else {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
      this.taskDetailsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.listDetailsLogsApiEndPoint + this.state.taskId,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet,
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getSplitTime(timing: string) {
    if (!timing) {
      return "0 Hour";
    }

    const [hours, minutes, seconds] = timing
      .split(":")
      .map((string) => parseInt(string, 10));
    let time = "";

    if (hours) {
      time += `${hours} Hour${hours === 1 ? "" : "s"} `;
    }
    if (minutes) {
      time += `${minutes} Minute${minutes === 1 ? "" : "s"} `;
    }
    if (seconds) {
      time += `${seconds} Second${seconds === 1 ? "" : "s"}`;
    }

    return time.trim() || "0 Hour";
  }

  openWorkerDetails(account_id: string, index: number) {
    this.props.navigation.push("TimeSheetWorkerLogs", {
      accountId: account_id,
      onGoBack: this.getTaskDetails,
    });
    this.setState({ clickedIndex: index });
  }

  initialGetLogs = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getLogsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listTaskApiEndPoint + `/${this.state.taskId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getLogListWeb = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getLogsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listDetailsLogsApiEndPoint + `${this.state.taskId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
