import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { FundraiserDetail } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchString: string;
  pageCurrent: number;
  pageTotal: number;
  fundraiserDetailList: FundraiserDetail[];
  loading: boolean;
  fundraisedCheck: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FundraiserListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  #token: string | null = null;

  fundraiserRequestId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchString: "",
      pageCurrent: 1,
      pageTotal: 1,
      loading: false,
      fundraiserDetailList: [],
      fundraisedCheck: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    this.#token = await getStorageData("TOKEN");

    if (!this.#token) {
      this.props.navigation.navigate("PledgeTracking");
    }

    await this.getFundraiserDetails();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (responseJson !== null && responseJson.error) {
        this.showAlert("Error", responseJson.error);
        this.setState({ loading: false });
        return;
      }

      if (!responseJson.requests) {
        this.setState({
          fundraiserDetailList: [],
          pageCurrent: 1,
          pageTotal: 1,
          loading: false,
        });

        return;
      }

      if (this.fundraiserRequestId === apiRequestCallId) {
        this.setState({
          fundraiserDetailList: responseJson.requests.data,
          pageCurrent: responseJson.meta.pagination.current_page,
          pageTotal: responseJson.meta.pagination.total_pages,
          loading: false,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToDetails(fundId: number) {
    this.setState({ fundraisedCheck: true });
    this.props.navigation.navigate("FundraiserDetails", {
      fundraiserId: fundId,
    });
  }

  handleInputDebounce<T extends (...args: string[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: unknown, ...args: Parameters<T>): void {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  }

  getFundraiserDetailsDebounced = this.handleInputDebounce((value: string) => {
    this.getFundraiserDetails(value);
  }, 800);

  updateSearch(value: string) {
    this.setState({ searchString: value });
    this.getFundraiserDetailsDebounced(value);
  }

  updatePagination(page: number) {
    this.setState({ pageCurrent: page }, this.getFundraiserDetails);
  }

  async getFundraiserDetails(searchString = "") {
    this.setState({ loading: true });

    const headers = {
      token: this.#token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.fundraiserRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetFundraiser}/all_fundraising_request?page=${this.state.pageCurrent}&per_page=20&status=active&search_query=${searchString}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetFundraiser,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
