import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import TimeSheetCreateLogController, {
  Props,
  configJSON,
} from "./TimeSheetCreateLogController";
import Input from "./components/Input.web";
import Title from "./components/Title.web";

export default class TimeSheetCreateLog extends TimeSheetCreateLogController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Title title="Time Log Creation form" />
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.inputWrapper}>
            <InputLabel>{configJSON.labelTitle}</InputLabel>
            <Input
              data-test-id="title"
              value={this.state.title}
              onChange={(event) =>
                this.txtTitleInputChange.onChangeText(event.target.value)
              }
            />
          </Box>

          <Box sx={webStyle.inputWrapper}>
            <InputLabel>{configJSON.labelDescription}</InputLabel>
            <Input
              data-test-id="description"
              value={this.state.description}
              onChange={(event) =>
                this.txtDescriptionInputChange.onChangeText(event.target.value)
              }
              multiline
              minRows={4}
            />
          </Box>

          <Box sx={webStyle.inputWrapper}>
            <InputLabel>{configJSON.labelStartTime}</InputLabel>
            <Input
              type="date"
              data-test-id="startDate"
              value={this.state.startDay}
              onChange={(event) => this.changeStartDate(event.target.value, "")}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Input
              type="time"
              data-test-id="startTime"
              onChange={(event) => this.changeStartDate("", event.target.value)}
              value={this.state.startTime}
              inputProps={{
                step: 900,
              }}
            />
          </Box>

          <Box sx={webStyle.inputWrapper}>
            <InputLabel>{configJSON.labelEndTime}</InputLabel>
            <Input
              type="date"
              data-test-id="endDate"
              value={this.state.endDay}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => this.changeEndDate(event.target.value, "")}
            />
            <Input
              type="time"
              data-test-id="endTime"
              value={this.state.endTime}
              onChange={(event) => this.changeEndDate("", event.target.value)}
              inputProps={{
                step: 900,
              }}
            />
          </Box>

          <Box style={webStyle.buttonWrapper}>
            <Button
              data-test-id="cancel"
              variant="contained"
              onClick={() => this.cancelTasks()}>
              {configJSON.cancel}
            </Button>
            <Button
              data-test-id="submit"
              variant="contained"
              color="primary"
              onClick={() => this.checkValidation()}>
              {this.state.logId ? configJSON.update : configJSON.create}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputWrapper: {
    marginTop: 24,
  },
  buttonWrapper: {
    display: "flex",
    gap: 24,
    marginTop: 24,
    marginBottom: 36,
  },
};
// Customizable Area End
