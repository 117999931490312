Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.RestAPIRequestContentType = "application/json";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.RestAPIRequestEndPoint = "bx_block_conversation/pinned_conversation";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PinConversation";
exports.labelBodyText = "PinConversation Body";

exports.btnExampleTitle = "CLICK ME";

exports.loginURLEndPoint = "bx_block_login/logins";
exports.loginEmail = "hanzala@gmail.com";
exports.loginPassword = "Password@123";
// Customizable Area End
