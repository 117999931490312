import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IMeetingInfo } from "./VideoComponent.web";
import { handleResponseMessage } from "./helpers/handle-response-message";
import createRequestMessage from "./helpers/create-request-message";
import { IHlsList, IMeetingList, IRecordingList } from "./types.web";

export const configJSON = require("./config");

export interface Props {
  navigation: FileList;
  id: string;
}

interface S {
  token: string;
  userId: string;
  roomId: string;
  roomIdRecording: string;
  meetingId: string;
  isValid: boolean | null;
  meetingInfo: IMeetingInfo;
  meetingList: IMeetingList[];
  recordingList: IRecordingList[];
  hlsList: IHlsList[];
  downStreamUrl: string;
  isParticipant: boolean;
}

interface SS {
  id: any;
}

export default class LiveStreamingController extends BlockComponent<
  Props,
  S,
  SS
> {
  signInCallId: string | null;
  generateTokenApiCallId: string | null;
  fetchAllMeetingsListApiCallId: string | null;
  fetchAllRecordingsApiCallId: string | null;
  createMeetingApiCallId: string | null;
  validateMeetingApiCallId: string | null;
  validateMeetingParticipantApiCallId: string | null;
  initalizeRecordingApiCallId: string | null;
  finalizeRecordingApiCallId: string | null;
  recordRemoveApiCallId: string | null;
  startLiveRtmpApiCallId: string | null;
  stopLiveRtmpApiCallId: string | null;
  initalizeHlsApiCallId: string | null;
  finalizeHlsApiCallId: string | null;
  listingHlsApiCallId: string | null;
  activeHlsApiCallId: string | null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      userId: "",
      meetingId: "",
      roomId: "",
      roomIdRecording: "",
      isValid: null,
      meetingInfo: {
        name: "",
        token: "",
        meetingId: "0",
        micEnabled: false,
        webcamEnabled: false,
        meetingType: "",
        view: "create",
        modeTypes: "VIEWER",
      },
      meetingList: [],
      recordingList: [],
      hlsList: [],
      downStreamUrl: "",
      isParticipant: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.signInCallId = "";
    this.generateTokenApiCallId = "";
    this.fetchAllMeetingsListApiCallId = "";
    this.fetchAllRecordingsApiCallId = "";
    this.createMeetingApiCallId = "";
    this.validateMeetingApiCallId = "";
    this.validateMeetingParticipantApiCallId = "";
    this.initalizeRecordingApiCallId = "";
    this.finalizeRecordingApiCallId = "";
    this.recordRemoveApiCallId = "";
    this.startLiveRtmpApiCallId = "";
    this.stopLiveRtmpApiCallId = "";
    this.initalizeHlsApiCallId = "";
    this.finalizeHlsApiCallId = "";
    this.listingHlsApiCallId = "";
    this.activeHlsApiCallId = "";
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      switch (apiRequestCallId) {
        case this.signInCallId: {
          this.signInCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                token: responseJson.meta.token,
                userId: responseJson.meta.id,
              });
              this.handleValidateParticipantMeetingFunction();
            },
            onFail: () => this.showAlert(`User Login Failed`, "Please retry!"),
          });
          break;
        }
        case this.generateTokenApiCallId: {
          this.generateTokenApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                meetingInfo: {
                  ...this.state.meetingInfo,
                  token: responseJson.data,
                },
              });
              if (this.state.meetingInfo.modeTypes === "CONFERENCE") {
                this.handleCreateMeeting(this.state.meetingInfo);
              } else {
                this.handleValidateMeeting(this.state.meetingInfo);
              }
            },
            onFail: () =>
              this.showAlert(`Generate token failed`, "Please retry!"),
          });
          break;
        }
        case this.fetchAllMeetingsListApiCallId: {
          this.fetchAllMeetingsListApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({ meetingList: responseJson.date.data });
            },
            onFail: () =>
              this.showAlert(`Fetch meeting list failed`, "Please retry!"),
          });
          break;
        }
        case this.fetchAllRecordingsApiCallId: {
          this.fetchAllRecordingsApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({ recordingList: responseJson.data.data });
            },
            onFail: () =>
              this.showAlert(`Fetch recording list failed`, "Please retry!"),
          });
          break;
        }
        case this.createMeetingApiCallId: {
          this.createMeetingApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                roomId: responseJson.data.data.attributes.roomId,
                meetingInfo: {
                  ...this.state.meetingInfo,
                  meetingId: responseJson.data.data.attributes.roomId,
                  view: "live",
                },
                isParticipant: false,
              });
            },
            onFail: () =>
              this.showAlert(`Create a meeting failed`, "Please retry!"),
          });
          break;
        }
        case this.validateMeetingApiCallId: {
          this.validateMeetingApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                roomId: responseJson.data.id,
                meetingInfo: {
                  ...this.state.meetingInfo,
                  meetingId: responseJson.data.roomId,
                  view: "live",
                },
              });
            },
            onFail: () =>
              this.showAlert(`Join a meeting failed`, "Please retry!"),
          });
          break;
        }
        case this.validateMeetingParticipantApiCallId: {
          this.validateMeetingParticipantApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                isParticipant: responseJson.data,
              });
            },
            onFail: () =>
              this.showAlert(
                `Join a meeting as participant failed`,
                "Please retry!",
              ),
          });
          break;
        }
        case this.initalizeRecordingApiCallId: {
          this.initalizeRecordingApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {},
            onFail: () =>
              this.showAlert(`Start recording failed`, "Please retry!"),
          });
          break;
        }
        case this.finalizeRecordingApiCallId: {
          this.finalizeRecordingApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {},
            onFail: () =>
              this.showAlert(`Stop recording failed`, "Please retry!"),
          });
          break;
        }
        case this.recordRemoveApiCallId: {
          this.recordRemoveApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.handleFetchAllRecordingFunction(this.state.roomIdRecording);
            },
            onFail: () =>
              this.showAlert(`Delete recording failed`, "Please retry!"),
          });
          break;
        }
        case this.startLiveRtmpApiCallId: {
          this.startLiveRtmpApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {},
            onFail: () =>
              this.showAlert(`Start live rtmp failed`, "Please retry!"),
          });
          break;
        }
        case this.stopLiveRtmpApiCallId: {
          this.stopLiveRtmpApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {},
            onFail: () =>
              this.showAlert(`Stop live rtmp failed`, "Please retry!"),
          });
          break;
        }
        case this.initalizeHlsApiCallId: {
          this.initalizeHlsApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {},
            onFail: () =>
              this.showAlert(`Start live hls failed`, "Please retry!"),
          });
          break;
        }
        case this.finalizeHlsApiCallId: {
          this.finalizeHlsApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {},
            onFail: () =>
              this.showAlert(`Stop live hls failed`, "Please retry!"),
          });
          break;
        }
        case this.listingHlsApiCallId: {
          this.listingHlsApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({ hlsList: responseJson.data.data });
            },
            onFail: () =>
              this.showAlert(`Fetching hls list failed`, "Please retry!"),
          });
          break;
        }
        case this.activeHlsApiCallId: {
          this.activeHlsApiCallId = null;
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.setState({
                downStreamUrl: responseJson.data.data.downstreamUrl,
              });
            },
            onFail: () =>
              this.showAlert(`Fetching active hls failed`, "Please retry!"),
          });
          break;
        }
      }
    }
  }

  async componentDidMount() {
    this.login();
  }

  login() {
    const body = {
      data: {
        attributes: {
          email: `${configJSON.loginId}`,
          password: `${configJSON.loginPass}`,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.signInCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.loginInApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  }

  handleGenerateTokenFunction = async (meetingInfo: IMeetingInfo) => {
    this.setState({ meetingInfo });
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.generateTokenApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.generateTokenApiEndPoint}`,
      method: configJSON.postApiMethodType,
    });
  };

  handleFetchAllMeetingFunction = () => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.fetchAllMeetingsListApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.fetchAllMeetingsApiEndPoint}`,
      method: configJSON.getApiMethodType,
    });
  };

  handleFetchAllRecordingFunction = (roomId: string) => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.fetchAllRecordingsApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.fetchAllRecordingsApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };
  handleCreateMeeting = (meetingInfo: IMeetingInfo) => {
    this.setState({ meetingInfo }, () => {
      const header = {
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.createMeetingApiCallId = requestMessage.messageId;

      createRequestMessage({
        header,
        requestMessage: requestMessage,
        endPoint: `${configJSON.createMeetingApiEndPoint}`,
        method: configJSON.postApiMethodType,
      });
    });
  };

  handleValidateMeeting = (meetingInfo: IMeetingInfo) => {
    this.setState({ meetingInfo }, () => {
      const header = {
        token: this.state.token,
      };

      const body = {
        roomId: this.state.meetingId,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.validateMeetingApiCallId = requestMessage.messageId;

      createRequestMessage({
        header,
        requestMessage: requestMessage,
        endPoint: `${configJSON.validateMeetingApiEndPoint}`,
        method: configJSON.postApiMethodType,
        body: JSON.stringify(body),
      });
    });
  };

  handleValidateParticipantMeetingFunction = () => {
    const header = {
      token: this.state.token,
    };

    const body = {
      participant_id: this.state.userId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.validateMeetingParticipantApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.validateMeetingParticipantApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleStartRecordingFunction = () => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId: this.state.meetingInfo.meetingId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.initalizeRecordingApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.startRecordingApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleStopRecordingFunction = () => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId: this.state.meetingInfo.meetingId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.finalizeRecordingApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.stopRecordingApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleDeleteRecordingFunction = (recordingId: string) => {
    this.setState({ roomIdRecording: recordingId }, () => {
      const header = {
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.recordRemoveApiCallId = requestMessage.messageId;

      createRequestMessage({
        header,
        requestMessage: requestMessage,
        endPoint: `${configJSON.deleteRecordingApiEndPoint}/?recordingId=${recordingId}`,
        method: configJSON.deleteApiMethodType,
      });
    });
  };

  handleStartLiveRtmpFunction = (streamKey: string, streamUrl: string) => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId: this.state.meetingInfo.meetingId,
      streamKey,
      streamUrl,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.startLiveRtmpApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.startRtmpLiveApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleStopLiveRtmpFunction = () => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId: this.state.meetingInfo.meetingId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.stopLiveRtmpApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.stopRtmpLiveApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleStartLiveHlsFunction = () => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId: this.state.meetingInfo.meetingId,
      mode: "video-and-audio",
      type: "SPOTLIGHT",
      priority: "PIN",
      gridSize: 12,
      orientation: "landscape",
      quality: "high",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.initalizeHlsApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.startHlsLiveApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleStopLiveHlsFunction = () => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId: this.state.meetingInfo.meetingId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.finalizeHlsApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.stopHlsLiveApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleLiveHlsListFunction = (roomId: string) => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId,
      sessionId: "",
      page: "",
      perPage: "",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.listingHlsApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.hlsLiveListApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  handleLiveActiveHlsFunction = () => {
    const header = {
      token: this.state.token,
    };

    const body = {
      roomId: this.state.meetingInfo.meetingId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.activeHlsApiCallId = requestMessage.messageId;

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.liveActiveHlsApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  getMeetingIdFunction = (value: string) => {
    this.setState({ meetingId: value });
  };

  handleChangeViewFunction = (meetingInfo: IMeetingInfo) => {
    this.setState({ meetingInfo });
  };

  getDownStreamUrlFunction = (value: string) => {
    this.setState({ downStreamUrl: value });
  };
}
