import React, { Component } from "react";
import {
  Box,
  IconButton,
  Container,
  Typography,
  Button,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBackSharp";
import { IProductList } from "./types";
import ProductItemWeb from "./ProductItem.web";
import { configJSON } from "./LocalInternationalShippingController.web";

type IProps = {
  cartList: IProductList[];
  handleChangeView: () => void;
  handleRemoveFromCart: (id: string) => void;
  handleEmptyCart: () => void;
};
type IState = {};
class CartView extends Component<IProps, IState> {
  render() {
    const {
      cartList,
      handleRemoveFromCart,
      handleChangeView,
      handleEmptyCart,
    } = this.props;

    return (
      <>
        <Box sx={webStyle.stickyHeader}>
          <Container maxWidth="md">
            <Box sx={webStyle.headerActions}>
              <IconButton data-test-id="back-button" onClick={handleChangeView}>
                <ArrowBack height={25} width={25} htmlColor="#000" />
              </IconButton>
              <Typography align="center" style={webStyle.mainTitle}>
                {configJSON.labelCart}
              </Typography>
            </Box>
          </Container>
        </Box>
        <Container data-test-id="container-cart" maxWidth="md">
          <Button
            data-test-id="empty-allCart"
            style={{
              background: "rgb(255, 165, 0)",
              textTransform: "none",
              marginTop: "10px",
            }}
            onClick={() => handleEmptyCart()}>
            {configJSON.proceedToCheckout}
          </Button>
          <Box sx={webStyle.productListBody}>
            {cartList.length ? (
              cartList.map((item: IProductList) => (
                <ProductItemWeb
                  key={item.id}
                  item={item}
                  view="cart"
                  handleRemoveFromCart={handleRemoveFromCart}
                />
              ))
            ) : (
              <Typography data-test-id="emptyCart" align="center">
                {configJSON.labelEmptyCart}
              </Typography>
            )}
          </Box>
        </Container>
      </>
    );
  }
}

export default CartView;

const webStyle = {
  stickyHeader: {
    position: "sticky",
    top: 0,
    textAlign: "center",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    zIndex: 1,
    paddingBottom: 20,
  },
  headerActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
  mainTitle: {
    margin: 20,
    fontSize: 20,
    fontWeight: 600,
  },
  productListBody: {
    marginTop: 50,
  },
};
