import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { CommentData, ResponseLogin, PaginationData } from "./types";
import { ChangeEvent } from "react";
type getIdType = string[] | number | string;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedValue: string | unknown;
  isSelected: boolean;
  data: CommentData[];
  bulk: string[];
  count: number;
  isLoading: boolean;
  token: string;
  bulkUpdate: boolean;
  bulkUpdateType: string;
  prePageMobile: number | null;
  nextPageMobile: number | null;
  currentPageMobile: number;
  limit: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageBlogCommentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listApiCallId: string = "";
  getDataAPICallId: string = "";
  spamApiCallId: string = "";
  approveApiCallId: string = "";
  deleteApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedValue: "bulkAction",
      isSelected: false,
      data: [],
      bulk: [],
      count: 0,
      isLoading: true,
      token: "",
      bulkUpdate: false,
      bulkUpdateType: "",
      prePageMobile: null,
      nextPageMobile: null,
      currentPageMobile: 1,
      limit: 100,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value,
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    switch (apiRequestCallId) {
      case this.listApiCallId: {
        this.switchListApiCallId(responseJson);
        break;
      }

      case this.getDataAPICallId: {
        this.switchGetDataAPICallId(responseJson);
        break;
      }

      case this.spamApiCallId: {
        await this.switchSpamApiCallId(responseJson);
        break;
      }

      case this.approveApiCallId: {
        await this.switchApproveApiCallId(responseJson);
        break;
      }

      case this.deleteApiCallId: {
        await this.switchDeleteApiCallId(responseJson);
        break;
      }
      default:
        break;
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue,
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    console.disableYellowBox = true;
    this.login();
  }

  login = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.email,
          password: configJSON.PASSWORD,
        },
        type: "email_account",
      },
    };
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.listApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPI,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTApiMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getData = async () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getDataAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDataAPICallId}${this.state.token}&limit=${this.state.limit}&page=${this.state.currentPageMobile}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  spam = async (getId: getIdType) => {
    this.setState({ isLoading: true });
    let spam_id;
    if (typeof getId === "string") {
      spam_id = [getId];
    } else if (typeof getId === "object") {
      if (getId.length === 0) {
        this.setState({ isLoading: false });
        return this.showAlert("Error", "Please select at least one blog");
      }
      spam_id = getId;
    }
    const body = {
      ids: spam_id,
      flag: "spam",
    };
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.spamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBulkUpdateAPI}${this.state.token}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTApiMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  approve = async (getId: getIdType) => {
    this.setState({ isLoading: true });
    let approve_id;
    if (typeof getId === "string") {
      approve_id = [getId];
    } else if (typeof getId === "object") {
      if (getId.length === 0) {
        this.setState({ isLoading: false });
        return this.showAlert("Error", "Please select at least one blog");
      }
      approve_id = getId;
    }
    const body = {
      ids: approve_id,
      flag: "approve",
    };
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.approveApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBulkUpdateAPI}${this.state.token}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTApiMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  delete = async (getId: getIdType) => {
    this.setState({ isLoading: true });
    let delete_id;
    if (typeof getId === "string") {
      delete_id = [getId];
    } else if (typeof getId === "object") {
      if (getId.length === 0) {
        this.setState({ isLoading: false });
        return this.showAlert("Error", "Please select at least one blog");
      }
      delete_id = getId;
    }
    const body = {
      ids: delete_id,
      flag: "delete",
    };
    const header = {
      "Accept": configJSON.validationApiContentType,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.deleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBulkUpdateAPI}${this.state.token}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTApiMethodType,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dateTime = (time: string) => {
    let created_at = new Date(time);
    const month = created_at.getUTCMonth(); // Add 1 to get the month number from 1 to 12
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[month];
    const year = created_at.getUTCFullYear();
    return `${monthName} ${year}`;
  };

  switchListApiCallId(responseJson: ResponseLogin) {
    if (responseJson && responseJson.meta) {
      this.setState({ token: responseJson.meta.token }, async () => {
        await this.getData();
      });
    } else {
      this.setState({ isLoading: false });
      return this.showAlert("Error", "Oops! Something went wrong");
    }
  }

  switchGetDataAPICallId(responseJson: {
    data?: null | CommentData[];
    meta: PaginationData;
  }) {
    try {
      if (responseJson.data) {
        let final = responseJson.data.filter(
          (item: CommentData) =>
            item.attributes.image.record.is_active === true ||
            item.attributes.image.record.is_active === null,
        );
        this.setState({ data: [...final] });
        if (this.state.bulkUpdate) {
          if (this.state.bulkUpdateType === "spam") {
            this.showAlert("Success", "Spam Successful");
          } else if (this.state.bulkUpdateType === "delete") {
            this.showAlert("Success", "Delete Successful");
          } else if (this.state.bulkUpdateType === "approve") {
            this.showAlert("Success", "Approve Successful");
          }
        }
        this.setState({
          prePageMobile: responseJson.meta.prev_page,
          nextPageMobile: responseJson.meta.next_page,
          currentPageMobile: responseJson.meta.current_page,
        });
      }
    } catch (error) {
      this.showAlert("Error", "Oops! Something went wrong");
    }
    this.setState({ isLoading: false });
  }

  async switchSpamApiCallId(responseJson: CommentData | boolean) {
    if (responseJson) {
      this.setState({ bulkUpdate: true, bulkUpdateType: "spam" });
      await this.getData();
    } else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "Oops! Something went wrong");
      await this.getData();
    }
  }

  async switchApproveApiCallId(responseJson: CommentData | boolean) {
    if (responseJson) {
      this.setState({ bulkUpdate: true, bulkUpdateType: "approve" });
      await this.getData();
    } else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "Oops! Something went wrong");
      await this.getData();
    }
  }

  async switchDeleteApiCallId(responseJson: CommentData | boolean) {
    if (responseJson) {
      this.setState({ bulkUpdate: true, bulkUpdateType: "delete" });
      await this.getData();
    } else {
      this.setState({ isLoading: false });
      this.showAlert("Error", "Oops! Something went wrong");
      await this.getData();
    }
  }

  onValueChange = async (itemValue: string) => {
    this.setState({ selectedValue: itemValue });
    let array = [...this.state.bulk];
    let counts = array.reduce((accumulate: { [key: string]: number }, curr) => {
      accumulate[curr] = (accumulate[curr] || 0) + 1;
      return accumulate;
    }, {});
    array = array.filter((item) => counts[item] % 2 !== 0);
    let uniqueArr = [...new Set(array)];
    if (itemValue === "approve") {
      await this.approve(uniqueArr);
    } else if (itemValue === "spam") {
      await this.spam(uniqueArr);
    } else if (itemValue === "delete") {
      await this.delete(uniqueArr);
    }
    this.setState({ selectedValue: "bulkAction" });
  };
  onchange = (event: ChangeEvent<{ value: unknown; name?: string }>) => {
    this.setState({ selectedValue: event.target.value });
    let array = [...this.state.bulk];
    let counts = array.reduce((accumulate: { [key: string]: number }, curr) => {
      accumulate[curr] = (accumulate[curr] || 0) + 1;
      return accumulate;
    }, {});
    array = array.filter((item) => counts[item] % 2 !== 0);
    let uniqueArr = [...new Set(array)];
    if (event.target.value === "approve") {
      this.approve(uniqueArr);
    } else if (event.target.value === "spam") {
      this.spam(uniqueArr);
    } else if (event.target.value === "delete") {
      this.delete(uniqueArr);
    }
    this.setState({ selectedValue: "bulkAction" });
  };

  approveText = (item: CommentData) => {
    return !item.attributes.image.record.is_approved ||
      item.attributes.image.record.is_approved == null
      ? "Approve"
      : "Approved";
  };

  spamText = (item: CommentData) => {
    return !item.attributes.image.record.is_spam ||
      item.attributes.image.record.is_spam == null
      ? "Mark Spam"
      : "Spam";
  };

  blogTitle = (item: CommentData) => {
    return item?.attributes?.blog?.title || item?.attributes?.comment;
  };

  descriptionText = (item: CommentData) => {
    return item.attributes.commentable.description || item.attributes.comment;
  };

  spamButton = (item: CommentData) => {
    item.attributes.image.record.is_spam
      ? this.showAlert("Error", "Comment has already mark as spam")
      : this.spam(item.id);
  };

  approveButton = (item: CommentData) => {
    if (item.attributes.image.record.is_approved) {
      this.showAlert("Error", "Comment has already approved");
    } else {
      this.approve(item.id);
    }
  };

  incrementPage = () => {
    this.setState(
      {
        currentPageMobile: this.state.currentPageMobile + 1,
      },
      () => {
        this.getData();
      },
    );
  };

  decrementPage = () => {
    this.setState(
      {
        currentPageMobile: this.state.currentPageMobile - 1,
      },
      () => {
        this.getData();
      },
    );
  };
  // Customizable Area End
}
