import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Dialog,
  DialogContent,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { IProductList } from "./types";
import ProductItem from "./ProductItem.web";
import "./styles/productView.css";
import ProductViewController, { configJSON } from "./ProductViewController.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
});

class ProductView extends ProductViewController {
  errorBlock() {
    return (
      this.state.error && (
        <Typography color="error" data-test-id="error">
          {this.state.error}
        </Typography>
      )
    );
  }

  buttons(onSubmit: () => void) {
    return (
      <Box sx={webStyle.buttonWrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
          data-test-id="submit-btn">
          {configJSON.labelDone}
        </Button>
        <Button onClick={this.handleCloseDialog}>Cancel</Button>
      </Box>
    );
  }

  renderProductCreationForm() {
    const { name, description, serialNumber, price } = this.state;
    return (
      <Box
        className="add-new-product-form"
        sx={webStyle.changeShippingDetailsForm}>
        <Typography align="center" style={webStyle.dialogTitle}>
          {configJSON.labelCreationForm}
        </Typography>
        <TextField
          variant="outlined"
          label="Name"
          value={name}
          onChange={(event) => this.nameUpdate(event)}
          data-test-id="nameInput"
          required
        />
        <TextField
          multiline
          minRows={3}
          maxRows={5}
          data-test-id="descriptionInput"
          value={description}
          onChange={(event) => this.chnageDescription(event)}
          label="Description"
          variant="outlined"
        />
        <TextField
          data-test-id="serialNumberInput"
          label="Serial Number"
          value={serialNumber}
          onChange={(event) => this.changeSerialNumber(event)}
          required
          variant="outlined"
        />
        <TextField
          required
          variant="outlined"
          label="Price"
          data-test-id="priceInput"
          value={price}
          onChange={(event) => this.changePrice(event)}
        />
        <Box>
          {this.errorBlock()}
          {this.buttons(this.validateProductForm)}
        </Box>
      </Box>
    );
  }

  renderUserAddressForm() {
    const { selectedCountry, selectedContinent, continents, countries } =
      this.state;
    return (
      <Box
        className="change-shipping-details-form"
        sx={webStyle.changeShippingDetailsForm}>
        <Typography align="center" style={webStyle.dialogTitle}>
          {configJSON.labelAddressForm}
        </Typography>
        <FormControl variant="outlined">
          <InputLabel>{configJSON.labelContinent}</InputLabel>
          <Select
            data-test-id="continentSelect"
            label="Continent"
            required
            value={selectedContinent.continentName}
            onChange={this.updateSlectedContinet}
            name="country"
            inputProps={{ "aria-label": "continentSelect" }}
            placeholder="Select">
            <MenuItem disabled value="choose">
              {configJSON.labelSelect}
            </MenuItem>
            {continents.map(
              (item: { continentCode: string; continentName: string }) => (
                <MenuItem key={item.continentName} value={item.continentName}>
                  {item.continentName}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>{configJSON.labelCountry}</InputLabel>
          <Select
            label="Country"
            data-test-id="countrySelect"
            value={selectedCountry}
            onChange={this.updateSelectedCountry}
            name="country"
            inputProps={{ "aria-label": "countrySelect" }}>
            <MenuItem disabled value="choose">
              {configJSON.labelSelect}
            </MenuItem>
            {countries.map(
              (item: { country: string; continentName: string }) => (
                <MenuItem key={item.country} value={item.country}>
                  {item.country}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <TextField
          data-test-id="address1"
          value={this.state.address1}
          onChange={(event) => this.addressOneUpdate(event)}
          label="Address 1"
          variant="outlined"
          required
        />
        <TextField
          data-test-id="address2"
          value={this.state.address2}
          onChange={(event) => this.addressTwoUpdate(event)}
          label="Address 2"
          variant="outlined"
        />
        <TextField
          data-test-id="pinCode"
          type="number"
          value={this.state.pinCode}
          onChange={(event) => this.updatePinCode(event)}
          label="Pincode"
          variant="outlined"
          required
        />
        <Box>
          {this.errorBlock()}
          {this.buttons(this.handleAddAddress)}
        </Box>
      </Box>
    );
  }

  renderProductAddressForm() {
    const { selectedContinent, selectedBlockCountries, continents, countries } =
      this.state;

    return (
      <Box
        data-test-id="modifyBtn"
        className="modify-shipping-details-form"
        sx={webStyle.changeShippingDetailsForm}>
        <Typography align="center" style={webStyle.dialogTitle}>
          {configJSON.shippingDetailsForm}
        </Typography>

        <FormControl variant="outlined">
          <InputLabel>{configJSON.labelContinent}</InputLabel>
          <Select
            data-test-id="continentSelect"
            label="Continent"
            required
            value={selectedContinent.continentName}
            onChange={this.updateSlectedContinet}
            name="country"
            inputProps={{ "aria-label": "continentSelect" }}
            placeholder="Select">
            <MenuItem disabled value="choose">
              {configJSON.labelSelect}
            </MenuItem>
            {continents.map(
              (item: { continentCode: string; continentName: string }) => (
                <MenuItem key={item.continentName} value={item.continentName}>
                  {item.continentName}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>

        <FormControl variant="outlined" className="filter-multiple-select-box">
          <InputLabel>{configJSON.labelBlockCountries}</InputLabel>
          <Select
            label="Block Countries"
            data-test-id="countrySelect"
            multiple
            required
            value={selectedBlockCountries}
            onChange={this.updateBlockCountries}
            name="blockCountries"
            placeholder="Select">
            <MenuItem disabled value="choose">
              {configJSON.labelSelect}
            </MenuItem>
            {countries.map(
              (item: { country: string; continentName: string }) => (
                <MenuItem key={item.country} value={item.country}>
                  {item.country}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>

        <Box data-test-id="updateBtn">
          {this.errorBlock()}
          {this.buttons(this.onModifyShippingDetail)}
        </Box>
      </Box>
    );
  }

  render() {
    const {
      productList,
      cartList,
      countries,
      handleChangeView,
      handleAddToCart,
    } = this.props;
    const { isDialogOpen, dialogView, continents } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.stickyHeader}>
          <Container maxWidth="md">
            <Typography align="center" style={webStyle.mainTitle}>
              {configJSON.labelShippingTitle}
            </Typography>
            <Box sx={webStyle.headerActions}>
              <Button
                onClick={() => this.handleOpenDialog("change")}
                className="product-action-button"
                data-test-id="change-shipping-address-button">
                {configJSON.labelChangeShipping}
              </Button>
              <Button
                onClick={handleChangeView}
                data-test-id="cart-button"
                className="product-action-button">
                <ShoppingCartIcon width={20} height={20} htmlColor="#000" />
                <Typography className="cart-item-count">
                  {cartList.length}
                </Typography>
              </Button>
              <Button
                onClick={() => this.handleOpenDialog("new")}
                className="product-action-button"
                data-test-id="create-new-product-button">
                {configJSON.labelCreateNewProduct}
              </Button>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="md">
          <Box sx={webStyle.productListBody}>
            {productList.map((item: IProductList) => (
              <ProductItem
                data-test-id={`productItemId${item.id}`}
                key={item.id}
                item={item}
                handleAddToCart={handleAddToCart}
                handleOpenModifyShippingDetailDialog={this.handleOpenDialog}
                view="product"
                continents={continents}
                countries={countries}
              />
            ))}
          </Box>
          <Dialog
            data-test-id="dialog"
            open={isDialogOpen}
            scroll="body"
            onClose={this.handleCloseDialog}
            maxWidth={false}
            className="generic-dialog"
            id="generic-dialog">
            <DialogContent
              className="dialog-container"
              data-test-id="dialogContent">
              {dialogView === "new" && this.renderProductCreationForm()}
              {dialogView === "change" && this.renderUserAddressForm()}
              {dialogView === "modify" && this.renderProductAddressForm()}
            </DialogContent>
          </Dialog>
        </Container>
      </ThemeProvider>
    );
  }
}

export default ProductView;

const webStyle = {
  stickyHeader: {
    position: "sticky",
    top: 0,
    textAlign: "center",
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    zIndex: 1,
    paddingBottom: 20,
  },
  headerActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
  cartItemCount: {
    marginLeft: 10,
  },
  productListBody: {
    marginTop: 50,
  },
  mainTitle: {
    margin: 20,
    fontSize: 20,
    fontWeight: 600,
  },
  changeShippingDetailsForm: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    gap: 24,
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  selectLabel: {
    marginBottom: 5,
    marginTop: 10,
    color: "#000",
  },
  label: {
    marginTop: 10,
    color: "#000",
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 32,
    marginTop: 24,
  },
};
