import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { FundraiserDetail } from "./types";
// Customizable Area End

export const configJSON = require("./config");

interface Pledge extends FundraiserDetail {}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  pledgeList: Array<Pledge>;
  selectedPledge: Pledge["id"] | null;
  pledgeInfo: Record<number, Pledge["attributes"]>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PledgeListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  #token: string | null = null;

  pledgeListRequestId: string = "";

  pledgeInfoRequestId: string = "";

  fundraiserId: number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      pledgeList: [],
      pledgeInfo: {},
      selectedPledge: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fundraiserId = this.props.navigation.getParam(
      "fundraiserId",
    ) as number;
    this.redirectToFundraiser = this.redirectToFundraiser.bind(this);
    this.hideModal = this.hideModal.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.#token = await getStorageData("TOKEN");

    if (!this.#token) {
      this.props.navigation.navigate("PledgeTracking");
    }
    await this.getPledgeList();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (responseJson !== null && responseJson.error) {
        this.showAlert("Error", responseJson.error);
        this.setState({ loading: false });
        return;
      }

      if (!responseJson.requests) {
        return;
      }

      if (apiRequestCallId === this.pledgeListRequestId) {
        this.setState({ pledgeList: responseJson.requests.data });
      }

      if (apiRequestCallId === this.pledgeInfoRequestId) {
        const { id, attributes } = responseJson.requests.data;

        this.setState({
          pledgeInfo: {
            ...this.state.pledgeInfo,
            [id]: attributes,
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async getPledgeList(page = 1, limit = 10) {
    const headers = {
      token: this.#token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.pledgeListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetPledge}?page=${page}&per_page=${limit}&fundraising_request_id=${this.fundraiserId}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetFundraiser,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPledgeInfo(pledgeId: Pledge["id"]) {
    const headers = {
      token: this.#token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.pledgeInfoRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetPledge}/${pledgeId}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGet,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  redirectToFundraiser() {
    this.props.navigation.navigate("FundraiserDetails", {
      fundraiserId: this.fundraiserId,
    });
  }

  selectPledge(pledgeId: Pledge["id"]) {
    this.getPledgeInfo(pledgeId);
    this.setState({ selectedPledge: pledgeId });
  }

  hideModal() {
    this.setState({ selectedPledge: null });
  }

  // Customizable Area End
}
