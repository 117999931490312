import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { anonymousPng } from "./assets";
import createRequestMessage from "framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import { IEmployee, IEmployeeHierarchy, ISearchData, ITaskList } from "./types";
import React from "react";

import { View, Image, Text, TouchableOpacity, StyleSheet } from "react-native";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  enableField: boolean;
  token: string;
  employeeHierarchy: IEmployeeHierarchy[];
  employees: IEmployee[];
  selectedOrgHierarchy: boolean;
  searchInputValue: string;
  employeeTaskDetails: ISearchData[];
  fullTaskList: ISearchData[];
  employeeDetailModal: boolean;
  selectedTaskField: IEmployee;
  searchedData: ISearchData[];
  specialTaskList: ISearchData[];
  selectedTaskId: number;
  employeeTaskDetailModal: boolean;
  selectedEmployeeId: number;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrganisationHierarchyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  getOrganisationHierarchyApiCallId: string = "";
  getEmployeesCallId: string = "";
  loginApiCallId: string = "";
  getTaskListCallId: string = "";
  getFullTaskListCallId: string = "";
  getSpecialTaskId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      token: "",
      employeeHierarchy: [],
      employees: [],
      enableField: false,
      selectedOrgHierarchy: true,
      searchInputValue: "",
      employeeTaskDetails: [],
      employeeDetailModal: false,
      selectedTaskField: {},
      searchedData: [],
      fullTaskList: [],
      selectedTaskId: -1,
      specialTaskList: [],
      employeeTaskDetailModal: false,
      selectedEmployeeId: -1,
      // Customizable Area Start
      // Customizable Area End
    };
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.login();
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined,
  ): void {
    if (prevState.employees === this.state.employees) {
      this.getEmployees();
    }
    if (this.state.token !== "" && this.state.token !== prevState.token) {
      this.getTaskList();
      this.getFullTaskList();
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      switch (apiRequestCallId) {
        case this.getOrganisationHierarchyApiCallId: {
          let newOrg: IEmployeeHierarchy[] = [];
          responseJson.map((organisationHierarchy: IEmployeeHierarchy) => {
            if (organisationHierarchy.is_active) {
              newOrg = [...newOrg, { ...organisationHierarchy, isOpen: false }];
            }
          });
          this.setState({ employeeHierarchy: newOrg });
          break;
        }
        case this.getEmployeesCallId: {
          this.setState({ employees: responseJson.employees });
          break;
        }
        case this.getTaskListCallId: {
          this.setState({
            employeeTaskDetails: responseJson.employee_task_details,
          });
          break;
        }
        case this.getSpecialTaskId: {
          this.setState({
            specialTaskList: responseJson.employee_task_details,
          });
          break;
        }
        case this.getFullTaskListCallId: {
          this.setState({
            fullTaskList: responseJson,
            searchedData: responseJson,
          });
          break;
        }
        case this.loginApiCallId: {
          handleResponseMessage({
            responseJson: responseJson,
            errorJson: errorResponse,
            onSuccess: () => {
              this.setState({ token: responseJson.meta.token }, () =>
                this.getOrganisationHierarchy(),
              );
            },
            onFail: () => {
              this.showAlert(`Error`, "Login Failed! Please retry");
            },
          });
          break;
        }
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  login = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const body = {
      data: {
        attributes: {
          email: configJSON.email,
          password: configJSON.securedText,
        },
        type: configJSON.loginBodyType,
      },
    };
    this.loginApiCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.loginURLEndPoint,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };

  getOrganisationHierarchy = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getOrganisationHierarchyApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.getEmployeeHierarchy,
      method: configJSON.getMethod,
      token: this.state.token,
    });
  };

  getEmployees = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getEmployeesCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.getEmployees,
      method: configJSON.getMethod,
      token: this.state.token,
    });
  };

  getTaskList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getTaskListCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.getEmployeeTask,
      method: configJSON.getMethod,
      token: this.state.token,
    });
  };
  getSpecialTask = (task_id: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getSpecialTaskId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `bx_block_tasks/employee_tasks?task_id=${task_id}`,
      method: configJSON.getMethod,
      token: this.state.token,
    });
  };

  getFullTaskList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getFullTaskListCallId = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.getAllTasks,
      method: configJSON.getMethod,
      token: this.state.token,
    });
  };

  changeSelectedTabView = (selectedState: boolean) => {
    this.setState({ selectedOrgHierarchy: selectedState });
  };

  onChangeTextInputValue = (value: string) => {
    this.setState({ searchInputValue: value });
  };

  searchData = (value: string) => {
    const filteredTaskList = this.state.fullTaskList.filter(
      (item: ITaskList) =>
        item.name && item.name.toLowerCase().includes(value.toLowerCase()),
    );
    this.setState({ searchedData: filteredTaskList });
  };

  changeEmployeeModalStatus = (value: boolean) => {
    this.setState({ employeeDetailModal: value });
  };

  selectClickedEmployee = (item: ITaskList) => {
    this.setState({ selectedTaskId: item.id }, () => {
      this.getSpecialTask(this.state.selectedTaskId);
    });
    this.changeEmployeeModalStatus(true);
  };
  changeIsOpen = (index: number) => {
    const employeeHierarchy = JSON.parse(
      JSON.stringify(this.state.employeeHierarchy),
    );
    employeeHierarchy[index].isOpen = !employeeHierarchy[index].isOpen;
    this.setState({ employeeHierarchy: employeeHierarchy });
  };

  openEmployeeDetailModal = (type: boolean, employeeId?: number) => {
    this.setState({
      employeeTaskDetailModal: type,
      selectedEmployeeId: employeeId ?? -1,
    });
  };

  employeeImage = (item: ISearchData) =>
    this.state.employees
      .filter((employee: IEmployee) => employee.id === item.employee_id)
      .map((employee: IEmployee) => (
        <Image
          key={employee.id}
          style={this.styles.bossImg}
          source={
            employee.employee_picture
              ? {
                  uri: employee.employee_picture,
                }
              : anonymousPng
          }
        />
      ));

  employeeInfo = () =>
    this.state.employees
      .filter((item) => item.id === this.state.selectedEmployeeId)
      .map((item) => (
        <View key={item.id}>
          <Text style={this.styles.selectedEmployeeInfo}>
            Employee Name: {item.employee_name}
          </Text>
          <Text style={this.styles.selectedEmployeeInfo}>
            Department: {item.department?.name}
          </Text>
          <Text style={this.styles.selectedEmployeeInfo}>
            Designation: {item.designation?.name}
          </Text>
        </View>
      ));

  employeeImageView = (item: IEmployeeHierarchy) =>
    item.employee_picture ? (
      <Image
        style={this.styles.bossImg}
        source={{
          uri: item.employee_picture,
        }}
      />
    ) : (
      <Image style={this.styles.bossImg} source={anonymousPng} />
    );

  taskInfo = () =>
    this.state.employeeTaskDetails
      .filter((item) => item.employee_id === this.state.selectedEmployeeId)
      .map((item) => (
        <View style={{ padding: 16 }} key={item.id}>
          <Text style={this.styles.selectedEmployeeInfo}>
            - Task Name: {item.task_name}
          </Text>
        </View>
      ));

  searchedDataFunc = () =>
    this.state.searchedData.map((item: ISearchData) => (
      <TouchableOpacity
        testID="see-employee"
        onPress={() => this.selectClickedEmployee(item)}
        style={this.styles.taskItemView}
        key={item.id}>
        <View>
          <Text style={{ fontWeight: "bold" }}>{item.name}</Text>
        </View>
        <View>
          <Text>See Employee</Text>
        </View>
      </TouchableOpacity>
    ));

  dynamicWorkers = (item: IEmployeeHierarchy) => {
    return (
      item.workers &&
      item.workers?.length > 0 &&
      item.workers.map((underItem: IEmployeeHierarchy) => (
        <View style={{ marginLeft: 4 }} key={underItem.id}>
          <View style={this.styles.workerHierarchyView}>
            <View style={this.styles.underItemView}>
              {underItem.employee_picture ? (
                <Image
                  style={this.styles.managerImg}
                  source={{
                    uri: underItem.employee_picture,
                  }}
                />
              ) : (
                <Image style={this.styles.managerImg} source={anonymousPng} />
              )}
              <View style={{ marginLeft: 8 }}>
                <Text style={this.styles.employeeWhiteText}>
                  {underItem.employee_name}
                </Text>
                {this.state.employees
                  .filter((employee: IEmployee) => employee.id === underItem.id)
                  .map((employee: IEmployee) => (
                    <Text
                      style={this.styles.designationNameText}
                      key={`designation-${employee.id}`}>
                      {employee.designation && employee.designation.name}
                    </Text>
                  ))}
                {this.state.employees
                  .filter((employee: IEmployee) => employee.id === underItem.id)
                  .map((employee: IEmployee) => (
                    <Text
                      style={this.styles.departmentNameText}
                      key={`department-${employee.id}`}>
                      {employee.department && employee?.department.name}
                    </Text>
                  ))}
              </View>
            </View>

            <TouchableOpacity
              onPress={() => this.openEmployeeDetailModal(true, underItem.id)}>
              <Text style={{ color: "white" }}>Detail</Text>
            </TouchableOpacity>
          </View>
          <View style={this.styles.divider} />
          {item.workers && item.workers.length > 0 && (
            <View style={{ marginLeft: 6 }}>
              {this.dynamicWorkers(underItem)}
            </View>
          )}
        </View>
      ))
    );
  };
  styles = StyleSheet.create({
    divider: {
      width: "100%",
      height: 2,
      backgroundColor: "white",
      marginVertical: 12,
      marginLeft: 16,
      alignSelf: "center",
    },
    departmentNameText: {
      fontSize: 12,
      color: "white",
      marginLeft: 8,
    },
    designationNameText: {
      marginLeft: 8,
      fontSize: 12,
      color: "white",
    },
    employeeWhiteText: {
      marginLeft: 8,
      fontSize: 14,
      fontWeight: "700",
      color: "white",
    },
    managerImg: {
      width: 36,
      height: 36,
      borderRadius: 160,
    },
    workerHierarchyView: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 8,
    },
    underItemView: {
      flexDirection: "row",
      alignItems: "center",
    },
    selectedEmployeeInfo: {
      marginBottom: 8,
      fontSize: 14,
      fontWeight: "400",
    },
    taskItemView: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: 16,
      marginTop: 16,
      marginHorizontal: 16,
      borderWidth: 1,
      borderRadius: 8,
      padding: 16,
      backgroundColor: "white",
      borderColor: "gray",
    },
    bossImg: {
      width: 45,
      height: 45,
      borderRadius: 160,
    },
  });

  // web events

  // Customizable Area Start
  // Customizable Area End
}
