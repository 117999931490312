import * as React from "react";
import { PropsWithChildren, ReactElement } from "react";
import { styled } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";

export function BottomButtonComponent(
  props: PropsWithChildren<BottomButtonComponentProps>,
): ReactElement {
  const { onSubmit, initialId } = props;

  return (
    <ButtonContainer>
      <Box>
        {initialId > 0 ? (
          <>
            <Button onClick={onSubmit}>Update</Button>
          </>
        ) : (
          <Button onClick={onSubmit}>Create</Button>
        )}
      </Box>
    </ButtonContainer>
  );
}

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 30,
});

export interface BottomButtonComponentProps {
  initialId: number;
  onSubmit: () => void;
}

BottomButtonComponent.defaultProps = {
  //
};

export default BottomButtonComponent;
