import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import { getStorageData } from "framework/src/Utilities";
import lodash from "lodash";
import { JsonObjToQueryString } from "./helpers/JsonObjToQueryString";
import { createRef } from "react";
import { FlatList, ImageSourcePropType } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface LogsData {
  id: string;
  type: string;
  attributes: {
    id: string;
    title: string;
    description: string;
    created_by: string;
    updated_by: string;
    start_time: string;
    end_time: string;
    hours: string;
    account_id: string;
    timesheet_task_id: string;
  };
}
export interface LogList {
  id: string;
  type: string;
  attributes: {
    id: string;
    title?: string;
    email: string;
    first_name: string;
    last_name: string;
    logs: {
      data: LogsData[];
    };
    total_logged_hours: string;
  };
}

export interface ResponseData {
  requests: { data: LogList[] };
  message: string;
  success: boolean;
  meta: {
    pagination: {
      current_page: number;
      total_pages: number;
      total_count: number;
    };
  };
}

export interface PageItem {
  label: number;
  isSelected: boolean;
}

export interface TaskList {
  id: number;
  title: string;
  planned_hours: number;
  name: string;
  description: string;
  is_active: boolean;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  status: string;
  members?: {
    key: string;
    img: ImageSourcePropType;
  }[];
}

interface S {
  // Customizable Area Start
  token: string;
  isInternetConnected: boolean;
  logList: LogList;
  responseMessage: string;
  currentPage: number;
  totalPage: number;
  displayPages: PageItem[];
  logsData: LogsData[];
  accountId: string;
  userAccountId: string;
  isOptionSelected: boolean;
  selectedIndex: number;
  isDeleteLogModal: boolean;
  deleteLogId: string;
  clickedIndex: number;
  workerData: Record<string, string>;
  contextMenuAnchorEl?: unknown;
  contextMenuId?: LogList["id"] | null;
  deleteLogIdWeb: string;
  isDeletedLog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TimeSheetWorkerLogsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listTimeManagementApiCallId: string = "";
  logDeleteApiCallId: string = "";
  flatListRef = createRef<FlatList<LogsData>>();
  timer: null | ReturnType<typeof setTimeout> = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isInternetConnected: false,
      logList: {
        id: "",
        type: "",
        attributes: {
          id: "",
          email: "",
          first_name: "",
          last_name: "",
          logs: {
            data: [],
          },
          total_logged_hours: "0:0",
          title: "",
        },
      },
      logsData: [],
      responseMessage: "",
      currentPage: 0,
      totalPage: 0,
      displayPages: [],
      accountId: "",
      userAccountId: "",
      isOptionSelected: false,
      selectedIndex: -1,
      isDeleteLogModal: false,
      deleteLogId: "",
      clickedIndex: 0,
      workerData: {},
      contextMenuAnchorEl: null,
      contextMenuId: null,
      deleteLogIdWeb: "",
      isDeletedLog: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse) {
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.parseApiCatchErrorResponse(errorResponse);
        }, 500);
      } else if (responseJson && (responseJson.error || responseJson.errors)) {
        this.parseApiErrorResponse(responseJson);
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.isPlatformWeb()) {
      const _token = await getStorageData("TOKEN");
      const userAccountIdWeb = await getStorageData("account_Id");

      this.setState(
        {
          token: _token,
          isInternetConnected: true,
          accountId: this.props.navigation.getParam("userId"),
          userAccountId: userAccountIdWeb,
        },
        () => {
          this.getLogList();
        },
      );

      return;
    }

    NetInfo.addEventListener((state: NetInfoState) => {
      this.setState({
        isInternetConnected: state.isConnected,
      });
    });
    const _token = await getStorageData("TOKEN");
    const account_Id = this.props.navigation.state.params.accountId;
    const _accountId = JSON.stringify(account_Id);
    const accountId = JSON.parse(_accountId);
    const _userAccountId = await getStorageData("account_Id");

    this.setState(
      {
        token: _token,
        accountId: accountId.toString(),
        userAccountId: _userAccountId,
      },
      () => this.getLogList(),
    );
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();
    if (this.state.isDeletedLog) {
      this.props.navigation.state.params.onGoBack();
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: ResponseData) => {
    switch (apiRequestCallId) {
      case this.listTimeManagementApiCallId:
        if (responseJson) {
          const pageCount = lodash.get(
            responseJson,
            "meta.pagination.total_pages",
            0,
          );
          const _currentPage = lodash.get(
            responseJson,
            "meta.pagination.current_page",
            0,
          );
          const logList = lodash.get(responseJson, "requests.data[0]", {
            id: "",
            type: "",
            attributes: {
              id: "",
              email: "",
              first_name: "",
              last_name: "",
              logs: {
                data: [],
              },
              total_logged_hours: "0:0",
            },
          });

          const { email, first_name, last_name, total_logged_hours } =
            responseJson.requests.data[0].attributes;

          const workerData = {
            ...this.state.workerData,
            name: first_name
              ? `${first_name} ${last_name}`
              : email.split("@")[0],
            email,
            totalHours: this.getSplitTime(total_logged_hours),
          };

          this.setState(
            {
              workerData,
              logList,
              logsData: lodash.get(logList, "attributes.logs.data", []),
              responseMessage: lodash.get(responseJson, "message", ""),
              currentPage: _currentPage,
              totalPage: pageCount,
              displayPages: this.generateArrayUsingMathCeil(
                pageCount,
                _currentPage,
              ),
            },
            () => this.scrollListToTop(),
          );
        }
        break;

      case this.logDeleteApiCallId:
        if (responseJson) {
          this.setState({
            responseMessage: lodash.get(responseJson, "message", ""),
            logsData: this.state.logsData.filter(
              (item) =>
                parseInt(item.id, 10) !== parseInt(this.state.deleteLogId, 10),
            ),
            deleteLogId: "",
            currentPage: this.state.currentPage - 1,
            isDeletedLog: true,
          });
          this.getLogList();
          this.timer && clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.showAlert(
              configJSON.workerDetailErrorTitle,
              responseJson.message
                ? responseJson.message
                : configJSON.messageDeleteLogSuccess,
            );
          }, 250);
        }
        break;
    }
  };

  generateArrayUsingMathCeil(pages: number, current: number) {
    const count = Math.ceil(pages - 1);
    const resultArray: PageItem[] = Array.from(
      { length: count + 1 },
      (_value, position) => ({
        label: 1 + position,
        isSelected: position === current - 1,
      }),
    );

    return resultArray;
  }

  getSplitTime(timing: string) {
    if (!timing) {
      return "0 Hour";
    }

    const [hours, minutes, seconds] = timing
      .split(":")
      .map((string) => parseInt(string, 10));
    let time = "";

    if (hours) {
      time += `${hours} Hour${hours === 1 ? "" : "s"} `;
    }
    if (minutes) {
      time += `${minutes} Minute${minutes === 1 ? "" : "s"} `;
    }
    if (seconds) {
      time += `${seconds} Second${seconds === 1 ? "" : "s"}`;
    }

    return time.trim() || "0 Hour";
  }

  getLogList = () => {
    if (!this.state.isInternetConnected) {
      this.showAlert(
        configJSON.workerDetailErrorTitle,
        configJSON.checkInternetConnection,
      );
    } else {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token,
      };

      const queryString = JsonObjToQueryString({
        page: this.state.currentPage + 1,
        per_page: 1,
        account_id: this.state.accountId,
      });

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.listTimeManagementApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listTimeManagementLogsApiEndPoint}?${queryString}`,
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  openContextMenu(
    event: { stopPropagation: Function; target: {} },
    taskId: LogList["id"],
  ) {
    this.setState({ contextMenuAnchorEl: event.target, contextMenuId: taskId });
  }

  closeContextMenu() {
    this.setState({ contextMenuId: "" });
  }

  editLogsWeb = (logId: string, item: string) => {
    this.props.navigation.navigate("TimeSheetCreateLog", {
      taskId: logId,
      logId: item,
      isUpdate: "true",
      userId: this.state.userAccountId,
    });
  };

  deleteLogWeb = (logId: string) => {
    this.hideOptions();
    this.setState({
      deleteLogId: logId,
      isDeleteLogModal: true,
      isOptionSelected: false,
    });
  };

  showOptions = (index: number) => {
    this.setState({
      isOptionSelected:
        this.state.selectedIndex === index
          ? !this.state.isOptionSelected
          : true,
      selectedIndex: index,
    });
  };

  hideOptions = () => {
    this.setState({
      isOptionSelected: false,
      selectedIndex: -1,
    });
  };

  editLog = (item: LogsData, index: number) => {
    this.hideOptions();
    this.props.navigation.navigate("TimeSheetCreateLog", {
      taskData: JSON.stringify(item.attributes),
      isUpdate: "true",
      onGoBack: this.updateData,
    });
    this.setState({
      clickedIndex: index,
      currentPage: this.state.currentPage - 1,
    });
  };

  scrollListToTop = () => {
    this.state.logsData.length > 0 &&
      this.flatListRef.current?.scrollToIndex({
        animated: true,
        index: this.state.clickedIndex,
      });
  };

  updateData = () => {
    this.getLogList();
    this.props.navigation.state.params.onGoBack();
  };

  onScrollToIndexFailed = (error: {
    index: number;
    highestMeasuredFrameIndex: number;
    averageItemLength: number;
  }) => {
    this.flatListRef.current?.scrollToOffset({
      offset: error.averageItemLength * error.index,
      animated: true,
    });
    setTimeout(() => {
      if (this.state.logsData.length !== 0 && this.flatListRef !== null) {
        this.flatListRef.current?.scrollToIndex({
          index: error.index,
          animated: true,
        });
      }
    }, 50);
  };

  hideDeleteModal() {
    this.setState({
      isDeleteLogModal: false,
      selectedIndex: -1,
      isOptionSelected: false,
    });
  }

  deleteLog = (LogId: string, index: number) => {
    this.hideOptions();
    this.setState({
      deleteLogId: LogId,
      isDeleteLogModal: true,
      isOptionSelected: false,
      clickedIndex: index,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      isDeleteLogModal: false,
      contextMenuId: "",
    });
  };

  callDeleteLog = async () => {
    const { isInternetConnected, token, deleteLogId: deleteLogId } = this.state;
    if (isInternetConnected) {
      this.setState({ isDeleteLogModal: false });
      const header = {
        token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
      this.logDeleteApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.logsUpdateDeleteApiEndPoint}${deleteLogId}`,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeDelete,
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Customizable Area End
}
