import * as React from "react";
import { ChangeEvent, PropsWithChildren, ReactElement } from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { CloudUploadOutlined } from "@material-ui/icons";

export function FormImagePicker(
  props: PropsWithChildren<FormImagePickerProps>,
): ReactElement {
  const { title, picture, onChangeFile, dataTestId } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Card data-test-id={dataTestId} className={classes.root}>
      <Box className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="h6">
            {title}
          </Typography>
          <Typography
            className={classes.pictureLink}
            variant="subtitle1"
            color="textSecondary">
            {picture}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <Button
            variant={"outlined"}
            component={"label"}
            startIcon={<CloudUploadOutlined />}>
            Upload
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={onChangeFile}
            />
          </Button>
        </div>
      </Box>
      <CardMedia
        className={classes.cover}
        image={picture}
        title="Employee Picture"
        src={picture}
      />
    </Card>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginTop: theme.spacing(4),
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "80%",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: "300",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    pictureLink: {
      width: "50%",
      flexWrap: "wrap",
    },
  }),
);

export interface FormImagePickerProps {
  //
  dataTestId?: string;
  title: string;
  picture: string;
  onChangeFile: (event: ChangeEvent<HTMLInputElement>) => void;
}

FormImagePicker.defaultProps = {
  //
};

export default FormImagePicker;
