import React from "react";

import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import EmployeeCertCreateController, {
  Props,
} from "./EmployeeCertCreateController.web";
import { Formik } from "formik";
import SelectDropdownComponentWeb from "../components/SelectDropdownComponent/SelectDropdownComponent.web";
import { employeeCertificateStatusList } from "../constants";
import CalendarComponentWeb from "../components/CalendarComponent/CalendarComponent.web";
import dayjs from "dayjs";
import FloatingButtonWeb from "../components/FloatingButton/FloatingButton.web";
import BottomButtonComponentWeb from "../components/BottomButtonComponent/BottomButtonComponent.web";
import AlertModal from "../components/AlertDialog/AlertModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class EmployeeCertCreate extends EmployeeCertCreateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      employeeCertIsActive,
      employeeList,
      employeeCertValidityDate,
      employeeCertificateStatus,
      employeeCertificateDate,
      employee,
      certificate,
      certificateList,
      isFetching,
      isLoading,
      titleModal,
      contentModal,
      alertModal,
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FloatingButtonWeb
          buttonType={"backward"}
          onClick={this.handleGoBackWeb}
        />
        <Container maxWidth={"sm"} data-test-id={"containerId"}>
          <Box sx={webSxStyle.mainWrapperStyle}>
            <Typography variant="h6">
              {`Certificate for Employee`}
              {isLoading && <CircularProgress color="inherit" />}
            </Typography>
            {!isFetching &&
              employeeList.length > 0 &&
              certificateList.length > 0 && (
                <Formik
                  initialValues={{
                    employee: employee.id as number,
                    certificate: certificate.id as number,
                    date: employeeCertificateDate,
                    validityDate: employeeCertValidityDate,
                    status: employeeCertificateStatus.id as number,
                    isActive: employeeCertIsActive as boolean,
                  }}
                  data-test-id={"formikTest"}
                  onSubmit={this.handleSubmitWeb}
                  validationSchema={
                    this.initialEmployeeCertId > 0
                      ? this.employeeCertUpdateSchema
                      : this.employeeCertCreateSchema
                  }>
                  {({
                    handleChange,
                    submitForm,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                  }) => (
                    <div>
                      <SelectDropdownComponentWeb
                        dataTestId={"employeeSelect"}
                        label={"Employee"}
                        onChange={handleChange("employee")}
                        error={!!(touched.employee && errors.employee)}
                        errorLabel={errors.employee!}
                        value={values.employee}
                        valueList={employeeList}
                      />

                      <SelectDropdownComponentWeb
                        dataTestId={"certificateSelect"}
                        label={"Certificate"}
                        onChange={handleChange("certificate")}
                        error={!!(touched.certificate && errors.certificate)}
                        errorLabel={errors.certificate!}
                        value={values.certificate}
                        valueList={certificateList}
                      />

                      <CalendarComponentWeb
                        dataTestId={"dateSelect"}
                        value={
                          values.date && dayjs(values.date).format("YYYY-MM-DD")
                        }
                        onChange={(event) =>
                          setFieldValue("date", new Date(event.target.value))
                        }
                        label={"Date"}
                      />

                      <CalendarComponentWeb
                        dataTestId={"validityDateSelect"}
                        value={
                          values.validityDate &&
                          dayjs(values.validityDate).format("YYYY-MM-DD")
                        }
                        onChange={(event) =>
                          setFieldValue(
                            "validityDate",
                            new Date(event.target.value),
                          )
                        }
                        label={"Validity Date"}
                      />

                      <SelectDropdownComponentWeb
                        dataTestId={"statusSelect"}
                        label={"Status"}
                        onChange={handleChange("status")}
                        error={!!(touched.status && errors.status)}
                        errorLabel={errors.status!}
                        value={values.status ?? ""}
                        valueList={employeeCertificateStatusList}
                      />

                      {this.initialEmployeeCertId > 0 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isActive}
                              onChange={handleChange("isActive")}
                              name="checkedA"
                            />
                          }
                          label="Is Active"
                        />
                      )}

                      <BottomButtonComponentWeb
                        onSubmit={submitForm}
                        initialId={this.initialEmployeeCertId}
                      />
                    </div>
                  )}
                </Formik>
              )}
            <AlertModal
              alertTitle={titleModal}
              handleCloseAlert={this.handleCloseAlert}
              alertVisible={alertModal}
              alertContent={contentModal}
            />
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const webSxStyle = {
  mainWrapperStyle: {
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    width: "100%",
  },
};

// Customizable Area End
