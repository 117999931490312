import countries from "countries-list";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { createRequestMessage } from "../../../framework/src/Helpers/create-request-message";

import { AddToCart, CountryList, IProductList, ResponseData } from "./types";
import { getStorageData, setStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface ProductResponse {
  product: Product[];
}

interface Product {
  data: ProductElement;
}

interface ProductElement {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Profile {
  address1: string;
  address2: string;
  pinCode: string;
  selectedCountry: string;
}

interface Attributes {
  product_name: string;
  serial_number: string;
  available_quantity: number;
  is_active: string;
  price: string;
  shipped: boolean;
  product_description: string;
  product_status: string;
  image: string;
  seller_details: SellerDetails;
}

interface ProductElement {
  id: string;
  type: string;
  attributes: Attributes;
}

interface SellerDetails {
  data: Data;
}

interface Data {
  id: string | null;
  type: string;
  attributes: Attributes2;
}

interface Attributes2 {
  activated: boolean;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string;
  unique_auth_id: string;
}

interface NewShipingData {
  id: string;
  type: string;
  attributes: {
    product_name: string;
    serial_number: string;
    available_quantity: number;
    price: string;
    product_description: string;
    product_status: string;
    is_active: boolean;
    image: null | string;
    shipped: boolean;
    seller_details: {
      data: {
        id: string;
        type: string;
        attributes: {
          activated: true;
          country_code: null | string;
          email: string;
          first_name: null | string;
          full_phone_number: string;
          last_name: null | string;
          phone_number: null | string;
          type: string;
          created_at: string;
          updated_at: string;
          device_id: null | string;
          unique_auth_id: string;
        };
      };
    };
  };
}

interface S {
  productList: IProductList[];
  cartList: IProductList[];
  tempStore: IProductList;
  isAddressAvailable: boolean;
  profileAddress: Profile;
  address1: string;
  address2: string;
  pinCode: string;
  countries: CountryList[];
  selectedCountry: string;
  view: string;
  token: string;
}

interface SS {
  updatedId: string;
}

export default class LocalInternationalShippingController extends BlockComponent<
  Props,
  S,
  SS
> {
  productListId: string = "";
  authenticateApiCallId: string = "";
  checkProductShippingId: string = "";
  getShippingId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      productList: [],
      cartList: [],
      tempStore: configJSON.tempStore,
      isAddressAvailable: false,
      profileAddress: {
        address1: "",
        address2: "",
        pinCode: "",
        selectedCountry: "",
      },
      address1: "",
      address2: "",
      pinCode: "",
      countries: [],
      selectedCountry: "",
      view: "product",
      token: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const countriesList = Object.entries(countries.countries);
    const updatedArray = countriesList.map(([_code, data]) => {
      return {
        country: data.name,
        continentName: data.continent,
      };
    });
    this.login();
    this.setState({ countries: updatedArray });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    const errorJson = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );

    if (!responseJson) {
      return;
    }

    if (errorJson || responseJson.error) {
      this.showAlert("", errorJson);

      return;
    }

    if (responseJson.errors) {
      let [error] = responseJson.errors;

      if (typeof error !== "string") {
        error = Object.values(error)[0];
      }

      this.showAlert("", error);

      return;
    }
    if (apiRequestCallId === this.authenticateApiCallId) {
      this.setState(
        {
          token: responseJson.meta.token,
        },
        async () => {
          await setStorageData("TOKEN", responseJson.meta.token);
          await setStorageData("account_Id", responseJson.meta.id.toString());
          this._retrieveData();
          this.getListOfProducts();
        },
      );
    }

    if (apiRequestCallId === this.productListId) {
      this._onGetProductList(responseJson);
    }

    if (apiRequestCallId === this.checkProductShippingId) {
      this._onAddingToCart(responseJson);
    }

    if (apiRequestCallId === this.getShippingId) {
      if (responseJson.message) {
        this.showAlert("Cart", responseJson.message);
      } else {
        this.onFetchShippingData(responseJson);
      }
    }
  }

  onFetchShippingData = (response: ProductResponse) => {
    if (response && response.product) {
      const responseChange: IProductList[] = response.product.map(
        (item: Product) => item.data,
      );
      this.setState({ cartList: responseChange });
    }
  };

  login = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.authenticateApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: "bx_block_login/logins",
      method: "POST",
      body: JSON.stringify(body),
    });
  };

  _retrieveData = async () => {
    try {
      const value = getStorageData("@ShippingAddress");
      if (value !== null) {
        const jsonObj = window.JSON.parse(await value);
        this.setState({ isAddressAvailable: true, profileAddress: jsonObj });
      }
    } catch (error) {
      // Error retrieving data
    }
  };

  _onGetProductList = (responseJson: ResponseData) => {
    if (!responseJson.product.data) {
      this.showAlert("", responseJson.message);
    }

    this.setState({ productList: responseJson.product.data });
  };

  handleEmpty = () => {
    this.setState({ cartList: [] });
  };

  _onAddingToCart = (responseJson: AddToCart) => {
    const duplicateCartItem = this.state.cartList.filter(
      (item) => item.id === this.state.tempStore.id,
    );
    if (duplicateCartItem.length == 0) {
      if (responseJson.is_shipped) {
        this.setState({
          cartList: [...this.state.cartList, this.state.tempStore],
          tempStore: configJSON.tempStore,
        });
      } else {
        this.setState({ tempStore: configJSON.tempStore });
      }
      this.showAlert("", responseJson.message);
    }
  };

  handleChangeView = (value: string) => {
    this.setState({ view: value });
    if (value === "cart") {
      this.checkShippingProduct();
    }
  };

  checkShippingProduct = () => {
    const productIds = this.state.cartList.map((item: IProductList) => item.id);
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };

    const body = {
      data: {
        attributes: {
          product_ids: productIds,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getShippingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkCartApiEndPoint +
        this.state.profileAddress.selectedCountry,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  _onAddToCartItem = (item: IProductList) => {
    const { isAddressAvailable } = this.state;
    if (isAddressAvailable) {
      this.setState({ tempStore: item });
      this.checkInCart(item.id);
      this.checkTheShipping(item.id);
    } else {
      this.showAlert(
        "Add Cart Error",
        "Please Enter the Shipping address first",
      );
    }
  };

  checkInCart = (productID: string) => {
    const listOfCartProduct = this.state.cartList.map(
      (productItem: IProductList) => productItem.id,
    );
    return listOfCartProduct.includes(productID);
  };

  _onClearSelectedItem = (element: string) => {
    const { cartList } = this.state;
    const tempCartList = cartList.filter(
      (item: IProductList) => item.id !== element,
    );
    this.setState({
      cartList: tempCartList,
    });
  };

  getListOfProducts = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.productListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productApiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  checkTheShipping = (element: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.checkProductShippingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShippingAvailabilityAPIEndPoint}${element}?query=${this.state.profileAddress.selectedCountry}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
