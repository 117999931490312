Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ExpenseTracking";
exports.labelBodyText = "ExpenseTracking Body";
exports.expenseCurrency = "INR";
exports.expenseCurrencySymbol = "₹";
exports.viewAllCategoriesEndpoint = "bx_block_expenses/expense_categories";
exports.expenseEndpointMonth =
  "bx_block_expenses/expenses/expenses_by_month_or_year?time_select=monthly";
exports.expenseEndpointYearly =
  "bx_block_expenses/expenses/expenses_by_month_or_year?time_select=yearly";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCategories = "bx_block_expenses/expense_categories";
exports.getCategoryById = "bx_block_expenses/expense_categories/";
exports.loginEmail = "test11@gmail.com";
exports.loginPassword = "Password@123";
exports.addToCartAPIEndPoint = "bx_block_categories/expense_categories";
exports.updateCategoriesEndPoint = "bx_block_expenses/expense_categories/";
exports.patchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.deleteCategoryEndPoint = "/bx_block_expenses/expense_categories/";

exports.postExpensesEndPoint = "bx_block_expenses/expenses";
exports.transactionApiEndPointALL = "bx_block_expenses/expenses";

exports.alertApiEndpoint = "bx_block_expenses/expense_notifications";
exports.importApiEndpoint = "bx_block_expenses/expenses/import";
exports.exportApiEndPoint =
  "bx_block_expenses/expenses/exportable_expense_data/?time_select=";
exports.deleteTransactionsEndPoint = "bx_block_expenses/expenses/";
exports.deleteCategoryEndPoint = "bx_block_expenses/expense_categories/";

exports.TrendingExpensesEndPoint =
  "bx_block_expenses/expenses/expenses_categories?time_select=";
exports.postNewCategoryEndpoints = "bx_block_expenses/expense_categories";
exports.updateCategoryEndPoint = "/bx_block_expenses/expense_categories/";
exports.transactionFirstPageLimit = "bx_block_expenses/expenses?page=";
exports.transactionFirstPageLimitEnd = "&limit=5";
exports.getExpenseById = "/bx_block_expenses/expenses/";
exports.userCategoriesLimited = "bx_block_expenses/expense_categories?page=";
exports.userCategoriesLimitedEndPoint = "&limit=5";
exports.limit3 = "&limit=3&page=1";
exports.selectedYearText = "&selected_year=";
exports.selectedMonthText = "&selected_month=";

exports.getAllDetailEndPoint =
  "bx_block_expenses/expenses/total_expense_details?time_select=";

exports.getAllDetailEndPointLimit = "&page=";
exports.limitTen = "&limit=10";
exports.updateExpenseApi = "bx_block_expenses/expenses/";
exports.selectedMonth = "&selected_month";
exports.selectedYear = "&selected_year";
// Customizable Area End
