import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export function createRequestMessage(request: {
  requestMessage: Message;
  endPoint: string;
  header?: object;
  method: string;
  token?: string;
  body?: string | FormData;
  isFormDataRequest?: boolean;
}) {
  const {
    requestMessage: requestMessage1,
    endPoint: apiRequestEndpoint,
    header: apiRequestHeader,
    method: apiRequestMethod,
    token: apiRequestToken,
    body: apiRequestBody,
    isFormDataRequest,
  } = request;

  const convertHeader = isFormDataRequest
    ? {
        Token: apiRequestToken ?? undefined,
      }
    : {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Token": apiRequestToken ?? undefined,
        ...apiRequestHeader,
      };

  requestMessage1.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiRequestEndpoint,
  );

  requestMessage1.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(convertHeader),
  );

  requestMessage1.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    apiRequestMethod,
  );

  apiRequestBody &&
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      apiRequestBody,
    );

  runEngine.sendMessage(requestMessage1.id, requestMessage1);
}

export default createRequestMessage;
