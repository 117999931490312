import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { EmployeeCertificate } from "../types";
import { handleResponseMessage } from "../helpers/handle-response-message";
import createRequestMessage from "../helpers/create-request-message";
import { getStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employeeCertList: EmployeeCertificate[];
  isLoading: boolean;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeCertListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeeCertListApi: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      employeeCertList: [],
      isLoading: false,
      token: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGoToDetailScreenWeb = this.handleGoToDetailScreenWeb.bind(this);
    this.handleGoToCreateScreenWeb = this.handleGoToCreateScreenWeb.bind(this);
    this.handleGetEmployeeCertListWeb =
      this.handleGetEmployeeCertListWeb.bind(this);
    this.handleGoBackWeb = this.handleGoBackWeb.bind(this);

    this.getEmployeeCertListApi = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequest = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      if (apiRequest === this.getEmployeeCertListApi) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            const { data } = responseJson;

            this.setState({
              employeeCertList: data,
            });
          },
          onFail: () => {
            this.showAlert(`Get List Failed`, "Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.handleGetTokenEmpCertWeb();
  }

  async handleGetTokenEmpCertWeb() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        this.handleGetEmployeeCertListWeb();
      },
    );
  }

  handleGetEmployeeCertListWeb() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getEmployeeCertListApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.employeesCertificateURLEndPoint,
      method: configJSON.getApiMethodType,
      token: this.state.token,
    });
  }

  handleGoToDetailScreenWeb(employeeCertificate?: EmployeeCertificate) {
    if (employeeCertificate) {
      this.props.navigation.navigate("EmployeeCertificateMappingCreate", {
        id: employeeCertificate.id,
      });
    }
  }
  handleGoToCreateScreenWeb() {
    this.props.navigation.navigate("EmployeeCertificateMappingCreate");
  }

  handleGoBackWeb() {
    this.props.navigation.navigate("CertificationTracking");
  }
  // Customizable Area End
}
