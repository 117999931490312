import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import { AllEmployeesLeaveData } from "./types";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  totalCountWeb: number;
  totalPageWeb: number;
  pageIndexWeb: number;
  pageSizeWeb: number;
  isLoading: boolean;
  token: string;
  allEmployeeLeaveListData: AllEmployeesLeaveData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllEmployeeLeaveListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  allEmployeeLeaveListDataApiCallID: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      isLoading: false,
      token: "",
      allEmployeeLeaveListData: [],
      pageIndexWeb: 1,
      totalCountWeb: 1,
      totalPageWeb: 1,
      pageSizeWeb: 10,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.allEmployeeLeaveListDataApiCallID = "";

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      this.setState({
        isLoading: false,
      });

      if (apiRequestCallId === this.allEmployeeLeaveListDataApiCallID) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.setState({
              allEmployeeLeaveListData: responseJson.leave_trackers
                .data as AllEmployeesLeaveData[],
            });
            this.handleUpdatePagination(responseJson.pagination.meta);
          },
          onFail: () => {
            this.showAlert(`Login  Failed`, "Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async getAllEmployeeLeaveListData() {
    this.setState({
      isLoading: true,
    });
    const userToken = await localStorage.getItem("userToken");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.allEmployeeLeaveListDataApiCallID = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.listAllEmployeeApiEndPointData}?per_page=${
        this.state.pageSizeWeb
      }&page=${1}`,
      method: configJSON.getApiMethodType,
      token: userToken!,
    });
  }

  handleChangePage = async (
    event?: React.ChangeEvent<unknown>,
    page?: number,
  ) => {
    if (page) {
      this.setState({
        pageIndexWeb: page,
        isLoading: true,
      });
      const userToken = await localStorage.getItem("userToken");

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.allEmployeeLeaveListDataApiCallID = requestMessage.messageId;

      createRequestMessage({
        requestMessage: requestMessage,
        endPoint: `${configJSON.listAllEmployeeApiEndPointData}?per_page=${this.state.pageSizeWeb}&page=${page}`,
        method: configJSON.getApiMethodType,
        token: userToken!,
      });
    }
  };

  handleUpdatePagination = (meta: {
    pagination: {
      current_page: number;
      next_page: number | null;
      prev_page: number | null;
      total_pages: number;
      total_count: number;
      current_count: number;
      per_page: number;
    };
  }) => {
    if (meta && meta.pagination) {
      const { total_pages, total_count, current_page } = meta.pagination;

      this.setState({
        totalPageWeb: total_pages,
        totalCountWeb: total_count,
        pageIndexWeb: current_page,
      });
    }
  };

  async componentDidMount() {
    this.getAllEmployeeLeaveListData();
  }
  // Customizable Area End
}
