import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  value: string;
  // Customizable Area Start
  title: string;
  description: string;
  amount: string;
  pledgeIds: string;
  errors: {
    title: string;
    amount: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PledgeCreationFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  #token: string | null = null;

  createFundraiserRequestId: string = "";

  fundraiserId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      value: "",
      token: "",
      title: "",
      description: "",
      pledgeIds: "",
      amount: "",
      errors: {
        title: "",
        amount: "",
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    this.fundraiserId = this.props.navigation.getParam("fundraiserId");
    this.redirectToFundraiser = this.redirectToFundraiser.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    this.#token = await getStorageData("TOKEN");

    if (!this.#token) {
      this.props.navigation.navigate("PledgeTracking");
    }

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (responseJson !== null && responseJson.error) {
        this.showAlert("Error", responseJson.error);
        return;
      }

      if (this.createFundraiserRequestId === apiRequestCallId) {
        this.handleSaveFundraiserResponse(
          responseJson.requests.data.id,
          responseJson.message,
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSaveFundraiserResponse(pledgeId: string, message: string) {
    this.setState({ pledgeIds: pledgeId });
    if (!pledgeId) {
      this.showAlert("Error", message);
      return;
    }

    this.props.navigation.navigate("FundraiserDetails", {
      fundraiserId: this.fundraiserId,
    });
  }

  onChangeTitle(value: string) {
    this.setState(({ errors: errorsPrev }) => ({
      title: value,
      errors: { ...errorsPrev, title: "" },
    }));
  }

  onChangeDescription(value: string) {
    this.setState({ description: value });
  }

  onChangeAmount(value: string) {
    this.setState(({ errors: errorsPrev }) => ({
      amount: value,
      errors: { ...errorsPrev, amount: "" },
    }));
  }

  isStringNullOrBlank(value: string) {
    return value === null || value.length === 0;
  }

  setFieldError(fieldName: string, error?: string) {
    this.setState(({ errors: errorsPrev }) => ({
      errors: {
        ...errorsPrev,
        [fieldName]: error || configJSON.fieldIsRequiredMessage,
      },
    }));
  }

  async createPledge() {
    const isTitleEmpty = this.isStringNullOrBlank(this.state.title);
    const isAmountEmpty = this.isStringNullOrBlank(this.state.amount);
    const isAmountNumber = Boolean(
      this.state.amount.match(configJSON.currencyRegExp),
    );

    if (isTitleEmpty || isAmountEmpty) {
      if (isTitleEmpty) {
        this.setFieldError("title");
      }

      if (isAmountEmpty) {
        this.setFieldError("amount");
      }

      return false;
    }

    if (!isAmountNumber) {
      this.setFieldError("amount", configJSON.notNumber);

      return;
    }

    const header = {
      token: this.#token,
    };

    const formData = new FormData();
    formData.append("amount", this.state.amount);
    formData.append("title", this.state.title);
    formData.append("description", this.state.description);
    formData.append("fundraising_request_id", this.fundraiserId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createFundraiserRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetPledge,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiPost,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  redirectToFundraiser() {
    this.props.navigation.navigate("FundraiserDetails", {
      fundraiserId: this.fundraiserId,
    });
  }
}

// Customizable Area End
