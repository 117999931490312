import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleResponseMessage } from "./helpers/handle-response-message";
import createRequestMessage from "./helpers/create-request-message";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CertificationTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTokenApiId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getTokenApiId = "";
    this.handleGoToEmployeeList = this.handleGoToEmployeeList.bind(this);
    this.handleGoToCertList = this.handleGoToCertList.bind(this);
    this.handleGoToEmployeeCertMappingList =
      this.handleGoToEmployeeCertMappingList.bind(this);
    this.handleGoToEmployeeCertStatusList =
      this.handleGoToEmployeeCertStatusList.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseDataJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorDataJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      if (apiRequestCallDataId === this.getTokenApiId) {
        handleResponseMessage({
          responseJson: responseDataJson,
          errorJson: errorDataJson,
          onSuccess: () => {
            const responseToken = responseDataJson.meta.token;
            this.setState({
              token: responseToken,
            });
            setStorageData("token", responseToken);
          },
          onFail: () => {
            this.showAlert(`Error`, "Get Token Failed. Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    if (!this.state.token) {
      this.handleGetToken();
    }
  }

  handleGetToken() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getTokenApiId = requestMessage.messageId;

    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.loginURLEndPoint,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  }
  handleGoToEmployeeList() {
    this.props.navigation.navigate("EmployeeList");
  }
  handleGoToCertList() {
    this.props.navigation.navigate("CertificateList");
  }
  handleGoToEmployeeCertMappingList() {
    this.props.navigation.navigate("EmployeeCertificateMappingList");
  }
  handleGoToEmployeeCertStatusList() {
    this.props.navigation.navigate("EmployeeCertificateEmployeeList");
  }
  // Customizable Area End
}
