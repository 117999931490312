import React, { Fragment } from "react";

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CertificateListController, {
  Props,
} from "./CertificateListController.web";
import { Certificate } from "../types";
import FloatingButtonWeb from "../components/FloatingButton/FloatingButton.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const ListContainer = styled(List)({
  width: "100%",
});

const Description = styled(Typography)({
  display: "inline",
});
// Customizable Area End

export default class CertificateList extends CertificateListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { certificateList, isLoading } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FloatingButtonWeb
          buttonType={"backward"}
          onClick={this.handleGoBack}
        />
        <Container maxWidth={"sm"} data-test-id={"containerId"}>
          <Box sx={webSxStyle.mainWrapperStyle}>
            <Typography variant="h6">
              Certificate List{" "}
              {isLoading && <CircularProgress color="primary" />}
            </Typography>
          </Box>
          <ListContainer>
            {certificateList?.length > 0 &&
              certificateList?.map((item: Certificate, index: number) => (
                <Fragment key={index}>
                  <ListItem
                    data-test-id={"listItemId"}
                    alignItems="flex-start"
                    key={index}
                    role={undefined}
                    button
                    onClick={() =>
                      this.handleGoToCertificateUpdateScreen(item)
                    }>
                    <ListItemText
                      primary={item.name}
                      secondary={
                        <React.Fragment>
                          <Description variant="body2" color="textPrimary">
                            {item.description}
                          </Description>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </Fragment>
              ))}
          </ListContainer>
        </Container>
        <FloatingButtonWeb
          buttonType={"add"}
          onClick={this.handleGoToCertificateInsert}
        />
      </ThemeProvider>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const webSxStyle = {
  mainWrapperStyle: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};
// Customizable Area End
