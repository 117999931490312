import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px"
    },
  },
});
// Customizable Area End

import ItemAvailabilityController, {
  Props,
  configJSON,
} from "./ItemAvailabilityController.web";

export default class ItemAvailability extends ItemAvailabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"} data-test-id="container">
          <Box sx={webStyle.mainWrapper}>
            <Typography style={webStyle.titleStyle} variant="h6">
              {configJSON.labelTitleText}
            </Typography>
            <TableContainer data-test-id="tableContainer" component={Paper}>
              {this.state.isLoading ? (
                <div>Loading...</div>
              ) : (
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={webStyle.tableHead} align="center">
                        Name
                      </TableCell>
                      <TableCell style={webStyle.tableHead} align="center">
                        Price
                      </TableCell>
                      <TableCell style={webStyle.tableHead} align="center">
                        Description
                      </TableCell>
                      <TableCell style={webStyle.tableHead} align="center">
                        Stock Quantity
                      </TableCell>
                      <TableCell style={webStyle.tableHead} align="center">
                        Availability
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.itemList.map((item) => (
                      <TableRow
                        data-test-id="catalogDetail"
                        onClick={() => this.catalogDetailsData(item.id)}
                        key={`${item.attributes?.name}-${item.id}`}>
                        <TableCell align="center">
                          {item.attributes.name}
                        </TableCell>
                        <TableCell align="center">
                          {item.attributes.price}
                        </TableCell>
                        <TableCell align="center">
                          {item.attributes.description}
                        </TableCell>
                        <TableCell align="center">
                          {item.attributes.stock_qty}
                        </TableCell>
                        <TableCell align="center">
                          {item?.attributes?.stock_qty &&
                            item.attributes.stock_qty >= 1
                            ? "True"
                            : "False"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <Dialog
              data-test-id="dialog"
              open={this.state.isModalVisible}
              onClose={this.onModalClose}
              fullWidth={true}
              maxWidth={"sm"}>
              <DialogContent>
                <div style={webStyle.modalContainer}>
                  <div style={webStyle.leaveDetailText}>
                    Item Availability Data
                  </div>
                  <div style={webStyle.dataContainer}>
                    <div style={webStyle.titleText}>Name</div>
                    <div style={webStyle.valueText}>
                      {this.state.catalogDetails?.name}
                    </div>
                  </div>
                  <div style={webStyle.dataContainer}>
                    <div style={webStyle.titleText}>Description</div>
                    <div style={webStyle.valueText}>
                      {this.state.catalogDetails?.description}
                    </div>
                  </div>
                  <div style={webStyle.dataContainer}>
                    <div style={webStyle.titleText}>Price</div>
                    <div style={webStyle.valueText}>
                      {this.state.catalogDetails?.price}
                    </div>
                  </div>
                  <div style={webStyle.dataContainer}>
                    <div style={webStyle.titleText}>Stock Quantity</div>
                    <div style={webStyle.valueText}>
                      {this.state.catalogDetails?.stock_qty}
                    </div>
                  </div>
                  <div style={webStyle.dataContainer}>
                    <div style={webStyle.titleText}>Availability</div>
                    <div style={webStyle.valueText}>
                      {this.state.catalogDetails?.availability}
                    </div>
                  </div>
                  <div style={webStyle.dataContainer}>
                    <div style={webStyle.titleText}>Reserve Quantity</div>
                    <div style={webStyle.valueText}>
                      {this.state.catalogDetails?.reserve_qty}
                    </div>
                  </div>

                  <div style={{ margin: 20 }} />
                  <Button
                    style={{ ...webStyle.button }}
                    variant="contained"
                    color="primary"
                    onClick={this.onModalClose}
                    data-test-id="closeModal">
                    Close
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <BottomPagination
              data-test-id="paginationButton"
              count={this.state.totalPageWeb}
              page={this.state.pageIndexWeb}
              onChange={this.handleGoToPage}
              shape={"round"}
            />
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    paddingBottom: "30px",
    background: "#fff",
    width: "100%",
  },
  titleStyle: {
    marginBottom: 20,
  },
  tableHead: {
    color: "rgb(160,78,236)",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column" as const,
    padding: 24,
  },
  leaveDetailText: {
    fontSize: 18,
    fontWeight: "bold" as const,
    fontStyle: "normal" as const,
    letterSpacing: 0,
    color: "#000000b2",
  },
  dataContainer: {
    marginTop: 24,
  },
  valueText: {
    fontFamily: "PlusJakartaSans",
    fontSize: 16,
    fontWeight: "bold" as const,
    fontStyle: "normal" as const,
    letterSpacing: -0.04,
    color: "#545454",
    marginTop: 4,
  },
  titleText: {
    opacity: 0.6,
    fontFamily: "PlusJakartaSans",
    fontSize: 14,
    fontWeight: "normal" as const,
    fontStyle: "normal" as const,
    letterSpacing: 0.01,
    color: "#333333",
  },
  button: {
    color: "#000",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#000",
  },
};

const BottomPagination = styled(Pagination)({
  marginTop: 16,
});
// Customizable Area End
