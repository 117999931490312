import React from "react";

import {
  Modal,
  Box,
  Container,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ImageEditor from "./ImageEditor";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LogoDesign2Controller, {
  Props,
  Root,
  imageEditorProps,
} from "./LogoDesign2Controller";
import Button from "../../CvresumeCandidateManagement2/src/Button";
import Loader from "../../../components/src/Loader";
import { TouchableOpacity } from "react-native";

export default class LogoDesign2 extends LogoDesign2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <div className="editor-container">
            <Button
              testID="galleryBtn"
              onPress={() => {
                this.onPressLogoList();
              }}
              buttonText="Gallery"
            />
            <Button
              testID="userGalleryBtn"
              onPress={() => {
                this.onPressUserLogoList();
              }}
              buttonText="User Gallery"
            />
            <Modal
              open={this.state.isShow}
              onClose={() => {
                this.onClose();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Box sx={webStyle.modal}>
                <h1>Logo List</h1>
                {this.state.logoList.map((logo: Root) => {
                  return (
                    <TouchableOpacity
                      key={logo.id}
                      testID={"logoBtn-" + logo.attributes.logo[0].id}
                      onPress={() => {
                        this.onPressImage(
                          logo.attributes.logo[0].url,
                          logo.attributes.id,
                        );
                      }}>
                      <div key={logo.id} style={webStyle.logo}>
                        <img
                          src={`${logo.attributes.logo[0].url}`}
                          srcSet={`${logo.attributes.logo[0].url}`}
                          alt={logo.attributes.logo[0].filename}
                          width={200}
                          height={200}
                        />
                        <div className="desc">
                          {logo.attributes.logo[0].filename}
                        </div>
                      </div>
                    </TouchableOpacity>
                  );
                })}
              </Box>
            </Modal>
            <ImageEditor
              tuiImageEditorProps={imageEditorProps}
              doButtonPressed={this.doButtonPressed}
              initialImage={this.state.image}
            />
          </div>
          <Modal open={this.state.loader}>
            <Loader loading={this.state.loader} />
          </Modal>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  logo: {
    margin: "5px",
    border: "1px solid #ccc",
    float: "left" as "left",
    width: "180px",
  },
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    height: 600,
    bgColor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    margin: 20,
    overflow: "hidden",
    overflowY: "scroll",
  },
};
// Customizable Area End
