import React from "react";

import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import EmployeeCreateController, {
  Props,
} from "./EmployeeCreateController.web";
import { Formik } from "formik";
import FormImagePicker from "../components/FormImagePicker/FormImagePicker.web";
import SelectDropdownComponentWeb from "../components/SelectDropdownComponent/SelectDropdownComponent.web";
import FileTextInput from "../components/FormTextInput/FileTextInput.web";
import FloatingButtonWeb from "../components/FloatingButton/FloatingButton.web";
import BottomButtonComponentWeb from "../components/BottomButtonComponent/BottomButtonComponent.web";
import AlertModal from "../components/AlertDialog/AlertModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class EmployeeCreate extends EmployeeCreateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      employeeName,
      employeePicture,
      employeeThumbnail,
      designationId,
      employeeIsActive,
      designationList,
      isFetching,
      isLoading,
      departmentList,
      departmentId,
      titleModal,
      contentModal,
      alertModal,
    } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FloatingButtonWeb
          buttonType={"backward"}
          onClick={this.handleGoBackWeb}
        />
        <Container maxWidth={"lg"} data-test-id={"containerId"}>
          <Box sx={webSxStyle.mainWrapperStyle}>
            <Typography variant="h6">
              {this.initialEmployeeId > 0
                ? "Employee Update"
                : "Employee Create"}{" "}
              {isLoading && <CircularProgress color="inherit" />}
            </Typography>
            {!isFetching && (
              <Formik
                initialValues={{
                  name: employeeName as string,
                  picture: employeePicture as string,
                  thumbnail: employeeThumbnail as string,
                  isActive: employeeIsActive as boolean,
                  designation: designationId ?? 0,
                  department: departmentId ?? 0,
                }}
                data-test-id={"formikTest"}
                onSubmit={(values) => this.handleSubmitWeb(values)}
                validationSchema={
                  this.initialEmployeeId > 0
                    ? this.employeeUpdateSchema
                    : this.employeeCreateSchema
                }>
                {({
                  handleChange,
                  handleBlur,
                  submitForm,
                  errors,
                  values,
                  touched,
                  setFieldValue,
                }) => (
                  <div>
                    <FileTextInput
                      dataTestId={"employeeName"}
                      label={"Employee Name"}
                      value={values.name}
                      error={!!(touched.name && errors.name)}
                      errorLabel={errors.name!}
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                    />

                    <SelectDropdownComponentWeb
                      dataTestId={"designationSelect"}
                      label={"Designation"}
                      onChange={(event) => {
                        setFieldValue("designation", event.target.value);
                      }}
                      error={!!(touched.designation && errors.designation)}
                      errorLabel={errors.designation}
                      value={values.designation}
                      valueList={designationList}
                    />

                    <SelectDropdownComponentWeb
                      dataTestId={"departmentSelect"}
                      label={"Department"}
                      onChange={(event) => {
                        setFieldValue("department", event.target.value);
                      }}
                      error={!!(touched.department && errors.department)}
                      errorLabel={errors.department}
                      value={values.department}
                      valueList={departmentList}
                    />

                    <FormImagePicker
                      dataTestId={"employeePicture"}
                      title={"Employee Picture"}
                      picture={values.picture}
                      onChangeFile={(event) => {
                        this.handleOpenFileWeb(event, true, setFieldValue);
                      }}
                    />

                    <FormImagePicker
                      dataTestId={"employeeThumbnail"}
                      title={"Employee Thumbnail"}
                      picture={values.thumbnail}
                      onChangeFile={(event) =>
                        this.handleOpenFileWeb(event, false, setFieldValue)
                      }
                    />

                    {this.initialEmployeeId > 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isActive}
                            onChange={handleChange("isActive")}
                            name="checkedA"
                          />
                        }
                        label="Is Active"
                      />
                    )}

                    <BottomButtonComponentWeb
                      onSubmit={submitForm}
                      initialId={this.initialEmployeeId}
                    />
                  </div>
                )}
              </Formik>
            )}
            <AlertModal
              alertTitle={titleModal}
              handleCloseAlert={this.handleCloseAlert}
              alertVisible={alertModal}
              alertContent={contentModal}
            />
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const webSxStyle = {
  mainWrapperStyle: {
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    marginTop: 100,
  },
};

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 30,
});
// Customizable Area End
