import React from "react";
import moment from "moment";

import {
  Container,
  Box,
  Card,
  Typography,
  Modal,
  Divider,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import Loader from "../../../components/src/Loader";

import PledgeListController, { Props } from "./PledgeListController.web";
import TitleComponent from "./components/Title/TitleComponent.web";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";

const StyledCard = styled(Card)({
  "padding": "20",
  "marginTop": "16",
  "cursor": "pointer",
  "&:hover": {
    background: "#F3F0FF",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class PledgeList extends PledgeListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      description,
      amount,
      title,
      create_by,
      created_at: createdPledge,
    } = this.state.pledgeInfo[parseInt(this.state.selectedPledge || "0", 10)] ||
    {};

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <TitleComponent
          title="Pledge list"
          onBackClick={this.redirectToFundraiser}
        />
        <Container maxWidth="sm">
          <Box sx={webStyle.mainWrapper}>
            {this.state.pledgeList.map(({ id, attributes }) => (
              <StyledCard
                key={attributes.title}
                data-test-id="card"
                variant="outlined"
                onClick={() => this.selectPledge(id)}>
                <Typography variant="body2" style={webStyle.pledgeTitle}>
                  {attributes.title}
                </Typography>
                <Typography variant="body2">
                  Amount: ${attributes.amount}
                </Typography>
                <Typography variant="body2">
                  Date: {moment(attributes.created_at).format("DD.MM.YYYY")}
                </Typography>
              </StyledCard>
            ))}
          </Box>
        </Container>
        <Modal
          open={Boolean(this.state.selectedPledge)}
          onClose={this.hideModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-test-id="modal">
          {title ? (
            <Box sx={webStyle.modal}>
              <Typography variant="h5" align="center">
                {title}
              </Typography>
              <Typography style={webStyle.person}>
                <PersonIcon /> {create_by?.full_name}
              </Typography>
              <Typography>
                Date: {moment(createdPledge).format("DD.MM.YYYY")}
              </Typography>
              <Typography>Amount: ${amount}</Typography>
              {description && (
                <>
                  <Divider variant="fullWidth" />
                  <Typography>Description: {description}</Typography>
                </>
              )}
              <Button
                variant="contained"
                color="primary"
                data-test-id="close-button"
                onClick={this.hideModal}
                style={webStyle.closeButton}>
                Close
              </Button>
            </Box>
          ) : (
            <Loader loading />
          )}
        </Modal>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    display: "flex",
    gap: 16,
    flexDirection: "column",
    alignItems: "stretch",
    paddingBottom: 32,
    paddingTop: 24,
    background: "#fff",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    background: "white",
    padding: 32,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: 8,
  },
  pledgeTitle: {
    color: "#7F70F4",
  },
  pledgeInfoTitle: {
    marginBottom: 16,
    width: "100%",
  },
  person: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  closeButton: {
    marginTop: 24,
  },
};
// Customizable Area End
