import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import { subDays } from "date-fns";
import { DatesData, LeaveData, LeaveTrackers, LeavesDetails } from "./types";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: Record<string, string>;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLeaveDetailModalVisible: boolean;
  accountID: string;
  isLoading: boolean;
  datesData: Array<string>;
  leaveStatus: Array<DatesData>;
  leaveDetails: LeavesDetails;
  markedDates: [];
  selectedDate: string;
  leaveData: LeaveData | undefined;
  data: LeaveData[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeaveTrackerHomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginCallId: string;
  getAllLeavesCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLeaveDetailModalVisible: false,
      isLoading: false,
      accountID: "",
      datesData: [],
      leaveStatus: [],
      leaveDetails: {
        employee_id: 0,
        created_at: "",
        created_by: 0,
        end_date: "",
        id: 0,
        is_active: true,
        leave_status: "",
        leave_type: "",
        leave_reason: "",
        name: "",
        start_date: "",
        employee_name: ""
      },
      markedDates: [],
      selectedDate: "",
      leaveData: undefined,
      data: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.loginCallId = "";
    this.getAllLeavesCallId = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value,
      );
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      this.setState({
        isLoading: false,
      });

      if (apiRequestCallId === this.getAllLeavesCallId) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.allLeavesCallIdSuccess(responseJson);
          },
          onFail: () => {
            this.showAlert(`Login  Failed`, "Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.allLeaves();
  }

  async allLeaves() {
    const userToken = await localStorage.getItem("userToken");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getAllLeavesCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.getUserLeavesApiEndPoint}`,
      method: configJSON.getApiMethodType,
      token: userToken!,
    });
  }

  allLeavesCallIdSuccess(responseJson: LeaveTrackers) {
    const generateMarkedDates = () => {
      this.setState({ data: responseJson.leave_trackers.data });
      let response = responseJson.leave_trackers.data;
      let markedDates: { [key: string]: Date[] }[] = [
        {
          "color-green": [],
        },
        {
          "color-red": [],
        },
        {
          "color-orange": [],
        },
      ];
      for (let leave of response) {
        let start = new Date(leave.attributes.start_date);
        let endDate = new Date(leave.attributes.end_date);

        let status = leave.attributes.leave_status;
        let color = "black";
        if (status === "Approved") {
          color = "color-green";
        } else if (status === "Pending") {
          color = "color-orange";
        } else if (status === "Rejected") {
          color = "color-red";
        }

        let current = new Date(start);
        while (current <= new Date(endDate)) {
          const date = current.toISOString().slice(0, 10);

          if (status === "Approved") {
            markedDates[0][color].push(subDays(new Date(date), 0));
          } else if (status === "Rejected") {
            markedDates[1][color].push(subDays(new Date(date), 0));
          } else if (status === "Pending") {
            markedDates[2][color].push(subDays(new Date(date), 0));
          }
          current.setDate(current.getDate() + 1);
        }
      }
      return markedDates;
    };

    this.setState({ markedDates: generateMarkedDates() as [] });
  }

  onClose = () => {
    this.setState({
      isLeaveDetailModalVisible: false,
    });
  };

  handleFormatDate(value: string) {
    const date = new Date(value);
    const dayDate = date.getDate();
    const month = date.getMonth() + 1;
    const formattedDay = dayDate < 10 ? `0${dayDate}` : dayDate;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedYear = date.getFullYear();
    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  }

  handleUpdateClick = () => {
    window.localStorage.setItem("updateLeave", "true");
    window.localStorage.setItem(
      "leaveDetails",
      JSON.stringify(this.state.leaveDetails),
    );

    this.props.navigation.navigate("CreateLeave", {
      updateLeave: true,
      leaveDetails: this.state.leaveDetails,
    });
    this.setState({
      isLeaveDetailModalVisible: false,
    });
  };

  handleDateChange = (days: [Date | null | string, Date | null]) => {
    this.setState({ selectedDate: days[0] as string });
    const formatDate = (date: string) => {
      return (
        Math.floor(new Date(date).getTime() / (24 * 60 * 60 * 1000)) *
        (24 * 60 * 60 * 1000)
      );
    };
    const date: LeaveData | undefined = this.state.data.find(
      (leave: LeaveData) => {
        return (
          formatDate(days[0] as string) >=
          new Date(leave.attributes.start_date).getTime() &&
          formatDate(days[0] as string) <=
          new Date(leave.attributes.end_date).getTime()
        );
      },
    );

    this.setState({ leaveData: date });
    if (date !== undefined) {
      this.setState({ leaveDetails: date.attributes });
      this.setState({ isLeaveDetailModalVisible: true });
    }
  };

  navigateToCreateLeaveView = () => {
    localStorage.removeItem("updateLeave");
    localStorage.removeItem("leaveDetails");

    this.props.navigation.navigate("CreateLeave");
  };
  // Customizable Area End
}
