import React from "react";

import {
  Container,
  Button,
  // Customizable Area Start
  Avatar,
  Switch,
  createTheme,
  ThemeProvider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontFamily: "",
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

type Nullable<T> = T | null;
interface PostConsent {
  consent_to: string;
  access_level: Nullable<string>;
  consent_for: string;
  status?: string;
  id?: number;
  account_id?: number;
  created_by?: null;
  updated_by?: Nullable<string>;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
}

import Dropdown from "./DropdownWeb";
import DropdownForAccess from "./DropdownForAccess";

// Customizable Area End

import PrivacySettingsController, {
  Props,
  configJSON,
} from "./PrivacySettingsController";

export default class PrivacySettings extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"} id="mainScrollView">
          <div>
            <div style={webStyle.headerContainer}>
              <div style={webStyle.headerProfileIcon}>
                <Avatar>OP</Avatar>
              </div>
              <span style={webStyle.text}>{configJSON.nameText}</span>
            </div>
            <span style={webStyle.descriptionText}>
              {configJSON.descriptionText}
            </span>
          </div>
          <div style={webStyle.privacyHeader}>
            <span style={{ fontSize: 20 }}>
              {configJSON.privacySettingsText}
            </span>
          </div>
          <div style={webStyle.width100}>
            <div style={webStyle.greyCard}>
              <span>{configJSON.labelPostConsent}</span>
              <Switch
                style={webStyle.marginLR}
                checked={this.state.postChecked}
                onChange={this.postSwitchWeb}
                data-testid={"postSwitchWeb"}
              />
            </div>
            {this.state.postChecked &&
              this.state.postConsent.map(
                (consent: PostConsent, index: number) => (
                  <div key={index} style={webStyle.consentCard}>
                    <Dropdown
                      dropDownText={configJSON.labelConsentTo}
                      isDataAvailable={this.state.noData}
                      isUpdating={this.state.isUpdating}
                      selectValue={consent.consent_to}
                      onChange={this.postConsentPickerPropsWeb}
                      index={index}
                      configJSON={configJSON}
                      testId={"postChecked1"}
                      selectTestId={"postConsentPicker"}
                    />
                    <DropdownForAccess
                      dropDownText={configJSON.labelAccessLevel}
                      isDataAvailable={this.state.noData}
                      isUpdating={this.state.isUpdating}
                      selectValue={consent.access_level}
                      onChange={this.postLevelPickerPropsWeb}
                      index={index}
                      configJSON={configJSON}
                      testId={"postChecked1"}
                      selectTestId={"postLevelPicker"}
                    />
                  </div>
                ),
              )}
          </div>
          <div style={webStyle.width100}>
            <div style={webStyle.greyCard}>
              <span>{configJSON.labelPhotoConsent}</span>
              <Switch
                style={webStyle.marginLR}
                checked={this.state.photoChecked}
                onChange={this.imageSwitchWeb}
                data-testid={"photoSwitchWeb"}
              />
            </div>
            {this.state.photoChecked &&
              this.state.photoConsent.map(
                (consent: PostConsent, index: number) => (
                  <div key={index} style={webStyle.consentCard}>
                    <Dropdown
                      dropDownText={configJSON.labelConsentTo}
                      isDataAvailable={this.state.noData}
                      isUpdating={this.state.isUpdating}
                      selectValue={consent.consent_to}
                      onChange={this.imageConsentPickerPropsWeb}
                      index={index}
                      configJSON={configJSON}
                      testId={"postChecked1"}
                      selectTestId={"photoConsentPicker"}
                    />
                    <DropdownForAccess
                      dropDownText={configJSON.labelAccessLevel}
                      isDataAvailable={this.state.noData}
                      isUpdating={this.state.isUpdating}
                      selectValue={consent.access_level}
                      onChange={this.imageLevelPickerPropsWeb}
                      index={index}
                      configJSON={configJSON}
                      testId={"postChecked1"}
                      selectTestId={"photoLevelPicker"}
                    />
                  </div>
                ),
              )}
          </div>

          <div style={webStyle.width100}>
            <div style={webStyle.greyCard}>
              <span>{configJSON.labelVideoConsent}</span>
              <Switch
                style={webStyle.marginLR}
                checked={this.state.videoChecked}
                onChange={this.videoSwitchWeb}
                data-testid={"videoSwitchWeb"}
              />
            </div>
            {this.state.videoChecked &&
              this.state.videoConsent.map(
                (consent: PostConsent, index: number) => (
                  <div key={index} style={webStyle.consentCard}>
                    <Dropdown
                      dropDownText={configJSON.labelConsentTo}
                      isDataAvailable={this.state.noData}
                      isUpdating={this.state.isUpdating}
                      selectValue={consent.consent_to}
                      onChange={this.videoConsentPickerPropsWeb}
                      index={index}
                      configJSON={configJSON}
                      testId={"postChecked1"}
                      selectTestId={"videoConsentPicker"}
                    />
                    <DropdownForAccess
                      dropDownText={configJSON.labelAccessLevel}
                      isDataAvailable={this.state.noData}
                      isUpdating={this.state.isUpdating}
                      selectValue={consent.access_level}
                      onChange={this.videoLevelPickerPropsWeb}
                      index={index}
                      configJSON={configJSON}
                      testId={"postChecked1"}
                      selectTestId={"videoLevelPicker"}
                    />
                  </div>
                ),
              )}
          </div>

          {this.state.noData ? (
            <Button
              style={webStyle.buttonStyle}
              data-testid="Createbutton"
              onClick={this.createButtonButtonPropsWeb}>
              <span style={webStyle.textWhite}> Create Privacy settings</span>
            </Button>
          ) : (
            <Button
              style={webStyle.buttonStyle}
              data-testid="UpdateButton"
              onClick={this.updateButtonButtonPropsWeb}>
              <span style={webStyle.textWhite}>
                {this.state.isUpdating
                  ? configJSON.labelUpdateSettings
                  : configJSON.labelEditSettings}
              </span>
            </Button>
          )}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    flexGrow: 1,
    alignItems: "center",
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "white",
  },
  privacyHeader: {
    width: "100%",
    backgroundColor: "#a04eef",
    padding: 20,
    marginTop: 20,
    borderRadius: 8,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 15,
  },
  headerProfileIcon: {
    marginRight: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "left",
    marginVertical: 4,
  },
  text: {
    fontSize: 14,
    fontFamily: "Roboto-Medium",
  },
  descriptionText: {
    fontSize: 14,
    fontFamily: "Roboto-Medium",
  },
  textWhite: {
    fontSize: 14,
    color: "white",
  },
  text16: {
    fontSize: 16,
  },
  width100: { width: "100%" },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  greyCard: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    borderRadius: 8,
    marginTop: 5,
    backgroundColor: "#9C9A9A",
  },
  consentCard: {
    width: "100%",
    borderRadius: 8,
    padding: 8,
    marginTop: 10,
    backgroundColor: "#f8f0ff",
  },
  buttonStyle: {
    backgroundColor: "#a04eec",
    padding: 15,
    borderRadius: 8,
    marginTop: 10,
  },
  marginLR: { marginRight: 5, marginLeft: 5 },
};
// Customizable Area End
