import React from "react";
import {
  Box,
  Button,
  Container,
  // Customizable Area Start
  Link,
  Modal,
  // Customizable Area End
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import RedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CvresumeCandidateListController, {
  Props,
} from "./CvresumeCandidateListController.web";

export default class CvresumeCandidateList extends CvresumeCandidateListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.publicMainContainer}>
          <Typography style={webStyle.publicHeading} variant="h3">
            Public Resumes
          </Typography>
          <Box sx={webStyle.publicResumeContainer}>
            {this.state.publicResumes.map((item, index) => {
              return (
                <Box sx={webStyle.listItemsContainerPublic} key={item.id}>
                  <Box sx={webStyle.listBoxItemPublic}>
                    <Box sx={webStyle.listBoxNameAndSummaryItemPublic}>
                      Name:
                    </Box>
                    <Box sx={webStyle.listBoxItemDataPublic}>
                      {item.attributes.title}
                    </Box>
                  </Box>
                  <Box sx={webStyle.listBoxItemPublic}>
                    <Box sx={webStyle.listBoxNameAndSummaryItemPublic}>
                      Summary:
                    </Box>
                    <Box sx={webStyle.listBoxItemDataPublic}>
                      {item.attributes.summary}
                    </Box>
                  </Box>
                  <Box sx={webStyle.listBoxItemPublic}>
                    <Box sx={webStyle.listBoxPublishTypeItemPublic}>
                      Publish type:
                    </Box>
                    <Box sx={webStyle.listBoxItemDataPublic}>
                      {item.attributes.allow_publish.toString()}
                    </Box>
                  </Box>
                  <Typography style={webStyle.warnTextPublic}>
                    {item.attributes.resume === null && "File is not available"}
                  </Typography>
                  <Box sx={webStyle.buttonsContainerPublic}>
                    <Link
                    target="_blank"
                      style={webStyle.eyeBigButtonPublic}
                      href={item?.attributes.resume?.toString()}
                      data-test-id={`publicResumeDownloadBtn-${index}`}>
                        {item.attributes.resume === null ?  <VisibilityOffIcon fontSize="large" color="error"/> : <RedEye fontSize="large"/>}
                    </Link>
                    <Button
                      style={webStyle.eyeBigButtonPublic}
                      data-test-id={`viewProfileBtn-${index}`}
                      onClick={() =>
                        this.toggleProfileWeb(item.attributes.created_by)
                      }>
                      View Candidate profile
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Modal open={this.state.isViewProfilePublicResume}>
          <Container maxWidth="sm">
            <Box sx={webStyle.profileDetailsPublic}>
              <Typography style={webStyle.publicHeading} variant="h3">
                Profile Details
              </Typography>
              <Box sx={webStyle.publicResumeProfileContainer}>
                <Box sx={webStyle.profilDetailsContainerRowPublic}>
                  <Box sx={webStyle.rowHeadingPublic}>Person Name:</Box>
                  <Box sx={webStyle.rowInfoPublic}>
                    {this.state.profileDataPublicResume.name}
                  </Box>
                </Box>
                <Box sx={webStyle.profilDetailsContainerRowPublic}>
                  <Box sx={webStyle.rowHeadingPublic}>Age:</Box>
                  <Box sx={webStyle.rowInfoPublic}>
                    {this.state.profileDataPublicResume.age}
                  </Box>
                </Box>
                <Box sx={webStyle.profilDetailsContainerRowPublic}>
                  <Box sx={webStyle.rowHeadingPublic}>
                    Years of Experience :
                  </Box>
                  <Box sx={webStyle.rowInfoPublic}>
                    {this.state.profileDataPublicResume.years_of_experience}
                  </Box>
                </Box>
                <Box sx={webStyle.profilDetailsContainerRowPublic}>
                  <Box sx={webStyle.rowHeadingPublic}>Experience Summary :</Box>
                  <Box sx={webStyle.rowInfoPublic}>
                    {this.state.profileDataPublicResume.experience_summary}
                  </Box>
                </Box>
                <Box sx={webStyle.profilDetailsContainerRowPublic}>
                  <Box sx={webStyle.rowHeadingPublic}>Education Summary :</Box>
                  <Box sx={webStyle.rowInfoPublic}>
                    {this.state.profileDataPublicResume.education_summary}
                  </Box>
                </Box>
                <Box sx={webStyle.profilDetailsContainerRowPublic}>
                  <Box sx={webStyle.rowHeadingPublic}>Education File :</Box>
                  <Link
                  target="_blank"
                    style={webStyle.rowInfoPublic}
                    href={this.state.profileDataPublicResume.education_file?.toString()}
                    data-test-id="downloadEduFile">
                    <RedEye fontSize="medium" />
                  </Link>
                </Box>
                <Box sx={webStyle.profilDetailsContainerRowPublic}>
                  <Box sx={webStyle.rowHeadingPublic}>Experience File :</Box>
                  <Link
                  target="_blank"
                    style={webStyle.rowInfoPublic}
                    href={this.state.profileDataPublicResume.experience_file?.toString()}
                    data-test-id="downloadExpFile">
                    <RedEye fontSize="medium" />
                  </Link>
                </Box>
                <Box sx={webStyle.profilDetailsButtonsContainerPublic}>
                  <Button
                    style={webStyle.publicProfileDetailsButton}
                    onClick={() => this.toggleProfileWeb("")}
                    data-test-id="goBackBtn">
                    Go Back
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Modal>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  publicMainContainer: {
    backgroundColor: "black",
    padding: "15px",
  },
  publicHeading: {
    color: "white",
    fontWeight: 500,
    fontSize: "22px",
    margin: "20px auto",
    width: "max-content",
  },
  publicResumeContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "15px",
  },
  profileDetailsPublic: {
    height: "100%",
    bgcolor: "black",
    padding: "10px",
    overflowY:"scroll"
  },
  warnTextPublic: {
    color: "#fff",
    fontWeight: 400,
    margin: "0 auto",
  },
  publicResumeProfileContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
  rowInfoPublic: {
    width: "50%",
    color: "white",
    fontWeight: 800,
  },
  rowHeadingPublic: {
    width: "50%",
    color: "white",
    fontWeight: "bold",
  },
  publicProfileDetailsButton: {
    width: "160px",
    height: "60px",
    color: "white",
    backgroundColor: "grey",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  profilDetailsButtonsContainerPublic: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    margin: "10px 0",
  },
  profilDetailsContainerRowPublic: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    marginBottom: "10px",
  },

  buttonsContainerPublic: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "10px",
  },

  listBoxPublishTypeItemPublic: {
    width: "192spx",
    fontSize: "19px",
    fontWeight: "bold",
  },
  listBoxNameAndSummaryItemPublic: {
    width: "162px",
    fontSize: "19px",
    fontWeight: "bold",
  },
  listBoxItemDataPublic: {
    width: "100%",
    textAlign: "center",
    textTransform: "capitalize",
    fontSize: "20px",
    padding: "2px 0",
  },
  listBoxItemPublic: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    padding: "6px 0",
  },
  listItemsContainerPublic: {
    border: "3px solid white",
    borderRadius: "12px",
    padding: "15px",
    width: "520px",
    minHeight: "333px",
    backgroundColor: "black",
    margin: "10px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  eyeBigButtonPublic: {
    height: "55px",
    width: "150px",
    backgroundColor: "#0000cc",
    color: "#fff",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};
// Customizable Area End
