import React, { useEffect, useRef } from "react";
import { useParticipant } from "@videosdk.live/react-sdk";
import { RemoteParticipantPresenterProps } from "../../types";

export default function RemoteParticipantPresenter({
  presenterId,
}: RemoteParticipantPresenterProps) {
  const screenShareRef = useRef<HTMLVideoElement>(null);
  const { screenShareStream, screenShareOn } = useParticipant(presenterId);

  useEffect(() => {
    if (screenShareRef.current) {
      if (screenShareOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(screenShareStream.track);
        screenShareRef.current.srcObject = mediaStream;
        screenShareRef.current.play();
      } else {
        screenShareRef.current.srcObject = null;
      }
    }
  }, [screenShareStream, screenShareOn]);

  return (
    <div style={webStyle.mainStyle}>
      {screenShareOn && screenShareStream ? (
        <div
          style={webStyle.divStyle}
          data-testid="remote-participant-presenter">
          <video height={"100%"} width={"100%"} ref={screenShareRef} autoPlay />
        </div>
      ) : (
        <div>no screenshare</div>
      )}
    </div>
  );
}

const webStyle = {
  divStyle: { width: "100%", height: "90%" },
  mainStyle: {
    borderTopColor: "green",
    borderWidth: 2,
    borderColor: "green",
  },
};
