import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  title: string;
  description: string;
  isInternetConnected: boolean;
  token: string;
  isStartDatePressed: boolean;
  isEndDatePressed: boolean;
  isDateTimeModalShow: boolean;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  startDay: string;
  endDay: string;
  taskId: number;
  logId: number;
  isUpdateLog: boolean;
  userAccountId: string;
  // Customizable Area End
}

// Customizable Area Start
interface LogResponseWeb {
  title: string;
  description: string;
  created_by: number;
  start_time: string;
  end_time: string;
  hours: string;
  account_id: number;
  timesheet_task_id: number;
}

interface LogResponse {
  title: string;
  description: string;
  created_by: number;
  start_time: string;
  end_time: string;
  hours: string;
  account_id: number;
  timesheet_task_id: number;
  id: number;
  is_active: boolean;
  updated_by: number;
}

interface ResponseLogData {
  log: LogResponse;
  data: LogResponse;
  message: string;
}
// Customizable Area End

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TimeSheetCreateLogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createLogApiCallId: string = "";
  getLogInfoApiCallId: string = "";
  receivedDateFormat: string = "ddd MMM DD YYYY HH:mm:ss ZZ";
  parsedDateFormat: string = "YYYY-MM-DD hh:mm:ss a";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      title: "",
      description: "",
      isInternetConnected: false,
      token: "",
      isStartDatePressed: false,
      isEndDatePressed: false,
      isDateTimeModalShow: false,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      startDay: "",
      endDay: "",
      taskId: 0,
      logId: 0,
      isUpdateLog: false,
      userAccountId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse || responseJson.error || responseJson.errors) {
        this.parseApiCatchErrorResponse(errorResponse);
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    NetInfo.addEventListener((state: NetInfoState) => {
      this.setState({
        isInternetConnected: state.isConnected,
      });
    });
    const _token = await getStorageData("TOKEN");
    this.setState({
      token: _token,
    });
    const taskId =
      this.props.navigation?.state?.params.taskId ||
      this.props.navigation?.getParam("taskId");
    const isUpdate = this.props.navigation?.state?.params?.isUpdate;
    const taskData = this.props.navigation?.state?.params?.taskData;
    if (isUpdate) {
      const detail = JSON.parse(taskData);
      const start_date_moment = moment(
        new Date(detail.start_time),
        this.receivedDateFormat,
      );
      const start_date = start_date_moment.format(this.parsedDateFormat);
      const end_date_moment = moment(
        new Date(detail.end_time),
        this.receivedDateFormat,
      );
      const end_date = end_date_moment.format(this.parsedDateFormat);

      this.setState({
        taskId: detail.timesheet_task_id,
        description: detail.description,
        title: detail.title,
        startDate: start_date,
        endDate: end_date,
        logId: detail.id,
        isUpdateLog: isUpdate,
      });
    } else {
      this.setState({
        taskId: taskId,
      });
    }
    if (this.isPlatformWeb()) {
      const { startDay, startTime, endDay, endTime } = this.convertToWebTime(
        new Date(),
      );
      const logId = this.props?.navigation?.getParam("logId");
      const isUpdate = this.props?.navigation?.getParam("isUpdate") || "false";
      const userId = this.props?.navigation?.getParam("userId");

      this.setState(
        {
          startDay,
          startTime,
          endDay,
          endTime,
          endDate: `${endDay} ${endTime}`,
          startDate: `${startDay} ${startTime}`,
          logId,
          isUpdateLog: JSON.parse(isUpdate),
          userAccountId: userId,
        },
        () => {
          logId && this.getLogData();
        },
      );
    }
  }

  convertToWebTime(start: string | Date, endD?: string | Date) {
    const endDate = endD || start;

    const startDay = moment(start).format("YYYY-MM-DD");
    const startTime = moment(start).format("HH:mm:ss");
    const endDay = moment(endDate).format("YYYY-MM-DD");
    const endTime = moment(endDate).format("HH:mm:ss");

    return {
      startDay,
      startTime,
      endDay,
      endTime,
    };
  }

  handleResponse = (
    apiRequestCallId: string,
    responseJson: ResponseLogData,
  ) => {
    switch (apiRequestCallId) {
      case this.createLogApiCallId: {
        if (responseJson && responseJson.message) {
          this.showAlert(configJSON.createLogErrorTitle, responseJson.message);
        } else {
          responseJson &&
            this.handleCreateUpdateLogRes(
              this.state.isUpdateLog ? responseJson.data : responseJson.log,
            );
        }
        break;
      }

      case this.getLogInfoApiCallId: {
        if (responseJson.data) {
          this.handleLogInfoResponse(responseJson.data);
        }
        break;
      }
    }
  };

  handleLogInfoResponse(data: LogResponseWeb) {
    const { description, title, start_time, end_time } = data;
    const { startDay, startTime, endDay, endTime } = this.convertToWebTime(
      start_time,
      end_time,
    );

    this.setState({
      description,
      title,
      startDay,
      startTime,
      endDay,
      endTime,
      endDate: `${endDay} ${endTime}`,
      startDate: `${startDay} ${startTime}`,
    });
  }

  handleCreateUpdateLogRes = (responseJson: LogResponse) => {
    if (!this.isPlatformWeb()) {
      this.props.navigation.state.params.onGoBack(responseJson);
      this.props.navigation.pop();
    } else if (this.isPlatformWeb() && responseJson) {
      this.handleResponseWeb(responseJson.timesheet_task_id);
    }
  };

  handleResponseWeb(taskId: number) {
    if (this.isPlatformWeb()) {
      if (this.state.isUpdateLog) {
        this.props.navigation.navigate("TimeSheetWorkerLogs", {
          userId: this.state.userAccountId,
        });
      } else {
        this.props.navigation.navigate("TimeSheetTaskDetails", {
          taskId,
        });
      }
      return;
    }
    this.setState(
      {
        title: "",
        description: "",
        startDate: "",
        endDate: "",
      },
      () => {
        this.props.navigation.state.params.onGoBack();
        this.props.navigation.pop();
      },
    );
  }

  txtTitleInputChange = {
    onChangeText: (text: string) => {
      this.setState({ title: text });
    },
  };

  txtDescriptionInputChange = {
    onChangeText: (text: string) => {
      this.setState({ description: text });
    },
  };

  cancelTasks = () => {
    if (this.isPlatformWeb()) {
      if (this.state.isUpdateLog) {
        return this.props.navigation.navigate("TimeSheetWorkerLogs", {
          userId: this.state.userAccountId,
        });
      } else {
        return this.props.navigation.navigate("TimeSheetTaskDetails", {
          taskId: this.state.taskId,
        });
      }
    }
    this.props.navigation.pop();
  };

  handleConfirm = (date: Date) => {
    const date_moment = moment(date, this.receivedDateFormat);
    const value = date_moment.format(this.parsedDateFormat);

    if (this.state.isStartDatePressed) {
      this.setState({ startDate: value });
    } else if (this.state.isEndDatePressed) {
      this.setState({ endDate: value });
    }

    this.setState({ isDateTimeModalShow: false });
  };

  handleCancel = () => {
    this.setState({ isDateTimeModalShow: false });
  };

  pressStartDate() {
    this.setState({
      isDateTimeModalShow: true,
      isStartDatePressed: true,
      isEndDatePressed: false,
    });
  }

  pressEndDate() {
    this.setState({
      isDateTimeModalShow: true,
      isStartDatePressed: false,
      isEndDatePressed: true,
    });
  }

  changeStartDate(date: string, time: string) {
    const startDay = date || this.state.startDay;
    const startTime = time || this.state.startTime;

    this.setState({
      startDate: `${startDay} ${startTime}`,
      startDay,
      startTime,
    });
  }

  changeEndDate(date: string, time: string) {
    const endDay = date || this.state.endDay;
    const endTime = time || this.state.endTime;

    this.setState({
      endDate: `${endDay} ${endTime}`,
      endDay,
      endTime,
    });
  }

  checkValidation = () => {
    const { title, description, startDate, endDate } = this.state;

    if (title.trim().length < 3) {
      this.showAlert(
        configJSON.createLogErrorTitle,
        configJSON.createLogErrorTitleMessage,
      );
      return;
    }

    if (description.trim().length < 3) {
      this.showAlert(
        configJSON.createLogErrorTitle,
        configJSON.createLogErrorDescriptionMessage,
      );
      return;
    }

    if (startDate.trim().length === 0) {
      this.showAlert(
        configJSON.createLogErrorTitle,
        configJSON.createLogErrorStartDateMessage,
      );
      return;
    }

    if (endDate.trim().length === 0) {
      this.showAlert(
        configJSON.createLogErrorTitle,
        configJSON.createLogErrorEndDateMessage,
      );
      return;
    } else {
      const startD = moment(startDate, this.parsedDateFormat);
      const endD = moment(endDate, this.parsedDateFormat);
      const timeDifference = endD.diff(startD, "milliseconds", false);
      if (timeDifference <= 0) {
        this.showAlert(
          configJSON.createLogErrorTitle,
          configJSON.createLogErrorStartEndDifferenceMessage,
        );
        return;
      }
    }

    this.createLogs();
  };

  createLogs = async () => {
    const {
      isInternetConnected,
      startDate,
      endDate,
      token,
      title,
      description,
      taskId,
      logId,
      isUpdateLog,
    } = this.state;
    if (!isInternetConnected) {
      this.showAlert(
        configJSON.createLogErrorTitle,
        configJSON.checkInternetConnection,
      );
    } else {
      let startD = moment(startDate, this.parsedDateFormat);
      let endD = moment(endDate, this.parsedDateFormat);
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.createLogApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isUpdateLog
          ? `${configJSON.logsUpdateDeleteApiEndPoint}${logId}`
          : configJSON.listDetailsLogsCreateApiEndPoint,
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );

      const httpBody: object = {
        log: {
          title: title,
          description: description,
          start_time: startD,
          end_time: endD,
          account_id: await getStorageData("account_Id"),
          timesheet_task_id: taskId,
        },
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody),
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        isUpdateLog
          ? configJSON.apiMethodTypePut
          : configJSON.apiMethodTypePost,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getLogData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getLogInfoApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listDetailsLogsCreateApiEndPoint}/${this.state.logId}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
