import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { IProductList, CountryList, ContinentList, ModifyData } from "./types";
import { createRequestMessage } from "../../../framework/src/Helpers/create-request-message";
import { ChangeEvent } from "react";
import { getStorageData, setStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");

type IDialog = "new" | "change" | "modify" | null;

export interface Props {
  productList: IProductList[];
  cartList: IProductList[];
  countries: CountryList[];
  handleChangeShippingAddress: () => void;
  handleChangeView: () => void;
  handleAddToCart: (item: IProductList) => void;
  handleCreateNewProduct: () => void;
  handleModifyShippingDetails: () => void;
  handleGetListOfProducts: () => void;
  handleRetrieveAddress: () => void;
}

interface ContinentElement {
  continentCode: string;
  continentName: string;
}

interface ShippingData {
  id: number;
  product_id: number;
  is_active: boolean;
  name_of_continents: string;
  block_countries: string;
  created_by: string;
  updated_by?: string | null;
  created_at: string;
  updated_at: string;
}

interface SelectedCountry {
  country: string;
  continentName: string;
}

interface S {
  dialogView: IDialog;
  isDialogOpen: boolean;
  isModified: boolean;
  productId: string | null;
  selectedCountry: string;
  address1: string;
  address2: string;
  pinCode: string;
  countries: { country: string; continentName: string }[];
  continents: ContinentList[];
  selectedContinent: ContinentElement;
  selectedBlockCountries: string[];
  name: string;
  description: string;
  serialNumber: string;
  price: string;
  error: string;
  token: string;
  shippingData: ShippingData | null;
  countryList: Array<SelectedCountry>;
  isAvailable: boolean;
  shippingID: number;
}

interface SS {
  UpdatedId: string;
}

export default class ProductViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  createProductId: string = "";
  initialLoginApiCallId: string = "";
  addShippingId: string = "";
  shippingListId: string = "";
  continentsListId: string = "";
  countriesListId: string = "";
  updateShippingId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      dialogView: null,
      isDialogOpen: false,
      isModified: false,
      productId: null,

      selectedCountry: "choose",
      address1: "",
      address2: "",
      pinCode: "",

      countries: [],
      continents: [],
      shippingID: 0,
      selectedContinent: { continentCode: "", continentName: "" },

      selectedBlockCountries: [],

      name: "",
      description: "",
      serialNumber: "",
      price: "",

      error: "",
      token: "",
      shippingData: null,
      countryList: [],
      isAvailable: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.login();
    this.getAddressData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    const errorJson = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );

    if (errorJson || responseJson.error) {
      this.handleError(errorJson);

      return;
    }

    if (responseJson.errors) {
      let error = responseJson.errors[0];

      if (!(error instanceof String)) {
        error = Object.values(error)[0];
      }

      this.handleError(error);

      return;
    }

    switch (apiRequestCallId) {
      case this.initialLoginApiCallId:
        return this.setState({
          token: responseJson.meta.token,
        });
      case this.createProductId:
        return this._onCreate(responseJson.product.data.id);
      case this.addShippingId:
        return this.onShippingAdd(responseJson);
      case this.updateShippingId:
        return this.onUpdateShippingId(responseJson);
      case this.shippingListId:
        return this._onFetch(responseJson);
      case this.continentsListId:
        return this.setContinents(responseJson.continents_list);
      case this.countriesListId:
        return this.setCountries(responseJson.countries_list);
      default:
        break;
    }
    // Customizable Area End
  }

  onShippingAdd = (responseJson: ModifyData) => {
    this.handleCloseDialog();
  };

  onUpdateShippingId = (responseJson: ModifyData) => {
    this.handleCloseDialog();
  };

  _onFetch = (responseJson: ModifyData) => {
    if (responseJson && responseJson.data !== null) {
      if (responseJson.data.length > 0) {
        const getData: ShippingData = responseJson.data[0];
        this.setState(
          {
            shippingData: getData,
            shippingID: getData.id,
            selectedBlockCountries: JSON.parse(getData.block_countries),
          },
          () => {
            this.getContinents();
          },
        );
        this.setAvailable(true);
      } else {
        this.setAvailable(false);
      }
    }
  };

  setAvailable(isAvailable: boolean) {
    this.setState({ isAvailable: isAvailable });
  }

  login = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.initialLoginApiCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: "bx_block_login/logins",
      method: "POST",
      body: JSON.stringify(body),
    });
  };

  setCountries = (_countries: Array<string>) => {
    const formattedCountries = _countries.map((country) => ({
      country: country,
      continentName: this.state.selectedContinent.continentName,
    }));
    this.setState({
      countries: formattedCountries,
      countryList: formattedCountries,
    });
  };

  getAddressData = async () => {
    try {
      const value = getStorageData("@ShippingAddress");
      if (value !== null) {
        const jsonObj = await JSON.parse(await value);
        this.setState({
          address1: jsonObj.address1,
          address2: jsonObj.address2,
          pinCode: jsonObj.pinCode,
          selectedCountry: jsonObj.selectedCountry,
          selectedContinent: {
            continentName: jsonObj.selectedContinent,
            continentCode: jsonObj.selectedContinent,
          },
        });
      }
    } catch (error) {
      // Error retrieving data
    }
  };

  handleError = (error: string) => {
    this.setState({
      error: "",
      isDialogOpen: false,
    });
    this.showAlert("", error);
  };

  handleOpenDialog = (value: IDialog, productId?: string) => {
    if (productId) {
      this.getListOfShipping(productId);
      this.setState({ productId });
    }
    this.getContinents();
    this.getAddressData();

    this.setState({ dialogView: value });
    setTimeout(() => {
      this.setState({ isDialogOpen: true });
    }, 300);
  };

  getContinents = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.continentsListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContinentsApiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  setContinents = (_continents: Array<string>) => {
    const formattedContinents = _continents.map((continent) => ({
      continentCode: continent,
      continentName: continent,
    }));
    this.setState({ continents: formattedContinents }, () => {
      const { shippingData } = this.state;
      this.getCountries();
      if (shippingData) {
        const existingContinents = {
          continentCode:
            shippingData.name_of_continents && shippingData.name_of_continents,
          continentName:
            shippingData.name_of_continents && shippingData.name_of_continents,
        };
        this.setState(
          {
            selectedContinent: existingContinents,
          },
          () => {
            this.getCountries();
          },
        );
      }
    });
  };

  getCountries = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.countriesListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountriesApiEndPoint}${this.state.selectedContinent.continentName}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
      productId: null,
      shippingData: null,
      error: "",
      selectedBlockCountries: [],
    });
    window.setTimeout(() => {
      this.setState({ dialogView: null });
    }, 300);
  };

  handleAddAddress = () => {
    const { address1, address2, pinCode, selectedCountry, selectedContinent } =
      this.state;

    const isSomeFieldEmpty = [
      address1,
      address2,
      pinCode,
      selectedCountry,
      selectedContinent,
    ].some((field) => !field);

    if (isSomeFieldEmpty) {
      this.setState({ error: "All required fields must be filled" });

      return;
    }

    if (pinCode.length < 6) {
      this.setState({ error: "Pin Code must be 6 characters" });

      return;
    }

    const updatedDetails = {
      address1,
      address2,
      pinCode,
      selectedCountry,
      selectedContinent: selectedContinent.continentName,
    };
    setStorageData("@ShippingAddress", JSON.stringify(updatedDetails));
    this.props.handleRetrieveAddress();
    this.handleCloseDialog();
  };

  _onCreate = (productId: string) => {
    this.setState({ name: "", serialNumber: "", description: "", price: "" });
    this.handleOpenDialog("modify", productId);
  };

  onModifyShippingDetail = () => {
    this.validateShippingForm();
  };

  getListOfShipping = (element: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.shippingListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShippingDetailsApiEndPoint}${element}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateShippingForm = () => {
    const { selectedContinent, selectedBlockCountries } = this.state;

    if (selectedContinent && selectedBlockCountries.length) {
      if (this.state.isAvailable) {
        this.updateShippingDetails();
      } else {
        this.addShippingDetails();
        this.props.handleGetListOfProducts();
      }
      return;
    }

    this.setState({ error: "All required fields must be filled" });
  };

  addShippingDetails = () => {
    const { selectedContinent, selectedBlockCountries, productId } = this.state;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const body = {
      data: {
        attributes: {
          product_id: productId,
          is_active: "true",
          name_of_continents: selectedContinent.continentName,
          block_countries: selectedBlockCountries,
          created_by: "sonu",
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.addShippingId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addShippingDetailsApiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateShippingDetails = () => {
    const { selectedContinent, selectedBlockCountries, shippingID } =
      this.state;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const body = {
      data: {
        attributes: {
          name_of_continents: selectedContinent.continentName,
          block_countries: selectedBlockCountries,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.updateShippingId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addShippingDetailsApiEndPoint + shippingID,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateProductForm = () => {
    const { name, serialNumber, price } = this.state;
    const isSomeFieldEmpty = [name, serialNumber, price].some(
      (field) => !field,
    );

    if (isSomeFieldEmpty) {
      this.setState({ error: "All required fields must be filled" });

      return;
    }

    const isNotANumber = Object.entries({ serialNumber, price }).some(
      ([fieldName, value]) => {
        const match = RegExp(/^\d+,?\d{0,2}$/).exec(value);
        !match &&
          this.setState({ error: `${fieldName} field must be a number` });
        return !match;
      },
    );

    if (isNotANumber) {
      return;
    }

    this.setState({ error: "" });
    this.createNewProduct();
  };

  nameUpdate = (event: { target: { value: string } }) => {
    this.setState({ name: event.target.value });
  };

  chnageDescription = (event: { target: { value: string } }) => {
    this.setState({ description: event.target.value });
  };

  changeSerialNumber = (event: { target: { value: string } }) => {
    this.setState({ serialNumber: event.target.value });
  };

  changePrice = (event: { target: { value: string } }) => {
    this.setState({ price: event.target.value });
  };

  updateSelectedCountry = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedCountry: event.target.value as string });
  };

  updateSlectedContinet = (event: ChangeEvent<{ value: unknown }>) => {
    const updateObj = {
      continentName: event.target.value as string,
      continentCode: event.target.value as string,
    };
    this.setState(
      {
        selectedContinent: updateObj,
      },
      () => {
        this.getCountries();
      },
    );
  };

  updateBlockCountries = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({
      selectedBlockCountries: event.target.value as string[],
    });
  };

  addressOneUpdate = (event: { target: { value: string } }) => {
    this.setState({ address1: event.target.value });
  };

  addressTwoUpdate = (event: { target: { value: string } }) => {
    this.setState({ address2: event.target.value });
  };

  updatePinCode = (event: { target: { value: string } }) => {
    this.setState({ pinCode: event.target.value });
  };

  createNewProduct = async () => {
    const { name, serialNumber, description, price } = this.state;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": this.state.token,
    };
    const body = {
      data: {
        account_id: await getStorageData("account_Id"),
        product_name: name,
        serial_number: serialNumber,
        available_quantity: 10,
        product_description: description,
        is_active: null,
        created_by: null,
        price,
        product_status: "Available",
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.createProductId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createProductApiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
