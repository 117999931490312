Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PledgeTracking";
exports.labelBodyText = "PledgeTracking Body";
exports.errorTitle = "Error";
exports.email = "testpledge@gmail.com";
exports.securedText = "test@123";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.notNumber = "This field should be an integer number";
exports.fieldIsRequiredMessage = "This field is required";

exports.CreatingfundreaiserAPIEndPoint =
  "bx_block_pledge_tracking/fundraising_requests";
exports.CreatingfundreaiserMethod = "POST";

exports.endPointApiGetFundraiser =
  "bx_block_pledge_tracking/fundraising_requests";
exports.methodTypeApiGetFundraiser = "GET";

exports.endPointApiGetPledge = "bx_block_pledge_tracking/pledges";
exports.methodTypeApiGetPledge = "GET";

exports.CreatingPledgeAPIEndPoint = "bx_block_pledge_tracking/pledges";

exports.methodTypeApiPost = "POST";
exports.methodTypeApiGet = "GET";
exports.btnExampleTitle = "CLICK ME";

exports.currencyRegExp = /^\d+$/;
// Customizable Area End
