import React from "react";

import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CertificateInsertController, {
  Props,
} from "./CertificateInsertController.web";
import { Formik } from "formik";
import FileTextInput from "../components/FormTextInput/FileTextInput.web";
import FloatingButtonWeb from "../components/FloatingButton/FloatingButton.web";
import BottomButtonComponentWeb from "../components/BottomButtonComponent/BottomButtonComponent.web";
import AlertModal from "../components/AlertDialog/AlertModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const FormControlContainer = styled(FormControl)({
  width: "100%",
  marginTop: 16,
});
// Customizable Area End

export default class CertificateInsert extends CertificateInsertController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      name,
      description,
      isActive,
      status,
      fetching,
      isLoading,
      titleModal,
      contentModal,
      alertModal,
    } = this.state;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FloatingButtonWeb
          buttonType={"backward"}
          onClick={this.handleGoBackWeb}
        />
        <Container maxWidth={"sm"} data-test-id={"containerId"}>
          {!fetching && (
            <Box sx={webSxStyle.mainWrapperStyle}>
              <Typography variant="h6">
                {`Certificate ${this.initialCertId > 0 ? "Update" : "Create"}`}{" "}
                {isLoading && <CircularProgress color="inherit" />}
              </Typography>
              <Formik
                initialValues={{
                  name: name,
                  description: description,
                  status: status,
                  isActive: isActive,
                }}
                data-test-id={"formikTest"}
                onSubmit={this.handleSubmitCertWeb}
                validationSchema={
                  this.initialCertId > 0
                    ? this.certUpdateSchema
                    : this.certCreateSchema
                }>
                {({
                  handleChange,
                  handleBlur,
                  submitForm,
                  errors,
                  values,
                  touched,
                }) => (
                  <div>
                    <FileTextInput
                      dataTestId={"nameInput"}
                      label={"Certificate Name"}
                      value={values.name}
                      error={!!(touched.name && errors.name)}
                      errorLabel={errors.name!}
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                    />

                    <FileTextInput
                      dataTestId={"descInput"}
                      label={"Certificate Description"}
                      value={values.description}
                      error={!!(touched.description && errors.description)}
                      errorLabel={errors.description!}
                      onChange={handleChange("description")}
                      onBlur={handleBlur("description")}
                    />

                    <FormControlContainer
                      error={!!(touched.status && errors.status)}
                      variant="filled">
                      <InputLabel id="demo-simple-select-filled-label standard-full-width">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled-label"
                        value={values.status || ""}
                        defaultValue={""}
                        onChange={handleChange("status")}>
                        {this.statusList.length > 0 &&
                          this.statusList?.map(
                            (item: string, index: number) => (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            ),
                          )}
                      </Select>
                      <FormHelperText>{errors.status}</FormHelperText>
                    </FormControlContainer>

                    {this.initialCertId > 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isActive}
                            onChange={handleChange("isActive")}
                            name="checkedA"
                          />
                        }
                        label="Is Active"
                      />
                    )}

                    <BottomButtonComponentWeb
                      initialId={this.initialCertId}
                      onSubmit={submitForm}
                    />
                  </div>
                )}
              </Formik>

              <AlertModal
                alertTitle={titleModal}
                handleCloseAlert={this.handleCloseAlert}
                alertVisible={alertModal}
                alertContent={contentModal}
              />
            </Box>
          )}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const webSxStyle = {
  mainWrapperStyle: {
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};
// Customizable Area End
