import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  NewExpanse: boolean;
  token: string;
  NewExpanseName: string;
  NewExpanseAmount: number | string;
  NewExpanseDate: string;
  spendItemData: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      created_at: string;
      updated_at: string;
      spends: []; // The type of spends is not specified
    };
  }[];
  deleteSpendItem: boolean;
  deleteSpendItemID: string | null;
  EditSpendItemId: string | null;
  authToken: string;
  date: string;
  deleteSpendName: string;
  selectSpendItemID: null | number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SpendItemController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  subScribedMsg: string[];
  loginApiCallId: string | undefined;
  postNewSpendItem: string | undefined;
  spendItemCallID: string | undefined;
  spendItemDelete: string | undefined;
  spendItemUpdate: string | undefined;
  viewUserExpense: string | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMsg = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      NewExpanse: false,
      NewExpanseName: "",
      NewExpanseAmount: "",
      NewExpanseDate: "",
      spendItemData: [],
      deleteSpendItem: false,
      deleteSpendItemID: null,
      EditSpendItemId: null,
      authToken: "",
      date: new Date().toISOString().slice(0, 10),
      deleteSpendName: "",
      selectSpendItemID: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMsg);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.signin();
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.loginApiCallId: {
            this.setState(
              {
                authToken: responseJson.meta.token,
              },
              () => {
                this.getSpendItemData();
              },
            );
            break;
          }
          case this.postNewSpendItem: {
            this.showAlert(`Success`, "New Spend Item Added Successfully");
            this.getSpendItemData();
            this.setState({
              NewExpanseName: "",
              NewExpanseAmount: "",
              NewExpanseDate: "",
            });
            break;
          }
          case this.spendItemDelete: {
            this.setState({
              deleteSpendItem: false,
              deleteSpendItemID: null,
            });
            this.showAlert(`Success`, "Spend Item Deleted Successfully");
            await this.getSpendItemData();
            break;
          }
          case this.spendItemUpdate: {
            this.setState({
              NewExpanseName: "",
              NewExpanseAmount: "",
              NewExpanseDate: "",
            });
            this.setState({ ...this.state, NewExpanse: false });
            this.showAlert(`Success`, "Spend Item Updated Successfully");
            await this.getSpendItemData();
            break;
          }
          case this.viewUserExpense: {
            let updatedData = responseJson.data.attributes;
            this.setState({
              NewExpanseName: updatedData.name,
              NewExpanseAmount: updatedData.description,
              NewExpanseDate: updatedData.updated_at.slice(0, 10),
            });
            break;
          }
          case this.spendItemCallID: {
            this.setState({
              spendItemData: responseJson.data,
            });
            break;
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSpendItemChange = (value: number) => {
    this.setState({ selectSpendItemID: value });
  };

  handleSpendItemClose = () => {
    this.setState({
      NewExpanse: false,
      EditSpendItemId: null,
      NewExpanseName: "",
      NewExpanseAmount: "",
      NewExpanseDate: "",
    });
  };

  handleDeleteSpendItem = (deletedId: string, deleteSpendName: string) => {
    this.setState({
      deleteSpendItem: true,
      deleteSpendItemID: deletedId,
      deleteSpendName: deleteSpendName,
    });
  };

  handleDeleteSpendItemClose = () => {
    this.setState({ deleteSpendItem: false, deleteSpendItemID: null });
  };

  async deleteSpendItem(removeId: string | null) {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.spendItemDelete = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteNewSpendItemEndpoints + removeId,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  signin = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSpendItemOpen = () => {
    this.setState({ ...this.state, NewExpanse: true });
  };

  createNewName = (createEventName: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseName: createEventName.target.value });
  };

  createNewAmount = (createEventAmount: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseAmount: createEventAmount.target.value });
  };

  CreateNewDate = (createEventDate: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseDate: createEventDate.target.value });
  };

  getSpendItemData = async () => {
    const header = {
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.spendItemCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getspendItemListEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateSpendItem = async () => {
    this.setState({ EditSpendItemId: null });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const body = {
      data: {
        attributes: {
          name: this.state.NewExpanseName,
          description: this.state.NewExpanseAmount,
          date: this.state.NewExpanseDate,
        },
      },
    };

    this.spendItemUpdate = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editNewSpendItemEndpoints + "/" + this.state.EditSpendItemId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditSpendItem = async (editId: string) => {
    await this.getEditedUserDetails(editId);
    this.setState({ EditSpendItemId: editId });
    this.setState({ NewExpanse: true });
  };

  getEditedUserDetails = async (editId: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewUserExpense = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editNewSpendItemEndpoints + "/" + editId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkAllDataFilled = async () => {
    if (
      this.state.NewExpanseName === "" ||
      this.state.NewExpanseAmount === ""
    ) {
      this.showAlert("Error", "All fields are mandatory");
    } else {
      await this.createNewSpendItem();
      await this.getSpendItemData();
      this.handleSpendItemClose();
    }
  };

  createNewSpendItem = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    const body = {
      data: {
        attributes: {
          name: this.state.NewExpanseName,
          description: this.state.NewExpanseAmount,
          created_at: "",
        },
      },
    };

    this.postNewSpendItem = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postNewSpendItemEndpoints,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  options = {
    chart: {
      title: "Company Performance",
    },
  };
  // Customizable Area End
}
