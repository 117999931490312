import * as React from "react";
import { ChangeEvent, PropsWithChildren, ReactElement } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  Certificate,
  CertificateStatusItem,
  Designation,
  Employee,
  EmployeeCertificateStatusItem,
} from "../../types";
import { ItemType } from "../SelectModalComponent/SelectModalComponentController";

type WebItemType =
  | Designation
  | Employee
  | Certificate
  | EmployeeCertificateStatusItem
  | CertificateStatusItem;

export function SelectDropdownComponent(
  props: PropsWithChildren<SelectDropdownComponentProps>,
): ReactElement {
  const { error, errorLabel, value, onChange, valueList, label, dataTestId } =
    props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <FormControl
      data-test-id={dataTestId}
      error={error}
      className={classes.root}
      variant="filled">
      <InputLabel id="demo-simple-select-filled-label standard-full-width">
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled-label"
        value={value ?? ""}
        defaultValue={""}
        onChange={onChange}>
        {valueList.length > 0 &&
          valueList.map((item: WebItemType) => (
            <MenuItem value={item.id!} key={item.id!}>
              {(item as Employee).employee_name ??
                (item as Exclude<ItemType, Employee>)!.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{errorLabel}</FormHelperText>
    </FormControl>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginTop: theme.spacing(4),
      width: "100%",
    },
  }),
);

export interface SelectDropdownComponentProps {
  //
  dataTestId?: string;
  error?: boolean;
  errorLabel?: string;
  label: string;
  onChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  value: number | null | undefined | string | CertificateStatusItem;
  valueList: WebItemType[];
}

SelectDropdownComponent.defaultProps = {
  //
};

export default SelectDropdownComponent;
