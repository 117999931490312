import * as React from "react";
import { ReactElement } from "react";
import { Typography, Box, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { styled } from "@material-ui/core/styles";

export interface TitleProps {
  title: string;
  onBackClick?: () => void;
}

const Container = styled(Box)({
  padding: 12,
  background: "#7F70F4",
  color: "white",
  width: "100%",
  marginBottom: 28,
  position: "relative",
});

const BackButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
});

function Title(props: TitleProps): ReactElement {
  const { title, onBackClick } = props;

  return (
    <Container>
      {onBackClick && (
        <BackButton data-test-id="back-button" onClick={onBackClick}>
          <ArrowBackIcon />
        </BackButton>
      )}
      <Typography variant="h6" align="center">
        {title}
      </Typography>
    </Container>
  );
}

Title.defaultProps = {};

export default Title;
