import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ManageBlogCommentsController, {
  Props,
  configJSON,
} from "./ManageBlogCommentsController";
import { CommentData } from "./types";

export default class ManageBlogComments extends ManageBlogCommentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <Loader loading={this.state.isLoading} />}
        {!this.state.isLoading && (
          <AppBar position="static" style={webStyle.appBarStyle}>
            <Toolbar>
              <Typography variant="h6" style={webStyle.blogText}>
                BLOG
              </Typography>
            </Toolbar>
            <Box display="flex" alignItems="space-between" p={2}>
              <Box flexGrow={1} display="flex" flexDirection="row">
                <Typography variant="subtitle1" style={webStyle.manageBlogText}>
                  Manage Blog Comment
                </Typography>
                <Select
                  id="select"
                  style={webStyle.selectBox}
                  defaultValue={"bulkAction"}
                  variant="outlined"
                  onChange={(event) => {
                    this.onchange(event);
                  }}>
                  <MenuItem value="bulkAction">Bulk Action</MenuItem>
                  <MenuItem value="approve">Approve</MenuItem>
                  <MenuItem value="spam">Spam</MenuItem>
                  <MenuItem value="delete">Delete</MenuItem>
                </Select>
              </Box>

              <Typography variant="subtitle2" style={webStyle.totalCommentText}>
                Total Comment: {this.state.data.length}
              </Typography>
            </Box>

            <div id="data_container" style={webStyle.mainContainer}>
              {this.state.data.length &&
                this.state.data.map((item: CommentData) => (
                  <Paper style={webStyle.paperStyle} key={item.id}>
                    <Box data-test-id="container">
                      <Box style={webStyle.checkBoxContainer}>
                        <Checkbox
                          id="check"
                          color="primary"
                          onChange={() => {
                            this.state.bulk.push(item.id);
                          }}
                        />
                        <Typography style={webStyle.commentContainer}>
                          {this.blogTitle(item)}
                        </Typography>
                      </Box>
                      <Box style={webStyle.descriptionContainer}>
                        <Typography>{this.descriptionText(item)}</Typography>
                      </Box>
                      <Box style={webStyle.bottomContainer}>
                        <Box style={webStyle.imgContainer}>
                          <img
                            style={webStyle.imgRound}
                            src={
                              item.attributes.profile_image !== null
                                ? item.attributes.profile_image
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiwVJV38db9e6c6qLcHw53zMFT9j81nnv13Go8rzlOSA&s"
                            }
                            alt="John Deo"
                          />
                          <Typography style={webStyle.ml}>
                            {item.attributes.account.full_name}
                          </Typography>
                          <Typography style={webStyle.ml}>
                            {this.dateTime(item.attributes.created_at)}
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            id="approve"
                            variant="contained"
                            color="primary"
                            style={webStyle.approveButton}
                            onClick={() => this.approveButton(item)}>
                            {this.approveText(item)}
                          </Button>
                          <Button
                            id="spam"
                            variant="contained"
                            color="primary"
                            style={webStyle.spamButton}
                            onClick={() => this.spamButton(item)}>
                            {this.spamText(item)}
                          </Button>
                          <Button
                            id="delete"
                            variant="contained"
                            color="primary"
                            style={webStyle.deleteButton}
                            onClick={() => this.delete(item.id)}>
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                ))}
            </div>

            <div style={webStyle.pageButtonContainer}>
              <button
                id="previous"
                disabled={this.state.prePageMobile === null}
                onClick={() => {
                  this.state.prePageMobile !== null && this.decrementPage();
                }}>
                Previous
              </button>
              <p style={webStyle.pageNumberText}>
                {this.state.currentPageMobile}
              </p>
              <button
                id="next"
                onClick={() => {
                  this.state.nextPageMobile !== null && this.incrementPage();
                }}
                disabled={this.state.nextPageMobile === null}>
                Next
              </button>
            </div>
          </AppBar>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  appBarStyle: {
    backgroundColor: "#a04eec",
    height: "50px",
    width: "90%",
    marginLeft: "5%",
    zIndex: -1,
    color: "red",
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: "auto",
    width: "80%",
    border: "2px solid grey",
    marginBottom: 20,
  },
  approveButton: {
    marginRight: 20,
    backgroundColor: "b5e51d",
    color: "black",
    border: "1px solid #db9c58",
  },
  spamButton: {
    marginRight: 20,
    backgroundColor: "white",
    color: "#db9c58",
    border: "1px solid #db9c58",
  },
  deleteButton: {
    backgroundColor: "#fc595b",
    color: "white",
  },
  mainContainer: {
    flexGrow: 1,
  },
  selectBox: {
    marginLeft: 20,
    height: 30,
    width: 140,
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
    zIndex: 1,
  },
  commentContainer: {
    marginLeft: 18,
  },
  descriptionContainer: {
    marginLeft: 60,
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  imgRound: {
    width: "40",
    height: "40",
    borderRadius: "50%",
  },
  ml: {
    marginLeft: 20,
  },
  pageButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pageNumberText: {
    margin: 50,
    color: "black",
    fontWeight: 700,
  },
  blogText: {
    color: "white",
    fontWeight: 700,
  },
  manageBlogText: {
    color: "black",
    fontWeigh: 700,
    fontSize: 20,
  },
  totalCommentText: {
    color: "grey",
    fontSize: 16,
  },
};
// Customizable Area End
