Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LeaveTracker";
exports.labelBodyText = "LeaveTracker Body";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.updateApiMethodType = "PATCH";
exports.loginInApiEndPoint = "bx_block_login/logins";
exports.leaveDetailsApiEndPoint = "bx_block_leave_tracker/leave_trackers/";
exports.getAllLeavesApiEndPoint = "bx_block_leave_tracker/leave_trackers";
exports.getUserLeavesApiEndPoint =
  "bx_block_leave_tracker/leave_trackers/user_records";
exports.updateLeaveApiEndPoint = "bx_block_leave_tracker/leave_trackers/";
exports.createLeaveApiEndPoint = "bx_block_leave_tracker/leave_trackers";
exports.updateEmployeeLeaveStatusApiEndPoint =
  "bx_block_leave_tracker/leave_trackers?";
exports.listAllEmployeeLeavesApiEndPoint =
  "/bx_block_leave_tracker/leave_trackers";
exports.listAllEmployeeApiEndPointData =
  "bx_block_leave_tracker/leave_trackers/all_employees";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
