Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SpendAnalysis";
exports.labelBodyText = "SpendAnalysis Body";

exports.methodPost = "POST";
exports.methodGet = "GET";
exports.methodPut = "PUT";
exports.methodDelete = "DELETE";
exports.email = "buraktest@gmail.com";
exports.securedText = "123456";
exports.loginEndpoint = "bx_block_login/logins";
exports.getSpendsEndpoint = "bx_block_spend_analysis/spends/spend_trends";
exports.getTransactionsEndpoint =
  "bx_block_spend_analysis/spends/spends_transactions?page=";
exports.getTransactionsEndpointSuffix = "&limit=5";
exports.postNewSpendEndpoint = "bx_block_spend_analysis/spends";
exports.editTransactionEndpoint = "bx_block_spend_analysis/spends/";
exports.deleteTransactionEndpoint = "bx_block_spend_analysis/spends/delete?id=";

exports.getTotalSpendEndPoint = "bx_block_spend_analysis/spends/spend_trends";
exports.getAllSpendEndPoint =
  "bx_block_spend_analysis/spends/spends_transactions?page=";
exports.getAllSpendEndPointEnd = "&limit=5";
exports.postNewSpendsEndpoints = "bx_block_spend_analysis/spends";
exports.deleteTransactionsEndPoint =
  "bx_block_spend_analysis/spends/delete?id=";
exports.getSpendTransactionById = "bx_block_spend_analysis/spends/";
exports.updateSpendApi = "bx_block_spend_analysis/spends/";
exports.btnExampleTitle = "CLICK ME";
exports.patchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.loginEmail = "test11@gmail.com";
exports.loginPassword = "Password@123";
exports.loginEndPoint = "bx_block_login/logins";
exports.getspendItemListEndpoint = "bx_block_spend_analysis/spend_items";
exports.postNewSpendItemEndpoints = "bx_block_spend_analysis/spend_items";
exports.editNewSpendItemEndpoints = "bx_block_spend_analysis/spend_items";
exports.deleteNewSpendItemEndpoints =
  "bx_block_spend_analysis/spend_items/delete?id=";
// Customizable Area End
