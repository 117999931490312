import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { Certificate } from "../types";
import createRequestMessage from "../helpers/create-request-message";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  certificateList: Certificate[];
  token: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CertificateListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  certificateListApi: string;
  loginApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      certificateList: [],
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.certificateListApi = "";
    this.loginApiCallId = "";
    this.handleGoToCertificateInsert =
      this.handleGoToCertificateInsert.bind(this);
    this.handleGoToCertificateUpdateScreen =
      this.handleGoToCertificateUpdateScreen.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiRequestCallId === this.certificateListApi) {
        if (!responseJson.errors) {
          this.setState({
            certificateList: responseJson.certificates,
          });
        }
      }
      // Customizable Area End
    }
  }
  // Customizable Area Start
  async componentDidMount() {
    this.handleGetToken();
  }

  handleGoToCertificateUpdateScreen(item: Certificate) {
    this.props.navigation.navigate("CertificateInsert", {
      id: item.id,
    });
  }

  handleGoToCertificateInsert() {
    this.props.navigation.navigate("CertificateInsert");
  }

  async handleGetToken() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        if (this.state.certificateList.length === 0) {
          this.handleGetCertificateList();
        }
      },
    );
  }

  handleGetCertificateList() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.certificateListApi = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.exampleAPiEndPoint,
      method: configJSON.validationApiMethodType,
      token: this.state.token,
    });
  }

  handleGoBack() {
    this.props.navigation.navigate("CertificationTracking");
  }
  // Customizable Area End
}
