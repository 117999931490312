import * as React from "react";
import { Fragment, PropsWithChildren, ReactElement } from "react";
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Employee } from "../../types";
import { styled } from "@material-ui/core/styles";

export function EmployeeListItem(
  props: PropsWithChildren<EmployeeListItemProps>,
): ReactElement {
  const { employee, onClick, dataTestId } = props;

  return (
    <Fragment data-test-id={dataTestId}>
      <ListItem
        data-test-id={"listItemId"}
        alignItems="flex-start"
        key={employee.id}
        role={undefined}
        button
        onClick={onClick}>
        <ListItemAvatar>
          <Avatar
            alt="EmployeeName"
            src={employee.employee_picture as string}
          />
        </ListItemAvatar>
        <ListItemText
          primary={employee.employee_name}
          secondary={
            <React.Fragment>
              <Name variant="body2" color="textPrimary">
                {employee.designation!.name ?? ""}
              </Name>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );
}

const Name = styled(Typography)({
  display: "inline",
});

export interface EmployeeListItemProps {
  employee: Employee;
  onClick: () => void;
  dataTestId?: string;
}

EmployeeListItem.defaultProps = {
  //
};

export default EmployeeListItem;
