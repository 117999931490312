Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TimesheetManagement";
exports.labelBodyText = "TimesheetManagement Body";

exports.btnExampleTitle = "CLICK ME";

exports.createTaskApiEndPoint = "bx_block_timesheet_management/timesheet_tasks";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";

exports.listTaskApiEndPoint = "bx_block_timesheet_management/timesheet_tasks";
exports.listDetailsLogsCreateApiEndPoint = "bx_block_timesheet_management/logs";
exports.listDetailsLogsApiEndPoint =
  "bx_block_timesheet_management/logs/total_logged_hours?timesheet_task_id=";
exports.listTimeManagementLogsApiEndPoint =
  "bx_block_timesheet_management/logs/aggregate";
exports.logsUpdateDeleteApiEndPoint = "bx_block_timesheet_management/logs/";
exports.taskListSearchTaskApiEndPoint =
  "bx_block_timesheet_management/timesheet_tasks/search_task";
exports.taskTotalLoggedHours =
  "bx_block_timesheet_management/logs/total_logged_hours";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypeDelete = "DELETE";
exports.apiTaskDelete = "bx_block_timesheet_management/timesheet_tasks/";

exports.labelSelectOption = "Select an option to start";
exports.labeViewTimeSheets = "View Timesheets";
exports.labelViewTasks = "View Tasks";
exports.search = "Search";
exports.searchAccountId = "Search with Account id";
exports.create = "Create";
exports.update = "Update";
exports.createNewTask = "Create a new task";
exports.createLog = "Create log";
exports.edit = "Edit";
exports.delete = "Delete";
exports.labelDateAdd = "Date: Add Date";
exports.taskList = "Task List";
exports.allTask = "All task";
exports.noTasks = "No such tasks";
exports.labelTotalAllocatedTime = "Total Time Allocated: ";
exports.labelTotalConsumedTime = "Total Time Consumed: ";
exports.labelTaskStatus = "Status: ";
exports.labelMembersWorking = " members currently working";
exports.cancel = "Cancel";
exports.labelTitle = "Title*";
exports.labelDescription = "Description";
exports.labelDescriptionDots = "Description : ";
exports.labelAllocatedHours = "Allocated Hours*";
exports.labelStatus = "Status";
exports.allMembers = "All members";
exports.labelTotalWorkedHours = "Total hrs worked : ";
exports.labelStartDate = "Start Date : ";
exports.labelAllActivities = "Showing: All activities";

exports.labelDaily = "Daily";
exports.labelMonthly = "Monthly";
exports.labelWeekly = "Weekly";
exports.labelTotalWorkedHoursToday = "Total Hrs worked today : ";
exports.labelTotalHours = "Total Hrs : ";
exports.labelActivityDate = "Activity Date : ";
exports.labelTaskName = "Task Name : ";
exports.labelLogName = "Log Name:";

exports.labelStartTime = "Start Time*";
exports.labelEndTime = "End Time*";
exports.labelAccountId = "Account id : ";

exports.email = "chirag@yopmail.com";
exports.securedText = "Test@1234";

exports.checkInternetConnection = "Please check your internet connection.";

exports.allLogs = "All Logs";
exports.noLogsMessage = "No Logs exist";
exports.createLogErrorTitle = "Log Create";
exports.createLogErrorTitleMessage =
  "Please add proper title with minimum 3 character";
exports.createLogErrorDescriptionMessage =
  "Please add proper description with minimum 3 character";
exports.createLogErrorStartDateMessage = "Please select Start Date";
exports.createLogErrorEndDateMessage = "Please select End Date";
exports.createLogErrorStartEndDifferenceMessage =
  "End Date must be higher than Start Date";

exports.createTaskErrorTitle = "Task Create";
exports.createTaskErrorTitleMessage =
  "Please add proper title with minimum 3 character";
exports.createTaskErrorDescriptionMessage =
  "Please add proper description with minimum 3 character";
exports.createTaskErrorAllocatedTimeMessage =
  "Please add proper hours like 1, 2, 3, 4, 8";

exports.taskDetailErrorTitle = "Task Details";
exports.workerDetailErrorTitle = "Worker Details";
exports.taskDetailShowingLabel = "Showing: All activities";
exports.messageDeleteTask = "Are you sure that you want to delete this task ?";
exports.messageDeleteTaskSuccess = "Task deleted successfully";
exports.messageDeleteLog = "Are you sure that you want to delete this log ?";
exports.messageDeleteLogSuccess = "Log deleted successfully";
// Customizable Area End
