import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import storage from "../../../framework/src/StorageProvider";

// Customizable Area End

export const configJs = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken: string;
  isLoading: boolean;
  referralCode: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReferralsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCodeIDReferral: string = "";
  loginApiId: string = "";
  subScribedMsg: string[];

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMsg = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken: "",
      isLoading: false,
      referralCode: "",

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMsg);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestDataIdCall = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorData = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      switch (apiRequestDataIdCall) {
        case this.getCodeIDReferral: {
          handleResponseMessage({
            responseJson: responseData,
            errorJson: errorData,
            onSuccess: () => {
              this.onReferralSuccess(responseData);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Referral Failed! Please retry");
            },
          });
          break;
        }

        case this.loginApiId: {
          handleResponseMessage({
            responseJson: responseData,
            errorJson: errorData,
            onSuccess: () => {
              this.onLoginSuccess(responseData);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Token Failed. Please retry!");
            },
          });
          break;
        }
      }
    }

    // Customizable Area End
  }


  // Customizable Area Start

  onLoginSuccess = async (responseData: { meta: { token: string } }) => {
    await storage.set("token", responseData.meta.token);
    this.setState(
      {
        authToken: responseData.meta.token,
      },
      () => this.getCodeReferral(),
    );
  };

  onReferralSuccess = (responseData: { data: { code: string } }) => {
    this.setState({
      referralCode: responseData.data.code,
    });
  };

  // Functions to get the Referral code for user confirmation
  getCodeReferral = () => {
    this.setState({
      isLoading: true,
    });
    const requests = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCodeIDReferral = requests.messageId;
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": configJs.validationApiContentType,
    };

    createRequestMessage({
      requestMessage: requests,
      endPoint: configJs.Referal_EndPoint,
      method: configJs.postApiMethodType,
      header: headers,
    });
  };

  // fake login function
  handleUserLogin() {
    this.setState({
      isLoading: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.loginApiId = requestMessage.messageId;
    const headers = {
      "Content-Type": configJs.validationApiContentType,
    };
    const body = {
      data: {
        attributes: {
          email: configJs.USERNAME,
          password: configJs.PASSWORD,
        },
        type: "email_account",
      },
    };
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJs.Login_EndPoint,
      method: configJs.postApiMethodType,
      header: headers,
      body: JSON.stringify(body),
    });
  }

  async componentDidMount() {
    const token = await storage.get("token");
    if (!token) {
      this.handleUserLogin();
    } else {
      this.setState({ authToken: token }, () => {
        this.getCodeReferral();
      });
    }
  }

  // Customizable Area End
}
