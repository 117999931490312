import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { FundraiserDetail } from "./types";
// Customizable Area End

export const configJSON = require("./config");

interface Pledge extends FundraiserDetail {}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  title: string;
  description: string;
  amount: string;
  pledgedAmount: string;
  createdAt: string;
  creator:
    | (FundraiserDetail["attributes"]["create_by"] & { name: string })
    | null;
  loading: boolean;
  pledgeList: Array<Pledge>;
  isNavigate: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FundraiserDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  #token: string | null = null;

  fundraiserRequestId: string = "";

  pledgeListRequestId: string = "";

  fundraiserId: number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      title: "",
      description: "",
      amount: "",
      pledgedAmount: "",
      createdAt: "",
      creator: null,
      loading: false,
      pledgeList: [],
      isNavigate: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fundraiserId = this.props.navigation.getParam(
      "fundraiserId",
    ) as number;
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    this.#token = await getStorageData("TOKEN");

    if (!this.#token) {
      this.props.navigation.navigate("PledgeTracking");
    }

    await this.getFundDetails(this.fundraiserId);
    await this.getPledgeList(1, 2);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (responseJson !== null && responseJson.error) {
        this.showAlert("Error", responseJson.error);
        this.setState({ loading: false });
        return;
      }

      if (!responseJson.requests) {
        return;
      }

      if (apiRequestCallId === this.fundraiserRequestId) {
        const { amount, description, title, create_by, created_at } =
          responseJson.requests.data.attributes;
        const { full_name, first_name, last_name } = create_by;
        const name = first_name ? `${first_name} ${last_name}` : full_name;

        const fundraiserInfo = {
          amount,
          description,
          title,
          createdAt: created_at,
          creator: { ...create_by, name },
        };
        this.setState((oldState) => ({
          ...oldState,
          ...fundraiserInfo,
          loading: false,
        }));
      } else if (apiRequestCallId === this.pledgeListRequestId) {
        this.setState({
          pledgeList: responseJson.requests.data,
          pledgedAmount: responseJson.meta.total_pledged_amount,
          loading: false,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async getFundDetails(index: number) {
    this.setState({ loading: true });

    const headers = {
      token: this.#token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.fundraiserRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetFundraiser}/${index}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetFundraiser,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPledgeList(page: number, limit: number) {
    const headers = {
      token: this.#token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.pledgeListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetPledge}?page=${page}&per_page=${limit}&fundraising_request_id=${this.fundraiserId}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetFundraiser,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToPledgeCreation() {
    this.setState({ isNavigate: true });
    this.props.navigation.navigate("PledgeCreationForm", {
      fundraiserId: this.fundraiserId,
    });
  }

  goToPledgeList() {
    this.props.navigation.navigate("PledgeList", {
      fundraiserId: this.fundraiserId,
    });
  }

  goToFundraiserList() {
    this.setState({ isNavigate: true });
    this.props.navigation.navigate("FundraiserList");
  }

  redirectToEdit() {
    this.props.navigation.navigate("FundraiserCreationForm", {
      fundraiserId: this.fundraiserId,
    });
  }

  // Customizable Area End
}
