Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FeedbackCollection";
exports.labelBodyText = "FeedbackCollection Body";
exports.submitFeedback = "bx_block_feedbackcollection/feedbacks";
exports.getFeedbackByPaginationFirst =
  "bx_block_feedbackcollection/feedbacks?page=";
exports.getFeedbackByPaginationSecond = "&per_page=5";

exports.loginInApiEndPoint = "bx_block_login/logins";
exports.postApiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.btnExampleTitle = "CLICK ME";
exports.loginId = "y@y.com";
exports.loginPass = "Y@123";
// Customizable Area End
