Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ManageBlogComments";
exports.labelBodyText = "ManageBlogComments Body";

exports.btnExampleTitle = "CLICK ME";
exports.email = "test0@gmail.com";
exports.PASSWORD = "Password@123";
exports.POSTApiMethodType = "POST";
exports.loginAPI = "bx_block_login/logins";
exports.getDataAPICallId = "bx_block_comments/comments/?token=";
exports.getBulkUpdateAPI = "bx_block_comments/comments/bulk_update/?token=";
// Customizable Area End
