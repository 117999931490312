Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LogoDesign2";
exports.labelBodyText = "LogoDesign2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.loginEmail = "hanzala@gmail.com";
exports.loginPassword = "Password@123";
// Customizable Area End
