import * as React from "react";
import { ChangeEvent, PropsWithChildren, ReactElement } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export function CalendarComponent(
  props: PropsWithChildren<CalendarComponentWebProps>,
): ReactElement {
  const { label, value, onChange, errorLabel, dataTestId } = props;

  return (
    <FormItem data-test-id={dataTestId}>
      <FormItemTypo>{label}</FormItemTypo>
      <TextField
        id="date"
        type="date"
        name={"goalEndDate"}
        data-test-id={"goalEndDateTest"}
        placeholder={"Please choose"}
        value={value}
        onChange={onChange}
      />
      {errorLabel && <ErrorTypo>{errorLabel}</ErrorTypo>}
    </FormItem>
  );
}

const FormItem = styled(Box)({
  padding: 24,
  width: "100%",
});

const FormItemTypo = styled(Typography)({
  fontWeight: "bold",
  fontSize: 16,
  color: "black",
});

const ErrorTypo = styled(Typography)({
  color: "red",
  marginTop: 8,
});
export interface CalendarComponentWebProps {
  label: string;
  value: string | null;
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  errorLabel?: string;
  dataTestId?: string;
}

CalendarComponent.defaultProps = {};

export default CalendarComponent;
