import React, { PropsWithChildren, ReactElement } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export function AlertModal(
  props: PropsWithChildren<AlertDialogProps>,
): ReactElement {
  const { alertVisible, handleCloseAlert, alertTitle, alertContent } = props;

  return (
    <div>
      <Dialog
        open={alertVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="inherit">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export interface AlertDialogProps {
  alertVisible: boolean;
  handleCloseAlert: () => void;
  alertTitle: string;
  alertContent: string;
}

AlertModal.defaultProps = {};

export default AlertModal;
