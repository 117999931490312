import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  modalVisible: boolean;
  token: string;
  isLoading: boolean;
  protectedCode: string;
  visiblePassword: boolean;
  visibleWarning: boolean;
  errorMessage: string;
  showSuccessModal: boolean;
  fromWeb: boolean | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PasswordProtectedPagesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiCallId: string;
  passwordProtectedPageApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.handleEnterPassword = this.handleEnterPassword.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
    this.handleToggleVisiblePassword =
      this.handleToggleVisiblePassword.bind(this);
    this.handleValidatePassword = this.handleValidatePassword.bind(this);
    this.handleLoginPasswordProtected =
      this.handleLoginPasswordProtected.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

    this.loginApiCallId = "";
    this.passwordProtectedPageApiCallId = "";

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      modalVisible: true,
      token: "",
      isLoading: false,
      protectedCode: "",
      visiblePassword: false,
      visibleWarning: false,
      errorMessage: "",
      showSuccessModal: false,
      fromWeb: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value,
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      switch (apiRequestCallId) {
        case this.loginApiCallId: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              this.handleValidatePassword(responseJson.meta.token);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Token Failed. Please retry!");
            },
          });
          break;
        }

        case this.passwordProtectedPageApiCallId: {
          handleResponseMessage({
            responseJson,
            errorJson,
            onSuccess: () => {
              const { status } = responseJson;
              if (status === "true") {
                if (this.state.fromWeb) {
                  this.setState({ showSuccessModal: true });
                } else {
                  this.showAlert(`Success`, "You can access page now");
                }

                this.setState({
                  modalVisible: false,
                  errorMessage: "",
                });
              } else {
                const { message: responseMessage } = responseJson;
                this.setState({
                  visibleWarning: true,
                  errorMessage: responseMessage,
                });
              }
            },
            onFail: () => {
              this.showAlert(`Error`, "Can not get result");
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue,
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleToggleVisiblePassword() {
    this.setState((prevState) => ({
      visiblePassword: !prevState.visiblePassword,
    }));
  }

  handleLoginPasswordProtected() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.loginApiCallId = requestMessage.messageId;

    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.loginURLEndPoint,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  }

  handleEnterPassword(text: string) {
    this.setState({
      protectedCode: text,
    });
  }

  handleToggleModal() {
    this.setState((prevState) => ({
      modalVisible: !prevState.modalVisible,
    }));
  }

  handleValidatePassword(token: string) {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.passwordProtectedPageApiCallId = requestMessage.messageId;

    const body = {};

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.protectedURLEndPoint}?password=${this.state.protectedCode}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
      token: token,
    });
  }

  handleConfirm(fromWeb?: boolean | undefined) {
    if (this.state.protectedCode.length > 0) {
      this.handleLoginPasswordProtected();
      this.setState({ fromWeb });
    } else {
      this.showAlert("Password Error", "Password is Empty");
    }
  }

  handleSuccessModalClose() {
    this.setState({ showSuccessModal: false, modalVisible: false });
  }
  // Customizable Area End
}
