import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import CreateContentWebController, {
  Props,
} from "./CreateContentWebController.web";
export default class CreateContentWeb extends CreateContentWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={styles.mainContainer}>
            <Box sx={styles.inputView}>
              <Typography>{"Text Content"}</Typography>
              <Input
                data-test-id="text_content"
                onChange={(event) => this.handleTitle(event.target.value)}
                value={this.state.textContent}
                placeholder="Text Content"
                type="text"
                maxRows={10}
                multiline={true}
                name="Text Content"
              />
            </Box>
            <input
              data-test-id="fileInputRef"
              type={"file"}
              style={styles.input}
              accept="image/*"
              onChange={this.onFileChange}
            />

            <Box component="button" sx={styles.buttonView}>
              <Button
                data-test-id="uploadContent"
                onClick={() => this.createContentData()}
                color={"primary"}>
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  mainContainer: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputView: {
    marginTop: "5px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonView: {
    width: "50%",
    height: "45px",
    marginTop: "10px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  input: {
    marginTop: "10px",
  },
};
// Customizable Area End
