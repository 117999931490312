import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import storage from "framework/src/StorageProvider.web";
import { WikiContent, WikiResponse } from "./Types";
import createRequestMessage from "./helpers/create-request-message";
import { handleResponseMessage } from "./helpers/handle-response-message";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface ContentFileResponse {
  fileName: string;
  objectUrl?: string;
  url?: string;
  file?: File | Blob | MediaSource;
}

interface S {
  // Customizable Area Start
  wikiName: string;
  wikiTags: string[];
  descriptions: string;
  contentFileResponse: ContentFileResponse[];
  loading: boolean;
  authToken: string;
  editWikiId?: number;
  wikiUrl: string;
  wikiID: number;
  editorState: EditorState;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WikiCreateController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWikiApi: string = "";
  createWikiApi: string = "";
  updateWikiApi: string = "";
  updateWithAttachmentWikiApi: string = "";
  createWikiContentApi: string = "";
  initialId: number = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleGoToListScreen = this.handleGoToListScreen.bind(this);
    this.initialId = this.props.navigation?.getParam("wikiId") as number;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      wikiName: "",
      wikiTags: [],
      descriptions: "",
      loading: false,
      wikiUrl: "",
      authToken: "",
      wikiID: this.initialId,
      editorState: EditorState.createEmpty(),
      contentFileResponse: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiWeb = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        loading: false,
      });
      switch (apiWeb) {
        case this.getWikiApi: {
          handleResponseMessage({
            responseJson: responseWeb,
            errorJson: errorWeb,
            onSuccess: () => {
              this.handleGetWikiDetailSuccess(responseWeb);
            },
            onFail: async () => {
              this.showAlert(`Error`, "Get Wiki Failed! Please retry");
              await this.handleLoginUser();
            },
          });
          break;
        }
        case this.createWikiContentApi: {
          handleResponseMessage({
            responseJson: responseWeb,
            errorJson: errorWeb,
            onSuccess: () => {
              this.handleAddWikiContentSuccess(responseWeb);
            },
            onFail: () => {
              this.showAlert(
                `Error`,
                "Get Wiki content adding failed! Please retry",
              );
            },
          });
          break;
        }
        case this.createWikiApi: {
          handleResponseMessage({
            responseJson: responseWeb,
            errorJson: errorWeb,
            onSuccess: () => {
              this.handleCreateWikiSuccess(responseWeb);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Wiki Failed! Please retry");
            },
          });
          break;
        }
        case this.updateWithAttachmentWikiApi: {
          handleResponseMessage({
            responseJson: responseWeb,
            errorJson: errorWeb,
            onSuccess: () => {
              this.handleEditWithAttachmentWikiSuccess(responseWeb);
            },
            onFail: () => {
              this.showAlert(
                `Error`,
                "Get Wiki content adding failed! Please retry",
              );
            },
          });
          break;
        }
        case this.updateWikiApi: {
          handleResponseMessage({
            responseJson: responseWeb,
            errorJson: errorWeb,
            onSuccess: () => {
              this.handleEditWikiSuccess(responseWeb);
            },
            onFail: () => {
              this.showAlert(`Error`, "Get Wiki Failed! Please retry");
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async handleLoginUser() {
    let token = await storage.get("token");
    this.setState({
      authToken: token,
    });
  }

  // get new wikis
  handleGetWikiDetail = () => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const getWiki = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWikiApi = getWiki.messageId;
    createRequestMessage({
      endPoint: `${configJSON.Wiki_EndPoint}/${this.initialId}`,
      requestMessage: getWiki,
      method: configJSON.getApiMethod,
      header: headers,
    });
  };

  handleGetWikiDetailSuccess = (apiResponse: { data: WikiResponse }) => {
    const { data } = apiResponse;
    if (apiResponse.data) {
      this.setState({
        loading: false,
      });
      const contentBlock = htmlToDraft(data.content!);
      const contentState = ContentState.createFromBlockArray(
        contentBlock?.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);

      this.setState({
        wikiID: data.id,
        wikiName: data.title ?? "",
        wikiUrl: data.link ?? "",
        wikiTags: data.tag?.split("|") ?? [],
        editorState: editorState,
      });
    }
  };

  //add new wiki
  handleCreateWikiContent = (wikiID: number) => {
    this.setState({ loading: true });

    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "multipart/form-data;",
    };

    const postWikiContent = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createWikiContentApi = postWikiContent.messageId;

    const formData = new FormData();

    formData.append("wiki_content[wiki_id]", wikiID.toString());

    formData.append("wiki_content[file_name]", "name");

    this.state.contentFileResponse?.map((item: ContentFileResponse) => {
      formData.append("wiki_content[images][]", item.file as unknown as Blob);
    });

    createRequestMessage({
      endPoint: configJSON.WikiContent_EndPoint,
      requestMessage: postWikiContent,
      method: configJSON.postApiMethod,
      header: headers,
      body: formData,
      isFormDataRequest: true,
      token: this.state.authToken,
    });
  };

  handleAddWikiContentSuccess = (apiResponse: { data: WikiContent }) => {
    if (apiResponse.data) {
      this.setState({ loading: true, editWikiId: apiResponse.data.wiki_id });

      const { images, wiki } = apiResponse.data;

      const prevWikiContent = wiki.content;

      const { contentFileResponse } = this.state;

      const contentFileMapping = contentFileResponse.map((content) => ({
        ...content,
        url: images.find((item) => item.filename === content.fileName)?.url,
      }));

      const regex = /blob:(https|http):\/\/(.+)\/[0-9a-f-]+/gm;

      let convertContent = prevWikiContent!.replace(regex, (substring) => {
        return (
          contentFileMapping.find((item) => item.objectUrl === substring)
            ?.url ?? ""
        );
      });

      this.setState({ loading: true });

      const headers = {
        "token": this.state.authToken, //static token
        "Content-Type": "application/json",
      };

      const editWikiWithAttachment = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.updateWithAttachmentWikiApi = editWikiWithAttachment.messageId;

      const Data = {
        wiki: {
          title: wiki.title,
          content: convertContent,
          tag: wiki.tag,
          link: "url",
        },
      };

      createRequestMessage({
        endPoint: `${configJSON.Wiki_EndPoint}/${wiki.id}`,
        requestMessage: editWikiWithAttachment,
        method: configJSON.putApiMethod,
        header: headers,
        body: JSON.stringify(Data),
      });
    }
  };

  handleEditWithAttachmentWikiSuccess = (apiResponse: {
    data: WikiContent;
    message: string;
  }) => {
    if (apiResponse.data && apiResponse.message) {
      this.handleGoToListScreen();
      if (this.initialId > 0) {
        this.showAlert("success", apiResponse.message);
      } else {
        this.showAlert("success", "wiki is created successfully");
      }
    }
  };

  handleSubmit = async () => {
    if (this.state.wikiName.length <= 1) {
      this.showAlert("Error", "Wiki name is required");
      return false;
    }

    if (this.state.wikiTags?.length < 1) {
      this.showAlert("Error", "Wiki tags is must be selected");
      return false;
    }

    const rawContentState = convertToRaw(
      this.state.editorState.getCurrentContent(),
    );

    if (draftToHtml(rawContentState)?.length < 3) {
      this.showAlert("Error", "Wiki description is required min 3 character");
      return false;
    }

    await (this.initialId > 0
      ? this.handleUpdateWiki()
      : this.handleCreateWiki());
  };

  //add new wiki
  handleCreateWiki = async () => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": configJSON.apiContentType,
    };
    const postWiki = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createWikiApi = postWiki.messageId;
    const rawContentState = convertToRaw(
      this.state.editorState.getCurrentContent(),
    );
    const Data = {
      wiki: {
        title: this.state.wikiName,
        tag: this.state.wikiTags.join("|"),
        link: "url",
        content: draftToHtml(rawContentState),
      },
    };
    createRequestMessage({
      endPoint: configJSON.Wiki_EndPoint,
      requestMessage: postWiki,
      method: configJSON.postApiMethod,
      header: headers,
      body: JSON.stringify(Data),
    });
  };

  handleCreateWikiSuccess = (apiResponse: {
    data: WikiResponse;
    message: string;
  }) => {
    if (apiResponse.data) {
      if (this.state.contentFileResponse.length > 0) {
        this.handleCreateWikiContent(apiResponse.data.id);
      } else {
        this.handleGoToListScreen();
        this.showAlert("success", apiResponse.message);
      }
    }
  };

  //update the Wiki
  handleUpdateWiki = async () => {
    this.setState({ loading: true });
    const headers = {
      "token": this.state.authToken, //static token
      "Content-Type": "application/json",
    };
    const editWiki = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateWikiApi = editWiki.messageId;
    const rawContentState = convertToRaw(
      this.state.editorState.getCurrentContent(),
    );
    const Data = {
      wiki: {
        title: this.state.wikiName,
        content: draftToHtml(rawContentState),
        tag: this.state.wikiTags.join("|"),
        link: "url",
      },
    };
    createRequestMessage({
      endPoint: `${configJSON.Wiki_EndPoint}/${this.initialId}`,
      requestMessage: editWiki,
      method: configJSON.putApiMethod,
      header: headers,
      body: JSON.stringify(Data),
    });
  };

  handleEditWikiSuccess = (apiResponse: {
    data: WikiResponse;
    message: string;
  }) => {
    if (apiResponse.data) {
      if (this.state.contentFileResponse.length > 0) {
        this.handleCreateWikiContent(apiResponse.data.id);
      } else {
        this.handleGoToListScreen();
        this.showAlert("success", apiResponse.message);
      }
    }
  };

  async componentDidMount() {
    let token = await storage.get("token");

    this.setState({ authToken: token }, () => {
      if (this.initialId > 0) {
        this.handleGetWikiDetail();
      }
    });
  }

  handleOnChange = (text: string, name: string) => {
    this.setState({ ...this.state, [name]: text });
  };

  handleDelete = (text: string) => {
    this.setState({
      wikiTags: this.state.wikiTags.filter((tags) => tags !== text),
    });
  };

  handleWikiTagChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const isAlreadyPresent = this.state.wikiTags.includes(
      String(event.target.value),
    );

    if (!isAlreadyPresent) {
      this.setState({
        wikiTags: [...this.state.wikiTags, String(event.target.value)],
      });
    }
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState });
    const text = editorState?.getCurrentContent()?.getPlainText("\u0001");
    this.setState({ descriptions: text });
  };

  uploadImageCallBack = (file: File) => {
    const fileObjectUrl = URL.createObjectURL(file);
    const convertFile = [
      ...this.state.contentFileResponse.filter(
        (item) => item.objectUrl !== fileObjectUrl,
      ),
      {
        file: file,
        fileName: file.name,
        objectUrl: fileObjectUrl,
      },
    ];
    this.setState({
      contentFileResponse: convertFile,
    });
    return Promise.resolve({ data: { link: fileObjectUrl } });
  };

  handleGoToListScreen() {
    this.props.navigation?.navigate("Wiki2");
  }

  // Customizable Area End
}
