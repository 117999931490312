import {
  CertificateStatusItem,
  EmployeeCertificateStatusItem,
  EmployeeCertificateStatusObject,
  StatusCertificateNumber,
  StatusEmployeeCertificateNumber,
} from "./types";

export const certificateStatusList: CertificateStatusItem[] = [
  {
    id: StatusCertificateNumber.AVAILABLE,
    name: "Available",
    code: "available",
  },
  {
    id: StatusCertificateNumber.NOT_AVAILABLE,
    name: "Unavailable",
    code: "unavailable",
  },
  {
    id: StatusCertificateNumber.DELETABLE,
    name: "Deletable",
    code: "deletable",
  },
  {
    id: StatusCertificateNumber.NOT_DELETABLE,
    name: "Not deletable",
    code: "undeletable",
  },
];

export const employeeCertificateStatusList: EmployeeCertificateStatusItem[] = [
  {
    id: StatusEmployeeCertificateNumber.STARTED,
    name: "Started",
    code: "started",
  },
  {
    id: StatusEmployeeCertificateNumber.CONTINUOUS,
    name: "Continuous",
    code: "cont",
  },
  { id: StatusEmployeeCertificateNumber.DONE, name: "Done", code: "done" },
  {
    id: StatusEmployeeCertificateNumber.REJECTED,
    name: "Rejected",
    code: "rejected",
  },
  {
    id: StatusEmployeeCertificateNumber.CONFIRM,
    name: "Confirm",
    code: "confirm",
  },
  {
    id: StatusEmployeeCertificateNumber.NEED_RETAKE,
    name: "Need retake",
    code: "need_retake",
  },
];

export const employeeCertificateStatusObject: EmployeeCertificateStatusObject =
  {
    started: {
      id: StatusEmployeeCertificateNumber.STARTED,
      name: "Started",
    },
    cont: {
      id: StatusEmployeeCertificateNumber.CONTINUOUS,
      name: "Continuous",
    },
    done: {
      id: StatusEmployeeCertificateNumber.DONE,
      name: "Done",
    },
    rejected: {
      id: StatusEmployeeCertificateNumber.REJECTED,
      name: "Rejected",
    },
    confirm: {
      id: StatusEmployeeCertificateNumber.CONFIRM,
      name: "Confirm",
    },
    need_retake: {
      id: StatusEmployeeCertificateNumber.NEED_RETAKE,
      name: "Need retake",
    },
  };
