import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface ResponseData {
  id: number;
  title: string;
  description: string;
  is_active: boolean;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  status: TaskStatus | string;
  planned_hours: number;
}

interface ResponseTask {
  task_details: ResponseData;
  data: ResponseData;
  message: string;
  errors: [];
}

export const taskStatuses = {
  to_do: "To do",
  in_progress: "In Progress",
  done: "Done",
} as const;
export type TaskStatus = keyof typeof taskStatuses;
// Customizable Area End

interface S {
  // Customizable Area Start
  title: string;
  description: string;
  allocatedTime: string;
  isInternetConnected: boolean;
  token: string;
  status: TaskStatus;
  name: string;
  taskId: string | null;
  statusM: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TimeSheetCreateTaskController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createTaskApiCallId: string = "";
  statusData = [
    { label: "To Do", value: "to_do" },
    { label: "In Progress", value: "in_progress" },
    { label: "Done", value: "done" },
  ];
  getTaskInfoApiCallId: string = "";
  updateTaskApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      title: "",
      description: "",
      allocatedTime: "",
      isInternetConnected: false,
      token: "",
      status: "to_do",
      taskId: "",
      statusM: "to_do",
      name: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse || responseJson.error || responseJson.errors) {
        this.parseApiCatchErrorResponse(errorResponse);
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (this.isPlatformWeb()) {
      const _token = await getStorageData("TOKEN");
      const taskIdWeb = this.props.navigation.getParam("taskId");
      this.setState(
        {
          token: _token,
          isInternetConnected: true,
          taskId: taskIdWeb ? taskIdWeb : "",
        },
        () => {
          this.state.taskId && this.initialGetTaskInfo();
        },
      );
      return;
    }
    NetInfo.addEventListener((state: NetInfoState) => {
      this.setState({
        isInternetConnected: state.isConnected,
      });
    });

    const _token = await getStorageData("TOKEN");

    this.setState({
      token: _token,
    });

    const taskData = this.props.navigation.state.params.taskData;
    if (taskData) {
      const detail = JSON.parse(taskData);
      this.setState({
        taskId: detail.id,
        description: detail.description,
        title: detail.title,
        status: detail.status,
        statusM: detail.status,
        allocatedTime: detail.planned_hours.toString(),
        name: detail.name,
      });
    }
  }

  handleCreateUpdateTaskRes = (responseJson: ResponseData) => {
    if (this.isPlatformWeb()) {
      this.setState({ title: "", description: "", allocatedTime: "" }, () => {
        this.state.taskId === ""
          ? this.props.navigation.navigate("TimeSheetTaskDetails", {
              taskId: responseJson.id,
              taskData: JSON.stringify(responseJson),
            })
          : this.refreshPrevData(responseJson);
      });
    } else {
      this.state.taskId === ""
        ? this.handleScreenNavigation(responseJson)
        : this.refreshPrevData(responseJson);
    }
  };

  handleScreenNavigation = (responseJson: ResponseData) => {
    this.props.navigation.replace("TimeSheetTaskDetails", {
      taskId: responseJson.id,
      taskData: JSON.stringify(responseJson),
    });
    this.props.navigation.state.params.onGoBack();
  };

  handleResponse = (apiRequestCallId: string, responseJson: ResponseTask) => {
    switch (apiRequestCallId) {
      case this.createTaskApiCallId:
        if (responseJson && (responseJson.message || responseJson.errors)) {
          responseJson.message
            ? this.showAlert(
                configJSON.createTaskErrorTitle,
                responseJson.message,
              )
            : this.parseApiErrorResponse(responseJson);
        } else {
          responseJson &&
            this.handleCreateUpdateTaskRes(
              responseJson.task_details || responseJson.data,
            );
        }
        break;
      case this.getTaskInfoApiCallId:
        this.handleGetTaskResponse(responseJson.data);
        break;
      case this.updateTaskApiCallId:
        this.handleUpdateTask(responseJson.data.id, responseJson.message);
        break;
    }
  };

  refreshPrevData(data: ResponseData) {
    this.state.taskId === ""
      ? this.props.navigation.state.params.onGoBack()
      : this.props.navigation.state.params.onGoBack(data);
    this.props.navigation.pop();
  }

  setStatusSelection(item: { label: string; value: string }) {
    this.setState({
      statusM: item.value,
    });
    if (this.state.taskId !== "" && this.isPlatformWeb()) {
      this.initialGetTaskInfo();
    }
  }

  handleGetTaskResponse(response: ResponseData) {
    const { title, description, planned_hours, status } = response;
    this.setState({
      title,
      description,
      allocatedTime: planned_hours.toString(),
      status: status as TaskStatus,
    });
  }

  handleUpdateTask(taskId: number, message: string) {
    if (!taskId) {
      this.showAlert("Task Update", message);

      return;
    }

    this.props.navigation.navigate("TimeSheetTaskDetails", {
      taskId,
    });
  }

  initialGetTaskInfo() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createTaskApiEndPoint}/${this.state.taskId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet,
    );

    this.getTaskInfoApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  txtTitleInputChange = {
    onChangeText: (text: string) => {
      this.setState({ title: text });
    },
  };

  txtDescriptionInputChange = {
    onChangeText: (text: string) => {
      this.setState({ description: text });
    },
  };

  txtAllocatedTimeInputChange = {
    onChangeText: (text: string) => {
      this.setState({ allocatedTime: text.replace(/\D/g, "") });
    },
  };

  handleStatusInputChange(value: TaskStatus) {
    this.setState({ status: value });
  }

  cancelTasks = () => {
    if (this.isPlatformWeb()) {
      return this.props.navigation.goBack();
    }

    this.props.navigation.pop();
  };

  checkValidation = () => {
    const { title, description, allocatedTime } = this.state;

    if (title.trim().length < 3) {
      this.showAlert(
        configJSON.createTaskErrorTitle,
        configJSON.createTaskErrorTitleMessage,
      );
      return;
    }

    if (description.trim().length < 3) {
      this.showAlert(
        configJSON.createTaskErrorTitle,
        configJSON.createTaskErrorDescriptionMessage,
      );
      return;
    }

    if (allocatedTime.trim().length === 0) {
      this.showAlert(
        configJSON.createTaskErrorTitle,
        configJSON.createTaskErrorAllocatedTimeMessage,
      );
      return;
    }

    if (this.state.taskId && this.isPlatformWeb()) {
      this.updateTask();
      return;
    }

    this.createTasks();
  };

  updateTask = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const httpBody: object = {
      task: {
        title: this.state.title,
        description: this.state.description,
        planned_hours: this.state.allocatedTime,
        status: this.state.status,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createTaskApiEndPoint}/${this.state.taskId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut,
    );

    this.updateTaskApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createTasks = async () => {
    if (!this.state.isInternetConnected) {
      this.showAlert(
        configJSON.createTaskErrorTitle,
        configJSON.checkInternetConnection,
      );
    } else {
      const { taskId, title, description, allocatedTime, statusM } = this.state;
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );
      this.createTaskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        taskId === ""
          ? configJSON.createTaskApiEndPoint
          : `${configJSON.createTaskApiEndPoint}/${taskId}`,
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header),
      );

      const httpBody: object = {
        task: {
          title: title,
          description: description,
          planned_hours: allocatedTime,
          status: statusM,
        },
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody),
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        taskId === ""
          ? configJSON.apiMethodTypePost
          : configJSON.apiMethodTypePut,
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  // Customizable Area End
}
