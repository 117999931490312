interface Colors {
  primary: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  black: string;
  purple: string;
}

const colors: Colors = {
  primary: {
    100: "#FFFFFF",
    200: "#EFEFEF",
    300: "#DADADA",
    400: "#818181",
    500: "#6F767E",
    600: "#404B53",
    700: "#232830",
    800: "#1A1C22",
    900: "#050A0E",
  },
  black: "#000",
  purple: "#5568FE",
};

export default colors;
