Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "bx_block_content_moderation/moderation";
exports.exampleWebAPiEndPoint = "bx_block_content_moderation/moderation";

exports.exampleAPiMethod = "POST";
exports.postMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Content Moderation";
exports.labelBodyText = "Content Moderation Body";
exports.validationApiMethodTypeUpdate = "PUT";
exports.btnExampleTitle = "CLICK ME";
exports.DeleteApiMethodType = "DELETE";
exports.InsertApiMethodType = "POST";
exports.loginEmail = "test11@gmail.com";
exports.loginPassword = "Password@123";
exports.loginEndpoint = "bx_block_login/logins";
// Customizable Area End
