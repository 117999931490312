import React from "react";

import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import TimeSheetTaskDetailsController, {
  Props,
  configJSON,
} from "./TimeSheetTaskDetailsController";
import Title from "./components/Title.web";
import moment from "moment";

export default class TimeSheetTaskDetails extends TimeSheetTaskDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      logList,
      taskDetailWeb: {
        title: taskTitle,
        description: taskDescription,
        status,
        planned_hours,
      },
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Title
          data-test-id="title"
          title="Task details"
          onBackClick={() => this.redirectToTaskList()}
        />
        <Container maxWidth="sm">
          <Box sx={webStyle.mainWrapper}>
            <Box style={webStyle.container}>
              <Box sx={webStyle.flexWrapper}>
                <Box sx={webStyle.nameWrapper}>
                  <Typography variant="h6">{taskTitle}</Typography>
                  <Typography color="textSecondary" variant="body2">
                    {`${configJSON.labelTotalAllocatedTime}${planned_hours}Hours`}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {`${configJSON.labelTotalConsumedTime}${this.getSplitTime(
                      this.state.totalLoggedHours,
                    )}`}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {`${configJSON.labelTaskStatus} ${status}`}
                  </Typography>
                </Box>
              </Box>
              <Typography color="textSecondary" style={{ marginTop: "4px" }}>
                {configJSON.labelDescriptionDots} {taskDescription}
              </Typography>
            </Box>
            <Box style={webStyle.logBox}>
              <Typography style={{ fontSize: "20px" }}>All Logs</Typography>
              <Button
                variant="contained"
                color="primary"
                data-test-id="createLog"
                onClick={() => this.openCreateLog()}>
                {configJSON.createLog}
              </Button>
            </Box>
            <Box>
              {logList.length ? (
                logList.map(
                  ({
                    id,
                    account_id,
                    description,
                    hours,
                    title,
                    start_time,
                  }) => (
                    <Card
                      key={id}
                      data-test-id="card"
                      variant="outlined"
                      style={webStyle.userCard}
                      data-testId="redirectUser"
                      onClick={() => this.redirectToUser(account_id)}
                      color="primary">
                      <CardContent>
                        <Typography color="primary" variant="h6">
                          {title}
                        </Typography>
                        <Typography>{description}</Typography>
                        <Typography variant="body2">
                          {configJSON.labelTotalWorkedHours}
                          <Typography color="textSecondary" component="span">
                            {hours}
                          </Typography>
                        </Typography>
                        <Typography variant="body2">
                          {configJSON.labelStartDate}
                          <Typography color="textSecondary" component="span">
                            {moment(start_time).format("YYYY-MM-DD")}
                          </Typography>
                        </Typography>
                        <Typography variant="body2">
                          {configJSON.labelAccountId}{" "}
                          <Typography color="textSecondary" component="span">
                            {account_id}
                          </Typography>
                        </Typography>
                      </CardContent>
                    </Card>
                  ),
                )
              ) : (
                <Typography style={webStyle.noLogMessage}>
                  No Logs exists
                </Typography>
              )}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    boxShadow: "2px 2px 2px 2px #979797",
    borderRadius: "10px",
    marginLeft: "9px",
    padding: "10px 0px 10px 25px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
    gap: 32,
  },
  flexWrapper: {
    with: "100%",
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  nameWrapper: {
    flex: "1 0 auto",
  },
  userCard: {
    background: "#F3F0FF",
    marginBottom: 16,
    cursor: "pointer",
  },
  logBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  noLogMessage: {
    fontSize: "20px",
    textAlign: "center",
  },
} as Record<string, Record<string, string | number>>;
// Customizable Area End
