import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AllEmployeeLeaveListController, {
  Props,
} from "./AllEmployeeLeaveListController.web";

// Customizable Area End

export default class AllEmployeeLeaveList extends AllEmployeeLeaveListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={webStyle.container}>
        <div style={webStyle.header}>
          <div style={webStyle.title}>Employee List</div>
        </div>
        <TableContainer data-test-id="tableContainer" component={Paper}>
          {this.state.isLoading ? (
            <div>Loading...</div>
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={webStyle.tableHead}>
                    Employee Name
                  </TableCell>
                  <TableCell style={webStyle.tableHead} align="center">
                    All Months
                  </TableCell>
                  <TableCell style={webStyle.tableHead} align="center">
                    LastMonth
                  </TableCell>
                  <TableCell style={webStyle.tableHead} align="center">
                    %Present
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.allEmployeeLeaveListData.map((item, index) => (
                  <TableRow key={`${item.attributes?.id}-${index}`}>
                    <TableCell component="th" scope="row">
                      {item.attributes.employee_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.attributes.all_months}
                    </TableCell>
                    <TableCell align="center">
                      {item.attributes.last_months}
                    </TableCell>
                    <TableCell align="center">
                      {item.attributes.present} %
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <BottomPagination
          data-test-id="paginationButton"
          count={this.state.totalPageWeb}
          page={this.state.pageIndexWeb}
          onChange={this.handleChangePage}
          shape={"round"}
        />
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    flexDirection: "column" as const,
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    paddingBottom: "30px",
    background: "#fff",
    width: "100%",
  },
  tableContainer: {
    width: "80%",
  },
  header: {
    width: "100%",
    display: "flex",
    backgroundColor: "#826ffc",
    height: 56,
    justifyContent: "center",
    flexDirection: "row" as const,
    alignItems: "center",
    paddingLeft: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold" as const,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "center" as const,
    color: "#ffffff",
  },
  imageStyle: {
    width: 50,
    height: 50,
  },
  titleStyle: {
    marginBottom: 20,
  },
  tableHead: {
    color: "rgb(160,78,236)",
  },
};

const BottomPagination = styled(Pagination)({
  marginTop: 16,
});
// Customizable Area End
