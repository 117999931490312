import React, { Component } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { IProductList, ContinentList } from "./types";

type IProps = {
  item: IProductList;
  handleAddToCart?: (item: IProductList) => void;
  checkInCart?: (item: IProductList) => void;
  handleRemoveFromCart?: (id: string) => void;
  handleOpenModifyShippingDetailDialog?: (value: "modify", id?: string) => void;
  view: "product" | "cart";
  continents?: ContinentList[];
  countries?: { country: string; continentName: string }[];
};

type IState = {};

class ProductItem extends Component<IProps, IState> {
  render() {
    const {
      item,
      handleOpenModifyShippingDetailDialog,
      handleAddToCart,
      handleRemoveFromCart,
      view,
    } = this.props;

    return (
      <Box
        className="product-item"
        key={item.id}
        data-test-id="product-item"
        sx={webStyle.productItem}>
        <Box sx={webStyle.productTitle}>
          <Typography
            variant="h6"
            className="product-item-name"
            style={webStyle.productName}>
            {item.attributes.product_name}
          </Typography>
          <Typography className="product-item-price">
            {`Price: $${item.attributes.price}`}
          </Typography>
        </Box>
        <Typography variant="body2" className="product-item-description">
          {item.attributes.product_description}
        </Typography>
        {view === "product" ? (
          <Box sx={webStyle.productActions}>
            <Button
              data-test-id="add-to-cart-button"
              className="product-action-button"
              onClick={() => handleAddToCart!(item)}>
              Add to Cart
            </Button>
            <Button
              onClick={() =>
                handleOpenModifyShippingDetailDialog!("modify", item.id)
              }
              className="product-action-button"
              data-test-id="modify-shipping-details-button">
              Modify the Shipping Details
            </Button>
          </Box>
        ) : (
          <>
            <Button
              onClick={() => handleRemoveFromCart!(item.id)}
              className="product-action-button"
              data-test-id="remove-item-button">
              Remove
            </Button>
            {!item.attributes.shipped && (
              <Typography style={webStyle.alertMsg}>
                Product can't shipped to your address
              </Typography>
            )}
          </>
        )}
      </Box>
    );
  }
}

export default ProductItem;

const webStyle = {
  productListBody: {
    marginTop: 50,
  },
  alertMsg: {
    color: "red",
    marginTop: "12px",
  },
  productItem: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#eddded",
    borderRadius: 10,
    marginBottom: 10,
  },
  productActions: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    marginTop: 16,
  },
  productTitle: {
    display: "flex",
    alignItems: "center",
  },
  productName: {
    flex: "1 0 auto",
  },
  dialogTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
};
