export enum StatusCertificateNumber {
  AVAILABLE,
  NOT_AVAILABLE,
  DELETABLE,
  NOT_DELETABLE,
}

export enum StatusEmployeeCertificateNumber {
  STARTED,
  CONTINUOUS,
  DONE,
  REJECTED,
  CONFIRM,
  NEED_RETAKE,
}

export interface Employee {
  id?: number;
  employee_name?: string | null;
  employee_picture?: string | null;
  employee_thumbnail?: string | null;
  is_active?: boolean | null;
  department?: Department | null;
  departmentId?: number | null;
  designation?: Designation | null;
  designationId?: number | null;
  managerId?: number | null;
  manager?: object | null;
}

export interface EmployeeResponse {
  data?: Employee;
  message?: string;
}

export interface Certificate {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  status?: number | string | null;
  is_active?: boolean | null;
}

export interface EmployeeCertificate {
  id?: number | null;
  employee?: Employee | null;
  employee_id?: number | null;
  certificate_id?: number | null;
  certificate?: Certificate | null;
  status?: string | number | null;
  date?: Date | string | null;
  validity_date?: Date | string | null;
  is_active?: boolean | null;
}

export interface EmployeeCertificateResponse {
  data?: EmployeeCertificate;
  message?: string;
}

export interface Department {
  id?: number | null;
  name?: string | null;
  parent_department_id?: number | null;
  parent_department_name?: string | null;
  is_active?: boolean;
}

export interface Designation {
  id?: number | null;
  name?: string | null;
  is_active?: boolean;
}

export interface CertificateStatusItem {
  id: StatusCertificateNumber;
  name: "Available" | "Unavailable" | "Deletable" | "Not deletable";
  code: "available" | "unavailable" | "deletable" | "undeletable";
}

export interface EmployeeCertificateStatusItem {
  id?: number;
  name?: string;
  code?: string;
}

export type CertificateStatusKey =
  | "started"
  | "cont"
  | "done"
  | "rejected"
  | "confirm"
  | "need_retake";

type CertificateStatusName =
  | "Started"
  | "Continuous"
  | "Done"
  | "Rejected"
  | "Confirm"
  | "Need retake";

export type EmployeeCertificateStatusObject = {
  [key in CertificateStatusKey]: {
    id: StatusEmployeeCertificateNumber;
    name: CertificateStatusName;
  };
};

export type CertificationMappingObject = {
  [id: string]: Certificate;
};

export type ResponseType = {
  error?: string | object;
  errors?: string | object;
};
