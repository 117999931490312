// App.js - WEB
import firebase from 'firebase';
import React, { Component } from 'react';
import { View } from 'react-native';

import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import TopNav from '../../components/src/TopNav';

import AccountGroups from '../../blocks/AccountGroups/src/AccountGroups.web';
import ActivityFeed from '../../blocks/ActivityFeed/src/ActivityFeed';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Analytics from '../../blocks/analytics/src/Analytics';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import BlogImporting from '../../blocks/BlogImporting/src/BlogImporting';
import BreadcrumbNavigation from '../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation';
import BudgetingForecasting from '../../blocks/BudgetingForecasting/src/BudgetingForecasting';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import CarouselDisplay from '../../blocks/carouseldisplay/src/CarouselDisplay';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CentralisedBilling from '../../blocks/CentralisedBilling/src/CentralisedBilling';
import CertificateInsert from '../../blocks/CertificationTracking/src/CertificateInsert/CertificateInsert';
import CertificateList from '../../blocks/CertificationTracking/src/CertificateList/CertificateList';
import CertificationTracking from '../../blocks/CertificationTracking/src/CertificationTracking';
import EmployeeCertCreate from '../../blocks/CertificationTracking/src/EmployeeCertCreate/EmployeeCertCreate';
import EmployeeCertEmployeeList from '../../blocks/CertificationTracking/src/EmployeeCertEmployeeList/EmployeeCertEmployeeList';
import EmployeeCertList from '../../blocks/CertificationTracking/src/EmployeeCertList/EmployeeCertList';
import EmployeeCertStatus from '../../blocks/CertificationTracking/src/EmployeeCertStatus/EmployeeCertStatus';
import EmployeeCreate from '../../blocks/CertificationTracking/src/EmployeeCreate/EmployeeCreate';
import EmployeeList from '../../blocks/CertificationTracking/src/EmployeeList/EmployeeList';
import Chat9 from '../../blocks/Chat9/src/Chat9';
import CollectTransactionFees from '../../blocks/CollectTransactionFees/src/CollectTransactionFees';
import CreateComment from '../../blocks/comments/src/CreateComment';
import ContentFlag from '../../blocks/ContentFlag/src/ContentFlag';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';

import ContentModeration from '../../blocks/ContentModeration/src/ContentModeration';

import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Customform from '../../blocks/customform/src/Customform';
import CvresumeCandidateManagement2 from '../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2';
import CvresumeCandidateList from '../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateList';
import CvresumeCandidateTemplateList from '../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateTemplateList.web';
import DataEncryption from '../../blocks/DataEncryption/src/DataEncryption';
import DocumentOpener from '../../blocks/documentopener/src/DocumentOpener';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import EmergencySos from '../../blocks/EmergencySos/src/EmergencySos';
import ExpenseTracking from '../../blocks/ExpenseTracking/src/ExpenseTracking.web';
import FeedbackCollection from '../../blocks/FeedbackCollection/src/FeedbackCollection';
import FileAttachment from '../../blocks/FileAttachment/src/FileAttachmentList.web';
import FileAttachmentUpload from '../../blocks/FileAttachment/src/FileUpload.web';
import GoalCreate from '../../blocks/GoalManagement/src/GoalCreate/GoalCreate';
import GoalDetail from '../../blocks/GoalManagement/src/GoalDetail/GoalDetail';
import GoalManagement from '../../blocks/GoalManagement/src/GoalManagement';
import GoalRetrieve from '../../blocks/GoalManagement/src/GoalRetrieve/GoalRetrieve';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import ItemAvailability from '../../blocks/ItemAvailability/src/ItemAvailability';
import LanguageDetection from '../../blocks/LanguageDetection/src/LanguageDetection';
import Leaderboard from '../../blocks/Leaderboard/src/Leaderboard';
import LeaveTracker from '../../blocks/LeaveTracker/src/LeaveTracker';
import LeaveTrackerHome from '../../blocks/LeaveTracker/src/LeaveTrackerHome.web';
import CreateLeave from '../../blocks/LeaveTracker/src/CreateLeave.web';
import LeaveRequest from '../../blocks/LeaveTracker/src/LeaveRequest.web';
import AllEmployeeLeaveList from '../../blocks/LeaveTracker/src/AllEmployeeLeaveList.web';
import LiveStreaming from '../../blocks/LiveStreaming/src/LiveStreaming';
import LocationbasedAlerts from '../../blocks/LocationbasedAlerts/src/LocationbasedAlerts';
import LogoDesign2 from '../../blocks/LogoDesign2/src/LogoDesign2';
import ManageBlogComments from '../../blocks/ManageBlogComments/src/ManageBlogComments';
import MatchAlgorithm2 from '../../blocks/MatchAlgorithm2/src/MatchAlgorithm2';
import Mentionstagging from '../../blocks/Mentionstagging/src/Mentionstagging';
import MentionstaggingAddPost from '../../blocks/Mentionstagging/src/MentionstaggingAddPost';
import MentionstaggingList from '../../blocks/Mentionstagging/src/MentionstaggingList';
import MentionstaggingPostDetails from '../../blocks/Mentionstagging/src/MentionstaggingPostDetails';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import OrganisationHierarchy from '../../blocks/OrganisationHierarchy/src/OrganisationHierarchy';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import PasswordProtectedPages from '../../blocks/PasswordProtectedPages/src/PasswordProtectedPages';
import PayrollIntegration2 from '../../blocks/PayrollIntegration2/src/PayrollIntegration2';
import PinConversation from '../../blocks/PinConversation/src/PinConversation';
import FundraiserCreationForm from '../../blocks/PledgeTracking/src/FundraiserCreationForm';
import FundraiserDetails from '../../blocks/PledgeTracking/src/FundraiserDetails';
import FundraiserList from '../../blocks/PledgeTracking/src/FundraiserList';
import PledgeCreationForm from '../../blocks/PledgeTracking/src/PledgeCreationForm';
import PledgeList from '../../blocks/PledgeTracking/src/PledgeList';
import VideoBackgroundInput from '../../blocks/VideoBackgrounds/src/VideoInput.web';
import VideoPageList from '../../blocks/VideoBackgrounds/src/VideoPageList.web';
import VideoBackgroundEdit from '../../blocks/VideoBackgrounds/src/VideoBackgroundEdit.web';
import PledgeTracking from '../../blocks/PledgeTracking/src/PledgeTracking';
import PortfolioManagement from '../../blocks/PortfolioManagement/src/PortfolioManagement';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import Posts from '../../blocks/postcreation/src/Posts';
import PrivacySettings from '../../blocks/PrivacySettings/src/PrivacySettings';
import Referrals from '../../blocks/Referrals/src/Referrals';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import ScreenSharing from '../../blocks/ScreenSharing/src/ScreenSharing';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import SpendAnalysis from '../../blocks/SpendAnalysis/src/SpendAnalysis';
import SpendItem from '../../blocks/SpendAnalysis/src/SpendItem';
import SummaryCard from '../../blocks/SummaryCard/src/SummaryCard';
import TaxCalculator from '../../blocks/TaxCalculator/src/TaxCalculator';
import VideoBackgrounds from '../../blocks/VideoBackgrounds/src/VideoBackgrounds';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Wiki2 from '../../blocks/Wiki2/src/Wiki2';
import LocalinternationalShipping from '../../blocks/LocalinternationalShipping/src/LocalInternationalShipping';
import TimesheetManagement from '../../blocks/TimesheetManagement/src/TimesheetManagement';
import TimeSheetTimeManagementList from '../../blocks/TimesheetManagement/src/TimeSheetTimeManagementList';
import TimeSheetTaskList from '../../blocks/TimesheetManagement/src/TimeSheetTaskList.web';
import TimeSheetCreateTask from '../../blocks/TimesheetManagement/src/TimeSheetCreateTask';
import TimeSheetTaskDetails from '../../blocks/TimesheetManagement/src/TimeSheetTaskDetails';
import TimeSheetWorkerLogs from '../../blocks/TimesheetManagement/src/TimeSheetWorkerLogs';
import TimeSheetCreateLog from '../../blocks/TimesheetManagement/src/TimeSheetCreateLog';
import WikiCreate from '../../blocks/Wiki2/src/WikiCreate.web';
import WikiRevision from '../../blocks/Wiki2/src/WikiRevision.web';
import CreateContentWeb from '../../blocks/ContentModeration/src/CreateContentWeb.web';
import ContentListWeb from '../../blocks/ContentModeration/src/ContentListWeb.web';
const routeMap = {
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups',
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics',
  },
  FeedbackCollection: {
    component: FeedbackCollection,
    path: '/FeedbackCollection',
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: '/CarouselDisplay',
  },
  PortfolioManagement: {
    component: PortfolioManagement,
    path: '/PortfolioManagement',
  },
  ContentFlag: {
    component: ContentFlag,
    path: '/ContentFlag',
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: '/OrganisationHierarchy',
  },
  BreadcrumbNavigation: {
    component: BreadcrumbNavigation,
    path: '/BreadcrumbNavigation',
  },
  LanguageDetection: {
    component: LanguageDetection,
    path: '/LanguageDetection',
  },
  PinConversation: {
    component: PinConversation,
    path: '/PinConversation',
  },
  Leaderboard: {
    component: Leaderboard,
    path: '/Leaderboard',
  },
  TaxCalculator: {
    component: TaxCalculator,
    path: '/TaxCalculator',
  },
  Mentionstagging: {
    component: Mentionstagging,
    path: '/Mentionstagging',
  },
  MentionstaggingAddPost: {
    component: MentionstaggingAddPost,
    path: '/MentionstaggingAddPost',
  },
  MentionstaggingPostDetails: {
    component: MentionstaggingPostDetails,
    path: '/MentionstaggingPostDetails',
  },
  MentionstaggingList: {
    component: MentionstaggingList,
    path: '/MentionstaggingList',
  },
  LogoDesign2: {
    component: LogoDesign2,
    path: '/LogoDesign2',
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch',
  },
  CentralisedBilling: {
    component: CentralisedBilling,
    path: '/CentralisedBilling',
  },
  BlogImporting: {
    component: BlogImporting,
    path: '/BlogImporting',
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth',
  },
  PledgeTracking: {
    component: PledgeTracking,
    path: '/PledgeTracking',
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: '/PrivacySettings',
  },
  LocationbasedAlerts: {
    component: LocationbasedAlerts,
    path: '/LocationbasedAlerts',
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling',
  },
  BudgetingForecasting: {
    component: BudgetingForecasting,
    path: '/BudgetingForecasting',
  },
  VideoBackgrounds: {
    component: VideoBackgrounds,
    path: '/VideoBackgrounds',
  },
  VideoBackgroundInput: {
    component: VideoBackgroundInput,
    path: '/VideoBackgroundInput',
  },
  VideoPageList: {
    component: VideoPageList,
    path: '/VideoPageList',
  },
  VideoBackgroundEdit: {
    component: VideoBackgroundEdit,
    path: '/VideoBacgkroundEdit/:id?',
  },

  Customform: {
    component: Customform,
    path: '/Customform',
  },
  ExpenseTracking: {
    component: ExpenseTracking,
    path: '/ExpenseTracking',
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: '/ActivityFeed',
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener',
  },
  ContentModeration: {
    component: ContentModeration,
    path: '/ContentModeration',
  },
  LiveStreaming: {
    component: LiveStreaming,
    path: '/LiveStreaming',
  },
  ScreenSharing: {
    component: ScreenSharing,
    path: '/ScreenSharing',
  },
  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: '/CollectTransactionFees',
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics',
  },
  EmergencySos: {
    component: EmergencySos,
    path: '/EmergencySos',
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation',
  },
  Posts: {
    component: Posts,
    path: '/Posts',
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails',
  },
  SpendAnalysis: {
    component: SpendAnalysis,
    path: '/SpendAnalysis',
  },
  SpendItem: {
    component: SpendItem,
    path: '/SpendItem',
  },
  ItemAvailability: {
    component: ItemAvailability,
    path: '/ItemAvailability',
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals',
  },
  GoalManagement: {
    component: GoalManagement,
    path: '/GoalManagement',
    exact: true,
  },
  GoalDetail: {
    component: GoalDetail,
    path: '/GoalManagement/detail/:goalId',
  },
  GoalRetrieve: {
    component: GoalRetrieve,
    path: '/GoalManagement/retrieve',
  },
  GoalCreate: {
    component: GoalCreate,
    path: '/GoalManagement/create/:goalId?',
  },
  MatchAlgorithm2: {
    component: MatchAlgorithm2,
    path: '/MatchAlgorithm2',
  },
  ManageBlogComments: {
    component: ManageBlogComments,
    path: '/ManageBlogComments',
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading',
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling',
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement',
  },
  CertificationTracking: {
    component: CertificationTracking,
    path: '/CertificationTracking',
    exact: true,
  },
  EmployeeList: {
    component: EmployeeList,
    path: '/CertificationTracking/employee/list',
  },
  EmployeeCreate: {
    component: EmployeeCreate,
    path: '/CertificationTracking/employee/create/:id?',
  },
  CertificateList: {
    component: CertificateList,
    path: '/CertificationTracking/certificate/list',
  },
  CertificateInsert: {
    component: CertificateInsert,
    path: '/CertificationTracking/certificate/create/:id?',
  },
  EmployeeCertificateMappingList: {
    component: EmployeeCertList,
    path: '/CertificationTracking/employee-certificate/list',
  },

  EmployeeCertificateMappingCreate: {
    component: EmployeeCertCreate,
    path: '/CertificationTracking/employee-certificate/create/:id?',
  },
  EmployeeCertificateEmployeeList: {
    component: EmployeeCertEmployeeList,
    path: '/CertificationTracking/employee-cert-status/list',
  },
  EmployeeCertificateChangeStatus: {
    component: EmployeeCertStatus,
    path: '/CertificationTracking/employee-cert-status/change/:id?',
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector',
  },
  DataEncryption: {
    component: DataEncryption,
    path: '/DataEncryption',
  },
  CvresumeCandidateManagement2: {
    component: CvresumeCandidateManagement2,
    path: '/CvresumeCandidateManagement2',
  },
  CvresumeCandidateList: {
    component: CvresumeCandidateList,
    path: '/PublicResumes',
  },
  CvresumeCandidateTemplateList: {
    component: CvresumeCandidateTemplateList,
    path: '/TemplateList',
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput',
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm',
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen',
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole',
  },
  PayrollIntegration2: {
    component: PayrollIntegration2,
    path: '/PayrollIntegration2',
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue',
  },
  Chat9: {
    component: Chat9,
    path: '/Chat9',
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest',
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2',
  },
  FileAttachment: {
    component: FileAttachment,
    path: '/FileAttachment',
  },
  FileAttachmentUpload: {
    component: FileAttachmentUpload,
    path: '/FileAttachmentUpload',
  },
  LocalinternationalShipping: {
    component: LocalinternationalShipping,
    path: '/LocalinternationalShipping',
  },
  Wiki2: {
    component: Wiki2,
    path: '/Wiki2',
    exact: true,
  },
  LeaveTracker: {
    component: LeaveTracker,
    path: '/LeaveTracker',
  },
  LeaveTrackerHome: {
    component: LeaveTrackerHome,
    path: '/LeaveTrackerHome',
  },
  CreateLeave: {
    component: CreateLeave,
    path: '/CreateLeave',
  },
  LeaveRequest: {
    component: LeaveRequest,
    path: '/LeaveRequest',
  },
  AllEmployeeLeaveList: {
    component: AllEmployeeLeaveList,
    path: '/AllEmployeeLeaveList',
  },
  PasswordProtectedPages: {
    component: PasswordProtectedPages,
    path: '/PasswordProtectedPages',
  },
  SummaryCard: {
    component: SummaryCard,
    path: '/SummaryCard',
  },
  Comment: {
    component: Comment,
    path: '/Comment',
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment',
  },
  TimesheetManagement: {
    component: TimesheetManagement,
    path: '/TimesheetManagement',
  },
  TimeSheetTimeManagementList: {
    component: TimeSheetTimeManagementList,
    path: '/TimeSheetTimeManagementList',
  },
  TimeSheetTaskList: {
    component: TimeSheetTaskList,
    path: '/TimeSheetTaskList',
  },
  TimeSheetCreateTask: {
    component: TimeSheetCreateTask,
    path: '/TimeSheetTask/:taskId?',
  },
  TimeSheetTaskDetails: {
    component: TimeSheetTaskDetails,
    path: '/TimeSheetTaskDetails/:taskId?',
  },
  TimeSheetWorkerLogs: {
    component: TimeSheetWorkerLogs,
    path: '/TimeSheetWorkerLogs/:userId',
  },
  TimeSheetCreateLog: {
    component: TimeSheetCreateLog,
    path: '/TimeSheetCreateLog/:taskId?/:logId?/:isUpdate?/:userId?',
  },
  FundraiserCreationForm: {
    component: FundraiserCreationForm,
    path: '/FundraiserForm/:fundraiserId?',
  },
  FundraiserList: {
    component: FundraiserList,
    path: '/FundraiserList',
    exact: true,
  },
  FundraiserDetails: {
    component: FundraiserDetails,
    path: '/FundraiserList/detail/:fundraiserId',
  },
  PledgeCreationForm: {
    component: PledgeCreationForm,
    path: '/PledgeForm/:fundraiserId/:plegdeId?',
  },
  PledgeList: {
    component: PledgeList,
    path: '/PledgeList/:fundraiserId',
  },
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  Revision: {
    component: WikiRevision,
    path: '/Revision',
  },
  WikiCreate: {
    component: WikiCreate,
    path: '/Wiki2/create/:wikiId?',
  },
  CreateContentWeb: {
    component: CreateContentWeb,
    path: '/CreateContentWeb',
  },
  ContentListWeb: {
    component: ContentListWeb,
    path: '/ContentListWeb',
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true,
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
