import React from "react";
import moment from "moment";

import {
  Container,
  Box,
  Card,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader";
// Customizable Area End

import FundraiserDetailsController, {
  Props,
} from "./FundraiserDetailsController.web";
import TitleComponent from "./components/Title/TitleComponent.web";

const theme = createTheme({});

export default class FundraiserDetails extends FundraiserDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  formatDate(date?: string) {
    return moment(date).format("DD.MM.YYYY");
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <TitleComponent
          title="Fundraiser details"
          data-testId="navigate_page"
          onBackClick={() => this.goToFundraiserList()}
        />
        <Container maxWidth="sm">
          {this.state.loading ? (
            <Loader loading />
          ) : (
            <>
              <Box sx={webStyle.mainWrapper}>
                <Typography variant="h5">{this.state.title}</Typography>

                <div style={webStyle.profile}>
                  <div style={webStyle.nameWrapper}>
                    <Typography variant="caption" component="p">
                      {this.state.creator && this.state.creator.name}
                    </Typography>
                    <Typography variant="caption" component="p">
                      {this.state.creator && this.state.creator.status}
                    </Typography>
                    <Typography variant="caption" component="p">
                      {`Fund created: ${this.formatDate(this.state.createdAt)}`}
                    </Typography>
                  </div>
                  <Button
                    data-test-id="edit-button"
                    onClick={() => this.redirectToEdit()}>
                    Edit
                  </Button>
                </div>

                <Card variant="outlined" style={webStyle.card}>
                  <Typography variant="body1">Total Amount Details</Typography>
                  <Typography variant="body2">
                    Fund Requested: ${this.state.amount}
                  </Typography>
                  <Typography variant="body2">
                    Fund Gathered: ${this.state.pledgedAmount || 0}
                  </Typography>
                </Card>

                <Button
                  data-test-id="btn-pledge"
                  style={webStyle.buttonAdd}
                  variant="outlined"
                  onClick={() => this.goToPledgeCreation()}>
                  Add pledge
                </Button>
              </Box>

              {Boolean(this.state.pledgeList.length) && (
                <Box sx={webStyle.secondaryWrapper}>
                  <Typography variant="body1">
                    {" "}
                    Previous Pledges made to investors
                  </Typography>
                  {this.state.pledgeList.map((pledge) => (
                    <Card
                      variant="outlined"
                      style={webStyle.card}
                      key={pledge.attributes.title}>
                      <Typography variant="body2" style={webStyle.pledgeTitle}>
                        {pledge.attributes.title}
                      </Typography>
                      <Typography variant="body2">
                        Amount: ${pledge.attributes.amount}
                      </Typography>
                      <Typography variant="body2">
                        Date: {this.formatDate(pledge.attributes.created_at)}
                      </Typography>
                    </Card>
                  ))}

                  <Button
                    data-test-id="btn-more"
                    variant="outlined"
                    style={webStyle.buttonMore}
                    size="small"
                    fullWidth
                    onClick={() => this.goToPledgeList()}>
                    View more
                  </Button>
                </Box>
              )}
            </>
          )}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    display: "flex",
    gap: 24,
    flexDirection: "column",
    alignItems: "stretch",
    paddingBottom: 32,
    paddingTop: 24,
    background: "#fff",
  },
  secondaryWrapper: {
    paddingBottom: 32,
  },
  nameWrapper: {
    flex: "1 1 auto",
  },
  card: {
    padding: "20",
    marginTop: "16",
  },
  avatar: {
    width: 54,
    height: 54,
  },
  profile: {
    display: "flex",
    gap: 24,
  },
  pledgeTitle: {
    color: "#7F70F4",
  },
  buttonAdd: {
    background: "#7F70F4",
    color: "white",
  },
  buttonMore: {
    marginTop: 12,
  },
};
// Customizable Area End
