Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.exampleApiContentType = "application/json";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PasswordProtectedPages";
exports.labelBodyText = "PasswordProtectedPages Body";

exports.btnExampleTitle = "CLICK ME";

exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";

exports.protectedURLEndPoint =
  "/bx_block_password_protected_page/verify_password";

exports.loginURLEndPoint = "bx_block_login/logins";
exports.loginEmail = "buraktest@gmail.com";
exports.loginPassword = "123456";

// Customizable Area End
