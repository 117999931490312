import React from "react";

import { Box, Button, Container, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Wiki2Controller, { Props } from "./Wiki2Controller.web";
import { WikiResponse } from "./Types";
import Loader from "../../../components/src/Loader.web";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#000040",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class Wiki2 extends Wiki2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  _renderAllWikis = (wiki: WikiResponse, index: number) => {
    return (
      <Box sx={webStyle.card} key={wiki.id}>
        <Box
          component={"section"}
          data-test-id={`edit-wikiID-${index}`}
          sx={webStyle.sectionStyle}
          onClick={() => this.handleGoToCreateScreen(wiki)}>
          <Typography component={"h4"}>{wiki.title}</Typography>
        </Box>
        <Button
          data-test-id="goToRevision"
          variant="outlined"
          onClick={() => this.goToRevision(wiki.id)}>
          Revision
        </Button>
        <Button
          data-test-id={`delete-wikiID-${index}`}
          variant="contained"
          style={webStyle.buttonStyle}
          onClick={() => this.handleDeleteWikiWeb(wiki.id)}>
          Delete
        </Button>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    if (this.state.loading) {
      return <Loader loading />;
    }
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography>Wiki</Typography>
            <Box sx={webStyle.width80}>
              {this.state.wikis.map((wiki: WikiResponse, index: number) =>
                this._renderAllWikis(wiki, index),
              )}
            </Box>
            <Box sx={webStyle.newButtonStyle}>
              <Button
                data-test-id="newButton"
                variant="contained"
                style={webStyle.buttonNew}
                onClick={() => this.handleGoToCreateScreen()}>
                New
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    position: "relative",
  },
  buttonStyle: {
    backgroundColor: "rgb(255, 0, 0,.6)",
    color: "#fff",
  },
  buttonNew: {
    backgroundColor: "rgb(99, 0, 99,.6)",
    color: "#fff",
  },
  card: {
    display: "flex",
    gap: 5,
    mt: 1,
    mb: 3,
  },
  newButtonStyle: {
    position: "sticky",
    bottom: "1rem",
    right: 0,
    left: "100%",
  },
  sectionStyle: { flex: 1, textAlign: "left" },
  width80: { width: "80%" },
};
// Customizable Area End
