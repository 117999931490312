// Customizable Area Start
// Customizable Area End
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Employee } from "../types";
import { handleResponseMessage } from "../helpers/handle-response-message";
import createRequestMessage from "../helpers/create-request-message";
import { getStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employees: Employee[];
  isLoading: boolean;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeCertEmployeeListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmpListDataApi: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      employees: [],
      isLoading: false,
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGetEmpListEmpCert = this.handleGetEmpListEmpCert.bind(this);
    this.handleShowCert = this.handleShowCert.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);

    this.getEmpListDataApi = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      if (apiResponse === this.getEmpListDataApi) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            const { employees } = responseJson;

            this.setState({
              employees: employees,
            });
          },
          onFail: () => {
            this.showAlert(`Get List Failed`, "Please retry!");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleLoginCertEmpList();
  }

  async handleLoginCertEmpList() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        this.handleGetEmpListEmpCert();
      },
    );
  }

  handleGetEmpListEmpCert() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getEmpListDataApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.employeesURLEndPoint,
      method: configJSON.getApiMethodType,
      token: this.state.token,
    });

    this.setState({
      isLoading: true,
    });
  }

  handleShowCert(employee?: Employee) {
    if (employee) {
      this.props.navigation.navigate("EmployeeCertificateChangeStatus", {
        id: employee.id,
      });
    }
  }

  handleGoBack() {
    this.props.navigation.navigate("CertificationTracking");
  }
  // Customizable Area End
}
