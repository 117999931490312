import React from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

export default function LocalParticipantPresenter() {
  const { disableScreenShare } = useMeeting();
  return (
    <div style={webStyle.divStyle}>
      <div style={webStyle.divStyleMain}>
        <span
          data-testid="local-participant-presenter"
          style={webStyle.spanStyle}>
          You are presenting to everyone
        </span>
        <button
          style={webStyle.buttonStyle}
          onClick={() => {
            disableScreenShare();
          }}>
          <span style={webStyle.spanStyle1}>Stop Presenting</span>
        </button>
      </div>
    </div>
  );
}

const webStyle = {
  divStyle: {
    flex: 3,
    justifyContent: "center",
    borderRadius: 8,
    margin: 4,
    height: "100%",
  },
  divStyleMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column" as "column",
  },
  spanStyle: {
    fontSize: 14,
    color: "white",
  },
  buttonStyle: {
    alignItems: "center",
    backgroundColor: "#5568FE",
    borderRadius: 12,
    marginTop: 10,
    border: "none",
    padding: 10,
    cursor: "pointer",
  },
  spanStyle1: {
    color: "blue",
    fontSize: 16,
  },
};
