import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  Select,
  MenuItem,
  NativeSelect,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Pagination } from "@material-ui/lab";
import { Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Chart } from "react-google-charts";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
interface ItemData {
  id: string;
  type: string;
  attributes: TransactionAttributes;
}

interface TransactionAttributes {
  account_id: number;
  amount: number;
  created_at: string;
  name: string;
  updated_at: string;
}

// Customizable Area End

import SpendAnalysisController, { Props } from "./SpendAnalysisController.web";

export default class SpendAnalysis extends SpendAnalysisController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    const getLengthOfTransaction = this.state.totalTransaction.length;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"} style={webStyle.backGroundContent}>
          <Box style={webStyle.mainWrapper}>
            <Box style={{ marginLeft: 50 }}>
              <Typography style={webStyle.spendText}>Total Spends</Typography>
              <Typography>{this.state.totalSpends}</Typography>
            </Box>
            <Box>
              <Button
                data-testid="new_spend"
                onClick={this.handleExpanseOpen}
                style={webStyle.newSpend}>
                Add Spend
              </Button>
              <Button
                data-testid="new_spend_item"
                onClick={this.navigateToSpendItem}
                style={webStyle.newSpendItem}>
                Spend Item
              </Button>
            </Box>
          </Box>
          <Modal
            open={this.state.NewExpanse}
            onClose={this.handleExpanseClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testid="new_spend_modal">
            <Box sx={webStyle.modalDesign}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {this.state.EditTransactionId !== null
                  ? "Update Spend"
                  : "Add Spend"}
              </Typography>
              <Divider />

              <Box style={webStyle.marginContent}>
                <InputLabel>Spend Item</InputLabel>
                <NativeSelect
                  data-test-id="SpendItem-select"
                  defaultValue={
                    this.state.spendItemIDSelect.id ?? "Uncategorized"
                  }
                  onChange={(event) => {
                    this.handleSpendItemChange(Number(event.target.value));
                  }}>
                  {this.state.spendNameArrayWithId.map((item) => {
                    return (
                      <option
                        key={`item-${item.id}`}
                        value={item.id ?? "Uncategorized"}>
                        {item.attributes.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Box>
              <Box style={webStyle.marginContent}>
                <InputLabel>Name</InputLabel>
                <input
                  data-testid="newSpendName"
                  type="text"
                  onChange={this.createNewName}
                  value={this.state.NewExpanseName}
                />
              </Box>
              <Box style={webStyle.marginContent}>
                <InputLabel>Amount</InputLabel>
                <input
                  data-testid="newSpendAmount"
                  type="number"
                  onChange={this.createNewAmount}
                  value={this.state.NewExpanseAmount}
                />
              </Box>
              <Box style={webStyle.marginContent}>
                <InputLabel>Date</InputLabel>
                <input
                  data-testid="newSpendDate"
                  type="Date"
                  placeholder=""
                  onChange={this.CreateNewDate}
                  value={this.state.date}
                />
              </Box>
              <Box style={webStyle.saveButton}>
                {this.state.EditTransactionId !== null ? (
                  <Button
                    data-testid="edit_new_spend"
                    style={webStyle.spendExpense}
                    onClick={this.putExpensesApi}>
                    Update
                  </Button>
                ) : (
                  <Button
                    data-testid="save_spend_expense"
                    style={webStyle.spendExpense}
                    onClick={this.checkAllDataFilled}>
                    Save
                  </Button>
                )}
              </Box>
            </Box>
          </Modal>
          <div>
            <Typography style={webStyle.chartWrapper}>Spend Trend</Typography>
            <div style={{ overflowX: "auto" }}>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={this.state.expensesLabels}
                options={this.options}
              />
            </div>
          </div>
          <Dialog
            open={this.state.deleteTransaction}
            onClose={this.handleDeleteTransactionClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <Typography style={webStyle.textWrapper}>Delete</Typography>
              Are you sure, you want to delete ${this.state.transactionName}?
            </DialogTitle>
            <DialogActions>
              <Button
                data-testid="yes_delete_button"
                style={webStyle.spendExpense}
                onClick={() =>
                  this.deleteTransactionMethod(this.state.deleteTransactionID)
                }>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Typography style={{ marginTop: "30px" }}>Transactions</Typography>
          <Table data-testid="Transaction_Table" style={webStyle.tableWrapper}>
            <TableHead style={webStyle.tableBackground}>
              <TableRow>
                <TableCell style={webStyle.textColor}>Name</TableCell>
                <TableCell style={webStyle.textColor} align="right">
                  Amount
                </TableCell>
                <TableCell style={webStyle.textColor} align="right">
                  Date
                </TableCell>
                <TableCell style={webStyle.textColor} align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={webStyle.tableBodyWrap}>
              {this.state.TransactionLimitedResponse.map(
                (item: ItemData, index: number) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.attributes.name}
                      </TableCell>
                      <TableCell align="right">
                        {item.attributes.amount}
                      </TableCell>
                      <TableCell align="right">
                        {item.attributes.updated_at.slice(0, 10)}
                      </TableCell>
                      <TableCell align="right">
                        <Edit
                          data-testid="EditTransaction"
                          onClick={() => {
                            this.handleEditTransaction(
                              this.state.TransactionLimitedResponse[index].id,
                            );
                          }}
                          style={webStyle.editIconWrap}
                        />
                        <DeleteIcon
                          data-testid="DeleteTransaction"
                          onClick={() => {
                            this.handleDeleteTransaction(
                              this.state.TransactionLimitedResponse[index].id,
                              this.state.TransactionLimitedResponse[index]
                                .attributes.name,
                            );
                          }}
                          style={webStyle.deleteIconWrap}
                        />
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
          <Pagination
            data-testid="TransactionPagination"
            count={Math.ceil(getLengthOfTransaction / 5)}
            variant="outlined"
            shape="rounded"
            onChange={(event: object, newPagTransaction: number) => {
              this.handleTransactionPage(event, newPagTransaction);
            }}
            style={webStyle.paginationWrapper}
          />
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    margin: "30px auto",
    display: "flex",
    width: "300px",
    background: "#FFFFFF",
    height: "100px",
    marginTop: "40px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
  backGroundContent: {
    background: "#D3D3D3",
  },
  spendText: {
    marginBottom: "20px",
  },
  tableBodyWrap: {
    background: "#FFFFFF",
  },
  tableBackground: {
    background: "#9370DB",
  },
  textColor: {
    color: "#FFFFFF",
  },
  saveButton: {
    marginLeft: "10px",
  },
  chartWrapper: {
    margin: "10px 0px 10px 0px",
  },
  textWrapper: {
    fontSize: "22px",
  },
  newSpend: {
    background: "#9370DB",
    color: "#FFFFFF",
  },
  newSpendItem: {
    background: "#9370DB",
    color: "#FFFFFF",
    marginTop: 20,
  },
  marginContent: {
    margin: "10px",
  },
  spendExpense: {
    backgroundColor: "#0ca3d5",
    width: "130px",
    color: "#FFFFFF",
  },
  tableWrapper: {
    margin: "10px 0px 10px 0px",
    width: "100%",
  },
  deleteWrapper: {
    background: "red",
  },
  modalDesign: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgColor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    background: "#FFFFFF",
    borderRadius: "15px",
  },
  deleteIconWrap: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  editIconWrap: {
    cursor: "pointer",
  },
  paginationWrapper: {
    marginBottom: "20px",
  },
};
// Customizable Area End
