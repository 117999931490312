import React, { useState } from "react";
import { JoinScreenProps } from "../../types";

export function JoinScreen(props: JoinScreenProps) {
  const [meetingVal, setMeetingVal] = useState("");
  const [userName, setUserName] = useState("");
  const [createUser, setCreateUser] = useState("");

  return (
    <div style={webStyles.divStyle}>
      <div>
        <input
          data-testid="username-input"
          type="text"
          value={userName}
          onChange={(event) => setUserName(event.target.value)}
          placeholder="Name"
          style={webStyles.textInput}
        />
      </div>
      <div>
        <input
          type="text"
          value={meetingVal}
          onChange={(event) => setMeetingVal(event.target.value)}
          placeholder="XXXX-XXXX-XXXX"
          style={webStyles.textInput1}
          data-testid="meeting-input"
        />
      </div>
      <div>
        <button
          style={webStyles.buttonStyle}
          onClick={() => {
            props.joinMeeting(userName, meetingVal);
          }}
          data-testid="join-button">
          Join Meeting
        </button>
      </div>
      <div style={webStyles.customSearch}>
        <input
          data-testid="createuser-input"
          type="text"
          onChange={(event) => setCreateUser(event.target.value)}
          value={createUser}
          style={webStyles.customSearchInput}
          placeholder="Enter your name"
        />
        <button
          data-testid="create-meeting"
          style={webStyles.customSearchButton}
          type="submit"
          onClick={() => {
            if (createUser) {
              props.joinMeeting(createUser);
            } else {
              alert("please enter name");
            }
          }}>
          Create Meeting
        </button>
      </div>
    </div>
  );
}

const webStyles = {
  customSearch: {
    position: "relative" as const,
    width: "300px",
    margin: "10px 0 0 0",
  },
  customSearchInput: {
    width: "100%",
    border: "1px solid #5568FE",
    borderRadius: "6px",
    padding: "10px 100px 10px 20px",
    lineHeight: "1",
    boxSizing: "border-box" as const,
    outline: "none",
  },
  customSearchButton: {
    position: "absolute" as const,
    right: "3px",
    top: "3px",
    bottom: "3px",
    border: "0",
    background: "#5568FE",
    color: "#fff",
    outline: "none",
    margin: "0",
    padding: "0 10px",
    borderRadius: "6px",
  },
  divStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonStyle: {
    background: "#5568FE",
    color: "#FFF",
    padding: "10px 15px",
    borderRadius: 6,
    borderWidth: 0,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
  },
  textInput: {
    marginBottom: 10,
    padding: "10px 15px",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#5568FE",
    backgroundColor: "#F4F4F4",
    color: "#333",
    fontSize: 16,
    fontWeight: 500,
    outline: "none",
    width: 300,
  },
  textInput1: {
    padding: "10px 15px",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#5568FE",
    backgroundColor: "#F4F4F4",
    color: "#333",
    fontSize: 16,
    fontWeight: 500,
    outline: "none",
    width: 300,
  },
};
