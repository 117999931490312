Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.putApiMethod = "PUT";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Wiki2";
exports.labelBodyText = "Wiki2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.Wiki_EndPoint = "bx_block_wiki/wikis";
exports.WikiContent_EndPoint = "bx_block_wiki/wiki_contents";
exports.Revision_EndPoint = "bx_block_wiki/wiki_revisions";
exports.Login_EndPoint = "bx_block_login/logins";
exports.Email = "buraktest1@gmail.com";
exports.Password = "123456";
// Customizable Area End
