import React from "react";

import {
  Container,
  Box,
  Card,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Pagination } from "@material-ui/lab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader";
import { searchIcon } from "./assets";
// Customizable Area End

import FundraiserListController, {
  Props,
} from "./FundraiserListController.web";
import InputComponent from "./components/Input/InputComponent.web";
import TitleComponent from "./components/Title/TitleComponent.web";

const theme = createTheme({});

export default class FundraiserList extends FundraiserListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <TitleComponent title="List" />
        <Container maxWidth="sm" id="fundraiser-list">
          <Loader loading={this.state.loading} />

          <InputComponent
            placeholder="Search"
            data-test-id="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => this.updateSearch(event.target.value)}
          />

          <Box sx={webStyle.mainWrapper}>
            {this.state.fundraiserDetailList.map(
              ({ attributes: { create_by, title, description, id } }) => {
                return (
                  <Card style={webStyle.card} key={id}>
                    <div style={webStyle.profileInfo}>
                      <div>
                        <Typography variant="h6">{title}</Typography>
                        <Typography variant="body1">
                          {create_by.full_name}
                        </Typography>
                      </div>
                    </div>

                    <Typography variant="caption" component="div">
                      {`Description: ${description}`}
                    </Typography>

                    <Button
                      data-test-id="btn-details"
                      style={webStyle.button}
                      size="small"
                      color="primary"
                      onClick={() => this.goToDetails(id)}>
                      Details
                    </Button>
                  </Card>
                );
              },
            )}
          </Box>

          {this.state.pageTotal > 1 && (
            <Pagination
              data-test-id="pagination"
              count={this.state.pageTotal}
              shape="rounded"
              onChange={(event, page) => this.updatePagination(page)}
            />
          )}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  card: {
    width: "100%",
    borderRadius: 8,
    background: "#F3F0FF",
    padding: "16px 24px",
    marginTop: 24,
  },
  profileInfo: {
    display: "flex",
    alignItems: "center",
    gap: 24,
    marginBottom: 24,
  },
  avatar: {
    width: 54,
    height: 54,
  },
  button: {
    background: "#EC9638",
    color: "white",
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: 16,
  },
};
// Customizable Area End
