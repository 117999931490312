export interface Goal {
  id?: number;
  account_id?: number;
  name?: string;
  description?: string;
  target?: string;
  goal_start_date?: Date;
  goal_end_date?: Date;
  rewards?: string;
  is_active?: boolean;
  goal_status?: GoalStatusKey;
  group_name?: string;
  account_groups_group_id?: number;
  created_at?: Date;
  created_by?: string;
  updated_at?: Date;
  updated_by?: string;
}

export interface GoalResponseData {
  id: number;
  type: "goal_management";
  attributes: Goal;
}

export type GoalStatusKey = "tracked" | "advanced" | "delayed";

export type GoalStatusName = "Tracked" | "Advanced" | "Delayed";

export enum GoalStatusNumber {
  TRACKED,
  ADVANCED,
  DELAYED,
}

export type GoalStatusObject = {
  [key in GoalStatusKey]: {
    id: GoalStatusNumber;
    code: GoalStatusKey;
    name: GoalStatusName;
  };
};

export interface TeamResponseData {
  id: number;
  type: "group";
  attributes: Team[];
}

export type Team = {
  id?: number;
  name?: string;
  settings?: string | null;
  accounts?: Account[];
};

export type Account = {
  id?: number;
  first_name?: string;
  last_name?: string;
  full_phone_number?: string;
  country_code?: string;
  phone_number?: string;
  email?: string;
  activated?: boolean;
  device_id?: number;
  unique_auth_id?: string;
  password_digest?: string;
  user_name?: string;
  platform?: string;
  user_type?: string;
  app_language_id?: string;
  last_visit_at?: string;
  is_blacklisted?: boolean;
  suspend_until?: string;
  status?: string;
  stripe_id?: number;
  stripe_subscription_id?: number;
  stripe_subscription_date?: string;
  role_id?: number;
  full_name?: string;
};

export type ResponseType = {
  error?: string | object;
  errors?: string | object;
};

export type Pagination = {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
  current_count: number;
  per_page: number;
};
