import React, { useState } from "react";
import { MeetingProvider } from "@videosdk.live/react-sdk";
import { createMeeting } from "./api/api";
import { JoinScreen } from "./components/web/JoinScreen";
import { MeetingView } from "./components/web/MeetingView";

interface MeetScreenWebProps {
  token: string;
}

export default function MeetScreenWeb(props: MeetScreenWebProps) {
  const [meetingId, setMeetingId] = useState("");
  const [userNameWeb, setUserNameWeb] = useState<string>("");

  const joinMeeting = async (userName1: string, meetingRoomId?: string) => {
    try {
      const newMeetingId =
        meetingRoomId == null
          ? await createMeeting({ token: props.token })
          : meetingRoomId;
      setMeetingId(newMeetingId);
      setUserNameWeb(userName1);
    } catch (error) {}
  };
  const onMeetingLeave = () => {
    setMeetingId("");
  };

  return meetingId ? (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: false,
        webcamEnabled: false,
        name: userNameWeb,
      }}
      token={props.token}>
      <MeetingView meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
    </MeetingProvider>
  ) : (
    <JoinScreen joinMeeting={joinMeeting} />
  );
}
