import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

import { handleResponseMessage } from "../../../framework/src/Helpers/handle-response-message";
import createRequestMessage from "../../../framework/src/Helpers/create-request-message";
import { ChangeEvent } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface TrendingApi {
  id: string | null;
  name: string;
  month: string;
  total_amount: string;
}
interface AttributeData {
  id: number;
  name: string;
  budget_id: number;
  is_active: boolean;
  created_by: number;
  updated_by: null;
  created_at: string;
  updated_at: string;
  total_amount: number | null;
  amount: number;
  currency: string;
}

export interface CategoryData {
  id: string;
  type: string;
  attributes: AttributeData;
}

interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  name: string;
  account_id: number;
  amount: number;
  date: string;
  expense_category_id: null | string;
  recurring: boolean;
  recurring_date: number | null;
  created_by: number;
  is_active: boolean;
  updated_by: null | string;
  created_at: string;
  updated_at: null | string;
  expense_category: ExpenseCategory;
  category_name: string | null;
}
interface DataForExportImport {
  data: ExportAttributeData[];
  headers: Headers[] | [];
  filename: string;
}

interface BudgetAlerts {
  id?: string;
  type?: string;
  attributes?: AttributeDataAlert;
}

interface AttributeDataAlert {
  account_id: number;
  contents: string;
  created_at: string;
  headings: string;
  id: number;
}

interface ExportAttributeData {
  id: number;
  account_id: number;
  name: string;
  amount: number;
  expense_category_id: null | string;
  recurring: true;
  recurring_date: number;
  is_active: true;
  created_by: number;
  updated_by: null;
  created_at: string;
  updated_at: string;
  date: string;
}

interface Headers {
  label: string;
  key: string;
}

interface ExpenseCategory {
  data: null | CategoryData;
}

interface S {
  // Customizable Area Start
  expenseChartData: [string, number | string][];
  trendingExpenses: TrendingApi[];
  expensesMonth: number;
  expensesYear: number;
  categoryId: number;
  newBudget: string;
  userAllCategories: CategoryData[];
  categories: CategoryData[];
  expenseTracking: string | unknown;
  transactionTracking: string | unknown;
  chartTracking: string | unknown;
  csvTracking: string | unknown;
  month: number;
  year: number;
  TransactionFilterData: Root[];
  userCategoriesLimited: CategoryData[];
  token: string;
  currentPage: number;
  PageNo: number;
  TranPageNo: number;
  ExpanseDetailsPageNo: number;
  NewExpanse: boolean;
  NewExport: boolean;
  TotalDetailsExpanse: boolean;
  CategoriesDetails: boolean;
  detailExpenses: Root[];
  CreateNewCategory: string;
  CreateNewBudget: string;
  CreateTextCurrency: string;
  NewExpanseName: string;
  NewExpanseAmount: number | string;
  NewExpanseDate: string;
  ExpanseCategoryId: number | unknown;
  NewExpanseRecurring: boolean;
  RecurringDate: string | unknown;
  EditCategories: boolean;
  EditCategoriesId: string;
  EditTransactionId: string;
  deleteCategories: boolean;
  deleteCategoriesID: number | null;
  deleteTransaction: boolean;
  deleteTransactionID: number | null;
  totalPages: number;
  csvReport: DataForExportImport;
  file: File | null;
  allTransactionListCount: number;
  alertBox: Array<object>;
  showAlertOnce: boolean;
  selectMonth: string | unknown;
  monthSelect: string | unknown;
  yearSelect: string | unknown;
  selectYear: string | unknown;
  viewDetailsCount: number;
  categoriesPageNo: number;
  errorResponseTransaction: string | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ExpenseTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  viewAllDetails: string = "";
  getCSVData: string = "";
  postCategoriesExpenseApi: string = "";
  ViewAllContentAnalysisEdit: string = "";
  TransactionListAnalysisEdit: string = "";
  InitialLoginApiCallId: string = "";
  viewAllExpenses: string = "";
  viewAllExpensesYear: string = "";
  removeCartItemsApiCallId: string = "";
  viewTrendingExpenses: string = "";
  viewTransactionsFirstPage: string = "";
  viewUserCategoryFirstPage: string = "";
  DeleteTransactionApiCallId: string = "";
  postCategoriesApi: string = "";
  ReconditionCategoriesAPI: string = "";
  viewUserExpense: string = "";
  viewAllUserCategories: string = "";
  updateExpensesAPIRequest: string = "";
  postImportFile: string = "";
  alertApiDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      TransactionFilterData: [],
      errorResponseTransaction: "",
      userCategoriesLimited: [],
      expenseChartData: [],
      trendingExpenses: [],
      expensesMonth: 0,
      expensesYear: 0,
      categoryId: 0,
      newBudget: "",
      userAllCategories: [],
      categories: [],
      expenseTracking: "monthly",
      transactionTracking: "monthly",
      chartTracking: "monthly",
      csvTracking: "monthly",
      month: 0,
      year: 0,
      token: "",
      currentPage: 1,
      PageNo: 1,
      TranPageNo: 1,
      ExpanseDetailsPageNo: 1,
      NewExpanse: false,
      NewExport: false,
      TotalDetailsExpanse: false,
      CategoriesDetails: false,
      detailExpenses: [],
      CreateNewCategory: "",
      CreateNewBudget: "",
      CreateTextCurrency: "",
      NewExpanseName: "",
      NewExpanseAmount: 0,
      NewExpanseDate: "",
      ExpanseCategoryId: null,
      NewExpanseRecurring: false,
      RecurringDate: "",
      EditCategories: false,
      EditCategoriesId: "",
      EditTransactionId: "",
      deleteCategories: false,
      deleteCategoriesID: null,
      deleteTransaction: false,
      deleteTransactionID: null,
      alertBox: [],
      totalPages: 0,
      selectMonth: (new Date().getMonth() + 1).toString(),
      monthSelect: (new Date().getMonth() + 1).toString(),
      yearSelect: new Date().getFullYear().toString(),
      selectYear: new Date().getFullYear().toString(),
      viewDetailsCount: 0,
      showAlertOnce: false,
      file: null,
      csvReport: {
        data: [],
        headers: [],
        filename: "exportFile.csv",
      },
      allTransactionListCount: 0,
      categoriesPageNo: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.initialLogin();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.postCategoriesExpenseApi: {
            this.setState({
              NewExpanseName: "",
              NewExpanseAmount: 0,
              NewExpanseDate: "",
              ExpanseCategoryId: null,
              NewExpanseRecurring: false,
              RecurringDate: "",
            });
            this.setState({ ...this.state, NewExpanse: false });
            this.showAlert(`Success`, "New Expense Added Successfully");
            this.transactionsApiFirstPage(this.state.currentPage);
            break;
          }
          case this.InitialLoginApiCallId: {
            this.setState(
              {
                token: responseJson.meta.token,
              },
              () => {
                this.getExpenses();
                this.getExpensesYear();
                this.trendingExpenseApi();
                this.getUserAllAlerts();
                this.getExportDataCSV();
                this.transactionsApiFirstPage(this.state.TranPageNo);
                this.getUserAllCategoriesFirstPage(this.state.PageNo);
                this.getUserAllCategories();
                this.transactionListApi();
              },
            );
            break;
          }
          case this.postCategoriesApi: {
            this.setState({
              CreateNewBudget: "",
              CreateNewCategory: "",
              CreateTextCurrency: "",
            });
            this.setState({ ...this.state, CategoriesDetails: false });
            this.getUserAllCategoriesFirstPage(this.state.PageNo);
            break;
          }
          case this.ReconditionCategoriesAPI: {
            this.setState({
              CreateNewBudget: "",
              CreateNewCategory: "",
              CreateTextCurrency: "",
              CategoriesDetails: false,
              EditCategories: false,
              EditCategoriesId: "",
            });
            this.getUserAllCategoriesFirstPage(this.state.PageNo);
            break;
          }
          case this.updateExpensesAPIRequest: {
            this.setState({
              NewExpanseName: "",
              NewExpanseAmount: 0,
              NewExpanseDate: "",
              ExpanseCategoryId: null,
              NewExpanseRecurring: false,
              RecurringDate: "",
            });
            this.setState({
              ...this.state,
              NewExpanse: false,
              EditTransactionId: "",
            });
            this.transactionsApiFirstPage(this.state.TranPageNo);
            this.getAllDetails(this.state.ExpanseDetailsPageNo);
            break;
          }
          case this.ViewAllContentAnalysisEdit: {
            this.setState({
              categories: responseJson.data,
            });
            let allData = responseJson.data.attributes;
            let allAmount = allData.amount;
            let newBudget = allAmount.toString();
            this.setState({
              CreateNewBudget: newBudget,
              CreateNewCategory: allData.name,
              CreateTextCurrency: allData.currency,
            });
            this.setState({ ...this.state, CategoriesDetails: true });
            break;
          }
          case this.TransactionListAnalysisEdit: {
            this.setState({
              allTransactionListCount: responseJson.data.length,
            });
            break;
          }
          case this.viewAllExpensesYear: {
            const date = new Date();
            const months = date.getMonth();
            const yearn = date.getFullYear();
            this.setState({ month: months });
            this.setState({ year: yearn });
            this.setState({
              expensesYear: responseJson[this.state.year],
            });
            this.trendingExpenseApi();
            break;
          }
          case this.viewUserCategoryFirstPage: {
            this.setState({
              userCategoriesLimited: responseJson.data,
              categoriesPageNo: responseJson.meta.total_pages,
            });
            break;
          }
          case this.viewTrendingExpenses: {
            this.setState({
              trendingExpenses: responseJson,
            });
            let array: [string, number | string][] = [["", "Total Amount"]];

            this.state.trendingExpenses.forEach((item: TrendingApi) => {
              array.push([item.name, parseInt(item.total_amount, 10)]);
            });
            this.setState({ expenseChartData: array });
            break;
          }
          case this.viewUserExpense: {
            let cData = responseJson.data.attributes;
            let allAmount = cData.amount;
            let newAmount = allAmount.toString();
            let recurring = cData.recurring;
            let recurringDate = cData.recurring_date;
            this.setState({
              NewExpanseName: cData.name,
              NewExpanseAmount: newAmount,
              ExpanseCategoryId: cData.expense_category_id,
              NewExpanseRecurring: recurring,
              NewExpanseDate: cData.date,
              RecurringDate: recurringDate,
            });
            break;
          }
          case this.viewAllExpenses: {
            const date = new Date();
            const monthG = date.getMonth();
            const yearG = date.getFullYear();
            this.setState({ month: monthG });
            this.setState({ year: yearG });
            let monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            const monthData =
              monthNames[this.state.month] + -+new Date().getFullYear();
            this.setState({
              expensesMonth: responseJson[monthData],
            });
            this.trendingExpenseApi();
            break;
          }
          case this.removeCartItemsApiCallId: {
            let checking = this.state.categories.length - 1;
            let checkingTwo = checking % 5;
            if (checkingTwo === 0) {
              this.setState((prev) => ({ currentPage: prev.currentPage - 1 }));
            }
            this.setState({
              deleteCategories: false,
              deleteCategoriesID: null,
            });
            this.getUserAllCategoriesFirstPage(this.state.PageNo);
            break;
          }
          case this.viewAllDetails: {
            this.setState({
              detailExpenses: responseJson.data,
              viewDetailsCount: responseJson && responseJson.meta.total_pages,
            });
            break;
          }
          case this.viewAllUserCategories: {
            this.setState({
              userAllCategories: responseJson && responseJson.data,
            });
            break;
          }
          case this.DeleteTransactionApiCallId: {
            this.setState({
              deleteTransaction: false,
              deleteTransactionID: null,
            });
            this.transactionsApiFirstPage(this.state.TranPageNo);
            this.getAllDetails(this.state.ExpanseDetailsPageNo);
            break;
          }
        }
      }
      this.handleAPICase(apiRequestCallId, message);
      this.handleTransactionAPI(apiRequestCallId, message);
    }
    // Customizable Area End
  }

  //  Customizable Area Start

  handleAPICase(apiRequestCallId: string, message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    const errorData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );

    switch (apiRequestCallId) {
      case this.getCSVData: {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorData,
          onSuccess: () => {
            this.onExportSuccessData(responseJson);
          },
          onFail: () => {
            this.showAlert(`Error`, "Get Token Failed. Please retry!");
          },
        });
        break;
      }
      case this.postImportFile:
        if (responseJson.error) {
          this.showAlert("Error", JSON.stringify(responseJson.error));
        } else {
          this.showAlert(
            "Success",
            `Added ${responseJson.success.length} new expenses`,
          );
        }
        break;
      case this.alertApiDetails:
        this.handleAlertAPI(!responseJson.errors!, responseJson.data);
        break;
    }
  }

  handleTransactionAPI(apiRequestCallId: string, message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    if (apiRequestCallId === this.viewTransactionsFirstPage) {
      if (!responseJson.message) {
        this.setState({
          TransactionFilterData: responseJson.data,
          errorResponseTransaction: null,
          totalPages: responseJson.meta.total_pages,
        });
      } else {
        this.setState({
          errorResponseTransaction: responseJson.message,
        });
      }
    }
  }

  initialLogin() {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.InitialLoginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins",
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserAllAlerts = () => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.alertApiDetails = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.alertApiEndpoint,
      method: configJSON.validationApiMethodType,
      header: header,
    });
  };

  handleAlertAPI(isSuccess: boolean, data: Array<object>) {
    if (isSuccess) {
      this.setState({
        alertBox: data,
      });

      if (this.state.alertBox.length !== 0) {
        const budgetAlerts = this.state.alertBox.map(
          (alert: BudgetAlerts) => alert.attributes!.headings,
        );

        if (budgetAlerts.length > 0 && !this.state.showAlertOnce) {
          this.showAlert("Budget alerts", `${budgetAlerts.join("\n")}`);
          this.setState({ showAlertOnce: true });
        }
      }
    }
  }

  onConvertFormData(response: File) {
    const formData = new FormData();
    formData.append("file", response);
    return formData;
  }

  selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      this.setState(
        {
          file: event.target.files && event.target.files[0],
        },
        () => {
          const requests = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
          );
          this.postImportFile = requests.messageId;
          const formData = this.onConvertFormData(this.state.file!);

          createRequestMessage({
            requestMessage: requests,
            endPoint: configJSON.importApiEndpoint,
            method: configJSON.exampleAPiMethod,
            body: formData,
            token: this.state.token,
            isFormDataRequest: true,
          });
        },
      );
    } catch (error) {
      this.showAlert("Error", "Failed to upload file");
    }
  };

  getExportDataCSV = () => {
    const requests = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCSVData = requests.messageId;
    const headers = {
      "token": this.state.token, //static token
      "Content-Type": configJSON.validationApiContentType,
    };

    createRequestMessage({
      requestMessage: requests,
      endPoint: `bx_block_expenses/expenses/exportable_expense_data?time_select=${this.state.csvTracking}&selected_year=${this.state.yearSelect}&selected_month=${this.state.monthSelect}`,
      method: configJSON.validationApiMethodType,
      header: headers,
    });
  };

  onExportSuccessData = (responseJson: {
    [key: string]: ExportAttributeData[];
  }) => {
    this.setState({
      csvReport: {
        data: Object.values(responseJson).length
          ? Object.values(responseJson)[0]
          : [],
        headers: [
          { label: "Name", key: "name" },
          { label: "Amount", key: "amount" },
          { label: "Date", key: "date" },
          { label: "Category", key: "category" },
        ],
        filename: "exportFile.csv",
      },
    });
  };

  createNewName = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseName: event.target.value });
  };

  createNewAmount = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseAmount: event.target.value });
  };

  createNewDate = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseDate: event.target.value });
  };

  createArrayOfDates = (
    event: ChangeEvent<{ name?: string; value: unknown | string }>,
  ) => {
    this.setState({ RecurringDate: event.target.value });
  };

  createNewCaTId = (
    event: ChangeEvent<{ name?: string; value: unknown | number }>,
  ) => {
    this.setState({ ExpanseCategoryId: event.target.value });
  };

  changeIsSelected = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ NewExpanseRecurring: event.target.checked });
  };

  checkIfAllDataFilled = async () => {
    if (this.state.NewExpanseName === "" || this.state.NewExpanseAmount === 0) {
      this.showAlert("Expense Error", "Please fill details");
    } else {
      this.postNewExpensesAPI();
    }
  };

  postNewExpensesAPI = async () => {
    const header = {
      "token": this.state.token, //static token
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    let categoryExpanseId: number | unknown = this.state.ExpanseCategoryId;
    let categoryId: number | unknown = categoryExpanseId;

    if (categoryId === 0) {
      categoryId = null;
    }
    const body = {
      data: {
        attributes: {
          name: this.state.NewExpanseName,
          amount: this.state.NewExpanseAmount,
          date: this.state.NewExpanseDate,
          expense_category_id: categoryId,
          recurring: this.state.NewExpanseRecurring,
          recurring_date: this.state.RecurringDate,
          is_active: true,
        },
      },
    };

    this.postCategoriesExpenseApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postExpensesEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeAccordingTransaction = (selected: string | unknown) => {
    this.setState({ transactionTracking: selected });
  };

  changeAccordingExpenseChart = (selected: string | unknown) => {
    this.setState({ chartTracking: selected });
    if (selected === "yearly") {
      this.getExpensesYear();
    }
    if (selected === "monthly") {
      this.getExpenses();
    }
  };

  changeAccordingCSVExport = (selected: string | unknown) => {
    this.setState({ csvTracking: selected });
  };

  createMonth = (selected: string | unknown) => {
    this.setState({ selectMonth: selected });
  };

  createYear = (selected: string | unknown) => {
    this.setState({ selectYear: selected });
  };

  createCSVMonth = (selected: string | unknown) => {
    this.setState({ ...this.state, monthSelect: selected }, () => {
      this.getExportDataCSV();
    });
  };

  createCSVYear = (selected: string | unknown) => {
    this.setState({ ...this.state, yearSelect: selected }, () => {
      this.getExportDataCSV();
    });
  };

  txtInputFirstNameProps = (event: ChangeEvent<HTMLInputElement>) => {
    let regExTxt = /^[a-zA-Z]*$/;
    if (event.target.value.length < 15 && regExTxt.test(event.target.value)) {
      this.setState({ CreateTextCurrency: event.target.value });
    }
  };

  txtInputZipCodeProps = (event: ChangeEvent<HTMLInputElement>) => {
    let regExTxt = /^\d*$/;
    if (regExTxt.test(event.target.value)) {
      this.setState({ CreateNewBudget: event.target.value });
    }
    if (
      this.state.newBudget.length === 0 &&
      regExTxt.test(event.target.value)
    ) {
      this.setState({ CreateNewBudget: event.target.value.replace(/\s/g, "") });
    }
  };

  txtInputNameProps = (event: ChangeEvent<HTMLInputElement>) => {
    let regExTxt = /^[a-zA-Z]*$/;
    if (event.target.value.length < 15 && regExTxt.test(event.target.value)) {
      this.setState({ CreateNewCategory: event.target.value });
    }
  };

  getExpenses = () => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewAllExpenses = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expenseEndpointMonth,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getExpensesYear = () => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewAllExpensesYear = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expenseEndpointYearly,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditCategories = (editId: string) => {
    this.contentTagsListListApi(parseInt(editId, 10));
    this.setState({ EditCategoriesId: editId });
  };

  contentTagsListListApi = async (listApiId: number) => {
    const header = {
      "token": this.state.token, //static token
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.ViewAllContentAnalysisEdit = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryById + listApiId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  transactionListApi = async () => {
    const header = {
      "token": this.state.token, //static token
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.TransactionListAnalysisEdit = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.transactionApiEndPointALL,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateCategories = async () => {
    const header = {
      "token": this.state.token, //static token
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    const body = {
      budget: {
        amount: this.state.CreateNewBudget,
        currency: this.state.CreateTextCurrency,
      },
      expense_category: {
        name: this.state.CreateNewCategory,
      },
    };

    this.ReconditionCategoriesAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCategoriesEndPoint + this.state.EditCategoriesId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteCategories = (deleteId: string) => {
    this.setState({ deleteCategories: true });
    this.setState({ deleteCategoriesID: parseInt(deleteId, 10) });
  };

  handleDeleteCategoriesClose = () => {
    this.setState({ deleteCategories: false, deleteCategoriesID: null });
  };

  handleEditTransaction = (editTransactionId: string) => {
    this.getEditedUserDetails(parseInt(editTransactionId, 10));
    this.setState({
      EditTransactionId: editTransactionId,
    });
    this.setState({ NewExpanse: true });
  };

  handleDeleteTransaction = (deleteTransactionId: string) => {
    this.setState({
      deleteTransaction: true,
      deleteTransactionID: parseInt(deleteTransactionId, 10),
    });
  };

  handleDeleteTransactionClose = () => {
    this.setState({ deleteTransaction: false, deleteTransactionID: null });
  };

  getEditedUserDetails = async (editedUserId: number) => {
    const header = {
      "token": this.state.token, //static token
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewUserExpense = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExpenseById + editedUserId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateExpensesApi = async () => {
    const header = {
      "token": this.state.token, //static token
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    let categoryId: number | unknown = this.state.ExpanseCategoryId;
    if (categoryId === 0) {
      categoryId = null;
    }
    const body = {
      data: {
        attributes: {
          name: this.state.NewExpanseName,
          amount: this.state.NewExpanseAmount,
          date: this.state.NewExpanseDate,
          expense_category_id: categoryId,
          recurring: this.state.NewExpanseRecurring,
          recurring_date: this.state.RecurringDate,
          is_active: true,
        },
      },
    };

    this.updateExpensesAPIRequest = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateExpenseApi + this.state.EditTransactionId,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  transactionsApiFirstPage = (paginationNo: number) => {
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewTransactionsFirstPage = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_expenses/expenses/total_expense_details?time_select=${this.state.transactionTracking}&selected_month=${this.state.selectMonth}&selected_year=${this.state.selectYear}&page=${paginationNo}&limit=5`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserAllCategoriesFirstPage = (paginationNo: number) => {
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewUserCategoryFirstPage = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userCategoriesLimited +
        paginationNo +
        configJSON.userCategoriesLimitedEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  trendingExpenseApi = async () => {
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewTrendingExpenses = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TrendingExpensesEndPoint +
        this.state.chartTracking +
        configJSON.limit3,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async removeTransaction(removeTranId: number | null) {
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.DeleteTransactionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTransactionsEndPoint + removeTranId,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async removeItems(removeItemId: number | null) {
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.removeCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCategoryEndPoint + removeItemId,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllDetails = async (paginationNo: number) => {
    const selection = this.state.expenseTracking;
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewAllDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllDetailEndPoint +
        selection +
        configJSON.getAllDetailEndPointLimit +
        paginationNo +
        configJSON.limitTen,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  postCreateNewCategories = async () => {
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    const body = {
      budget: {
        amount: this.state.CreateNewBudget,
        currency: configJSON.expenseCurrency,
      },
      expense_category: {
        name: this.state.CreateNewCategory,
      },
    };

    this.postCategoriesApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postNewCategoryEndpoints,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserAllCategories = async () => {
    const header = {
      "token": this.state.token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.viewAllUserCategories = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllCategoriesEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  pageSize = 5;
  transactionPageSize = 5;
  detailExpansePageSize = 10;

  pageDataChange(event: ChangeEvent<unknown>, newPage: number) {
    this.setState({ ...this.state, PageNo: newPage });
    this.getUserAllCategoriesFirstPage(newPage);
  }

  handleTransactionPage(
    event: ChangeEvent<unknown>,
    newPagTransaction: number,
  ) {
    this.transactionsApiFirstPage(newPagTransaction);
  }

  pageDataChangeDetails(
    event: ChangeEvent<unknown>,
    newPagTransaction: number,
  ) {
    this.setState({ ...this.state, ExpanseDetailsPageNo: newPagTransaction });
    this.getAllDetails(newPagTransaction);
  }

  handleExpanseClose = () => {
    this.setState({ ...this.state, NewExpanse: false });
  };

  handleExpanseOpen = () => {
    this.setState({ ...this.state, NewExpanse: true });
  };

  handleExportOpen = () => {
    this.setState({ ...this.state, NewExport: true });
  };

  handleExportClose = () => {
    this.setState({ ...this.state, NewExport: false });
  };

  handleDetailExpanseOpen = (selection: string) => {
    this.setState(
      { ...this.state, TotalDetailsExpanse: true, expenseTracking: selection },
      () => {
        this.getAllDetails(this.state.ExpanseDetailsPageNo);
      },
    );
  };

  handleDetailExpanseClose = () => {
    this.setState({ ...this.state, TotalDetailsExpanse: false });
  };

  handleCategoriesOpen = () => {
    this.setState({ ...this.state, CategoriesDetails: true });
  };

  handleCategories = () => {
    this.setState({ ...this.state, CategoriesDetails: false });
  };

  options = {
    tooltips: {
      trigger: "none",
    },
    chart: {
      title: "Company Performance",
    },
  };
  // Customizable Area End
}
