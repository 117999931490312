import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Link,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import RedEye from "@material-ui/icons/RemoveRedEye";
import { imgDOCType } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CvresumeCandidateTemplateListController, {
  Props,
} from "./CvresumeCandidateTemplateListController";

export default class CvresumeCandidateTemplateList extends CvresumeCandidateTemplateListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.templateMainContainer}>
          <Box sx={webStyle.templateHeadingContainer}>
            <Typography variant="h4" style={webStyle.templateHeading}>
              Resume Template List
            </Typography>
          </Box>
          <Box sx={webStyle.templatesContainer}>
            {Object.entries(this.state.webTemplateResume).map(
              ([item, value], index) => (
                <Box sx={webStyle.templateBox} key={`template-${item}`}>
                  <Link
                  target="_blank"
                    style={webStyle.templateIconContainer}
                    href={value}
                    data-test-id={`templateDownloadBtn1-${index}`}>
                    <RedEye fontSize="medium" />
                  </Link>
                  <Box sx={webStyle.templateImageContainer}>
                    <img
                      src={imgDOCType}
                      height="80px"
                      width="80px"
                      alt="Image document type."
                    />
                  </Box>
                  <Typography>{item}</Typography>
                </Box>
              ),
            )}
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  templateMainContainer: {
    minHeight: "100vh",
    padding: "15px",
    bgcolor: "#fff",
    border: "1px solid black",
  },
  templateHeadingContainer: {
    width: "100%",
    textAlign: "center",
    margin: "10px 0",
  },
  templateHeading: {
    fontWeight: 500,
  },
  templatesContainer: {
    display: "flex",
    padding: "10px",
    flexWrap: "wrap",
    alignItem: "center",
  },
  templateBox: {
    border: "1px solid black",
    height: "200px",
    width: "200px",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    margin: "10px",
  },
  templateIconContainer: {
    borderRadius: "50%",
    height: "35px",
    width: "35px",
    border: ".5px solid grey",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    cursor: "pointer",
  },
  templateImageContainer: {
    borderRadius: "50%",
    height: "108px",
    width: "108px",
    border: ".5px solid grey",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
// Customizable Area End
