import React, { CSSProperties } from "react";

import {
  Container,
  Box,
  Card,
  Button,
  Typography,
  InputAdornment,
  OutlinedInput,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import { imgCal } from "./assets";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";

type ButtonStyles = {
  color?: "primary" | "default";
  variant: "text" | "contained";
  className: string;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
});

const TaskCard = styled(Card)({
  borderRadius: 4,
  border: "1px solid #7f70f4",
  borderLeft: "8px solid #7f70f4",
  padding: "16px 20px",
  marginBottom: 16,
});

const DaySelector = styled(Button)({
  "flex": "1 0 auto",
  "padding": "4px 8px",
  "borderRadius": 16,
  "lineHeight": 1.5,
  "&>span": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const CalendarWrapper = styled("span")({
  display: "inline-block",
  position: "relative",
  width: 40,
  height: 40,
});

const CalendarImage = styled("img")({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
});

const CalendarInput = styled("input")({
  "position": "absolute",
  "left": 0,
  "top": 0,
  "width": "100%",
  "height": "100%",
  "opacity": 0,
  "cursor": "pointer",
  "boxSizing": "border-box",

  "&::-webkit-calendar-picker-indicator": {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    cursor: "pointer",
  },
});
// Customizable Area End

import TimeSheetTimeManagementListController, {
  Props,
  configJSON,
  PageItem,
} from "./TimeSheetTimeManagementListController";
import moment from "moment";
import Title from "./components/Title.web";

export default class TimeSheetTimeManagementList extends TimeSheetTimeManagementListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  rangeButtons() {
    return [configJSON.labelDaily, configJSON.labelMonthly].map((period) => (
      <Button
        key={period}
        data-test-id={period}
        variant={this.state.selectedTime === period ? "contained" : "outlined"}
        color="primary"
        onClick={() => this.setSelectionTime(period)}>
        {period}
      </Button>
    ));
  }

  datesList() {
    const activeProps: ButtonStyles = {
      variant: "contained",
      color: "primary",
      className: "active",
    };

    const defaultProps: ButtonStyles = {
      variant: "text",
      color: "default",
      className: "inactive",
    };

    if (this.state.selectedTime === configJSON.labelMonthly) {
      return this.state.monthNames.map((item, index) => {
        const isActive = index + 1 === parseInt(this.state.selectedMonth, 10);

        return (
          <DaySelector
            {...(isActive ? activeProps : defaultProps)}
            key={item.monthNumber}
            data-test-id="currentMonth"
            onClick={() => this.setMonthlySelectedNameNumber(item)}>
            <span>{item.monthNumber}</span>
            <span>{item.monthName}</span>
          </DaySelector>
        );
      });
    }

    return this.state.selectedMonthDate.map((item, index) => {
      const isActive = index + 1 === parseInt(this.state.selectedDate, 10);

      return (
        <DaySelector
          {...(isActive ? activeProps : defaultProps)}
          key={item.date}
          data-test-id="currentDate"
          onClick={() => this.setDailySelectedDate(item.date)}>
          <span>{item.date}</span>
          <span>{item.dayName}</span>
        </DaySelector>
      );
    });
  }

  tasksList() {
    return this.state.timeList.length ? (
      this.state.timeList.map((task, index) => (
        <TaskCard
          key={task.id}
          data-test-id="timeListTaskDetail"
          onClick={() => this.openWorkerDetails(task.id, index)}>
          <Typography>
            {configJSON.labelAccountId}{" "}
            <Typography color="textSecondary" component="span">
              {task.attributes.id}
            </Typography>
          </Typography>
          <Typography color="primary" variant="h6">
            {this.getUserFullName(task)}
          </Typography>
          <Typography color="textSecondary">{task.attributes.email}</Typography>
          <Typography color="textSecondary">
            {this.getSplitTime(task.attributes.total_logged_hours)}
          </Typography>
        </TaskCard>
      ))
    ) : (
      <Typography>No logs in this time range</Typography>
    );
  }

  calendar() {
    const { selectedYear, selectedMonth, selectedDate } = this.state;
    const date = new Date(
      parseInt(selectedYear, 10),
      parseInt(selectedMonth, 10) - 1,
      parseInt(selectedDate, 10),
    );
    const formattedDate = moment(date).format("YYYY-MM-DD");

    return (
      <CalendarWrapper>
        <CalendarImage src={imgCal} />

        <CalendarInput
          type="date"
          data-test-id="calendar"
          value={formattedDate}
          onChange={(event) => this.selectCalendarDateWeb(event.target.value)}
        />
      </CalendarWrapper>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Title
          data-test-id="title"
          title={configJSON.labelTitleText}
          onBackClick={() => this.redirectToMainScreen()}
        />
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <OutlinedInput
              placeholder="Search with account_id"
              data-test-id="search"
              onChange={(event) =>
                this.txtSearchInputChange.onChangeText(event.target.value)
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />

            <Box sx={webStyle.dateTitle}>
              <Box sx={webStyle.bigDate}>
                <Typography
                  data-test-id="month-text"
                  variant="h4"
                  component="span">
                  {this.state.selectedMonthName +
                    ", " +
                    this.state.selectedYear}
                </Typography>
              </Box>

              {this.calendar()}
            </Box>

            <Box sx={webStyle.buttonsWrapper}>{this.rangeButtons()}</Box>

            <div style={webStyle.datesList} ref={this.datesListWeb}>
              {this.datesList()}
            </div>

            <Box>{this.tasksList()}</Box>
            {this.state.totalPage > 1 && (
              <Pagination
                data-test-id="pagination"
                count={this.state.totalPage}
                shape="rounded"
                onChange={(event, page) =>
                  this.setCurrentPage({} as PageItem, page - 1)
                }
              />
            )}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
    gap: 36,
  },
  text: {
    marginTop: 50,
    marginBottom: 24,
  },
  dateTitle: {
    display: "flex",
  },
  bigDate: {
    flex: "1 0 auto",
  },
  buttonsWrapper: {
    display: "flex",
    gap: 24,
  },
  datesList: {
    display: "flex",
    overflowX: "scroll",
    paddingBottom: 4,
    gap: 8,
  } as CSSProperties,
  daySelector: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 4,
  },
};
// Customizable Area End
