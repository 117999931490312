import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import * as Yup from "yup";
import { handleResponseMessage } from "../helpers/handle-response-message";
import createRequestMessage from "../helpers/create-request-message";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  description: string;
  status: string;
  isActive: boolean;
  token: string;
  isLoading: boolean;
  fetching: boolean;
  alertModal: boolean;
  titleModal: string;
  contentModal: string;
  success: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CertificateInsertController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCertApi: string;
  updateCertApi: string;
  getCertApi: string;
  initialCertId: number;
  defaultWebSchema: object;
  certCreateSchema: object;
  certUpdateSchema: object;
  statusList: string[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.initialCertId = (this.props.navigation?.getParam("id") as number) ?? 0;

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      name: "",
      description: "",
      status: "",
      isActive: true,
      token: "",
      isLoading: false,
      fetching: false,
      alertModal: false,
      titleModal: "",
      contentModal: "",
      success: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.handleSubmitCertWeb = this.handleSubmitCertWeb.bind(this);
    this.handleGoBackWeb = this.handleGoBackWeb.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    this.createCertApi = "";
    this.updateCertApi = "";
    this.getCertApi = "";

    this.statusList = ["available", "unavailable", "deletable", "undeletable"];

    this.defaultWebSchema = {
      name: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
    };

    this.certCreateSchema = Yup.object().shape({
      ...this.defaultWebSchema,
    });

    this.certUpdateSchema = Yup.object().shape({
      ...this.defaultWebSchema,
      isActive: Yup.boolean(),
    });
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequest = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseWebJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorWebJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      switch (apiRequest) {
        case this.getCertApi: {
          handleResponseMessage({
            responseJson: responseWebJson,
            errorJson: errorWebJson,
            onSuccess: () => {
              const { name, description, status, is_active } =
                responseWebJson.data;

              this.setState({
                name: name,
                description: description,
                status: status,
                isActive: is_active,
                fetching: false,
              });
            },
            onFail: () =>
              this.showAlert(
                `Get Certificate Information Failed`,
                "Please retry!",
              ),
          });
          break;
        }

        case this.createCertApi: {
          handleResponseMessage({
            responseJson: responseWebJson,
            errorJson: errorWebJson,
            onSuccess: () => {
              this.setState({
                alertModal: true,
                titleModal: "Success",
                success: true,
                contentModal: "Certificate created successfully!",
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Create Failed!",
              });
            },
          });
          break;
        }

        case this.updateCertApi: {
          handleResponseMessage({
            responseJson: responseWebJson,
            errorJson: errorWebJson,
            onSuccess: () => {
              this.setState({
                alertModal: true,
                titleModal: "Success",
                success: true,
                contentModal: "Certificate updated successfully!",
              });
            },
            onFail: () => {
              this.setState({
                alertModal: true,
                titleModal: "Fail",
                success: false,
                contentModal: "Update Failed!",
              });
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.handleGetTokenCertWeb();
  }

  async handleGetTokenCertWeb() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        if (this.initialCertId) {
          this.handleGetDetailCertWeb();
        }
      },
    );
  }

  handleGetDetailCertWeb() {
    this.setState({
      isLoading: true,
      fetching: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getCertApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.exampleAPiEndPoint}/${this.initialCertId}`,
      method: configJSON.getApiMethodType,
      token: this.state.token,
    });
  }
  handleCreateCertWeb(values: {
    name: string;
    description: string;
    status: string;
  }) {
    const { name, description, status } = values;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.createCertApi = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.exampleAPiEndPoint,
      method: configJSON.InsertApiMethodType,
      token: this.state.token,
      body: JSON.stringify({
        name: name,
        description: description,
        status: status,
        is_active: true,
      }),
    });
  }

  handleUpdateCertWeb(values: {
    name: string;
    description: string;
    status: string;
    isActive: boolean;
  }) {
    const { name, description, status, isActive } = values;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.updateCertApi = requestMessage.messageId;
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.exampleAPiEndPoint}/${this.initialCertId}`,
      method: configJSON.putApiMethodType,
      token: this.state.token,
      body: JSON.stringify({
        name: name,
        description: description,
        status: status,
        is_active: isActive,
      }),
    });
  }

  handleSubmitCertWeb(values: {
    name: string;
    description: string;
    status: string;
    isActive: boolean;
  }) {
    if (this.initialCertId) {
      this.handleUpdateCertWeb(values);
    } else {
      this.handleCreateCertWeb(values);
    }
  }

  handleCloseAlert() {
    this.setState({
      alertModal: false,
      titleModal: "",
      contentModal: "",
    });

    if (this.state.success) {
      this.props.navigation.navigate("CertificateList");
    }
  }

  handleGoBackWeb() {
    this.props.navigation.navigate("CertificateList");
  }
  // Customizable Area End
}
