// Customizable Area Start
// Customizable Area End
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { handleResponseMessage } from "../helpers/handle-response-message";
import createRequestMessage from "../helpers/create-request-message";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "../../../../framework/src/Utilities";
import { Employee } from "../types";

export const configEmpListJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  employees: Employee[];
  isLoading: boolean;
  token: string;
  needRetakeToken: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeesDataApi: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      employees: [],
      isLoading: false,
      token: "",
      needRetakeToken: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGetEmployeeList = this.handleGetEmployeeList.bind(this);
    this.handleEmployeeUpdate = this.handleEmployeeUpdate.bind(this);
    this.handleNavigateToEmployeeCreate =
      this.handleNavigateToEmployeeCreate.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.getEmployeesDataApi = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const empCreateApiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      this.setState({
        isLoading: false,
      });

      if (empCreateApiResponse === this.getEmployeesDataApi) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            const { employees } = responseJson;

            this.setState({
              employees: employees,
            });
          },
          onFail: () => {
            this.showAlert(`Get List Failed`, "Please retry!");
          },
        });
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleGetToken();
  }

  async handleGetToken() {
    const authToken = await getStorageData("token", false);
    this.setState(
      {
        token: authToken,
      },
      () => {
        this.handleGetEmployeeList();
      },
    );
  }

  handleGetEmployeeList() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getEmployeesDataApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configEmpListJSON.employeesURLEndPoint,
      method: configEmpListJSON.getApiMethodType,
      token: this.state.token,
    });
  }

  handleEmployeeUpdate(employee?: Employee) {
    if (employee) {
      this.props.navigation.navigate("EmployeeCreate", {
        id: employee.id,
      });
    }
  }

  handleNavigateToEmployeeCreate() {
    this.props.navigation.navigate("EmployeeCreate");
  }

  handleGoBack() {
    this.props.navigation.navigate("CertificationTracking");
  }

  // Customizable Area End
}
