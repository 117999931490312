import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Revision } from "./Types";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import UserIcon from "@material-ui/icons/Person";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000040",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import WikiRevisionController, { Props } from "./WikiRevisionController";
import { ContentState, EditorState } from "draft-js";
import Loader from "../../../components/src/Loader.web";

export default class WikiRevision extends WikiRevisionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  _renderItem = (revision: Revision, index: number) => {
    const splitDate = revision.created_at!.split("/");

    const exactDate = splitDate.join(",");

    const contentBlock = htmlToDraft(revision.wiki_data.content ?? "");
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    const editorState = EditorState.createWithContent(contentState);

    return (
      <Box key={revision.id}>
        <Box
          data-test-id={`userButton-${index}`}
          sx={webStyle.userBox}
          onClick={() => this.currentItemShown(revision.id)}>
          <Typography>Revision</Typography>
          <Typography>{exactDate}</Typography>
        </Box>

        {revision.is_shown && (
          <Box sx={webStyle.revisionBox}>
            <Box sx={webStyle.iconBox1}>
              <Box sx={webStyle.iconBox}>
                <UserIcon style={webStyle.icon} />
                <Typography>{revision.user}</Typography>
              </Box>
            </Box>
            <Editor
              editorStyle={webStyle.editorStyles}
              toolbarHidden
              editorState={editorState}
              readOnly
            />
          </Box>
        )}
      </Box>
    );
  };

  // Customizable Area End

  render() {
    const { loading, revisions, wikiID } = this.state;

    if (loading) {
      return <Loader loading />;
    }

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"lg"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography>Wiki Revision [{wikiID ?? "null"}]</Typography>
          </Box>
          <Box>
            {revisions.length > 0 ? (
              revisions.map((revision: Revision, index: number) =>
                this._renderItem(revision, index),
              )
            ) : (
              <Typography>No data found</Typography>
            )}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    position: "relative",
  },
  iconBox: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  iconBox1: { width: "20%" },
  editorStyles: {
    width: "100%",
    flex: 1,
    overflow: "hidden",
  },
  icon: { fontSize: 40 },
  revisionBox: {
    position: "relative",
    width: "100%",
    border: "1px solid black",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
  userBox: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 10,
  },
};
// Customizable Area End
