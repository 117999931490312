import React, { Fragment } from "react";

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import EmployeeCertListController, {
  Props,
} from "./EmployeeCertListController.web";
import { CertificateStatusKey, EmployeeCertificate } from "../types";
import { configJSON } from "./EmployeeCertListController";
import { employeeCertificateStatusObject } from "../constants";
import dayjs from "dayjs";
import FloatingButtonWeb from "../components/FloatingButton/FloatingButton.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const ListContainer = styled(List)({
  width: "100%",
});
const ListItemContainer = styled(ListItem)({
  display: "flex",
  flexDirection: "column",
});
const ItemContainer = styled(`div`)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});
// Customizable Area End

export default class EmployeeCertList extends EmployeeCertListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { employeeCertList, isLoading } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FloatingButtonWeb
          buttonType={"backward"}
          onClick={this.handleGoBackWeb}
        />
        <Container maxWidth={"sm"} data-test-id={"containerId"}>
          <Box sx={webSxStyle.mainWrapperStyle}>
            <Typography variant="h6">
              Employee Certification List{" "}
              {isLoading && <CircularProgress color="inherit" />}
            </Typography>
          </Box>
          <ListContainer>
            {employeeCertList.map(
              (item: EmployeeCertificate, index: number) => (
                <Fragment key={item.id!}>
                  <ListItemContainer
                    data-test-id={"listItemId"}
                    key={index}
                    role={undefined}
                    button
                    onClick={() => this.handleGoToDetailScreenWeb(item)}>
                    {[
                      {
                        labelWeb: configJSON.labelEmployee,
                        valueWeb: item.employee!.employee_name ?? "",
                      },
                      {
                        labelWeb: configJSON.labelCertificate,
                        valueWeb: item.certificate!.name ?? "",
                      },
                      {
                        labelWeb: configJSON.labelDate,
                        valueWeb: !item.date
                          ? "Not Defined"
                          : dayjs(item.date).format("MM/DD/YYYY"),
                      },
                      {
                        labelWeb: configJSON.labelValidityDate,
                        valueWeb: !item.validity_date
                          ? "Not Defined"
                          : dayjs(item.validity_date).format("MM/DD/YYYY"),
                      },
                      {
                        labelWeb: configJSON.labelStatus,
                        valueWeb:
                          employeeCertificateStatusObject[
                            item.status! as CertificateStatusKey
                          ] &&
                          employeeCertificateStatusObject[
                            item.status! as CertificateStatusKey
                          ].name,
                      },
                    ].map(
                      (keyValue: { labelWeb: string; valueWeb: string }) => (
                        <ItemContainer
                          key={`${keyValue.labelWeb}_${keyValue.valueWeb}`}>
                          <Typography>{keyValue.labelWeb}</Typography>
                          <Typography>{keyValue.valueWeb}</Typography>
                        </ItemContainer>
                      ),
                    )}
                  </ListItemContainer>
                  <Divider variant="inset" component="li" />
                </Fragment>
              ),
            )}
          </ListContainer>
        </Container>
        <FloatingButtonWeb
          buttonType={"add"}
          onClick={this.handleGoToCreateScreenWeb}
        />
      </ThemeProvider>
      // Customizable Area End
    ); /*  */
  } /*  */
}

// Customizable Area Start
const webSxStyle = {
  mainWrapperStyle: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};
// Customizable Area End
