import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import ScreenSharingController, { Props } from "./ScreenSharingController";
import MeetScreenWeb from "./MeetScreenWeb";

export default class ScreenSharing extends ScreenSharingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={webStyle.container}>
          <MeetScreenWeb token={this.state.token} />
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#555",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
// Customizable Area End
