import * as React from "react";
import { ChangeEvent, PropsWithChildren, ReactElement } from "react";
import { TextField } from "@material-ui/core";

export function FileTextInput(
  props: PropsWithChildren<FileTextInputProps>,
): ReactElement {
  const { error, errorLabel, value, onChange, label, dataTestId, onBlur } =
    props;

  return (
    <TextField
      data-test-id={dataTestId}
      id="standard-full-width"
      label={label}
      error={error}
      helperText={errorLabel}
      multiline
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      variant="filled"
      fullWidth={true}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export interface FileTextInputProps {
  error: boolean;
  errorLabel: string;
  value: string | undefined;
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  label: string;
  dataTestId?: string;
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

FileTextInput.defaultProps = {
  //
};

export default FileTextInput;
