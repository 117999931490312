import React from "react";

import { Box, Button, Container, Typography } from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  imgFacebook,
  imgInstagram,
  imgReferalWeb,
  imgTwitter,
  imgWhatsapp,
} from "./assets";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import ReferralsController, { Props } from "./ReferralsController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      color: "#000000",
      textAlign: "center",
      fontSize: "24px",
      margin: "20px 0 20px 0",
      minHeight: "30px",
    },
    subtitle1: {
      color: "#000000",
      textAlign: "center",
      fontSize: "18px",
      marginTop: "20px",
    },
    subtitle2: {
      textAlign: "center",
      fontSize: "18px",
      color: "#ffffff",
      paddingTop: "15px",
    },
  },
});
// Customizable Area End

export default class Referrals extends ReferralsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const socialURL: string = `https://example.com/id=${this.state.referralCode}`;
    const message: string = `Hi! Please join me! You can use my code: ${this.state.referralCode}`;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"}>
          <div style={webStyle.backgroundContent}>
            <Typography variant="subtitle2" component="div">
              Refer a Friend
            </Typography>
            <img
              style={webStyle.imgContainer}
              src={imgReferalWeb}
              alt="Refer a friend!"
            />
          </div>
          <Typography variant="subtitle1" component="div">
            Your Referral Code
          </Typography>
          <Typography variant="h6" component="div">
            {this.state.referralCode}
          </Typography>
          <Box sx={webStyle.buttonStyle}>
            <Button fullWidth color={"primary"}>
              Invite Friend
            </Button>
          </Box>
          <div style={webStyle.iconContainer}>
            <div style={webStyle.iconsView}>
              <FacebookShareButton url={socialURL} quote={message}>
                <img
                  src={imgFacebook}
                  style={webStyle.socialImg}
                  alt="Facebook"
                />
              </FacebookShareButton>
            </div>
            <div style={webStyle.iconsView}>
              <WhatsappShareButton url={socialURL} title={message}>
                <img
                  src={imgWhatsapp}
                  style={webStyle.socialImg}
                  alt="Whatsapp"
                />
              </WhatsappShareButton>
            </div>
            <div style={webStyle.iconsView}>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src={imgInstagram}
                  style={webStyle.socialImg}
                  alt="Instagram"
                />
              </a>
            </div>
            <div style={webStyle.iconsView}>
              <TwitterShareButton url={socialURL} title={message}>
                <img
                  src={imgTwitter}
                  style={webStyle.socialImg}
                  alt="Twitter"
                />
              </TwitterShareButton>
            </div>
          </div>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  socialImg: {
    width: "50px",
    padding: "5px",
  },
  buttonStyle: {
    height: "45px",
    marginBottom: "30px",
    border: "none",
    backgroundColor: "rgb(158, 79, 231)",
    display: "flex",
    textAlign: "center",
    color: "#ffffff",
    margin: "auto",
    maxWidth: "150px",
  },
  socialMediaContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  textHeading: {
    textAlign: "center",
    fontSize: "18px",
    color: "#ffffff",
    paddingTop: "15px",
  },
  backgroundContent: {
    backgroundColor: "rgb(158, 79, 231)",
    height: "60%",
  },
  imgContainer: {
    alignItems: "center",
    display: "block",
    justifyContent: "center",
    margin: "auto",
    marginTop: "50px",
    width: "20rem",
  },
  iconsView: {
    elevation: 4,
    marginLeft: 12,
    width: 60,
    height: 60,
    backgroundColor: "#A04EEC",
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    margin: "40px auto 40px auto",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};
// Customizable Area End
