Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ItemAvailability";
exports.labelBodyText = "ItemAvailability Body";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.getItemsURL = exports.cataloguesDataURL =
  "bx_block_item_availability/item_availabilities";
exports.cataloguesItemDataURL =
  "bx_block_item_availability/item_availabilities";
exports.loginInApiEndPoint = "bx_block_login/logins";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
