import React from "react";

import {
  Container,
  Box,
  Button,
  InputLabel,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import PledgeCreationFormController, {
  Props,
} from "./PledgeCreationFormController.web";
import InputComponent from "./components/Input/InputComponent.web";
import TitleComponent from "./components/Title/TitleComponent.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7F70F4",
      contrastText: "#fff",
    },
  },
});

export default class PledgeCreationForm extends PledgeCreationFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <TitleComponent
          title="Pledge Creation form"
          onBackClick={this.redirectToFundraiser}
        />
        <Container maxWidth="sm">
          <Box sx={webStyle.inputWrapper}>
            <InputLabel>Title*</InputLabel>
            <InputComponent
              data-test-id="title"
              value={this.state.title}
              onChange={(event) => this.onChangeTitle(event.target.value)}
              errorTitle={this.state.errors.title}
            />
          </Box>

          <Box sx={webStyle.inputWrapper}>
            <InputLabel>Description</InputLabel>
            <InputComponent
              data-test-id="description"
              value={this.state.description}
              onChange={(event) => this.onChangeDescription(event.target.value)}
              multiline
              minRows={4}
            />
          </Box>

          <Box sx={webStyle.inputWrapper}>
            <InputLabel>Amount*</InputLabel>
            <InputComponent
              data-test-id="amount"
              value={this.state.amount}
              onChange={(event) => this.onChangeAmount(event.target.value)}
              type="number"
              errorTitle={this.state.errors.amount}
            />
          </Box>

          <Box sx={webStyle.buttonWrapper}>
            <Button
              data-test-id="cancel"
              variant="contained"
              onClick={() => this.redirectToFundraiser()}>
              Cancel
            </Button>
            <Button
              data-test-id="submit"
              variant="contained"
              color="primary"
              onClick={() => this.createPledge()}>
              Done
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputWrapper: {
    marginTop: "24px",
  },
  buttonWrapper: {
    display: "flex",
    gap: 16,
    marginTop: 24,
  },
};
// Customizable Area End
